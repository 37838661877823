<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import RealEstateCollateral from "../classes/DB_Entities/RealEstateCollateral";
import { ElMessage } from "element-plus";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import { useMOSAPIStore } from "@/store/mos_api";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
const props = defineProps<{
  re_collateral_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const { t } = useI18n({ useScope: "global" });
const loanAgreementsList = ref<any[]>([]);
const realEstatesList = ref<any[]>([]);
const TypeofPrenotationList = ref<any>([]);
const trueFalseList = ref<any>([]);
const disabledRE = ref(false);

const {
  cleanObject,
  fullObj,
  hasPendingChanges,
  table_name,
  clickAcceptAll,
  close,
  loadData,
  resetObjects,
  save,
  showError,
  setup_decision_listener,
} = useGenericMethodsVariables();

watch(
  () => props.re_collateral_id,
  async (newVal, oldVal) => {
    //console.log("Got re_collateral_id -> " + newVal);

    if (props.re_collateral_id === undefined || props.re_collateral_id == null) return;
    mainStore.isLoading = true;
    await loadSelects();
    await loadLoanAgreements();
    await loadRealEstates();

    resetObjects();

    if (props.newEntity) return;

    await loadData(props.re_collateral_id as number);
    await fixPendingSelects();
    mainStore.isLoading = false;
  }
);

onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  table_name.value = RealEstateCollateral.getTableNameStatic();
  cleanObject.value = new RealEstateCollateral();

  //console.log("re_collateral_id", props.re_collateral_id, "newEntity", props.newEntity);

  await loadSelects();
  await loadLoanAgreements();
  await loadRealEstates();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  mainStore.isLoading = true;
  await loadData(props.re_collateral_id);
  await fixPendingSelects();
  mainStore.isLoading = false;
});

const loadLoanAgreements = async () => {
  loanAgreementsList.value = [];

  //  result = await .get("loan_agreement?select=*,debtor!inner(*)&approve_status=eq.1");
  // if (mainStore.loggedUser.user_type === 2) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 1) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 0) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  // }
  const result = await mosapi.get_view(UIViews.LoanAgreementView2, { debtor_approve_status: 1, approve_status: 1 });
  console.log(result.data);
  if (result.error) {
    showError(result.error);
    return;
  }
  //console.log(result);
  for (let i = 0; i < result.data.length; i++) {
    loanAgreementsList.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs + " (" + result.data[i].fullname + ")",
    });
  }
};

const loadRealEstates = async () => {
  realEstatesList.value = [];

  //const real_estate = (await .get(`re_collaterals?select=real_estate_id,real_estate!inner(id,approve_status)&id=eq.${props.re_collateral_id}`)).data[0]?.real_estate;
  const real_estate = (await (mosapi.generic_entity_get(Entities.RECollateral, { id: props.re_collateral_id })));
  console.log(props.re_collateral_id);
  if (real_estate?.data[0]?.approve_status === 3) {
    disabledRE.value = true;
    const result = await mosapi.generic_entity_get(Entities.RealEstate, { id: real_estate.data[0].real_estate_id });
    realEstatesList.value.push({
      value: result.data[0].id,
      label:
        result.data[0].arithmos_akinitou_diax +
        " (" +
        result.data[0].debtor.fullname +
        ")",
    });
    // result = await .get(
    //   "real_estate?select=*,debtor!inner(*)&id=eq." + real_estate.id
    // ); // Retrieve only the linked real estate, user is unable to alter the field
  } else {
    disabledRE.value = false;
    //let result = await mosapi.GetRealEstateApprovedStatus();
    let result = await mosapi.generic_entity_get(Entities.RealEstate, {});
    if (result.error) {
      showError(result.error);
      return;
    }
    for (let i = 0; i < result.data.length; i++) {
      realEstatesList.value.push({
        value: result.data[i].id,
        label:
          result.data[i].arithmos_akinitou_diax +
          " (" +
          result.data[i].debtor.fullname +
          ")",
      });
    }
    // result = await .get(
    //   "real_estate?select=*,debtor!inner(*)&approve_status=eq.1"
    // ); // Only approved real estates
  }


};

const onPreSave = async () => {
  if (props.newEntity as boolean) {
    if (fullObj.value.loan_agreement_id.originalValue === 0) {
      ElMessage({
        showClose: true,
        message: t("errors.missing_loan_agreement"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    if (fullObj.value.real_estate_id.originalValue === 0) {
      ElMessage({
        showClose: true,
        message: t("errors.missing_real_estate"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    if (fullObj.value.meteggrafh_se_ypothikof_ktimat_graf.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_meteggrafh_se_ypothikof_ktimat_graf"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    // if (fullObj.value.ekxor_asfalist.originalValue === "") {
    //   ElMessage({
    //     showClose: true,
    //     message: t("errors.missing_ekxor_asfalist"),
    //     type: "warning",
    //     duration: 1500,
    //   });
    //   return;
    // }
  }

  // if (
  //   fullObj.value.lhksh_asfalistiriou.originalValue === "" ||
  //   fullObj.value.lhksh_asfalistiriou.originalValue === null
  // ) {
  //   ElMessage({
  //     showClose: true,
  //     message: t("errors.missing_lhksh_asfalistiriou"),
  //     type: "warning",
  //     duration: 1500,
  //   });
  //   return;
  // }

  if (
    fullObj.value.real_estate_id.originalValue === "" ||
    fullObj.value.real_estate_id.originalValue === null
  ) {
    ElMessage({
      showClose: true,
      message: t("errors.missing_real_estate_id"),
      type: "warning",
      duration: 1500,
    });
    return;
  }

  delete fullObj.value.loan_agreement;
  delete fullObj.value.real_estate;

  await save();
  resetObjects();
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    // Loan Agreement
    if (field === "loan_agreement_id") {
      // const loan1 = await .get(
      //   `loan_agreement?select=ar_sumbashs&id=eq.` +
      //   fullObj.value[field].pendingValue.value
      // );
      const loan1 = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: fullObj.value[field].pendingValue.value });
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${loan1.data[0].debtor.cid} (${loan1.data[0].debtor.fullname})`;

      // const loan2 = await .get(
      //   `loan_agreement?select=ar_sumbashs&id=eq.` + fullObj.value[field].originalValue
      // );
      const loan2 = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: fullObj.value[field].originalValue });
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${loan2.data[0].debtor.cid} (${loan2.data[0].debtor.fullname})`;
    }

    // Real Estate
    if (field === "real_estate_id") {
      // const re1 = await .get(
      //   `real_estate?select=arithmos_akinitou_diax&id=eq.` +
      //   fullObj.value[field].pendingValue.value
      // );
      const re1 = await mosapi.generic_entity_get(Entities.RealEstate, { id: fullObj.value[field].pendingValue.value });
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${re1.data[0].debtor.cid} (${re1.data[0].debtor.fullname})`;

      // const re2 = await .get(
      //   `real_estate?select=arithmos_akinitou_diax&id=eq.` +
      //   fullObj.value[field].originalValue
      // );
      const re2 = await mosapi.generic_entity_get(Entities.RealEstate, { id: fullObj.value[field].originalValue });
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${re2.data[0].debtor.cid} (${re2.data[0].debtor.fullname})`;
    }

    if (field === "eidos_prosim") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = TypeofPrenotationList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = TypeofPrenotationList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }

    if (
      field === "cession_of_insurance_policy" ||
      field === "meteggrafh_se_ypothikof_ktimat_graf"
    ) {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = trueFalseList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue["pendingDisplayValue"] = trueFalseList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }
  if (["meteggrafh_se_ypothikof_ktimat_graf", "ekxor_asfalist"].includes(field)) {
  if (
    fullObj.value[field]?.pendingValue?.value !== undefined &&
    fullObj.value[field]?.pendingValue?.value !== null
  ) {
    fullObj.value[field].pendingValue["pendingDisplayValue"] =
      String(fullObj.value[field].pendingValue.value) === "true"
        ? t("global.true")
        : t("global.false");

    fullObj.value[field].pendingValue["originalDisplayValue"] =
      Boolean(fullObj.value[field].originalValue)
        ? t("global.true")
        : t("global.false");
  }
}
  }
};

const loadSelects = async () => {
  TypeofPrenotationList.value = [
    {
      value: "sunenetikh", // proshmeiwsh
      label: t("TypeofPrenotation.sunenetikh"),
    },
    {
      value: "anagkastikh", // proshmeiwsh
      label: t("TypeofPrenotation.anagkastikh"),
    },
    {
      value: "sunenetikh_ypothiki",
      label: t("TypeofPrenotation.sunenetikh_ypothiki"),
    },
    {
      value: "anagkastikh_ypothiki",
      label: t("TypeofPrenotation.anagkastikh_ypothiki"),
    },
  ];

  trueFalseList.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];
};

</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only"
        @accept="clickAcceptAll(props.re_collateral_id)" />
      <p />

      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.loan_agreement_id"
            :label="$t('real_estate_collateral.loan_agreement_number')" :options="loanAgreementsList"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.real_estate_id"
            :label="$t('real_estate_collateral.real_estate_nbr')" :options="realEstatesList"
            :is-disabled="props.view_only || disabledRE" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.collateral_number')"
            :object="fullObj.ar_kalummatos" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.original_collateral_nbr')"
            :object="fullObj.arxikos_arith_kalummatos" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos_prosim"
            :label="$t('real_estate_collateral.type_of_prenotation')" :options="TypeofPrenotationList"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.lien')" :object="fullObj.seira_prosim"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('real_estate_collateral.prenotation_amount')"
            :object="fullObj.poso_prosim" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('real_estate_collateral.total_prenotation_amount')"
            :object="fullObj.sunoliko_poso_prosim" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.perc_of_participation')"
            :object="fullObj.pososto_summetoxhs" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.prenotation_summary')"
            :object="fullObj.perilhps_eggrafhs_prosim" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.meteggrafh_se_ypothikof_ktimat_graf"
            :label="$t('real_estate_collateral.transfer_of_prenot_to_cadastral')" :options="trueFalseList"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.volume')" :object="fullObj.tomos"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.page')" :object="fullObj.fullo"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.number')" :object="fullObj.number"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.insurer')" :object="fullObj.asfal_foreas"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.insurance_policy_nbr')"
            :object="fullObj.ar_asfal" :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.ekxor_asfalist"
            :label="$t('real_estate_collateral.cession_of_insurance_policy')" :options="trueFalseList"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          {{ $t('real_estate_collateral.legal_status') }} : Legal Status Loan Agreement *********
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('real_estate_collateral.comments')" :object="fullObj.comments"
            :is-disabled="props.view_only" />
        </el-col>
        <!-- <el-col :span="24">
          <EntityField type="datepicker" :label="$t('real_estate_collateral.lhksh_asfalistiriou')"
            :object="fullObj.lhksh_asfalistiriou" :is-disabled="props.view_only" />
        </el-col> -->
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
