<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import Project from "../classes/DB_Entities/Projects";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "@/store/mos_api";

const mosapi = useMOSAPIStore();
const props = defineProps<{
	projectid: number;
	newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const data = ref<Project>(new Project());
const projectsTemplatesList = ref<any>([]);

const {
	showError,
	close,
} = useGenericMethodsVariables();

onMounted(async () => {
	mainStore.isLoading = true;
	console.log("project", props.projectid, "newEntity", props.newEntity);

	// await getUsers();
	// await getTasks();
	await getProjectTemplates();

	if (props.newEntity === true) {
		data.value.project_template_id = null;
		mainStore.isLoading = false;
		return;
	}

	await loadData();
	mainStore.isLoading = false;
});

const onPreSave = async () => {
	//   console.log(JSON.stringify(fullObj.value));
	//VALIDATION CHECKS
	await save();
};

const getProjectTemplates = async () => {
	//result = await .get("project_templates?status=eq.1");
	const result = await mosapi.generic_entity_get(Entities.ProjectTemplates, {status: 1});
	if (result.error) {
		showError(result.error);

		return;
	}

	projectsTemplatesList.value = result.data;
	//storeProjectTemplates.value = result.data;
	// console.log(
	//   "projectsTemplatesList: ",
	//   JSON.stringify(projectsTemplatesList.value)
	// )
};

const loadData = async () => {
	mainStore.isLoading = true;

	//result = await .get("projects?id=eq." + props.projectid);
	const result = await mosapi.generic_entity_get(Entities.Projects, {id: props.projectid});
	if (result.error) {
		showError(result.error);
		return;
	}

	data.value = result.data[0];

	mainStore.isLoading = false;
};

const save = async () => {

	if (props.newEntity) {
		if (data.value.project_template_id == null) {
			showError("Please select a project template");
			return;
		}
		console.log('posting', JSON.stringify(data.value));
		const obj = JSON.parse(JSON.stringify(data.value));
		delete obj.id;
		if (obj.hmeromhnia_anathesis == null || obj.hmeromhnia_anathesis == "") {
			delete obj.hmeromhnia_anathesis;
		}
		console.log("obj.date" + obj.hmer_anatheshs)
		//const result = await .post_data("projects", obj);
		const result = await mosapi.generic_entity_post(Entities.Projects, obj);
		//console.log("result:" + result.data[0].id);

		//data.value.project_template_id
		//let objTask = new ProjectTask
		const objTasks = projectsTemplatesList.value.find((item: any) => { return item.id === data.value.project_template_id }).task_templates_ids;
		for (let i = 0; i < objTasks.length; i++) {
			const projectTask = await mosapi.generic_entity_post(Entities.ProjectTasks, {
				project_id: result.data[0].id,
				task_template_id: objTasks[i]
			})
			// const projectTask = await .post_data("project_tasks", {
			// 	project_id: result.data[0].id,
			// 	task_template_id: objTasks[i]
			// });

			//const taskTemplate = await .get("task_templates?id=eq." + objTasks[i])
			const taskTemplate = await mosapi.generic_entity_get(Entities.TaskTemplates, {id: objTasks[i]});

			for (const field_id of taskTemplate.data[0].fields_ids) {
				//const field = await .get("custom_fields?id=eq." + field_id);
				const field = await mosapi.generic_entity_get(Entities.CustomFields, {id:field_id});
				// const projectTaskValue = await .post_data("project_task_values", {
				// 	project_task_id: projectTask.data[0].id,
				// 	task_variable: field.data[0].id,
				// 	task_variable_value: ""
				// })
				const projectTaskValue = await mosapi.generic_entity_post(Entities.ProjectTaskValues, {
					project_task_id: projectTask.data[0].id,
					task_variable: field.data[0].id,
					task_variable_value: ""
				});
			}
		}
	} else {
		//console.log('patching', JSON.stringify(data.value));
		const projectid = props.projectid as number;
		// const result = await .patch("projects?id=eq." + props.projectid, {
		// 	customer: data.value.customer,
		// 	customer_code: data.value.customer_code,
		// 	hmeromhnia_anathesis: data.value.hmeromhnia_anathesis
		// });
		const result = await mosapi.generic_entity_patch(Entities.Projects, projectid, {
			customer: data.value.customer,
			customer_code: data.value.customer_code,
			hmeromhnia_anathesis: data.value.hmeromhnia_anathesis
		})
	}
	emits("save");
}

const filterMethod = (query: string, item: any) => {
	return item.label.toLowerCase().includes(query.toLowerCase())
}

</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item :label="$t('ProjectsList.projectTemplate')">
						<el-select v-model="data.project_template_id as number" class="m-2 header-filter" size="small"
							clearable filterable :disabled=!props.newEntity placeholder=" ">
							<el-option v-for="item in projectsTemplatesList" :key="item.id" :label="item.name_el"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('ProjectsList.customer')">
						<el-input v-model="data.customer" autocomplete="off" />
					</el-form-item>
					<el-form-item :label="$t('ProjectsList.customer_code')">
						<el-input v-model="data.customer_code" autocomplete="off" />
					</el-form-item>
					<el-form-item :label="$t('ProjectsList.hmer_anatheshs')">
						<el-date-picker v-model="data.hmeromhnia_anathesis" autocomplete="off" format="DD-MM-YYYY"
							value-format="YYYY-MM-DD" />
					</el-form-item>

				</el-col>



				<p></p><br><br><br><br><br>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {
	margin: 0px !important;
}

.el-transfer {
	--el-transfer-panel-width: 400px;
}
</style>
