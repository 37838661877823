import RealEstate from "./RealEstate";
import LoanAgreement from "./LoanAgreement";
import ITableName from "../../interfaces/ITableName";

class RealEstateCollateral implements ITableName {
  id: number = -1;
  loan_agreement_id: string = "";
  ar_kalummatos: string = "";
  arxikos_arith_kalummatos: string = "";
  real_estate_id: string = "";
  eidos_prosim: string = "";
  seira_prosim: string = "";
  poso_prosim: string = "";
  sunoliko_poso_prosim: string = "";
  pososto_summetoxhs: string = "";
  perilhps_eggrafhs_prosim: string = "";
  meteggrafh_se_ypothikof_ktimat_graf: boolean = false;
  tomos: string = "";
  fullo: string = "";
  number: string = "";
  asfal_foreas: string = "";
  ar_asfal: string = "";
  ekxor_asfalist: boolean = false;
  comments: string = "";
  pistop_ypoth_ktimatol: string = "";
  lhksh_asfalistiriou: string = "";
  loan_agreement: LoanAgreement = new LoanAgreement();
  real_estate: RealEstate = new RealEstate();
  lockedrow: boolean = false;
  static getTableNameStatic(): string {
    return "re_collaterals";
  }

  getTableName(): string {
    return RealEstateCollateral.getTableNameStatic();
  }
}

export default RealEstateCollateral;
