<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { ElMessage } from "element-plus";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import LoanAgreement from "@/classes/DB_Entities/LoanAgreement";
import Debtor from "@/classes/DB_Entities/Debtor";

const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const filtersTEMP = ref<any>({
	editor: "",
	approver: "",
	status: "",
});
const filters = ref<any>({
	editor_user_id: "",
	fieldname: "",
	approval_status: "",
	approver_user_id: ""
});

const filterEntity = ref<string>("");
const dataList = ref<any[]>([]);
const dataCount = ref(0);
const lastPage = ref(0);
const selectedEntity = ref<string>("");

const entitiesList = [
	{
		label: "Approvals",
		value: Entities.EntityChangesApprovals,
	},
	{
		label: "Auctions",
		value: Entities.EntityChangesAuctions,
	},
	{
		label: "Clients",
		value: Entities.EntityChangesMOSClients,
	},
	{
		label: "Debtors",
		value: Entities.EntityChangesDebtors,
	},
	{
		label: "Greek State Guarantees",
		value: Entities.EntityChangesGreekStateGuarantees,
	},
	{
		label: "Guarantees",
		value: Entities.EntityChangesGuarantees,
	},
	{
		label: "Loan Accounts",
		value: Entities.EntityChangesLoanAccount,
	},
	{
		label: "Loan Agreements",
		value: Entities.EntityChangesLoanAgreement,
	},
	{
		label: "Portfolios",
		value: Entities.EntityChangesPortfolios,
	},
	{
		label: "Real Estate",
		value: Entities.EntityChangesRealEstate,
	},
	{
		label: "Real Estate Collateral",
		value: Entities.EntityChangesRECollateral,
	},
	{
		label: "Register of Bonds",
		value: Entities.EntityChangesROBBond,
	},
	{
		label: "Register of Bonds Banks",
		value: Entities.EntityChangesROBParticipatingBank,
	},
	{
		label: "Register of Bonds Titles",
		value: Entities.EntityChangesROBTitle,
	},
	{
		label: "Rest of Collateral",
		value: Entities.EntityChangesNRECollateral,
	},
	{
		label: "Summary Check",
		value: Entities.EntityChangesSummaryCheck,
	},
];

const timer = ref<any>(null);
const filterEditor = ref<string>("");
const statusOptions = [
	{
		value: "0",
		label: "approval_status_null",
	},
	{
		value: "1",
		label: "approval_status_accepted",
	},
	{
		value: "2",
		label: "approval_status_rejected",
	},
];
onMounted(async () => { });

const loadPage = async (page: number) => {
	mainStore.isLoading = true;

	lastPage.value = page;
	page = page - 1;

	if (filterEditor.value !== "") {
		//   `users?select=id&username=eq.${filterEditor.value}`,
		const filtereditorresult = await mosapi.getUserByUsername(filterEditor.value);
		/* if (filtereditorresult.data.length > 0) {
		   innerFilterUrl += `&editor_user_id=eq.${filtereditorresult.data[0].id}`;
		 } else {
		   innerFilterUrl += `&editor_user_id=eq.0`;
		 }*/
		console.log('user id: ' + JSON.stringify(filtereditorresult))
	}
	/*
	if (filterStatus.value !== "") {
	  innerFilterUrl += `&approval_status=eq.${filterStatus.value}`;
	}
	if (filterFieldname.value !== "") {
	  innerFilterUrl += `&field=ilike.*${filterFieldname.value}*`;
	}
	if (filterApprover.value !== "") {
	  const filterapproverresult:  = await .getPaginated(
		`users?select=id&username=eq.${filterApprover.value}`,
		page
	  );
	  if (filterapproverresult.data.length > 0) {
		innerFilterUrl += `&approver_user_id=eq.${filterapproverresult.data[0].id}`;
	  } else {
		innerFilterUrl += `&approver_user_id=eq.0`;
	  }
	}*/
	if (filterEntity.value !== "") {
		//   `entity_${filterEntity.value}_changes?select=*,editor:fk_user_editor(username),approver:fk_user_approver(username)${filterURL}${innerFilterUrl}&order=insert_timestamp.desc`,
		const result = await mosapi.generic_entity_get<any[]>(filterEntity.value as Entities, { ...filters.value, orderBy: 'insert_timestamp.desc' });

		if (result.error) {
			ElMessage({
				showClose: true,
				message: result.error,
				type: "error",
			});

			return;
		}

		for (let i = 0; i < result.data.length; i++) {
			if (result.data[i].approval_status == 1) {
				result.data[i].approval_status = "Accepted";
			} else if (result.data[i].approval_status == 2) {
				result.data[i].approval_status = "Rejected";
			} else {
				result.data[i].approval_status = "-";
			}
			if (filterEntity.value === Entities.EntityChangesNRECollateral) {
				result.data[i].entity = result.data[i].rest_of_collaterals.ar_kalimatos;
			}
			if (filterEntity.value === Entities.EntityChangesPortfolios) {
				result.data[i].entity = result.data[i].portfolio.portf_name;
			}
			if (filterEntity.value === Entities.EntityChangesRECollateral) {
				result.data[i].entity = result.data[i].re_collaterals.ar_kalummatos;
			}
			if (filterEntity.value === Entities.EntityChangesRealEstate) {
				result.data[i].entity = result.data[i].real_estate.arithmos_akinitou_diax;
			}
			if (filterEntity.value === Entities.EntityChangesLoanAgreement) {
				result.data[i].entity = result.data[i].loan_agreement.ar_sumbashs;
			}
			if (filterEntity.value === Entities.EntityChangesGuarantees) {
				result.data[i].entity = result.data[i].guarantees.debtor_cid;
			}
			if (filterEntity.value === Entities.EntityChangesDebtors) {
				result.data[i].entity = result.data[i].debtor.cid;
			}
			if (filterEntity.value === Entities.EntityChangesMOSClients) {
				result.data[i].entity = result.data[i].mosclients.clientname;
			}
			if (filterEntity.value === Entities.EntityChangesSummaryCheck) {
				const result2 = await mosapi.generic_entity_get<LoanAgreement[]>(Entities.LoanAgreement, { id: result.data[i].summary_check_xtra.loan_agreement_id })
				result.data[i].entity = result2.data[0].ar_sumbashs;
			}
			if (filterEntity.value === Entities.EntityChangesApprovals) {
				const result2 = await mosapi.generic_entity_get<LoanAgreement[]>(Entities.LoanAgreement, { id: result.data[i].approvals.loan_agreement_id })
				result.data[i].entity = result2.data[0].ar_sumbashs;
			}
			if (filterEntity.value === Entities.EntityChangesROBBond) {
				const result2 = await mosapi.get_view<any>(UIViews.RegisterOfBondsFullBonds, { rob_bond_id: result.data[i].rob_bonds.id })
				result.data[i].entity = result2.data[i].ar_sumbashs;
			}
			if (filterEntity.value === Entities.EntityChangesROBParticipatingBank) {
				const result2 = await mosapi.generic_entity_get<any[]>(Entities.ROBParticipatingBank, { rob_bond_id: result.data[i].entity_id })
				const result3 = await mosapi.generic_entity_get<any[]>(Entities.RegisterOfBonds, { rob_bond_id: result2.data[i].register_of_bond_id })
				result.data[i].entity = result3.data[i].loan_agreement.ar_sumbashs;
			}
			if (filterEntity.value === Entities.EntityChangesGreekStateGuarantees) {
				const result2 = await mosapi.generic_entity_get<LoanAgreement[]>(Entities.LoanAgreement, { id: result.data[i].greek_state_guarantees.loan_agreement_id })
				result.data[i].entity = result2.data[0].ar_sumbashs;
			}
			if (filterEntity.value === Entities.EntityChangesAuctions) {
				const result2 = await mosapi.generic_entity_get<Debtor[]>(Entities.Debtors, { id: result.data[i].auctions.debtor_id })
				result.data[i].entity = result2.data[0].cid;
			}
			if (filterEntity.value === Entities.EntityChangesLoanAccount) {
				result.data[i].entity = result.data[i].loan_accounts.ar_trexontos_logariasmou;
			}

			if (filterEntity.value === Entities.EntityChangesROBTitle) {
				const result1c = await mosapi.generic_entity_get<any[]>(Entities.ROBParticipatingBank, { id: result.data[0].rob_titles.rob_participating_bank_id })
				const result1d = await mosapi.generic_entity_get<any[]>(Entities.RegisterOfBonds, { id: result1c.data[0].register_of_bond_id })
				const result2 = await mosapi.generic_entity_get<any[]>(Entities.LoanAgreement, { id: result1d.data[0].loan_agreement_id })
				result.data[i].entity = result2.data[0].ar_sumbashs;
			}

			if (filterEntity.value === Entities.EntityChangesRegisterOfBonds) {
				const result1b = await mosapi.generic_entity_get<any[]>(Entities.ROBTitle, { id: result.data[i].rob_bonds.rob_title_id })
				const result1c = await mosapi.generic_entity_get<any[]>(Entities.ROBParticipatingBank, { id: result1b.data[0].rob_participating_bank_id })
				const result1d = await mosapi.generic_entity_get<any[]>(Entities.RegisterOfBonds, { id: result1c.data[0].register_of_bond_id })
				const result2 = await mosapi.generic_entity_get<any[]>(Entities.LoanAgreement, { id: result1d.data[0].loan_agreement_id })
				result.data[i].entity = result2.data[0].ar_sumbashs;
			}
		}
		dataList.value = result.data;
		dataCount.value = result.total;
	}
	mainStore.isLoading = false;
};

const filterEntityChanged = async (value: string) => {
	if (filterEntity.value === "") return;
	for (const item of entitiesList) {
		if (item.value === value) {
			selectedEntity.value = item.label;
			break;
		}
	}
	loadPage(1);
};
const filterChanged = async () => {
	if (filtersTEMP.value.approver !== '') {
		console.log(filtersTEMP.value.approver);
		const tempRes = await mosapi.getUserByUsername(filtersTEMP.value.approver);
		filters.value.approver_user_id = tempRes.data.id
		if (filters.value.approver_user_id === undefined) filters.value.approver_user_id = 0;
		console.log(filters.value.approver_user_id);
	}
	if (filtersTEMP.value.editor !== '') {
		console.log(filtersTEMP.value.editor);
		const tempRes2 = await mosapi.getUserByUsername(filtersTEMP.value.editor);
		console.log(JSON.stringify(tempRes2))
		filters.value.editor_user_id = tempRes2.data.id;
		if (filters.value.editor_user_id === undefined) filters.value.editor_user_id = 0;
		console.log(filters.value.editor_user_id);
	}
	if (filtersTEMP.value.status !== '') {
		console.log(filtersTEMP.value.status);

		filters.value.approval_status = filtersTEMP.value.status
		console.log(filters.value.approval_status);
	}
	if (timer.value !== 0) {
		clearTimeout(timer.value);
	}

	timer.value = setTimeout(() => {
		loadPage(1);

		timer.value = 0;
	}, 500);
};
</script>

<template>
  <div class="content-wrapper">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          Entity Changes {{ selectedEntity !== "" ? " - " + selectedEntity : "" }}
        </h3>
      </el-col>
      <el-col :span="1" :offset="11" class="user-add-new-btn"> </el-col>
    </el-row>

    <div>
      <div style="margin: 30px">
        <el-row :gutter="20">
          <el-col :span="6" style="padding-left: 0px">
            <el-input v-model="filtersTEMP.editor" class="client-input" :placeholder="$t('EntityChangesView.editor')"
              @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-input v-model="filters.fieldname" class="client-input" :placeholder="$t('EntityChangesView.fieldname')"
              @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-input v-model="filtersTEMP.approver" class="client-input" :placeholder="$t('EntityChangesView.approver')"
              @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-select clearable v-model="filtersTEMP.status" class="m-2 client-input"
              :placeholder="$t('EntityChangesView.approval_status')" value-key="id" @change="filterChanged">
              <el-option v-for="item in statusOptions" :key="item.value" :label="$t('loan_account.' + item.label)"
                :value="item.value" />
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>

    <div>
      <el-row :gutter="20" style="margin: 10px; margin-top: 15px">
        <el-col :span="6">
          <el-select clearable v-model="filterEntity" class="client-input"
            :placeholder="$t('EntityChangesView.select_entity_type')" @change="filterEntityChanged">
            <el-option v-for="client in entitiesList" :label="client.label" :value="client.value" :key="client.value" />
          </el-select>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed" :cell-style="{
		padding: '0',
		height: '15px',
	}" size="small">
      <el-table-column fixed prop="editor.username" :label="$t('EntityChangesView.editor')" />
      <el-table-column v-if="
		(filterEntity !== Entities.EntityChangesSummaryCheck)
		&& (filterEntity !== Entities.EntityChangesAuctions)
		&& (filterEntity !== Entities.EntityChangesApprovals)
		&& (filterEntity !== Entities.EntityChangesLoanAccount)
		&& (filterEntity !== Entities.EntityChangesGreekStateGuarantees)
		&& (filterEntity !== Entities.EntityChangesRegisterOfBonds)
		&& (filterEntity !== Entities.EntityChangesROBTitle)
		&& (filterEntity !== Entities.EntityChangesROBParticipatingBank)
	" fixed prop="entity" :label="$t('EntityChangesView.entity')" />
      <el-table-column v-if="(filterEntity === Entities.EntityChangesLoanAccount)" fixed prop="entity"
        :label="$t('EntityChangesView.entity')" />
      <el-table-column v-if="
		(filterEntity === Entities.EntityChangesSummaryCheck)
		|| (filterEntity === Entities.EntityChangesAuctions)
		|| (filterEntity === Entities.EntityChangesApprovals)
		|| (filterEntity === Entities.EntityChangesGreekStateGuarantees)
		|| (filterEntity === Entities.EntityChangesRegisterOfBonds)
		|| (filterEntity === Entities.EntityChangesROBTitle)
		|| (filterEntity === Entities.EntityChangesROBParticipatingBank)
	" fixed prop="entity" :label="$t('EntityChangesView.entity')" />
      <el-table-column fixed prop="field" :label="$t('EntityChangesView.field')" />
      <el-table-column fixed prop="value" :label="$t('EntityChangesView.value')" />
      <el-table-column fixed prop="approval_status" :label="$t('EntityChangesView.approval_status')" />
      <el-table-column fixed prop="insert_timestamp" :label="$t('EntityChangesView.inserted_at')" />
      <el-table-column fixed prop="decision_timestamp" :label="$t('EntityChangesView.decision_at')" />
      <el-table-column fixed prop="approver.username" :label="$t('EntityChangesView.approver')" />
    </el-table>
    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped></style>
