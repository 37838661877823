<script lang="ts" setup>
import { ref, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { Edit, Delete, Plus, User, RefreshLeft } from "@element-plus/icons-vue";
import AddEdit_Portfolio from "../components/AddEdit_Portfolio.vue";
import Client from "../classes/DB_Entities/Client";
import Option from "../interfaces/Option";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";
import { UIViews } from "@/enums/UIViews";
import router from "@/router";

const { showError, showSuccess, setup_global_filter_listener } = useGenericMethodsVariables();

const route = useRoute();
const mainStore = useMainStore();
const filterClient = ref<{ key: string, label: string, id: number }>();
const mosapi = useMOSAPIStore();
let timer = 0;
const { onSortChange } = useSort();
const clientsList = ref<Client[]>([]);
const dataList = ref([]);
const dataCount = ref(0);
const lastPage = ref(0);
const supervisorSelectionVisible = ref(false);
const addEditVisible = ref(false);
//const LockedRow = ref(false);
//const UnlockAllRowsVisible = ref(false);
const selectedRow = ref(0);
const selectedRowForSupervisors = ref(0);
const deleteDialogVisible = ref(false);
const availableSupervisors = ref<Option[]>([]);
const assignedSupervisors = ref([]);

// const handleClickToUnlock = async (row_id: number) => {
//   const result = await .patch("portfolio?id=eq." + row_id, {
//     lockedrow: "false",
//   });
//   await loadPage(lastPage.value);
// };

// const UnlockAllLockedRows = async () => {
//   const result = await .patch("portfolio?", { lockedrow: "false" });
//   await loadPage(lastPage.value);
//   UnlockAllRowsVisible.value = false;
// };

// onMounted(async () => {
//   const ResultLockedRow = await .get("portfolio?select=*&lockedrow=eq.true");

//   if (ResultLockedRow.data.length > 0) {
//     UnlockAllRowsVisible.value = true;
//     UnlockAllRowsVisible.value = false; //να κρυφτει για να παιξει***********************
//   } else {
//     UnlockAllRowsVisible.value = false;
//   }
// });

const reloadPage = async () => {
	await loadClients();
	await loadPage(1);
};

const handleClickEdit = async (row_id: number) => {
	selectedRow.value = row_id;
	addEditVisible.value = true;

	/*let ResultLockedRow = await .get("portfolio?select=*&id=eq." + row_id);
	LockedRow.value = ResultLockedRow.data[0].lockedrow;
	console.log(LockedRow);
	LockedRow.value=false;
	//να κρυφτει για να παιξει****************************************
  
	if(LockedRow.value){
	  await loadPage(lastPage.value);
	  return
	} else if (LockedRow.value == false){
	  let result = await .patch("portfolio?id=eq." + row_id, { lockedrow: 'true' });
	  await loadPage(lastPage.value);*//*}*/
};

const handleClickAdd = () => {
	addEditVisible.value = true;
	selectedRow.value = -1;
};

const handleClickDelete = (row_id: number) => {
	selectedRow.value = row_id;
	deleteDialogVisible.value = true;
};

const loadSupervisors = async (row_id: number) => {
	let result = await mosapi.getUserSupervisors();

	availableSupervisors.value = [];

	for (const agent of (result.data as any[])) {
		availableSupervisors.value.push({
			key: agent.id,
			label: agent.username,
			disabled: false,
		});
	}
	// result = await .get("portfolio?id=eq." + row_id);
	result = await mosapi.get_users_for_portfolio(row_id);
	assignedSupervisors.value = (result.data as any[]).map((x: { user_id: number, portfolio_id: number }) => x.user_id);
};

const closeSupervisorPicking = async () => {
	supervisorSelectionVisible.value = false;
	await mosapi.post_users_for_portfolio(selectedRowForSupervisors.value, assignedSupervisors.value);
	mainStore.isLoading = false;
};

const handleSupervisors = async (row_id: number) => {
	mainStore.isLoading = true;
	selectedRowForSupervisors.value = row_id;

	await loadSupervisors(row_id);

	supervisorSelectionVisible.value = true;
};

onMounted(async () => {
	setup_global_filter_listener(reloadPage);
	mainStore.isLoading = true;
	await loadClients();

	if (route.query.client_name) {
		nextTick(async () => {
			const filterObj = {
				key: route.query.client_id as string,
				label: route.query.client_name as string,
				id: parseInt(route.query.client_id as string),
			};

			filterClient.value = filterObj;
			await loadPage(1);
		});

		return;
	}
	await loadPage(1);
	mainStore.isLoading = false;
});

const loadClients = async () => {
	mainStore.isLoading = true;
	// console.log('uwot', mainStore.globalPortfolioID)
	const result = await mosapi.get_view(UIViews.ClientsView, {}, 1, 999);

	if (result.error) {
		showError("Failed retrieving clients list:" + result.error, 2500);
		mainStore.isLoading = false;
		return;
	}

	clientsList.value = result.data;
	mainStore.isLoading = false;
};

const loadPage = async (page: number) => {
	mainStore.isLoading = true;

	lastPage.value = page;

	const filters: Record<string, string | number> = {};

	if (filterClient.value?.id !== null && filterClient.value?.id !== undefined) {
		filters.client_id = filterClient.value.id;
	}

	const result = await mosapi.get_view(UIViews.PortfoliosView, filters, lastPage.value, 10);

	if (result.status_code === 401) {
		mainStore.unsetUser();
		showError("Authorization error, please login again", 2000);
		router.push("/login");
		return;
	}

	dataList.value = result.data;
	dataCount.value = result.total;

	// ΤΟ ΚΡΑΤΑΜΕ ΕΙΝΑΙ Η ΛΕΙΤΟΥΡΓΙΑ LOCKEDROW
	// const ResultLockedRow = await .get("portfolio?select=*&lockedrow=eq.true");
	// if (ResultLockedRow.data.length > 0) {
	//   UnlockAllRowsVisible.value = true;
	// } else {
	//   UnlockAllRowsVisible.value = false;
	// }

	mainStore.isLoading = false;
};

const onAdd = async () => {
	showSuccess("Portfolio updated");
	addEditVisible.value = false;
	loadPage(lastPage.value);
};

const onFailSave = async () => {
	selectedRow.value = -1;
	addEditVisible.value = false;
	showError("Portfolio failed to update", 2000);
};

const filterChanged = async () => {
	if (timer) {
		window.clearTimeout(timer);
		timer = 0;
	}

	timer = window.setTimeout(() => {
		loadPage(1);

		window.clearTimeout(timer);
		timer = 0;
	}, 100);
};

const localSortChange = async (sortObj: { column: unknown, prop: string, order: string }) => {
	// console.log(JSON.stringify(sortObj));
	onSortChange(sortObj);
	await loadPage(lastPage.value);
};

const onCloseAddEdit = async () => {
	// const result = await .patch("portfolio?id=eq." + selectedRow.value, {
	//   lockedrow: "false",
	// });
	await loadPage(lastPage.value);
	addEditVisible.value = false;
	selectedRow.value = -1;
};

const onDelete = async () => {
	showSuccess("Portfolio deleted successfully");
	deleteDialogVisible.value = false;
	loadPage(lastPage.value);
};
</script>

<template>
	<div class="content-wrapper" v-loading="mainStore.isLoading">
		<el-row :gutter="10">
			<el-col :span="12">
				<h3 class="heading-page">
					{{ $t("PortfolioView.portfolios") }} ({{ dataCount }})
				</h3>
			</el-col>
		</el-row>
		<div style="margin: 30px">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-select clearable v-model="filterClient" class="m-2 client-input" placeholder="Client"
						size="large" value-key="id" @change="filterChanged">
						<el-option v-for="item in clientsList" :key="item.id" :label="item.clientname" :value="item" />
					</el-select>
				</el-col>
			</el-row>
		</div>
		<div>
			<el-row :gutter="10" style="margin: 10px; margin-top: 15px">
				<el-col :span="12" class="realestate-add-new-btn" />

				<el-col :span="12" class="realestate-add-new-btn">
					<el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
						style="float: right; margin: 2.5px" :icon="RefreshLeft" />
					<el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
						size="small" class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()"
						:icon="Plus" />
					<!--
          <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible && false">
            <el-button link type="primary" size="small" class="btn-add"
              style="float: right; margin: 2.5px; background-color: #f56c6c !important" @click="UnlockAllLockedRows"
              :icon="Lock" />
          </el-tooltip>
          -->
				</el-col>
			</el-row>
		</div>
		<el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
			size="small">
			<el-table-column sortable fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" />
			<el-table-column sortable fixed prop="asset_owner" label="Asset Owner" />
			<el-table-column fixed="right" :label="$t('global.operations')">
				<template #default="scope">
					<el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
						size="small" class="btn-portfolios" @click="handleSupervisors(scope.row.id)" :icon="User" />
					<el-button link type="primary" size="small" class="btn-detail"
						@click="handleClickEdit(scope.row.id)" :icon="Edit" :disabled="scope.row.lockedrow && false"
						style="background-color: #d6d6d6 !important" />
					<el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
						size="small" class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
					<!--  
          <el-tooltip :content="$t('global.lockedrow')" v-if="scope.row.lockedrow == true && false">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip>
          -->
				</template>
			</el-table-column>
		</el-table>

		<div>
			<el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
				@current-change="loadPage" />
		</div>

		<el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
			:title="selectedRow === -1 ? 'Add Portfolio' : 'Portfolio Details'">
			<AddEdit_Portfolio :row_id="selectedRow" @close="onCloseAddEdit" @save="onAdd" @fail-save="onFailSave" />
		</el-dialog>

		<DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Portfolio"
			:table="Entities.Portfolios" title="Delete Portfolio" width="50%" @close="deleteDialogVisible = false"
			@delete="onDelete" />


		<el-dialog v-if="supervisorSelectionVisible" v-model="supervisorSelectionVisible" title="Supervisor assignment"
			@close="mainStore.isLoading = false;" width="60%">
			<el-transfer v-model="assignedSupervisors" :data="availableSupervisors"
				:titles="['Available', 'Assigned']" />
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="closeSupervisorPicking">{{ $t("generic.close") }}</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<style scoped></style>