<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import RestOfCollaterals from "../classes/DB_Entities/RestOfCollaterals";
import { ElMessage } from "element-plus";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import { Edit, UploadFilled } from "@element-plus/icons-vue";
import { useValueLists } from "../composables/valueLists";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "@/store/mos_api";
import { UIViews } from "@/enums/UIViews";
import APIReply from "../classes/APIReply";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
const props = defineProps<{
    nrecollateralid: number;
    view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();

const { CollateralTypeOptions } = useValueLists();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref(new Array<any>());
const active = ref(0);
const breadcrumbText = ref("");

const {
    showError,
    setup_decision_listener,
    hasPendingChanges,
    fullObj,
    table_name,
    clickAcceptAll,
    loadData,
    save,
    cleanObject,
    resetObjects,
} = useGenericMethodsVariables();

onMounted(async () => {
    setup_decision_listener(fixPendingSelects);
    mainStore.isLoading = true;
    breadcrumbText.value = t("global.select");

    table_name.value = RestOfCollaterals.getTableNameStatic();
    cleanObject.value = new RestOfCollaterals();

    //console.log("new nrecollateralid", props.nrecollateralid);

    await loadLoanAgreements();

    if (props.nrecollateralid === -1) {
        resetObjects();
        mainStore.isLoading = false;
        return;
    }

    await loadData(props.nrecollateralid);
    await fixPendingSelects();
    breadcrumbText.value = t("nre_collaterals_category." + fullObj.value?.type?.originalValue);

    active.value = 1;
    mainStore.isLoading = false;
});

const loadLoanAgreements = async () => {
    loanAgreementsList.value = [];

    // let query = `view_mosloanagreement_view1?approve_status=eq.1&debtor_approve_status=eq.1`;
    const result = await mosapi.get_view(UIViews.LoanAgreementView, {approve_status: 1, debtor_approve_status: 1});
    if (result.error) {
        showError(result.error);
        return;
    }

    for (let i = 0; i < result.data.length; i++) {
        loanAgreementsList.value.push({
            value: result.data[i].id,
            label: result.data[i].ar_sumbashs + " (" + result.data[i].fullname + ")",
        });
    }
};

const onPreSave = async () => {
    if (props.nrecollateralid === -1) {
        if (fullObj.value.loan_agreement_id.originalValue === 0) {
            ElMessage({
                showClose: true,
                message: t("errors.missing_loan_agreement"),
                type: "warning",
                duration: 1500,
            });
            return;
        }
    }

    delete fullObj.value.loan_agreement;

    await save();
};

const isNextDisabled = (): boolean => {
    return fullObj.value?.loan_agreement_id?.originalValue === '' || fullObj.value?.ar_kalimatos?.originalValue === '' || fullObj.value?.type?.originalValue === '';
}

const nextClicked = () => {
    breadcrumbText.value = t("nre_collaterals_category." + fullObj.value.type.originalValue);
    active.value = 1;
}

const backClicked = () => {
    breadcrumbText.value = t("global.select");
    active.value = 0;
}

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value
    // Loan Agreement
    if (field === "loan_agreement_id") {
      const loan1 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: fullObj.value[field].pendingValue.value}) as APIReply<LoanAgreement[]>;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${loan1.data[0].ar_sumbashs} (${loan1.data[0].debtor?.fullname || ''})`;

      const loan2 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: fullObj.value[field].originalValue}) as APIReply<LoanAgreement[]>;
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${loan2.data[0].ar_sumbashs} (${loan2.data[0].debtor?.fullname || ''})`;
    }

    if (field === "type") {
        fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = CollateralTypeOptions.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = CollateralTypeOptions.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }
  }
};

</script>

<template>
    <div v-loading="mainStore.isLoading">
        <el-steps :space="200" :active="active" simple finish-status="success">
            <el-step :title="breadcrumbText" :icon="Edit" />
            <el-step :title="$t('global.data_entry')" :icon="UploadFilled" />
        </el-steps>
        <el-form>
            <div v-if="active == 0" style="padding: 50px">
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="select" :object="fullObj.loan_agreement_id"
                            :label="$t('GreekStateGuarantees.loan_agreement')" :options="loanAgreementsList" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="input" :object="fullObj.ar_kalimatos"
                            :label="$t('RealEstateCollateralsView.collateral_nbr')" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="select" :object="fullObj.type"
                            :label="$t('nre_collaterals_category.collateral_type')" :options="CollateralTypeOptions" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="money" :object="fullObj.poso_prosim"
                            :label="$t('NRECollateralsDetails.poso_prosim')" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="input" :object="fullObj.seira_prosim"
                            :label="$t('NRECollateralsDetails.seira_prosim')" />
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <EntityField type="datepicker" :object="fullObj.imer_axias"
                            :label="$t('NRECollateralsDetails.imer_axias')" />
                    </el-col>
                </el-row>
            </div>

            <div v-if="active == 1">
                <AcceptAllPending v-show="hasPendingChanges && !props.view_only"
                    @accept="clickAcceptAll(props.nrecollateralid)" />
                <p />

                <div v-if="fullObj?.type?.originalValue === 'listed_shares'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_ekd_etair_metox"
                                :label="$t('NRECollateralsDetails.listed_shares.issuing_stock_company')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_katox_metox"
                                :label="$t('NRECollateralsDetails.listed_shares.stockholder')" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_sumbash_enexurou"
                                :label="$t('NRECollateralsDetails.listed_shares.pledge_agreement')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_plithos_metoxon"
                                :label="$t('NRECollateralsDetails.listed_shares.nbr_of_shares')" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_onomastikh_timh"
                                :label="$t('NRECollateralsDetails.listed_shares.nominal_value')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.em_dhlosh_protheshs_metabib"
                                :label="$t('NRECollateralsDetails.listed_shares.declaration_of_intent')" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.em_hmer_epidoshs"
                                :label="$t('NRECollateralsDetails.listed_shares.servicing_date')" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'unlisted_shares'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_ekdo_eteria_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.issuing_stock_company')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_katoxos_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.stockholder')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_sumbash_enexurou"
                                :label="$t('NRECollateralsDetails.unlisted_shares.pledge_agreement')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_plithos_metoxon"
                                :label="$t('NRECollateralsDetails.unlisted_shares.nbr_of_shares')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_onom_aksia_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.nominal_value')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_sunol_onomas_aksia_metox"
                                :label="$t('NRECollateralsDetails.unlisted_shares.total_nominal_value')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.mem_epishm_metox_biblio_metox" :label="$t(
                                'NRECollateralsDetails.unlisted_shares.share_and_shareholder_book_annotations'
                            )
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.mem_hmer_epidoshs"
                                :label="$t('NRECollateralsDetails.unlisted_shares.servicing_date')" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'deposits'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_dikaiouxos_katath"
                                :label="$t('NRECollateralsDetails.deposits.beneficiary')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_sumbash_enex"
                                :label="$t('NRECollateralsDetails.deposits.pledge_agreement')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_sumbatika_eggrafa" :label="$t('NRECollateralsDetails.deposits.contractual_document_agreement')
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_trapeza"
                                :label="$t('NRECollateralsDetails.deposits.bank')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_iban"
                                :label="$t('NRECollateralsDetails.deposits.iban')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.k_ypol"
                                :label="$t('NRECollateralsDetails.deposits.balance')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.k_hmer_ypol"
                                :label="$t('NRECollateralsDetails.deposits.date_of_balance')" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'pledged_claims'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.ea_sumbash_enex"
                                :label="$t('NRECollateralsDetails.pledged_claims.pledged_agreement')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.ea_dikaiol_enexur_apait" :label="$t('NRECollateralsDetails.pledged_claims.evidence_of_the_pledged_claim')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.ea_gnost_sust_enex"
                                :label="$t('NRECollateralsDetails.pledged_claims.notice_of_pledge')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.ea_hmer_epidoshs"
                                :label="$t('NRECollateralsDetails.pledged_claims.servicing_date')" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'warehouse_receipt'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sumbash_enex" :label="$t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).pledge_agreement')
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.tp_hmer_epidoshs" :label="$t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).servicing_date')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sxetikh_praksh" :label="$t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).relevant_act')
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_ypogr_titl_paegae" :label="$t('NRECollateralsDetails.Warehouse_receipt_(PAEGAE).sign_of_titles')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sumbatika_eggrafa" :label="$t(
                                'NRECollateralsDetails.Warehouse_receipt_(PAEGAE).contractual_document'
                            )
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.tp_sumbas_enex_apait_" :label="$t(
                                'NRECollateralsDetails.Warehouse_receipt_(PAEGAE).pledge_agreement_of_insurance_policy'
                            )
                                " />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'checks'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_sumbatika_eggrafa"
                                :label="$t('NRECollateralsDetails.checks.contractual_document')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_pinakio_epitagon"
                                :label="$t('NRECollateralsDetails.checks.checks_table')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_sxetika_dikaiol"
                                :label="$t('NRECollateralsDetails.checks.other_documents')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_log_takseos"
                                :label="$t('NRECollateralsDetails.checks.off_balance_account')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_xor_log"
                                :label="$t('NRECollateralsDetails.checks.loan_account')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_log_xre_eks_prom"
                                :label="$t('NRECollateralsDetails.checks.commission_account')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_egkekr_orio_diasp_ana_ekdoth" :label="$t('NRECollateralsDetails.checks.approved_spread_limit_per_issuer')
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_nomotipo_ekdos_epitaghs"
                                :label="$t('NRECollateralsDetails.checks.legal_issuing_of_check')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_sumf_poson_epitagis"
                                :label="$t('NRECollateralsDetails.checks.amount_agreement')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_diaspora_ana_ekdoth"
                                :label="$t('NRECollateralsDetails.checks.amount_agreement')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_ypar_kartel_xreost_ypol" :label="$t(
                                'NRECollateralsDetails.checks.existence_of_account_and_debit_balance'
                            )
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="datepicker" :object="fullObj.e_hmer_ekd_epitaghs"
                                :label="$t('NRECollateralsDetails.checks.issuing_date')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_end_sfragishs_epit"
                                :label="$t('NRECollateralsDetails.checks.stamped_check')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.e_loipa_stoixeia_epitaghs"
                                :label="$t('NRECollateralsDetails.checks.check_details')" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'bill_of_exchange'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_eborikotita"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.commerciality')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_pinakio_sunal" :label="$t('NRECollateralsDetails.bill_of_exchange.bill_of_exchange_table')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_sxetika_dikaol"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.related_documents')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypo_ait_pa_meta_sunal" :label="$t(
                                'NRECollateralsDetails.bill_of_exchange.signed_request_for_the_receipt'
                            )
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_log_takseos"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.off_balance_account')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_xor_log_sund_takseos"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.loan_account')" />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_log_xre_eks_prom"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.loan_account')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_egkek_orio_diasporas_ekd" :label="$t('NRECollateralsDetails.bill_of_exchange.spread_limit_per_issuer')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_nom_ekd_epit" :label="$t('NRECollateralsDetails.bill_of_exchange.legal_issuing_of_check')
                                " />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_sumf_poson_epit" :label="$t('NRECollateralsDetails.bill_of_exchange.legal_issuing_of_check')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_hmer_ekd_epit"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.issue_date')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypar_kart_xreost_ypol" :label="$t(
                                'NRECollateralsDetails.bill_of_exchange.existence_of_account_and_ebit_balance'
                            )
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_diasp_ana_ekd"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.spread_per_issuer')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypar_dusmenon_stoix" :label="$t('NRECollateralsDetails.bill_of_exchange.default_credit_behavior')
                                " />
                        </el-col>
                    </el-row>

                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.s_ypog_sumbash"
                                :label="$t('NRECollateralsDetails.bill_of_exchange.signed_contract')" />
                        </el-col>
                    </el-row>
                </div>

                <div v-if="fullObj?.type?.originalValue === 'movable_collaterals'" style="padding: 50px">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.kel_eidos"
                                :label="$t('NRECollateralsDetails.movable_collaterals.type')" />
                        </el-col>
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.kel_idioktisia"
                                :label="$t('NRECollateralsDetails.movable_collaterals.property')" />
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <EntityField type="input" :object="fullObj.kel_poso"
                                :label="$t('NRECollateralsDetails.movable_collaterals.amount')" />
                        </el-col>
                    </el-row>
                </div>
            </div>

            <el-row :gutter="10" style="padding-left: 50px; padding-right: 50px">
                <el-col :span="24">
                    <el-form-item class="btn-wrapper-components">
                        <div v-if="active == 0">
                            <el-button :disabled="isNextDisabled()" style="margin-top: 12px; margin: 5px"
                                @click="nextClicked()">
                                {{ $t("global.next_step") }}
                            </el-button>
                        </div>
                        <div v-if="active == 1">
                            <el-button style="margin-top: 12px; margin: 5px" @click="backClicked()">{{
                                $t("global.back")
                            }}</el-button>
                        </div>
                        <div v-if="active == 1">
                            <el-button type="primary" style="margin-top: 12px; margin: 5px" @click="onPreSave()">
                                {{ $t("global.save") }}
                            </el-button>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>


<style scoped></style>
