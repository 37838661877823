import ITableName from "../../interfaces/ITableName";
import Debtor from "./Debtor";

class LoanAgreement implements ITableName {
  id: number = -1;
  debtor_id: number = 0;
  ar_sumbashs: string = "";
  ar_sumbashs_fao: string = "";
  katast_sumbashs: string = "";
  product: number = 0;
  currency: number = 0;
  synol_poso_sumbashs: number = 0;
  hmer_sumbashs: string = "";
  hmer_tropop_sumbashs: string = "";
  agent: string = "";
  status: string = "";
  comments: string = "";
  tropos_apopl?: string;
  hmer_enark_period_xaritos?: string;
  hmer_liksis_period_xaritos?: string;
  program_apopl_kefal?: number;
  hmer_prwths_doshs_kefal?: string;
  program_apopl_tokon?: number;
  hmer_protis_doshs_tokon?: string;
  plithos_doseon_kefal?: string;
  hmer_poso_ofeilhs?: string;
  dikaioma_propl?: string;
  hmer_enarx_dikast_energ: string = "";
  hmer_epidosis_dikast_energ: string = "";
  exoda_epidosis: number = -1;
  ekdosi_diatagis: boolean = false;
  diatagi_pl_hmek_hmep_ex_per: string = "";
  aitisi_anastolis: boolean = false;
  perithorio_aitisis_anastolis: string = "";
  katasxesi: boolean = false;
  ektimisi: number = 0;
  exoda_ektimisis: number = 0;
  hmer_epid_ekth_katasx: string = '';
  exoda_epidosis_ekth_katasx: number = 0;
  poso_katasxesis: number = 0;
  eis_xeiras_tritou: string = '';
  hmer_eperx_pleist: string = '';
  perithorio_anakopis: string = '';
  perithorio_anastolis_apo: string = '';
  perithorio_anastolis_eos: string = '';
  kefaleo: number = 0;
  log_tokoi: number = 0;
  mh_log_tokoi: number = 0;
  promithies_exoda: number = 0;
  tokoi_promithion_exodon: number = 0;
  denouncement_amount: number = 0;
  auction: boolean = false;
  arxikh_hmer_lukshs: string = "";
  nea_hmer_lukshs: string = "";
  vasi_ektokismou: number = 0;
  kathgor_epitok_bashs: number = 0;
  perithorio: number = 0;
  epitok_uperhm: number = 2.5;
  eisfora_n128_1975: number = 0.6;
  parakra_forou: number = 0;
  oros_propliromis: string = '';
  lockedrow: boolean = false;

  debtor?: Debtor = undefined;
  
  static getTableNameStatic(): string {
    return "loan_agreement";
  }

  getTableName(): string {
    return LoanAgreement.getTableNameStatic();
  }
}

export default LoanAgreement;
