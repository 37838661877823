<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import { ElMessage } from "element-plus";
import type { FormInstance } from "element-plus";
import ConfigInsComp from "../classes/DB_Entities/ConfigInsComp";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { useMainStore } from "../store/main";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();
const props = defineProps({
  inscompid: Number,
});
const isLoading = ref(false);
const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new ConfigInsComp());
const activeName = ref("first");
const ruleFormRef = ref<FormInstance>();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.inscompid,
  async (newVal, oldVal) => {
    // console.log("Loading debtor with id " + props.debtorid);
    await loadInsComp(props.inscompid as number);
  }
);

onMounted(async () => {
  await loadInsComp(props.inscompid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const ins_comp_id = data.value.id as number;
  delete data.value.id;
  const result = await mosapi.generic_entity_patch(Entities.ConfigInsuranceCompany, ins_comp_id, data.value);
  if (result.error) {
    showError(result.error);
    return;
  }
  emits("save");
};

const loadInsComp = async (inscomp_id: number) => {
  mainStore.isLoading = true;
  if (inscomp_id === -1) return;
  const result = await mosapi.generic_entity_get(Entities.ConfigInsuranceCompany, { id: inscomp_id });
  if (result.error) {
    showError(result.error);
    return;
  }
  data.value = result.data[0];
  mainStore.isLoading = false;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.ins_comp')">
            <el-input v-model="data.ins_comp" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
