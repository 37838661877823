<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { ElMessage } from "element-plus";
import { Edit, Notebook } from "@element-plus/icons-vue";
import LoanAgreementDetails from "../components/LoanAgreementDetails.vue";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { UIViews } from "@/enums/UIViews";

const { showError } = useGenericMethodsVariables();

const router = useRouter();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const portfolioList = ref<any>([]);
const loanAgreementDataList = ref<any>([]);
const ChooseMosClient = ref("");
const detailsVisible = ref(false);
const selectedRow = ref(0);
const selectedPortfolio = ref<number>(0);
const { onSortChange, sortColumn, sortOrder } = useSort();

onMounted(async () => {
  await LoadPortfolios();
});

const loadPage = async (portfolio_id: number) => {
  mainStore.isLoading = true;
  if (sortOrder.value !== "") {
    sortOrder.value = 'ar_sumbashs'
  }
  for (let i = 0; i < portfolioList.value.length; i++) {
    const result1 = await mosapi.get_view(UIViews.LoanAgreementView, { debtorportfolio: portfolioList.value[i].id, orderBy: `${sortColumn.value}.${sortOrder.value}` })
    loanAgreementDataList.value[portfolioList.value[i].id] = result1.data;
  }
  //   let filterUrl = "";

  //   if (sortOrder.value !== "") {
  //     filterUrl += `&orderFIX=${sortColumn.value}.${sortOrder.value}`;
  //   }

  //     "loan_agreement?select=id,ar_sumbashs,katast_sumbashs,status,config_product!inner(id,product),debtor!inner(id,portfolio!inner(id,portf_name))&debtor.portfolio.id=eq." +portfolio_id + `${filterUrl}`

  //   if (result.headers)
  //     dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  //   loanAgreementDataList.value[portfolio_id] = result.data;

  mainStore.isLoading = false;
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const onSave = async (portf_id: number) => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Agreement updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(portf_id);
  loadLoanAgreement();
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Agreement failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

/*const LoadMosClientsForUser = async () => {
    `mosclients?allowed_users=cs.{${mainStore.loggedUser.id}}`

  if (result.error) {
    showError(result.error);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    storePortfolios.value.push(result.data[i].id);
  }

  await LoadPortfolios();
};*/

const LoadPortfolios = async () => {
  /* let Url = "";
   let Url2 = "";
   switch (mainStore.loggedUser.user_type) {
     case 0:
       Url = "portfolio?";
       break;
     case 1:
       Url += "portfolio?allowed_users=cs.{" + mainStore.loggedUser.id + "}";
       break;
     case 2:
       Url2 = "debtor?select=portfolio&allowed_users=cs.{" + mainStore.loggedUser.id + "}";
     //   result2 = await .get(Url2);
   //   console.log('gigi 2 ' + JSON.stringify(result2) )
 
       if (result2.error) {
         ElMessage({
           showClose: true,
           message: result2.error.message,
           type: "error",
         });
         return;
       }
       for (let i = 0; i < result2.data.length; i++) {
         portfolioListIds.value.push(result2.data[i].portfolio);
       }
       Url = "portfolio?id=in.(" + portfolioListIds.value + ")";
       break;
   }*/
  //    = await .get(Url);
  const result = await mosapi.get_view(UIViews.PortfoliosView, {}, 1, 1000);

  if (result.error) {
    showError(result.error);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    portfolioList.value.push(result.data[i]);
  }

  await loadLoanAgreement();
};

// const GenerateUncheckedRate = async (
//   portf_id: number,
//   portf_name: string,
//   length: number
// ) => {
//   //Find unchecked Match Rate

//   const resultUnchecked: = await  *** REMOVE ***api.get(
//     `loan_agreement?select=id,config_product!inner(id),debtor!inner(id,portfolio!inner(id,portf_name))&debtor.portfolio.id=eq.${portf_id}&status=eq.unchecked`
//   );
// };

// const GenerateInProgressRate = async (
//   portf_id: number,
//   portf_name: string,
//   length: number
// ) => {
//   //Find unchecked Match Rate

//   const resultInProgress:  = await *** REMOVE ***api.get(
//     `loan_agreement?select=id,config_product!inner(id),debtor!inner(id,portfolio!inner(id,portf_name))&debtor.portfolio.id=eq.${portf_id}&status=eq.in_progress`
//   );
// };

// const GenerateCompletedRate = async (
//   portf_id: number,
//   portf_name: string,
//   length: number
// ) => {
//   //Find unchecked Match Rate

//   const resultCompleted:  = await ***REMOVE***api.get(
//     `loan_agreement?select=id,config_product!inner(id),debtor!inner(id,portfolio!inner(id,portf_name))&debtor.portfolio.id=eq.${portf_id}&status=eq.completed`
//   );
// };

const loadLoanAgreement = async () => {
  for (let i = 0; i < portfolioList.value.length; i++) {
    const result1 = await mosapi.get_view(UIViews.LoanAgreementView, { debtorportfolio: portfolioList.value[i].id })
    loanAgreementDataList.value[portfolioList.value[i].id] = result1.data;
  }
  for (let i = 0; i < portfolioList.value.length; i++) {
    if (mainStore.loggedUser.id === 0) { // Exit if user logged out
      return;
    }
    //       `loan_agreement?select=id,ar_sumbashs,katast_sumbashs,status,config_product!inner(id,product),debtor!inner(id,portfolio!inner(id,portf_name))&debtor.portfolio.id=eq.${portfolioList.value[i].id}`
    // loanAgreementDataList.value[portfolioList.value[i].id] = result.data;
  }
};

const handleLoanAgreementClick = (loan_agreement_id: number) => {
  console.log("redirecting");
  router.replace({
    name: "Summary Check",
    query: { loan_agreement_id },
  });
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(selectedPortfolio.value);
};

</script>

<template>
  <div class="">

    <el-row :gutter="20" style="margin: 30px">
      <el-col :span="24" style="background-color: white; border-radius: 25px">
        <div v-if="portfolioList.length != 0" class="grid-content ep-bg-purple" style="margin: 50px">
          <div>
            <h3 class="heading-page">
              {{
                mainStore.loggedUser.user_type === 0
                  ? "Portfolios"
                  : "Your assigned Portfolios"
              }}
            </h3>
            <h3 class="" style="text-align: right; margin-top: 0px"></h3>
          </div>

          <el-tabs v-model="selectedPortfolio" type="border-card">
            <el-tab-pane v-for="portfolios in portfolioList" :label="portfolios.portf_name">
              <div>
                <el-table stripe border table-layout="fixed" :data="loanAgreementDataList[portfolios.id]"
                  sortable="custom" @sort-change="localSortChange">
                  <el-table-column sortable fixed prop="ar_sumbashs" label="Contract Number" />
                  <el-table-column sortable fixed prop="katast_sumbashs" label="Contract Status" />
                  <el-table-column fixed prop="config_product.product" label="Product" />
                  <el-table-column fixed="right" label="Operations">
                    <template #default="scope">
                      <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                        <el-icon>
                          <Edit />
                        </el-icon>
                      </el-button>
                      <el-tooltip class="box-item" effect="dark" content="Summary Check" placement="top">
                        <el-button link type="primary" class="btn-portfolios" size="small"
                          @click="handleLoanAgreementClick(scope.row.id)">
                          <el-icon>
                            <Notebook />
                          </el-icon>
                        </el-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>

                <el-dialog v-model="detailsVisible" title="Loan Agreement Details">
                  <LoanAgreementDetails :rowid="selectedRow" @save="onSave(portfolios.id)"
                    @close="detailsVisible = false; selectedRow = -1;" @fail-save="onFailSave" />
                </el-dialog>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <div v-else>
          <div v-if="ChooseMosClient && portfolioList.length == 0" class="info"
            style="margin-top: 100px; margin-bottom: 100px">
            <h2>Nothing Found!</h2>
            <p>This Client does not have any assigned portfolios</p>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content ep-bg-purple" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="grid-content ep-bg-purple" />
      </el-col>
      <el-col :span="8">
        <div class="grid-content ep-bg-purple" />
      </el-col>
      <el-col :span="4">
        <div class="grid-content ep-bg-purple" />
      </el-col>
      <el-col :span="4">
        <div class="grid-content ep-bg-purple" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="grid-content ep-bg-purple" />
      </el-col>
      <el-col :span="16">
        <div class="grid-content ep-bg-purple" />
      </el-col>
      <el-col :span="4">
        <div class="grid-content ep-bg-purple" />
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.demo-progress .el-progress--line {
  margin-bottom: 15px;
  width: 450px;
}
</style>
