<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMainStore } from "../store/main";
import RegisterOfBond from "../classes/DB_Entities/RegisterOfBond";
import { useMOSAPIStore } from "../store/mos_api";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";

const mosapi = useMOSAPIStore();
const emits = defineEmits(["save", "close", "fail-save"]);
const mainStore = useMainStore();
const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
  rob_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const loanAgreementsList = ref<any>([]);
const representativesList = ref<any[]>([]);

const {
  setup_decision_listener,
  updateDecision,
  cleanObject,
  fullObj,
  table_name,
  data,
  loadData,
  save,
  close,
  TransformFullObj,
  showError,
  showWarning
} = useGenericMethodsVariables();

watch(
  () => props.rob_id,
  async (newVal, oldVal) => {
    if (props.rob_id === undefined || props.rob_id == null || Number.isNaN(props.rob_id)) return;

    await loadRepresentatives()

    resetObjects();

    if (props.newEntity) return;

    await loadData(props.rob_id);
  }
);

const loadLoanAgreements = async () => {
  // let url = `view_mosloanagreement_view1?select=id,ar_sumbashs&orderFIX=ar_sumbashs&approve_status=eq.1&omologiako=eq.true`;
  const result = await mosapi.get_view(UIViews.LoanAgreementView, {
    approve_status: 1, omologiako: true, orderBy: 'ar_sumbashs'
  }, 1,1000);
  loanAgreementsList.value = [];
  for (let i = 0; i < result.data.length; ++i) {
    loanAgreementsList.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs,
    });
  }
};

const loadRepresentatives = async () => {
  representativesList.value = [];

  //result = await .get("config_agent");
  const result = await mosapi.generic_entity_get(Entities.ConfigAgent, {});
  if (result.error) {
    showError('Error retrieving representatives: ' + result.error)
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    representativesList.value.push({
      value: result.data[i].agent,
      label: result.data[i].agent,
    });
  }
};

const resetObjects = () => {
  data.value = new RegisterOfBond();
  fullObj.value = new RegisterOfBond();
  data.value.id = props.rob_id;
  fullObj.value.id = props.rob_id;
  TransformFullObj();
};

onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  table_name.value = RegisterOfBond.getTableNameStatic();
  cleanObject.value = new RegisterOfBond();

  console.log("rob_id", props.rob_id, "newEntity", props.newEntity);

  await loadRepresentatives()
  await loadLoanAgreements();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  mainStore.isLoading = true;
  await loadData(props.rob_id);
  fixPendingSelects();
  mainStore.isLoading = false;
});

const onDecision = async (decision: Decision) => {
  mainStore.isLoading = true;

  await updateDecision(decision);

  await loadData(props.rob_id);

  mainStore.isLoading = false;
};

const onPreSave = async () => {
  if (props.newEntity as boolean) {
    if (fullObj.value.payment_representative.originalValue === "") {
      showWarning(t("errors.missing_payment_representative"))
      return;
    }
  }

  await save();
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    // Payment Representative 
    if (field === "payment_representative") {
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = fullObj.value[field].pendingValue.value;

      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = fullObj.value[field].originalValue;
    }
  }
};
</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <EntityField type="select" label="Loan Agreement" :object="fullObj.loan_agreement_id"
        :options="loanAgreementsList"  :is-disabled="props.view_only" />

      <EntityField type="select" :object="fullObj.payment_representative" label="Payment Representative"
        :options="representativesList" :is-disabled="props.view_only"  />

      <EntityField type="input" label="Replacement Mechanism" :object="fullObj.replacement_mechanism"
         :is-disabled="props.view_only" />

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
