import { createWebHistory, createRouter } from "vue-router";
import LoginView from "../views/LoginView.vue";
import MainView from "../views/MainView.vue";
import UsersView from "../views/UsersView.vue";
import ClientsView from "../views/ClientsView.vue";
import DebtorView from "../views/DebtorView.vue";
import DigitizedLAView from "../views/DigitizedLAView.vue";
import PortfoliosView from "../views/PortfoliosView.vue";
import PrivilegesView from "../views/PrivilegesView.vue";
import LoanAgreementsView from "../views/LoanAgreementsView.vue";
import RegisterOfBondsView from "../views/RegisterOfBondsView.vue";
import LoanAccountsView from "../views/LoanAccountsView.vue";
import RealEstatesView from "../views/RealEstatesView.vue";
import RealEstateCollateralsView from "../views/RealEstateCollateralsView.vue";
import GuaranteesView from "../views/GuaranteesView.vue";
import GreekStateGuaranteesView from "../views/GreekStateGuaranteesView.vue";
import DashboardView from "../views/DashboardView.vue";
import SummaryCheckView from "../views/SummaryCheck.vue";
import SummaryCheckKODView from "../views/SummaryCheckKOD.vue";
import SummaryCheckAnotherLoanView from "../views/SummaryCheckAnotherLoan.vue";
import AuctionsView from "../views/AuctionsView.vue";
import AddEdit_Auction from "../components/AddEdit_Auction.vue";
import RestOfCollateralsView from "../views/RestOfCollateralsView2.vue";
import PlatformSettingsView from "../views/PlatformSettingsView.vue";
import ApprovalsView from "../views/ApprovalsView.vue";
import EktokismosView from "../views/EktokismosView.vue";
import ROB_Selected from "../views/ROB_Selected.vue";
import EntityChangesView from "../views/EntityChangesView.vue";
import UploadView from "../views/UploadView.vue";
import AddInterestView from "../components/AddInterest.vue"
import MonitoringView from "../views/MonitoringView.vue";
import LiksiarioView from "../views/LiksiarioView.vue";
import ReportsView from "../views/ReportsView.vue";
import ReportPreview from "../components/ReportPreview.vue";
import MetabaseVue from "../views/MetabaseView.vue";
import LoanAccountCalculations from "../views/LoanAccountCalculations.vue";

import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import UserType from "../enums/UserType";
import ProjectsView from "../views/Workflow_ProjectsView.vue";
import ProjectTemplatesView from "../views/Workflow_ProjectTemplatesView.vue";
import TaskTemplatesView from "../views/Workflow_TaskTemplatesView.vue";
import CustomFieldsView from "../views/Workflow_CustomFieldsView.vue";
import Apografi_Frontier from "../views/Apografi_Frontier.vue";
import GroupsView from "../views/GroupsView.vue";
import ScreensView from "@/views/ScreensView.vue";
import MenuListView from "@/views/MenuListView.vue";
import MenuEntry from "@/classes/DB_Entities/MenuEntry";
import APIReply from "@/classes/APIReply";

const client = (import.meta.env.VITE_CLIENT);

let routes = [
	{ path: "/login", name: "Login", component: LoginView, meta: { skipFiltering: true }, },
	{
		path: "/",
		redirect: "/dashboard",
		name: "Root",
		component: MainView,
		children: [
			{ path: "/users", name: "Users", component: UsersView },
			{
				path: "/privileges",
				name: "Privileges",
				component: PrivilegesView,
			},
			{
				path: "/groups",
				name: "Groups",
				component: GroupsView,
			},
			{
				path: "/screens",
				name: "Screens",
				component: ScreensView,
			},
			{
				path: "/menus",
				name: "Menus",
				component: MenuListView,
			},
			{ path: "/clients", name: "Clients", component: ClientsView },
			{ path: "/portfolios", name: "Portfolios", component: PortfoliosView },
			{ path: "/debtors", name: "Debtors", component: DebtorView },
			{ path: "/digitizedla", name: "Digitized Loan Agreements", component: DigitizedLAView },
			{ path: "/loanagreements", name: "Loan Agreements", component: LoanAgreementsView },
			{ path: "/monitoring", name: "Monitoring", component: MonitoringView },
			{ path: "/liksiario", name: "Liksiario", component: LiksiarioView },
			{
				path: "/registerofbonds",
				name: "Register of Bonds",
				component: RegisterOfBondsView,
			},
			{
				path: "/platformsettings/interest/:catg_id",
				name: "Interest Data",
				component: AddInterestView,
			},
			{
				path: "/rob_selected",
				name: "Register of Bonds Selection",
				component: ROB_Selected,
				props: true
			},
			{ path: "/loanaccounts", name: "Loan Accounts", component: LoanAccountsView },
			// { path: "/pliromes/:loan_account_id", name: "Pliromes", component: PliromesView },
			// { path: "/loanAccountInstallments/:loan_account_id", name: "Loan_Account_Ektokismos", component: LoanAccountEktokismosView },
			// { path: "/loanAgreementEktokismos/:loan_agreement_id", name: "Loan_Agreement_Ektokismos", component: LoanAgreementEktokismosView },
			{ path: "/realestates", name: "Real Estates", component: RealEstatesView },
			{ path: "/recollaterals", name: "RE Collaterals", component: RealEstateCollateralsView },
			{ path: "/guarantees", name: "Guarantees", component: GuaranteesView },
			{ path: "/greekstateguarantees", name: "Greek State Guarantees", component: GreekStateGuaranteesView },
			{ path: "/auctions", name: "Auctions", component: AuctionsView },
			{ path: "/auctions/:auction_id/:view_only?", name: "Auction Add/Edit", component: AddEdit_Auction },
			{ path: "/nrecollaterals", name: "NRE Collaterals", component: RestOfCollateralsView },
			{ path: "/dashboard", name: "Dashboard", component: DashboardView },
			{ path: "/summary_check", name: "Summary Check", component: SummaryCheckView },
			{ path: "/summary_check_kod/:loan_agreement_id", name: "Summary Check KOD", component: SummaryCheckKODView },
			{
				path: "/summary_check_od/:loan_agreement_id",
				name: "Summary Check OD",
				component: SummaryCheckAnotherLoanView,
			},
			{
				path: "/summary_check_al/:loan_agreement_id",
				name: "Summary Check AL",
				component: SummaryCheckAnotherLoanView,
			},
			{ path: "/approvals", name: "Approvals", component: ApprovalsView },
			{
				path: "/platformsettings",
				name: "Platform Settings",
				component: PlatformSettingsView,
			},
			{ path: "/ektokismos", name: "Ektokismos", component: EktokismosView },
			{ path: "/entitychanges", name: "Entity Changes", component: EntityChangesView },
			{ path: "/upload", name: "File Upload", component: UploadView },
			{ path: "/reports", name: "Report", component: ReportsView },
			{ path: "/reportpreview", name: "Report Preview", component: ReportPreview },
			{ path: "/metabase/:dashboard_id", name: "Metabase", component: MetabaseVue },
			// { path: "/test1", name: "Test1", component: () => import("../views/TestEktokismos.vue") },
			{ path: "/la_calculations/:loan_account_id", name: "LACalculations", component: LoanAccountCalculations },
			{ path: "/projects", name: "Projects", component: ProjectsView },
			{ path: "/projecttemplates", name: "Project Templates", component: ProjectTemplatesView },
			{ path: "/tasktemplates", name: "Task Templates", component: TaskTemplatesView },
			{ path: "/customfields", name: "Custom Fields", component: CustomFieldsView },
			{ path: "/indexing/frontier", name: "Frontier Physical File Indexing", component: Apografi_Frontier },

		],
	},
];

if (client === 'DOVALUE') {
	routes = [
		{ path: "/login", name: "Login", component: LoginView },
		{
			path: "/",
			redirect: "/dashboard",
			name: "Root",
			component: MainView,
			children: [
				{ path: "/users", name: "Users", component: UsersView, meta: { privileges: [UserType.Administrator] } },
				{
					path: "/privileges",
					name: "Privileges",
					component: PrivilegesView,
					meta: { privileges: [UserType.Administrator] },
				},
				{ path: "/clients", name: "Clients", component: ClientsView },
				{ path: "/portfolios", name: "Portfolios", component: PortfoliosView },
				{ path: "/debtors", name: "Debtors", component: DebtorView },
				{ path: "/loanagreements", name: "Loan Agreements", component: LoanAgreementsView },
				{
					path: "/platformsettings/interest/:catg_id",
					name: "Interest Data",
					component: AddInterestView,
				},
				{
					path: "/rob_selected",
					name: "Register of Bonds Selection",
					component: ROB_Selected,
					props: true
				},
				{ path: "/loanAccountInstallments/:loan_account_id", name: "Loan_Account_Ektokismos", component: LoanAccountEktokismosView },
				{ path: "/loanAgreementEktokismos/:loan_agreement_id", name: "Loan_Agreement_Ektokismos", component: LoanAgreementEktokismosView },

				{ path: "/dashboard", name: "Dashboard", component: DashboardView },
				{ path: "/approvals", name: "Approvals", component: ApprovalsView },
				{
					path: "/platformsettings",
					name: "Platform Settings",
					component: PlatformSettingsView,
					meta: { privileges: [UserType.Administrator] },
				},
				{ path: "/entitychanges", name: "Entity Changes", component: EntityChangesView },
				{ path: "/upload", name: "File Upload", component: UploadView },
				{ path: "/reports", name: "Report", component: ReportsView },
				{ path: "/reportpreview", name: "Report Preview", component: ReportPreview },
				{ path: "/metabase/:dashboard_id", name: "Metabase", component: MetabaseVue },
				// { path: "/test1", name: "Test1", component: () => import("../views/TestEktokismos.vue") },
				{ path: "/la_calculations/:loan_account_id", name: "LACalculations", component: LoanAccountCalculations },
			],
		},
	];
}

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to, from) => {
	const mainStore = useMainStore();
	const mosapi = useMOSAPIStore();
	let user_reply: APIReply<unknown> | undefined = undefined;

	console.log('from:', from.path, 'to:', to.path);

	if (mainStore.menuOrder.length === 0) {
		user_reply = await mosapi.user_get_current();
		console.log('user reply:', JSON.stringify(user_reply));

		if (user_reply?.status_code !== 401) {
			// Session is ok, fill data
			await mainStore.setUser(user_reply.data);
			const reply = await mosapi.user_get_screens();
			await mainStore.setup_menu(reply.data as MenuEntry[]);
		} else if (to.path === "/login") { // No auth and we wanna go to login
			return true;
		} else { // No auth and we wanna go to another page, redirect to login
			return "/login";
		}
	}

	const { skipFiltering = false } = to.meta;
	// // console.log('to meta', JSON.stringify(to.meta), 'to path:', to.path);

	if (!skipFiltering && user_reply?.status_code !== 401) {
		const route_that_starts_with_to_path_found = mainStore.filteredRoutes.filter(route_path => to.path.startsWith(route_path));
		// console.log('coocoo filtered routes:', JSON.stringify(mainStore.filteredRoutes), 'route_that_starts_with_to_path_found:', JSON.stringify(route_that_starts_with_to_path_found));
		if (route_that_starts_with_to_path_found.length === 0) {
			return "/dashboard";
		}
	}

	// We're authenticated but where trying to go to login page, redirect to dashboard
	if (to.path === "/login") {
		return "/dashboard";
	}
});

export default router;

