<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMOSAPIStore } from "../store/mos_api";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";
import Apografi_Frontier from "../classes/DB_Entities/Apografi_Frontier";
const { t } = useI18n({ useScope: "global" });
import type { FormInstance } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import Portfolio from "../classes/DB_Entities/Portfolio";
import { Entities } from "@/enums/Entities";
import APIReply from "@/classes/APIReply";
const mosapi = useMOSAPIStore();
const { showError, showSuccess } = useGenericMethodsVariables();

const emits = defineEmits(["save", "close", "fail-save"]);

const activeName = ref("first");
const mainStore = useMainStore();
const data = ref(new Apografi_Frontier());
const ruleFormRef = ref<FormInstance>();
const detailsVisible = ref(false);
const selectedRow = ref(-1);
const currentPortfolio = ref<Portfolio>(new Portfolio());
const portfolios = ref<any>([]);
const allowedUsers = ref<any>([]);
const allowedSupervisors = ref<any>([]);
const hasRowProblem = ref(false);

const props = defineProps<{
    row_id: number; // apografi_frontier_id
}>();

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;

    await formEl.validate((valid, fields) => {
        if (valid) {
            console.log("submit!");
            save();
        } else {
            console.log("error submit!", fields);
        }
    });
};

const loadData = async (entity_id: number) => {
    if (entity_id === -1) return;

    //const result = await .get(`apografi_frontier?id=eq.${entity_id}`);
    const result = await mosapi.generic_entity_get(Entities.ApografiFrontier, { id: entity_id }, 1, 1000);
    if (result.data[0].portfolio_id !== null) {
        //const portfolio = await .get(`portfolio?id=eq.${result.data[0].portfolio_id}`);
        const portfolio = await mosapi.generic_entity_get(Entities.Portfolios, { id: result.data[0].portfolio_id }, 1,1000) ;

        if (portfolio.data[0].length > 0)
            currentPortfolio.value = portfolio.data[0];
    }

    mainStore.isLoading = false;

    if (result.error) {
        showError(result.error);
        return;
    }

    data.value = result.data[0];
    if (data.value.row_problem) {
        hasRowProblem.value = data.value.row_problem.length > 0;
    }
    else {
        hasRowProblem.value = false;
    }
};

onMounted(async () => {
    hasRowProblem.value = false;
    mainStore.isLoading = true;
    await GetPortfolios();
    //console.log('loading digitized_la id', props.row_id);

    if (props.row_id === -1) {
        mainStore.isLoading = false;
        await GetUsers();
        await GetSupervisors();
        return;
    }

    await loadData(props.row_id);
    await GetUsers();
    await GetSupervisors();
});

const close = async () => {
    hasRowProblem.value = false;
    emits("close");
};

const onFailSave = async () => {
    selectedRow.value = -1;
    showError("Frontier Indexing row failed to update", 2000);
    detailsVisible.value = false;
    hasRowProblem.value = false;
};

const onInsert = async () => {
    selectedRow.value = -1;
    showSuccess("Frontier Indexing row saved Successfully", 1500);
    detailsVisible.value = false;
};

function isNumber(value?: string | number): boolean {
    return ((value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString())));
}

const save = async () => {
    let res:any;
    console.log(JSON.stringify(data.value.priority));
    if (!isNumber(data.value.priority) && (data.value.priority !== undefined && data.value.priority !== null)) {
        showError("Priority is not a number");
        return;
    }

    if (data.value.anathesi_mo_officer) {
        data.value.anathesi_mo_officer = parseInt(data.value.anathesi_mo_officer.toString());
    }

    // @ts-ignore
    if (data.value.anathesi_mo_officer == '') {
        // @ts-ignore
        data.value.anathesi_mo_officer = null;
    }

    if (data.value.mo_supervisor) {
        data.value.mo_supervisor = parseInt(data.value.mo_supervisor.toString());
    }

    // @ts-ignore
    if (data.value.mo_supervisor == '') {
        // @ts-ignore
        data.value.mo_supervisor = null;
    }

    if (data.value.id === -1) {
        const { allowed_users, ...newData } = data.value;
        data.value = newData as typeof data.value;
        //data.value.allowed_users.push((mainStore.loggedUser as any).id);
        console.log(data.value);
        //console.log("data.value.allowed_users: " + data.value.allowed_users);
        const obj = JSON.parse(JSON.stringify(data.value));
        delete obj.id;
        //result = await .post_data("apografi_frontier", obj);
        const result = await mosapi.generic_entity_post(Entities.ApografiFrontier, obj);
        res = result;
    } else {
        //const result = await .patch("apografi_frontier?id=eq." + props.row_id, data.value);
        const { allowed_users, ...newData } = data.value;
        data.value = newData as typeof data.value;
        const result = await mosapi.generic_entity_patch(Entities.ApografiFrontier, props.row_id, data.value);
        res = result;
    }

    //console.log(result);

    if (res.error) {
        onFailSave();
        return;
    }

    onInsert();

    emits("save");
};
const GetUsers = async () => {
    const result1 = await mosapi.users_get_all_active(UserType.Digitizer, true) as APIReply<{ id: number, username: string, first_name, last_name, deleted }[]>;
    const result2 = await mosapi.users_get_all_active(UserType.Supervisor, true) as APIReply<{ id: number, username: string, first_name, last_name, deleted }[]>;

    result1.data = result1.data.concat(result2.data);
    //let url = `users?&digitization=eq.true&id=in.(${data.value.allowed_users.join(",")})`;
    // const url = `users?&digitization=eq.true`;
    // const result = await .get(url + '&orderFIX=username');
    //const result = await mosapi.generic_entity_get(Entities.Users, { digitazion:true}, 1,1000);

    allowedUsers.value = [];

    for (const user of result1.data) {
        allowedUsers.value.push({
            value: user.id,
            label: `${user.first_name} ${user.last_name} (${user.username})`,
        })
    }
}
const GetSupervisors = async () => {
    //const url = `users?&id=in.(${currentPortfolio.value.allowed_users.join(",")}))`;
    //const result = await .get(url);

    const result = await mosapi.getUserSupervisors();

    allowedSupervisors.value = [];

    for (const user of result.data) {

        allowedSupervisors.value.push({
            value: user.id,
            label: `${user.first_name} ${user.last_name} (${user.username})`,
        })
    }
}
const GetPortfolios = async () => {
    // let url = `portfolio?select=*,mosclients!inner(clientname)`;

    // if (mainStore.loggedUser.user_type === UserType.Supervisor)
    //     url += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;

    // const result = await .get(url);
	const result = await mosapi.generic_entity_get(Entities.Portfolios, {	orderyBy:'portf_name.desc'	},1,10000);
	console.log('coco 1 ' +JSON.stringify(result.data))

    portfolios.value = [];

    for (const portfolio of result.data) {
        portfolios.value.push({
            value: portfolio.id,
            label: `${portfolio.portf_name} (${portfolio.mosclients.clientname})`,
        });
    }
};

const LoanTypes = [
    {
        value: "",
        label: "",
    },
    {
        value: "Other",
        label: t("Apografi_Frontier.LoanTypeOther"),
    },
    {
        value: "L3869",
        label: t("Apografi_Frontier.L3869"),
    },
    {
        value: "L4605",
        label: t("Apografi_Frontier.L4605"),
    },
];

const Y_N_blank = [
    {
        value: "",
        label: "",
    },
    {
        value: "Y",
        label: t("Apografi_Frontier.Y"),
    },
    {
        value: "N",
        label: t("Apografi_Frontier.N"),
    },

];

const RemedyCases = [
    {
        value: "",
        label: "",
    },
    {
        value: "Completed",
        label: t("Apografi_Frontier.Completed"),
    },
    {
        value: "PartiallyCompleted",
        label: t("Apografi_Frontier.PartiallyCompleted"),
    },
    {
        value: "NoRemedy",
        label: t("Apografi_Frontier.NoRemedy"),
    },
];

const entopismos_se_sustimaCases = [
    {
        value: "",
        label: "",
    },
    {
        value: "dms_ete",
        label: t("Apografi_Frontier.dms_ete"),
    },
    {
        value: "rc",
        label: t("Apografi_Frontier.rc"),
    },
    {
        value: "mitos",
        label: t("Apografi_Frontier.mitos"),
    }
];

const PendingCompleted = [
    {
        value: "",
        label: "",
    },
    {
        value: "Pending",
        label: t("Apografi_Frontier.Pending"),
    },
    {
        value: "Completed",
        label: t("Apografi_Frontier.Completed"),
    }
];

const Y_N_DeZhteitai = [
    {
        value: "",
        label: "",
    },
    {
        value: "Y",
        label: t("Apografi_Frontier.Y"),
    },
    {
        value: "N",
        label: t("Apografi_Frontier.N"),
    },
    {
        value: "deZhteitai",
        label: t("Apografi_Frontier.deZhteitai"),
    }
];

const Y_N_DeZhteitai_Entopistike = [
    {
        value: "",
        label: "",
    },
    {
        value: "Y",
        label: t("Apografi_Frontier.Y"),
    },
    {
        value: "N",
        label: t("Apografi_Frontier.N"),
    },
    {
        value: "deZhteitai",
        label: t("Apografi_Frontier.deZhteitai"),
    },
    {
        value: "entopistike_public_DMS",
        label: t("Apografi_Frontier.entopistike_public_DMS"),
    }
];

const teleutaia_pp_plirisCases = [
    {
        value: "",
        label: "",
    },
    {
        value: "Y",
        label: t("Apografi_Frontier.Y"),
    },
    {
        value: "N",
        label: t("Apografi_Frontier.N"),
    },
    {
        value: "deZhteitai",
        label: t("Apografi_Frontier.deZhteitai"),
    },
    {
        value: "denApaiteitaiLogwMirythmisis",
        label: t("Apografi_Frontier.denApaiteitaiLogwMirythmisis"),
    }
    ,
    {
        value: "vrethikan_eggrafa3869",
        label: t("Apografi_Frontier.vrethikan_eggrafa3869"),
    }
    ,
    {
        value: "den_vrethikan_eggrafa3869",
        label: t("Apografi_Frontier.den_vrethikan_eggrafa3869"),
    }
    ,
    {
        value: "DenApaiteitai_L4605",
        label: t("Apografi_Frontier.DenApaiteitai_L4605"),
    }
];

const teleutaia_pp_plirisCases_se_allo_sustima = [
    {
        value: "",
        label: "",
    },
    {
        value: "Y",
        label: t("Apografi_Frontier.Y"),
    },
    {
        value: "N",
        label: t("Apografi_Frontier.N"),
    },
    {
        value: "deZhteitai",
        label: t("Apografi_Frontier.deZhteitai"),
    },
    {
        value: "denApaiteitaiLogwMirythmisis",
        label: t("Apografi_Frontier.denApaiteitaiLogwMirythmisis"),
    }
    ,
    {
        value: "vrethikan_eggrafa3869",
        label: t("Apografi_Frontier.vrethikan_eggrafa3869"),
    }
    ,
    {
        value: "den_vrethikan_eggrafa3869",
        label: t("Apografi_Frontier.den_vrethikan_eggrafa3869"),
    }
    ,
    {
        value: "DenApaiteitai_L4605",
        label: t("Apografi_Frontier.DenApaiteitai_L4605"),
    },
    {
        value: "entopistike_public_DMS",
        label: t("Apografi_Frontier.entopistike_public_DMS"),
    }
];

const SubfolderCases = [
    {
        value: "",
        label: "",
    },
    {
        value: "ektimiseis_texn_fakelos",
        label: t("Apografi_Frontier.ektimiseis_texn_fakelos"),
    },
    {
        value: "aitisi_egkrisi_oikonomika_stoixeiaN",
        label: t("Apografi_Frontier.aitisi_egkrisi_oikonomika_stoixeia"),
    },
    {
        value: "sumvatika_eggrafa",
        label: t("Apografi_Frontier.sumvatika_eggrafa"),
    },
    {
        value: "prosim_upoth_dikogr",
        label: t("Apografi_Frontier.prosim_upoth_dikogr"),
    },
    {
        value: "eggrafa_epidik_kai_anagk_ektel",
        label: t("Apografi_Frontier.eggrafa_epidik_kai_anagk_ektel"),
    },
    {
        value: "dikografa_3869",
        label: t("Apografi_Frontier.dikografa_3869"),
    }
];

</script>

<template>
    <div v-loading="mainStore.isLoading">
        <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
            <el-tabs v-model="activeName" type="card" class="demo-tabs">
                <el-tab-pane :label="$t('Apografi_Frontier.DataTape')" name="first">
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-form-item :label="$t('DDebtorDetailsView.portfolio')">
                                <el-select v-model="data.portfolio_id" placeholder="Select" filterable
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)">
                                    <el-option v-for="item in portfolios" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item :label="$t('Apografi_Frontier.borrower_id')"><el-input
                                    v-model="data.borrower_id" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.borrower_name')"><el-input
                                    v-model="data.borrower_name" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.asset_class')">
                                <el-input v-model="data.asset_class" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.contract_id')"><el-input
                                    v-model="data.contract_id" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.loan_id_31_12_2021')"><el-input
                                    v-model="data.loan_id_31_12_2021" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.origination_date')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.origination_date" type="date"
                                    placeholder="Pick a day" size="default"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.loan_type_31_12_2021')">
                                <el-select v-model="data.loan_type_31_12_2021" :value="data.loan_type_31_12_2021"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)"
                                    placeholder=" ">
                                    <el-option v-for="loanType in LoanTypes" :label="loanType.label" :value="loanType.value" :key="loanType.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.loan_id_open_date_31_12_2021')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.loan_id_open_date_31_12_2021" type="date"
                                    placeholder="Pick a day" size="default"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.last_restructured_cut_off')"><el-input
                                    v-model="data.last_restructured_cut_off" class="ml-2"
                                    :active-text="$t('global.true')" :inactive-text="$t('global.false')"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.loan_id_30_06_2020')"><el-input
                                    v-model="data.loan_id_30_06_2020" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.origination_date_loan_id_30_06_2020')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.origination_date_loan_id_30_06_2020"
                                    type="date" placeholder="Pick a day" size="default"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.looking_for_initial')">
                                <el-input v-model="data.looking_for_initial" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />

                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.looking_for_last')">
                                <el-input v-model="data.looking_for_last" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />

                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.looking_for_collateral')">
                                <el-input v-model="data.looking_for_collateral" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />

                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.breach_category')"><el-input
                                    v-model="data.breach_category" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.management_branch_31_12_2021_meta_609')"><el-input
                                    v-model="data.management_branch_31_12_2021_meta_609" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.priority')"><el-input
                                    v-model="data.priority" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.activation_branch')"><el-input
                                    v-model="data.activation_branch" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.management_branch_30_06_2020')"><el-input
                                    v-model="data.management_branch_30_06_2020" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.management_branch_31_12_2021_me_609_pro_antik')"><el-input
                                    v-model="data.management_branch_31_12_2021_me_609_pro_antik" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.dms_status')">
                                <el-input v-model="data.dms_status" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.iron_mountain_deliv_status')">
                                <el-input v-model="data.iron_mountain_deliv_status" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.dovlaue_delivery_status')">
                                <el-input v-model="data.dovlaue_delivery_status" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />

                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.kwd')"><el-input
                                    v-model="data.kwd" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.katastasi')">
                                <el-input v-model="data.katastasi" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" />
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.sxolio')"><el-input
                                    v-model="data.sxolio" autocomplete="off"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator)" /></el-form-item></el-col>

                        <el-col :span="12">
                            <el-form-item :label="$t('Apografi_Frontier.anathesi_mo_officer')">
                                <el-select filterable clearable v-model="data.anathesi_mo_officer" autocomplete="off"
                                    :disabled="(data.id > 0 && (mainStore.loggedUser.user_type !== UserType.Administrator && mainStore.loggedUser.user_type !== UserType.Supervisor))"
                                    class="m-2 header-filter" placeholder="" style="width: 100%">
                                    <el-option v-for="item in allowedUsers" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.hmerominia_enarxis_epexergasias')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.hmerominia_enarxis_epexergasias" type="date"
                                    placeholder="Pick a day" size="default"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator) && mainStore.loggedUser.id !== data.anathesi_mo_officer" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.imerominia_olokl_epexerg')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.imerominia_olokl_epexerg" type="date"
                                    :disabled="(data.id > 0 && mainStore.loggedUser.user_type !== UserType.Administrator) && mainStore.loggedUser.id !== data.anathesi_mo_officer"
                                    placeholder="Pick a day" size="default" /></el-form-item></el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('Apografi_Frontier.mo_supervisor')">
                                <el-select clearable v-model="data.mo_supervisor" autocomplete="off"
                                    :disabled="(data.id > 0 && (mainStore.loggedUser.user_type === UserType.Agent || mainStore.loggedUser.user_type === UserType.Digitizer))"
                                    class="m-2 header-filter" placeholder="" style="width: 100%">
                                    <el-option v-for="item in allowedSupervisors" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.imeromhnia_elegxou')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.imeromhnia_elegxou" type="date"
                                    :disabled="(data.id > 0 && (mainStore.loggedUser.user_type === UserType.Agent || mainStore.loggedUser.user_type === UserType.Digitizer))"
                                    placeholder="Pick a day" size="default" /></el-form-item></el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane :label="$t('Apografi_Frontier.EpivevaiosiClaim')" name="second">

                    <el-row :gutter="10">
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.anartimeno_psif_iliko_public_dms')">
                                <el-select v-model="data.anartimeno_psif_iliko_public_dms" placeholder="Select"
                                    filterable>
                                    <el-option v-for="item in Y_N_blank" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.arxiki_sumvasi_pliris')">
                                <el-select v-model="data.arxiki_sumvasi_pliris" placeholder="Select" filterable>
                                    <el-option v-for="item in Y_N_DeZhteitai" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>

                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.teleutaia_pp_pliris')">
                                <el-select v-model="data.teleutaia_pp_pliris" placeholder="Select" filterable>
                                    <el-option v-for="item in teleutaia_pp_plirisCases" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.eggrafa_prosimiosis')">
                                <el-select v-model="data.eggrafa_prosimiosis" placeholder="Select" filterable>
                                    <el-option v-for="item in Y_N_DeZhteitai" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>

                            </el-form-item></el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24"><el-divider border-style="dotted" />
                            <h3>Path Initial Contract</h3>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.initial_loan_id')"><el-input
                                    v-model="data.initial_loan_id" autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.initial_contract_id')"><el-input
                                    v-model="data.initial_contract_id" autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.initial_digitization_date')"><el-input
                                    v-model="data.initial_digitization_date"
                                    autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.initial_subfolder')">
                                <el-select v-model="data.initial_subfolder" placeholder="Select" filterable>
                                    <el-option v-for="item in SubfolderCases" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.initial_pages')"><el-input
                                    v-model="data.initial_pages" autocomplete="off" /></el-form-item></el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24"><el-divider border-style="dotted" />
                            <h3>Path Last Amendment</h3>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.last_amend_loan_id')"><el-input
                                    v-model="data.last_amend_loan_id" autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.last_amend_contract_id')"><el-input
                                    v-model="data.last_amend_contract_id" autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.last_amend_digitization_date')"><el-input
                                    v-model="data.last_amend_digitization_date"
                                    autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.last_amend_subfolder')">
                                <el-select v-model="data.last_amend_subfolder" placeholder="Select" filterable>
                                    <el-option v-for="item in SubfolderCases" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.last_amend_pages')"><el-input
                                    v-model="data.last_amend_pages" autocomplete="off" /></el-form-item></el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24"><el-divider border-style="dotted" />
                            <h3>Path RE Collateral Documentation</h3>
                        </el-col>
                    </el-row><el-row :gutter="10">
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.re_collateral_loan_id')"><el-input
                                    v-model="data.re_collateral_loan_id" autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.re_collateral_contract_id')"><el-input
                                    v-model="data.re_collateral_contract_id"
                                    autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.re_collateral_digitization_date')"><el-input
                                    v-model="data.re_collateral_digitization_date"
                                    autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.re_collateral_subfolder')">
                                <el-select v-model="data.re_collateral_subfolder" placeholder="Select" filterable>
                                    <el-option v-for="item in SubfolderCases" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.re_collateral_pages')"><el-input
                                    v-model="data.re_collateral_pages" autocomplete="off" /></el-form-item></el-col>
                    </el-row><el-row :gutter="10">
                        <el-col :span="24">
                            <el-divider border-style="dotted" />
                            <h3>Ενέργειες Remedy</h3>
                        </el-col>
                    </el-row><el-row :gutter="10">
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.elegxos_dms')">
                                <el-select v-model="data.elegxos_dms" placeholder="Select" filterable>
                                    <el-option v-for="item in PendingCompleted" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.fakelos_rc')">
                                <el-select v-model="data.fakelos_rc" placeholder="Select" filterable>
                                    <el-option v-for="item in PendingCompleted" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.mitos')">
                                <el-select v-model="data.mitos" placeholder="Select" filterable>
                                    <el-option v-for="item in PendingCompleted" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.arxiki_sumv_pliris_se_allo_sustima')">
                                <el-select v-model="data.arxiki_sumv_pliris_se_allo_sustima" placeholder="Select"
                                    filterable>
                                    <el-option v-for="item in Y_N_DeZhteitai_Entopistike" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.teleutaia_pp_pliris_se_allo_sustima')">

                                <el-select v-model="data.teleutaia_pp_pliris_se_allo_sustima" placeholder="Select"
                                    filterable>
                                    <el-option v-for="item in teleutaia_pp_plirisCases_se_allo_sustima"
                                        :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.eggrafa_pros_se_allo_sustima')">

                                <el-select v-model="data.eggrafa_pros_se_allo_sustima" placeholder="Select" filterable>
                                    <el-option v-for="item in Y_N_DeZhteitai_Entopistike" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.entopismos_se_sustima')">
                                <el-select v-model="data.entopismos_se_sustima" placeholder="Select" filterable>
                                    <el-option v-for="item in entopismos_se_sustimaCases" :key="item.value"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select></el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.remedy')">

                                <el-select v-model="data.remedy" placeholder="Select" filterable>
                                    <el-option v-for="item in RemedyCases" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.apothikeusi_psifiakou_ulikou')"><el-input
                                    v-model="data.apothikeusi_psifiakou_ulikou"
                                    autocomplete="off" /></el-form-item></el-col>
                        <el-col :span="4">
                            <el-form-item style="border-bottom:0px" :label="$t('Apografi_Frontier.ektiposi')"><el-switch
                                    v-model="data.ektiposi" /></el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('Apografi_Frontier.ektiposi_date')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.ektiposi_date" type="date"
                                    placeholder="Pick a day" size="default" /></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-form-item :label="$t('Apografi_Frontier.remedy_actions_comment')">
                            <el-input v-model="data.remedy_actions_comment" style="width: 600px;" type="textarea"
                                :autosize="{ minRows: 4 }" />
                        </el-form-item>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="24">
                            <el-divider border-style="dotted" />
                            <h3>Επιβεβαίωση Καταχώρησης Ευρημάτων στο DMS Public</h3>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.epivevaiosi_kataxorisis')"><el-switch
                                    v-model="data.epivevaiosi_kataxorisis" /></el-form-item></el-col>
                        <el-col :span="12"><el-form-item
                                :label="$t('Apografi_Frontier.epivevaiosi_date')"><el-date-picker
                                    value-format="YYYY-MM-DD" v-model="data.epivevaiosi_date" type="date"
                                    placeholder="Pick a day" size="default" /></el-form-item></el-col>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.xristis')">
                                <el-select clearable v-model="data.xristis" autocomplete="off" class="m-2 header-filter"
                                    placeholder="" style="width: 100%">
                                    <el-option v-for="item in allowedUsers" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </el-form-item></el-col>
                        <el-col :span="12"><el-form-item :label="$t('Apografi_Frontier.arithmos_kivotiou')"><el-input
                                    v-model="data.arithmos_kivotiou" /></el-form-item></el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item class="btn-wrapper-components">
                        <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
                        <el-button @click="close">{{ $t('generic.close') }}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

    </div>
</template>

<style scoped></style>
