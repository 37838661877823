<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { CopyDocument, Edit, Delete, Plus, RefreshLeft } from "@element-plus/icons-vue";
import AddEdit_TaskTemplate from "../components/AddEdit_TaskTemplate.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";
import { Entities } from "@/enums/Entities";
import TaskTemplates from "@/classes/DB_Entities/TaskTemplates";

const { dataCount, last_page, showError, close } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const dataList = ref<TaskTemplates[]>([]);

const selectedRow = ref(-1);
const deleteDialogVisible = ref(false);
const addEditVisible = ref(false);
const addEditVisibleTask = ref(false);
const isNewEntity = ref(false);
const copyName = ref("");

const { onSortChange } = useSort();

const handleClickEdit = async (row_id: number) => {
  selectedRow.value = row_id;
  isNewEntity.value = false;
  addEditVisible.value = true;
};

onMounted(async () => {
  await loadPage(1);
});

const handleClickAdd = () => {
  selectedRow.value = -1;
  isNewEntity.value = true;
  addEditVisible.value = true;
};

const handleClickDelete = (row_id: number) => {
  selectedRow.value = row_id;
  deleteDialogVisible.value = true;
};

const handleClickCopy = async (row: TaskTemplates) => {
  mainStore.isLoading = true;
  try {
    console.log(JSON.stringify(row))
    const newObj = JSON.parse(JSON.stringify(row));
    newObj.name_en = copyName.value;
    newObj.name_el = newObj.name_en;
    delete newObj.id;
    await mosapi.generic_entity_post(Entities.TaskTemplates, newObj);
    // await .post_data("task_templates", newObj);
    copyName.value = "";
    await loadPage(last_page.value);
  } catch (ex) {
    showError(ex);
  }
  mainStore.isLoading = false;
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  last_page.value = page;

  const result = await mosapi.generic_entity_get<TaskTemplates[]>(Entities.TaskTemplates, {}, last_page.value);
  // let result:  = await .getPaginated("task_templates", page);

  if (result.error) {
    mainStore.isLoading = false;
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total;

  mainStore.isLoading = false;
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Task template failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const localSortChange = async (sortObj: { column: unknown, prop: string, order: string }) => {
  onSortChange(sortObj);
  await loadPage(last_page.value);
};

const onCloseAddEdit = async () => {
  /*let result = await .patch("mosclients?id=eq." + selectedRow.value, { lockedrow: 'false' });*/
  await loadPage(last_page.value);
  addEditVisible.value = false;
};
const onCloseAddEditTask = async () => {
  /*let result = await .patch("mosclients?id=eq." + selectedRow.value, { lockedrow: 'false' });*/
  await loadPage(last_page.value);
  addEditVisibleTask.value = false;
};

const parentBorder = ref(false)

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Task Template added successfully",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(last_page.value);
};

</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("TaskTemplatesList.name") }} <!--({{ dataCount }})--></h3>
      </el-col>
    </el-row>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(last_page)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" :border="parentBorder" style="width: 100%" stripe table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">

      <el-table-column :label="$t('TaskTemplatesList.name_el')" prop="name_el" />
      <el-table-column :label="$t('TaskTemplatesList.name_en')" prop="name_en" />

      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" style="background-color: #d6d6d6 !important;" />
          <el-popover placement="right" :width="400" trigger="click">
            <template #reference>
              <el-button link type="primary" size="small" class="btn-detail" :icon="CopyDocument"
                style="background-color: #d6d6d6 !important;" />

            </template>
            <template #default>
              <el-input v-model="copyName" placeholder="English name of new task template" style="width: 300px" />
              <el-button :disabled="copyName === ''" @click="handleClickCopy(scope.row)">Copy</el-button>
            </template>

          </el-popover>

          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
        </template>
      </el-table-column>
    </el-table>


    <div><!--
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
      -->
    </div>

    <el-dialog width="80%" v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Task Template' : 'Task Template Details'">
      <AddEdit_TaskTemplate :tasktemplateid="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit"
        @save="onAdd" @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-if="addEditVisibleTask" v-model="addEditVisibleTask" @update:model-value="onCloseAddEditTask"
      :title="selectedRow === -1 ? 'Add Task' : 'Task Details'">
      <el-form>
      </el-form>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="close">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-dialog>

    <DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Task Template" :table="Entities.TaskTemplates"
      title="Delete Task Template" width="50%" @close="deleteDialogVisible = false" />
  </div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {
  margin: 0px !important;
}

.el-transfer {
  --el-transfer-panel-width: 400px;
}
</style>
