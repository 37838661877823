<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";

import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import Printer from "../classes/DB_Entities/Printer";
import { GroupResponseDto, PrivilegeResponseDto } from "@/models";
import { Entities } from "@/enums/Entities";
import { orderBy } from "lodash";
const { showError, showLocalizedError } = useGenericMethodsVariables();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();


type FormData = {
	username: string,
	first_name: string,
	last_name: string,
	company: string,
	password: string,
	email: string,
	user_type: UserType,
	digitization: boolean,
	assigned_printer: string
	privileges: number[],
	groups: number[]
	errors: string[]
};
const formDataInitialState = {
	username: "",
	first_name: "",
	last_name: "",
	company: "",
	password: "",
	email: "",
	user_type: 2,
	digitization: false,
	assigned_printer: "",
	privileges: [],
	groups: [],
	errors: []
}
const formData = ref<FormData>(formDataInitialState);

const printersList = ref<{ value: string, label: string }[]>([]);
const privileges = ref<{ key: number, label: string }[]>([]);
const groups = ref<{ key: number, label: string }[]>([]);
const passwordconfirm = ref<string>("")
const datapass = ref<{ passwordconfirm: string, errors: string[] }>({
	passwordconfirm: "",
	errors: [],
});

onMounted(async () => {
	await Promise.all([loadPrivileges(), loadGroups(), loadPrinters(),]);
});

const close = async () => {
	emits("close");
};

const resetData = async () => {
	formData.value = formDataInitialState;
	passwordconfirm.value = ""
}

const checkPassword = (newPassOne: string, newPassTwo:string): string => {
	if (newPassOne !== newPassTwo) {
		return "Passwords must match";
	}

	if (newPassOne.length < 8) {
		return "Password must be at least 8 characters long.";
	}

	const hasUpperCase = /[A-Z]/.test(newPassOne) ? 1 : 0;
	const hasLowerCase = /[a-z]/.test(newPassOne) ? 1 : 0;
	const hasNumbers = /\d/.test(newPassOne) ? 1 : 0;
	const hasNonalphas = /\W/.test(newPassOne) ? 1 : 0;

	if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
		return "Password must contain at least one uppercase letter, one lowercase letter, one digit and one non-alphanumeric character (e.g. !, @, #, $)";
	}

	return "";
};

const save = async () => {
	const { password, first_name, last_name, user_type, privileges, groups } = formData.value
	const { passwordconfirm } = datapass.value;
	formData.value.errors = [];
	datapass.value.errors = [];
	if (password !== '' || passwordconfirm !== '') {
		const pass_check = checkPassword(password, passwordconfirm);
		if (pass_check !== '') {
			datapass.value.errors.push(pass_check);
		}
	}
	if (datapass.value.errors.length > 0) {
		for (const error of datapass.value.errors) {
			showError(error);
		}

		return;
	}
	if (password != passwordconfirm) { formData.value.errors.push('Passwords do not match.'); }
	if (first_name == "") { formData.value.errors.push('First name cant be empty.'); }
	if (last_name == "") { formData.value.errors.push('Last name cant be empty.'); }
	if(privileges.length === 0){formData.value.errors.push('Privileges cant be empty.');}
	if(groups.length === 0){formData.value.errors.push('Groups cant be empty.');}
	if (formData.value.errors.length > 0) {
		for (const error of formData.value.errors) {
			showError(error);
		}
		return;
	}
	if (user_type == 3 || user_type == 0) {
		formData.value.digitization = true;
	} else if (user_type == 2) {
		formData.value.digitization = false;
	}
	const obj = JSON.parse(JSON.stringify(formData.value));
	delete obj.errors;
	const result = await mosapi.createUser(obj);

	if (result.error) {
		showLocalizedError(result.error); // emits("fail-save");
		return;
	}
	resetData();
	emits("save");
};

const loadPrinters = async () => {
	

	//result = await .get("printers?orderFIX=printer_name.asc");
	const result = await mosapi.generic_entity_get(Entities.Printers, {orderBy:'printer_name'}, 1, 1000);
	if (result.error) {
		showError(result.error);
		return;
	}

	printersList.value = [];
	result.data.forEach((element: Printer) => {
		printersList.value.push({
			value: element.printer_name,
			label: `${element.printer_name} (${element.printer_ip})`,
		});
	});
};

const loadPrivileges = async () => {
	const result = await mosapi.generic_entity_get(Entities.Privileges);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}
	if (result.data)
		privileges.value = result.data?.map((privilege: PrivilegeResponseDto) => ({
			key: privilege.id,
			label: privilege.name,
		}));
};

const loadGroups = async () => {
	const result = await mosapi.getGroups();

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	groups.value = result.data?.map((group: GroupResponseDto) => ({
		key: group.id,
		label: group.name
	})) ?? [];
};

</script>

<template>
	<div>
		<div v-if="formData.errors.length">
			<ul>
				<li v-for="(error, index) in formData.errors" :key="index">{{ error }}</li>
			</ul>
		</div>
		<el-form :model="formData">
			<el-row>
				<el-col :span="24">
					<el-form-item label="Username">
						<el-input type="text" v-model="formData.username" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="Password">
						<el-input v-model="formData.password" type="password" placeholder="Please input password"
							autocomplete="off" show-password />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="24">
					<el-form-item label="Confirm Password">
						<el-input v-model="datapass.passwordconfirm" type="password" placeholder="Please confirm your password"
							autocomplete="off" show-password />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="First Name">
						<el-input v-model="formData.first_name" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="Surname">
						<el-input v-model="formData.last_name" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="E-mail">
						<el-input v-model="formData.email" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="Company">
						<el-input v-model="formData.company" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="User Type">
						<el-select v-model="formData.user_type" placeholder="Please Select User Type">
							<el-option label="Administrator" :value="UserType.Administrator" />
							<el-option label="Supervisor" :value="UserType.Supervisor" />
							<el-option label="Agent" :value="UserType.Agent" />
							<el-option label="Digitizer" :value="UserType.Digitizer" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="12" v-if="formData.user_type == UserType.Supervisor">
					<el-form-item label="Has digitization rights">
						<el-switch v-model="formData.digitization" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10" v-if="formData.digitization || formData.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Barcode Printer">
						<el-select v-model="formData.assigned_printer" placeholder="Select printer">
							<el-option v-for="(printer, index) in printersList" :label="printer.label"
								:value="printer.value" :key="index" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10" v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Privileges">
						<el-transfer v-model="formData.privileges" :data="privileges"
							:titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Groups">
						<el-transfer v-model="formData.groups" :data="groups" :titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save()">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
