import { useRouter } from "vue-router";
import { ref } from "vue";
import { defineStore } from "pinia";
import LoginRequest from "../classes/LoginRequest";
import CreateUserRequest from "../classes/CreateUserRequest";
import APIReply from "../classes/APIReply";
import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import {
	UserRequestDto,
	EntityChangeAddRequestDto,
	EntityChangeUpdateRequestDto,
	GroupRequestDto
} from "@/models";

import { Entities } from "@/enums/Entities";
import Decision from "@/classes/Decision";
import ChangeApprovalStatus from "@/enums/ChangeApprovalStatus";
import UserType from "@/enums/UserType";
import MenuEntry from "@/classes/DB_Entities/MenuEntry";
import { UIViews } from "@/enums/UIViews";

import { useMainStore } from "./main";
import { AuthResponseDto } from "@/types/Auth";

export const useMOSAPIStore = defineStore("mos_api", () => {

	const mainStore = useMainStore();
	const router = useRouter();

	const ip = ref<string>(import.meta.env.VITE_MOSAPI_URL);
	const port = ref<number>(0);
	const token = ref<string>("");

	const apiURL = (): string => {
		if (port.value === 0) return ip.value;

		return `${ip.value}:${port.value}/`;
	};

	const setToken = async (token_: string) => {
		token.value = token_;
	};

	function unsetToken() {
		token.value = "";
	}

	const handleReply = <T>(result: AxiosResponse<APIReply<T>>, redirect_on_401 = true): APIReply<T> => {
		// console.log('coocoo handleReply result', JSON.stringify(result));

		if (result?.status === 401) {
			mainStore.unsetUser();
			mainStore.isLoading = false;
			if (redirect_on_401) {
				router.push("/login");
			}
		}

		if (result?.data?.error !== undefined) {
			if (result.data.status_code === 401) { // Back to login page if unauthorized
				mainStore.unsetUser();
				mainStore.isLoading = false;
				if (redirect_on_401) {
					router.push("/login");
				}
			}
		}

		// Update session time left, if we receive it in the reply
		if (result?.data?.session_time_left !== undefined) {
			mainStore.remaining_time = result.data.session_time_left;
			mainStore.remaining_time_received_at = DateTime.now().toMillis();
		}

		return { data: result?.data?.data ? result.data.data : [], status_code: result.status, total: result?.data?.total, error: result?.data?.error };
	}

	const filters_to_query_params = (filters: { [key: string]: unknown }) => {
		let query_params = "";

		for (const key in filters) {
			if (filters[key] !== "" && filters[key] !== undefined && filters[key] !== null) {
				query_params += `&${key}=${filters[key]}`;
			}
		}

		return query_params;
	}

	// Default first page, 10 items per page
	const get_view = async <T>(view_name: UIViews, filters: { [key: string]: unknown }, page: number = 1, page_size: number = 99999): Promise<APIReply<T[]>> => {
		// console.log('get_view', view_name, page, page_size, 'filters', JSON.stringify(filters));

		let query_params = "";

		if (mainStore.globalDebtorID !== '')
			query_params += `&debtor_id=${mainStore.globalDebtorID}`;
		if (mainStore.globalPortfolioID !== '')
			query_params += `&portfolio_id=${mainStore.globalPortfolioID}`;
		if (mainStore.globalLoanAgreementID !== '')
			query_params += `&loan_agreement_id=${mainStore.globalLoanAgreementID}`;

		query_params += filters_to_query_params(filters);
		query_params += `&limit=${page_size}&offset=${(page - 1) * page_size}`;

		return handleReply(await axios.get(`${apiURL()}views/${view_name}?${query_params}`));
	}

	const get_users_for_portfolio = async (portfolio_id: number): Promise<APIReply<{ user_id: number, portfolio_id: number }[]>> => {
		return handleReply(await axios.get(`${apiURL()}portfolios/${portfolio_id}/access`)) as APIReply<{ user_id: number, portfolio_id: number }[]>;
	}

	const post_users_for_portfolio = async (portfolio_id: number, user_id_list: number[]): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}portfolios/${portfolio_id}/access`, { user_id_list })) as APIReply<unknown>;
	}

	const get_users_for_debtor = async (debtor_id: number): Promise<APIReply<{ user_id: number, debtor_id: number }[]>> => {
		return handleReply(await axios.get(`${apiURL()}debtor/${debtor_id}/access`)) as APIReply<{ user_id: number, debtor_id: number }[]>;
	}

	const post_users_for_debtor = async (debtor_id: number, user_id_list: number[]): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}debtor/${debtor_id}/access`, { user_id_list })) as APIReply<unknown>;
	}

	const get_users_for_d_debtor = async (d_debtor_id: number): Promise<APIReply<{ user_id: number, d_debtor_id: number }[]>> => {
		return handleReply(await axios.get(`${apiURL()}d_debtor/${d_debtor_id}/access`)) as APIReply<{ user_id: number, d_debtor_id: number }[]>;
	}

	const post_users_for_d_debtor = async (d_debtor_id: number, user_id_list: number[]): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}d_debtor/${d_debtor_id}/access`, { user_id_list })) as APIReply<unknown>;
	}

	// Retrieves the current logged in user, based on session
	const user_get_current = async (): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}users/current`), false);
	}

	const user_get_screens = async (): Promise<APIReply<MenuEntry[]>> => {
		return handleReply(await axios.get(`${apiURL()}users/screens`)) as APIReply<MenuEntry[]>;
	}

	const generic_entity_get = async <T>(table_name: Entities, filters: { [key: string]: unknown } = {}, page: number | undefined = undefined, page_size: number | undefined = undefined): Promise<APIReply<T>> => {
		let query_params = "";

		query_params += filters_to_query_params(filters);
		console.log('adadadarararara', table_name);
		if (page && page_size)
			query_params += `&limit=${page_size}&offset=${(page - 1) * page_size}`;
		console.log('res123: ' + apiURL() + table_name + query_params)
		return handleReply(await axios.get(`${apiURL()}${table_name}?${query_params}`));
	}

	const generic_entity_post = async (table_name: Entities, entity: { [key: string]: unknown }): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}${table_name}`, entity));
	}

	const generic_entity_post_bulk = async (table_name: Entities, entities: { [key: string]: unknown }[]): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}${table_name}/bulk`, entities));
	}

	const generic_entity_patch = async (table_name: Entities, entity_id: number, entity: { [key: string]: unknown }): Promise<APIReply<unknown>> => {
		return handleReply(await axios.patch(`${apiURL()}${table_name}/${entity_id}`, entity));
	}

	const generic_entity_delete = async (table_name: Entities, entity_id: number): Promise<APIReply<unknown>> => {
		return handleReply(await axios.delete(`${apiURL()}${table_name}/${entity_id}`));
	}

	const get_pending_changes = async (table_name: Entities, entity_id: number): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}entity_changes/pending_changes/${table_name}/${entity_id}`));
	}

	const clients_get_one_by_id = async (client_id: number): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}clients/${client_id}`));
	}

	const clients_get_one_by_name = async (clientname: string): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}clients/byName/${clientname}`));
	}

	const clients_get_one_by_name_and_other_id = async (clientname: string, client_id: number): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}clients/byNameAndOtherId/${clientname}/${client_id}`));
	}

	const clients_get_all = async (): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}clients`));
	}

	const login = async (username: string, password: string): Promise<APIReply<AuthResponseDto>> => {
		const body: LoginRequest = {
			username,
			password,
		};

		const fv = handleReply<AuthResponseDto>(await axios.post(apiURL() + "login/", body));
		// console.log('coocoo', JSON.stringify(fv));

		if (fv?.error === undefined) {
			fv.data[0].screens = fv.data[0].menu;
		}


		return fv;
	};

	const add_entity_change = async (entity_change_create_request: EntityChangeAddRequestDto): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}entity_changes`, entity_change_create_request));
	}

	const update_entity_change = async (entity_change_update_request: EntityChangeUpdateRequestDto): Promise<APIReply<unknown>> => {
		return handleReply(await axios.patch(`${apiURL()}entity_changes`, entity_change_update_request));
	}

	const delete_entity = async (table_name: Entities, entity_id: number): Promise<APIReply<unknown>> => {
		return handleReply(await axios.delete(`${apiURL()}${table_name}/${entity_id}`));
	}

	const getUser = async (userId: number): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}users/${userId}`));
	}

	const users_get_all_active = async (user_type: UserType, digitization_flag: boolean): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}users/getAllActiveUsers/${user_type}/${digitization_flag}`));
	}

	const createUser = async (userRequest: CreateUserRequest): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}users/`, userRequest));
	}

	const updateUser = async (userId: number, userRequest: UserRequestDto): Promise<APIReply<unknown>> => {
		return handleReply(await axios.patch(`${apiURL()}users/${userId}`, userRequest));
	}

	const updateUserLanguage = async (language: string): Promise<APIReply<unknown>> => {
		return handleReply(await axios.post(`${apiURL()}users/changeLanguage`, { language }));
	}

	const updatePassword = async (user_id: number, password: string): Promise<APIReply<unknown>> => {
		const body = {
			id: user_id,
			password: password
		};

		return handleReply(await axios.post(apiURL() + "updatePassword", body));
	};

	const getGroups = async (): Promise<APIReply<unknown>> => {
		return handleReply(await axios.get(`${apiURL()}groups`));
	}

	const getSummaryCheckKOD = async (loan_agreement_id: number, ar_sumbashs: string): Promise<string> => {
		try {
			const result = await axios.post(apiURL() + "export/summary_check_kod/" + loan_agreement_id, {}, { responseType: 'blob' });

			// console.log(JSON.stringify(result?.data))
			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `summary_check_kod_${ar_sumbashs}_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			return `Could not find all required data for loan agreement summary - ${err}`;
		};

		return "";
	}

	const getSummaryCheckAL = async (loan_agreement_id: number, ar_sumbashs: string) => {
		try {
			const result = await axios.post(apiURL() + "export/summary_check_al/" + loan_agreement_id, {}, { responseType: 'blob' });

			// console.log(JSON.stringify(result))
			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `summary_check_al_${ar_sumbashs}_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			if (err.response?.status === 405) {
				// console.log(await err.response.data.text());
				return JSON.parse((await err.response.data.text())).error.message;
			} else {
				console.log(err);
			}

			return 'Could not find all required data for loan agreement summary.';
		};

		return "";
	}

	const getexportDT = async (portfolio_id: number): Promise<string> => {
		try {
			const result = await axios.post(apiURL() + "export_digitized/digitizedDT/" + portfolio_id, {}, { responseType: 'blob' });

			// console.log(JSON.stringify(result?.data))
			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `datatape_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			return 'Could not find all required data for loan agreement summary - ' + err;
		};

		return "";
	}

	const getexportProt = async (portfolio_id: number): Promise<string> => {
		try {
			const result = await axios.post(apiURL() + "export_digitized/digitizedProt/" + portfolio_id, {}, { responseType: 'blob' });

			// console.log(JSON.stringify(result?.data))
			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `protocol_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			return 'Could not find all required data for loan agreement summary - ' + err;
		};

		return "";
	}

	const getexportProtFrontier = async (portfolio_id: number): Promise<string> => {
		try {
			const result = await axios.post(apiURL() + "frontierProtocol/frontierProtocol/" + portfolio_id, {}, { responseType: 'blob' });

			// console.log(JSON.stringify(result?.data))
			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `protocol_FC_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			return 'Could not find all required data for loan agreement summary - ' + err;
		};

		return "";
	}

	const forceDownload = (result: AxiosResponse, filename: string) => {
		const url = window.URL.createObjectURL(new Blob([(result as AxiosResponse).data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	}

	const updateEntityApproveStatus = async (table_name: string, entity_id: number): Promise<APIReply<unknown>> => {
		const body = {
			table_name,
			entity_id
		}

		return handleReply(await axios.post(apiURL() + "updateEntityApproveStatus", body));
	};

	const downloadLog = async (filename: string) => {
		try {
			const result = await axios.post(apiURL() + "getLog/" + filename, {}, { responseType: 'blob' });

			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			forceDownload(result as AxiosResponse, filename + '.log');
		} catch (err) {
			console.log(err);
			return 'Could not retrieve import log file';
		};

		return "";
	}

	const getDebtorSummary = async (debtor_id: number) => {
		try {
			const result = await axios.post(apiURL() + "reports/debtor_group?debtor_id=" + debtor_id, {}, { responseType: 'blob' });

			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `debtor_summary_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			console.log(err);
			return 'Could not get debtor summary.';
		};

		return "";
	}

	const getGroupSummary = async (group_id: number) => {
		try {
			const result = await axios.post(apiURL() + "reports/debtor_group?group_id=" + group_id, {}, { responseType: 'blob' });

			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

			const filename = `group_summary_${DateTime.now().toFormat('yyyy-MM-dd_HH_mm_ss')}.xlsx`
			forceDownload(result as AxiosResponse, filename)
		} catch (err) {
			console.log(err);
			return 'Could not get debtor summary.';
		};

		return "";
	}

	const notify = async (obj: unknown) => {
		try {
			const result = await axios.post(apiURL() + "notify", obj, { responseType: 'blob' });

			if (result?.data.error !== undefined) {
				return result.data.error.message;
			}

		} catch (err) {
			console.log(err);
		};
	}

	const getUserSupervisors = async () => {
		return handleReply(await axios.get(`${apiURL()}users/getSupervisors`))
	};

	const getUserByUsername = async (username: string) => {
		return handleReply(await axios.get(`${apiURL()}users/getUserByUsername/${username}`))
	};

	const getUserMosOfficers = async () => {
		return handleReply(await axios.get(`${apiURL()}users/getMosOfficers`));
	};

	const updateDecision = async (decision: Decision) => {
		console.log('*************************');
		console.log(JSON.stringify(decision));
		const entity_change_update_request: EntityChangeUpdateRequestDto = {
			id: decision.db_change_id as number,
			entity_type: decision.table_name as Entities, // entity_type: table_name.value as Entities,
			approval_status: decision.accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected,
			field: decision.field,
			value: decision.value
		}
		await update_entity_change(entity_change_update_request); // approval status is handled on the backend

		// set to approve_status = 1 if no more pending changes
		// TODO await updateEntityApproveStatus(decision.table_name as Entities, decision.entity_id as number);
	};

	const getMenu = async (menu_id: number) => {
		return handleReply(await axios.get(`${apiURL()}menus/${menu_id}`));
	};

	const getMenus = async () => {
		return handleReply(await axios.get(`${apiURL()}menus`));
	};

	const deleteGroup = async (group_id: number) => {
		return handleReply(await axios.delete(`${apiURL()}groups/${group_id}`));
	};

	const getGroup = async (group_id: number) => {
		return handleReply(await axios.get(`${apiURL()}groups/${group_id}`));
	}

	const getGroupsPaginated = async (paginated: boolean, page: number, page_size: number = 10) => {
		return handleReply(await axios.get(`${apiURL()}groups?paginated=${paginated}&page=${page}&pageSize=${page_size}`));
	};

	const updateGroup = async (id: number, data: GroupRequestDto) => {
		return handleReply(await axios.put(`${apiURL()}groups/${id}`, data));
	};

	const download_file = async (entity_type: Entities, entity_id: number, file_id: number) => {

		const headers = {
			Accept: 'application/octet-stream',
		};

		try {
			const result = await axios.get(apiURL() + `upload/${entity_type}/${entity_id}/${file_id}`, { headers, responseType: 'arraybuffer' });
			console.log(JSON.stringify(result))
			return result.data;
		} catch (err: any | AxiosError) {
			console.log(err);
			return undefined;
		}
	};

	const delete_file = async (entity_type: Entities, entity_id: number, file_id: number) => {
		return handleReply(await axios.delete(`${apiURL()}upload/${entity_type}/${entity_id}/${file_id}`));
	};

	const get_file_list = async (entity_type: Entities, entity_id: number) => {
		console.log('file list for', entity_type, entity_id);
		return handleReply(await axios.get(`${apiURL()}upload/${entity_type}/${entity_id}`));
	};

	const reset_attempts = async (user_id: number) => {
		return handleReply(await axios.post(`${apiURL()}users/${user_id}/resetAttempts`));
	};

	const logout = async () => {
		return handleReply(await axios.delete(`${apiURL()}login`));
	}

	return {
		ip,
		port,
		token,
		apiURL,
		downloadLog,
		getDebtorSummary,
		getGroupSummary,
		getSummaryCheckAL,
		getSummaryCheckKOD,
		login,
		notify,
		setToken,
		unsetToken,
		createUser,
		updateEntityApproveStatus,
		updateUser,
		updatePassword,
		getexportDT,
		getexportProt,
		getexportProtFrontier,
		getUser,
		users_get_all_active,
		user_get_current,
		user_get_screens,
		clients_get_all,
		clients_get_one_by_id,
		clients_get_one_by_name,
		clients_get_one_by_name_and_other_id,
		getUserSupervisors,
		getUserMosOfficers,
		delete_entity,
		add_entity_change,
		update_entity_change,
		generic_entity_get,
		generic_entity_patch,
		generic_entity_post,
		generic_entity_post_bulk,
		generic_entity_delete,
		get_pending_changes,
		updateDecision,
		get_users_for_portfolio,
		post_users_for_portfolio,
		get_users_for_debtor,
		post_users_for_debtor,
		get_users_for_d_debtor,
		post_users_for_d_debtor,
		get_view,
		getGroup,
		getGroups,
		getGroupsPaginated,
		getMenu,
		getMenus,
		deleteGroup,
		updateGroup,
		getUserByUsername,
		download_file,
		delete_file,
		get_file_list,
		updateUserLanguage,
		reset_attempts,
		logout,
	};
});
