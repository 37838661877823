<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import type { FormInstance } from "element-plus";
import ConfigInterest from "../classes/DB_Entities/ConfigInterest";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "../store/main";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();

const props = defineProps({
  interestid: Number,
});
const currencies = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const mainStore = useMainStore();
const data = ref(new ConfigInterest());
const ruleFormRef = ref<FormInstance>();
const mosapi = useMOSAPIStore();
const isLoading = ref(false);

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.interestid,
  async (newVal, oldVal) => {
    await loadInterests(props.interestid as number);
  }
);

onMounted(async () => {
  await loadInterests(props.interestid as number);
  await loadCurrencies();
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const interest_id = data.value.id as number;
  delete data.value.id;
  const result = await mosapi.generic_entity_patch(Entities.ConfigInterest, interest_id, data.value);
  if (result.error) {
    showError(result.error);
    return;
  }
  emits("save");
};

const loadInterests = async (interest_id: number) => {
  mainStore.isLoading = true;
  if (interest_id === -1) return;

  const result = await mosapi.generic_entity_get(Entities.ConfigInterest, { id: interest_id });
  if (result.error) {
    showError(result.error);
    return;
  }

  data.value = result.data[0];
  mainStore.isLoading = false;
};

const loadCurrencies = async () => {
  const result = await mosapi.generic_entity_get(Entities.ConfigCurrency, {}, 1, 999);
  if (result.error) {
    showError(result.error);

    return;
  }
  currencies.value = result.data;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.interest_categories_details')">
            <el-input v-model="data.interest" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.currencies_details')">
            <el-select v-model="data.currency" placeholder="Select Currency">
              <el-option v-for="currency in currencies" :key="currency.id" :label="currency.currency"
                :value="currency.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.automato_epitokio')">
            <el-switch v-model="data.automato_epitokio">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
