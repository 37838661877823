<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { useMainStore } from "../store/main";
import { Bell, Notebook, Download } from "@element-plus/icons-vue";
import { useMOSAPIStore } from "../store/mos_api";
import { ref, onMounted } from "vue";
import { RefreshLeft, Loading } from "@element-plus/icons-vue";
import { useSort } from "../composables/sort";
import { useRouter } from "vue-router";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { locale } = useI18n({ useScope: "global" });
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import { GenericEntity } from "@/types/GenericEntity";

const {
  showError,
  setup_global_filter_listener,
  dataList,
  dataCount,
  last_page,
} = useGenericMethodsVariables();

const router = useRouter();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const LoadSummaryCheckToggler = ref(true);
const { onSortChange, sortColumn, sortOrder } = useSort();
const selectedLoanAgreementID = ref<string>("");
const filters = ref<any>( {
  contract_number: "",
  fao: "",
  vat: "",
  product: "",
  pending: false,
})

const timer = ref<any>(null);
const productTypes = ref<GenericEntity[]>([]);

onMounted(async () => {
  setup_global_filter_listener(reloadPage);
  productTypes.value = (await mosapi.generic_entity_get(Entities.ConfigProduct, {}, 1, 1000)).data as GenericEntity[];
  await loadPage(1);
});

const reloadPage = async () => {
  await loadPage(last_page.value);
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  last_page.value = page;

  // if (sortOrder.value !== "") {
  //   filterUrl += `&orderFIX=${sortColumn.value}.${sortOrder.value}`;
  // }
	if (sortColumn.value ==='') sortColumn.value = 'ar_sumbashs';
  const result = await mosapi.get_view(UIViews.SummaryCheck, {...filters.value, orderBy:`${sortColumn.value}.${sortOrder.value}`}, page, 10);
  if (result.error) {
    showError(result.error);
    mainStore.isLoading = false;
    return;
  }

  dataList.value = result.data as any;
  dataCount.value = result.total as any;

  mainStore.isLoading = false;

  // Download all for testing
  // for (let i = 0; i < dataList.value.length; ++i) {
  //   console.log(i);
  //   await DownloadExport(dataList.value[i]);
  // }
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(last_page.value);
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const handleClick = async (row: any) => {
  LoadSummaryCheckToggler.value = false;
  selectedLoanAgreementID.value = row.loan_agreement_id;


  if (row.product_type == "summary_check_KOD") {
    router.push({
      name: "Summary Check KOD",
      params: { loan_agreement_id: row.loan_agreement_id },
    });
  } else if (row.product_type == "summary_check_OD") {
    router.push({
      name: "Summary Check OD",
      params: { loan_agreement_id: row.loan_agreement_id },
    });
  } else if (row.product_type == "summary_check_another_loan") {
    router.push({
      name: "Summary Check AL",
      params: { loan_agreement_id: row.loan_agreement_id },
    });
  }
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const DownloadExport = async (row: any) => {
  //(product_type: string, loan_agreement_id: number) => {
  if (row.product_type === "summary_check_KOD") {
    try {
      row.is_loading = true;
      const res = await mosapi.getSummaryCheckKOD(row.loan_agreement_id, row.ar_sumbashs);

      if (res !== "") {
        showError(res);
      }

    } catch (ex) {
      console.log(ex);
    }

    row.is_loading = false;
    return;
  }

  if (row.product_type === "summary_check_another_loan") {
    try {
      row.is_loading = true;
      const res = await mosapi.getSummaryCheckAL(row.loan_agreement_id, row.ar_sumbashs);

      if (res !== "") {
        showError(res);
      }

    } catch (ex) {
      console.log(ex);
    }

    row.is_loading = false;
    return;
  }
};
</script>

<template style="height: 100%">
  <div class="content-wrapper" v-loading="mainStore.isLoading" v-if="LoadSummaryCheckToggler == true">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("SummaryCheckView.summary_check") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="filters.contract_number" class="client-input"
            :placeholder="$t('SummaryCheckView.loan_agreement')" @input="filterChanged" />
        </el-col>
        <el-col :span="5">
          <el-input v-model="filters.fao" class="client-input" :placeholder="$t('SummaryCheckView.loan_agreement_fao')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="5">
          <el-input v-model="filters.vat" class="client-input"
            :placeholder="$t('SummaryCheckView.tax_registration_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="5">
          <el-select clearable v-model="filters.product" class="m-2 client-input" :placeholder="$t('SummaryCheckView.product')" value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in productTypes" :key="item.id" :label="locale === 'en' ? item.english_trans : item.greek_trans" :value="item.id" />
          </el-select>
          <!-- <el-input v-model="filterProduct" class="client-input" :placeholder="$t('SummaryCheckView.product')"
            @input="filterChanged" /> -->
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(last_page)" style="float: right">
            <el-icon>
              <RefreshLeft />
            </el-icon>
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
        size="small">
        <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('SummaryCheckView.loan_agreement')" />
        <el-table-column sortable fixed prop="ar_sumbashs_fao" :label="$t('SummaryCheckView.loan_agreement_fao')" />
        <el-table-column sortable fixed prop="product" :label="$t('SummaryCheckView.product')" />
        <el-table-column sortable fixed prop="vat_number" :label="$t('SummaryCheckView.tax_registration_nbr')" />
        <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />

        <el-table-column fixed="right" :label="$t('SummaryCheckView.summary_check')">
          <template #default="scope">
            <el-button link type="primary" class="btn-portfolios" size="small"
              @click="handleClick(scope.row)">
              <el-icon>
                <Notebook />
              </el-icon>
            </el-button>
            <el-button link type="primary" class="btn-portfolios" size="small" @click="DownloadExport(scope.row)">
              <el-icon :class="{ 'is-loading': scope.row.is_loading }">
                <Loading v-if="scope.row.is_loading" />
                <Download v-else />
              </el-icon>
            </el-button>
            <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155"
              trigger="hover" :content="$t('global.pending_changes')">
              <template #reference>
                <el-button :icon="Bell" circle size="small"
                  style="background-color: coral; border: 0px; color: white" />
              </template>
            </el-popover>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-pagination size="small" layout=" prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

th,
td {
  padding: 5px;
  text-align: left;
}

.border_table {
  border: 0px !important;
}

.el-form-item {
  margin-bottom: 0px;
}
</style>
