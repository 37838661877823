<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { ElMessage } from "element-plus";
import { Edit, Delete } from "@element-plus/icons-vue";
import AgentDetails from "../components/ConfigAgentDetails.vue";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
import APIReply from "@/classes/APIReply";

const { showError } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const DeleteDialogVisible = ref(false);
const detailsVisible = ref(false);
const deleteVisible = ref(false);
const selectedRow = ref(0);
const DeleteSelectedRow = ref(0);
const dataList = ref<AgentItem[]>([]);
const lastPage = ref(0);
const dataCount = ref(0);

onMounted(async () => {
	loadPage(1);
});

const deleteHandleClick = async (row_id: number) => {
	DeleteSelectedRow.value = row_id;
	deleteVisible.value = true;
	await delete_entity(row_id);
};

const handleClick = (row_id: number) => {
	selectedRow.value = row_id;
	detailsVisible.value = true;
};

const delete_entity = async (row_id: number) => {
	const result = await mosapi.generic_entity_delete(Entities.ConfigAgent, row_id);

	if (result.error) {
		showError(result.error);
		DeleteDialogVisible.value = false;
		return;
	}

	onDelete();
};

const onSave = async () => {
	selectedRow.value = -1;
	ElMessage({
		showClose: true,
		message: "Agent updated Successfully",
		type: "success",
		duration: 1000,
	});

	detailsVisible.value = false;
	loadPage(lastPage.value);
};

const onInsert = async () => {
	selectedRow.value = -1;
	ElMessage({
		showClose: true,
		message: "Agent saved Successfully",
		type: "success",
		duration: 1000,
	});

	detailsVisible.value = false;
	loadPage(lastPage.value);
};

const onDelete = async () => {
	ElMessage({
		showClose: true,
		message: "Agent Deleted Successfully",
		type: "success",
		duration: 1000,
	});

	DeleteDialogVisible.value = false;
	loadPage(lastPage.value);
};

const onFailSave = async () => {
	selectedRow.value = -1;
	ElMessage({
		showClose: true,
		message: "Agent failed to update",
		type: "error",
		duration: 2000,
	});

	detailsVisible.value = false;
};

const new_entities = ref<AgentItem[]>([]);

interface AgentItem {
	agent: string;
	short: string;
}

const removeAgent = (item: AgentItem) => {
	const index = new_entities.value.indexOf(item);
	if (index !== -1) {
		new_entities.value.splice(index, 1);
	}
};

const addAgent = () => {
	new_entities.value.push({
		agent: "",
		short: "",
	});
};

const save = async () => {
	mainStore.isLoading = true;
	const result = await mosapi.generic_entity_post_bulk(Entities.ConfigAgent, new_entities.value);

	if (result.error) {
		mainStore.isLoading = false;
		onFailSave();
		return;
	}

	new_entities.value = [];

	onInsert();

	loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
	console.log('loading page', page);
	mainStore.isLoading = true;
	lastPage.value = page;
	const result = await mosapi.generic_entity_get(Entities.ConfigAgent, {}, page, 10) as APIReply<AgentItem[]>;

	if (result.error) {
		showError(result.error);
		return;
	}

	dataList.value = result.data as AgentItem[];
	dataCount.value = result.total as number;

	mainStore.isLoading = false;
};
</script>

<template>
  <div style="height: 100%" v-loading="mainStore.isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="agent" :label="$t('PlatformSettingsView.agents')" type="text" />
          <el-table-column fixed sortable prop="short" :label="$t('PlatformSettingsView.short')" type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;">
                <el-icon>
                  <Delete />
                </el-icon>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-if="DeleteDialogVisible" v-model="DeleteDialogVisible" title="Agent Delete" width="50%">
          <span>{{ $t("PlatformSettingsView.agent_delete_message") }}</span>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="Agent Details" width="50%">
          <AgentDetails v-if="detailsVisible" :entity_id="selectedRow" @save="onSave" @close="detailsVisible = false; selectedRow = -1;"
            @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form label-width="120px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(agent, index) in new_entities" :label="$t('PlatformSettingsView.agents') + ' ' + index"
				  	:prop="'agents.' + index + '.value'" :key="index" style="border-bottom: 0px; margin-top: 30px">
                    <el-col :span="11">
                      <el-input :placeholder="$t('PlatformSettingsView.agent_name')" v-model="agent.agent"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="11">
                      <el-input :placeholder="$t('PlatformSettingsView.short_details')" v-model="agent.short"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>

                    <el-col :span="2">
                      <el-button style="float: right" @click.prevent="removeAgent(agent)">{{ $t('generic.delete') }}
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="new_entities.length" type="primary" @click="save"> {{ $t('generic.save') }}</el-button>
                    <el-button @click="addAgent">{{$t("PlatformSettingsView.new_agent")}}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
