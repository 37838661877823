<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { Avatar, Edit, Delete, Document, Plus, RefreshLeft, View, Lock } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddLiksiario from "../components/Add_Liksiario.vue";
import LiksiarioDetails from "../components/LiksiarioDetails.vue"
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useMOSAPIStore } from "../store/mos_api";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();

const { locale } = useI18n({ useScope: "global" });
const { t } = useI18n({ useScope: "global" });
const mainStore = useMainStore();
const { onSortChange, } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const mosapi = useMOSAPIStore();
const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const locked_rows = ref(false);

const dataList = ref([]);
const dataCount = ref(0);
const isLoading = ref(false);
const lastPage = ref(0);
const detailsVisible = ref(false);
const selectedRow = ref(0);
const selectedLoanAgreement = ref(0);
const SelectedGlobalLa = ref<string>("");
const activeLang = ref<string>("en");
const DeleteDialogVisible = ref(false);
const DeleteSelectedRow = ref(0);
const deleteVisible = ref(false);
const ΑddLiksiarioVisible = ref(false);
const timer = ref<any>(null);
const viewOnly = ref(false);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(locale, async () => {
  await loadPage(1);
});

const handleClickAdd = () => {
  ΑddLiksiarioVisible.value = true;
};

const handleClick = async (row: any) => {
  viewOnly.value = !isEditable(row);
  console.log('viewOnly->', viewOnly.value)
  selectedRow.value = row.id;
  detailsVisible.value = true;
  // let ResultLockedRow = await .get("la_liksiario?select=*&id=eq." + row.id);
  // LockedRow = ResultLockedRow.data[0].lockedrow;

  // if (LockedRow) {
  //   await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow == false) {
  //   let result = await .patch("la_liksiario?id=eq." + row.id, { lockedrow: 'true' });
  //   await loadPage(lastPage.value);
  // }
};

const handleClickToUnlock = async (row_id: number) => {
  //let result = await .patch("la_liksiario?id=eq." + row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
}

const UnlockAllLockedRows = async () => {
  // let result = await .patch("la_liksiario?", { lockedrow: 'false' });
  // await loadPage(lastPage.value);
  // UnlockAllRowsVisible.value = false;
}

onMounted(async () => {

  //MiscSettings
  //let result = await .get("config_misc_settings?select=*");
  // locked_rows.value = result.data[0].locked_rows;

  // console.log('locked_rows ' + locked_rows);

  // const ResultLockedRow = await .get("la_liksiario?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteLiksiario(row_id);
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Row added Successfully",
    type: "success",
    duration: 1000,
  });

  ΑddLiksiarioVisible.value = false;

  loadPage(lastPage.value);
};

const DeleteLiksiario = async (row_id: number) => {
  let liksiario_id = row_id as number;
  //const result = await mosapi.DeleteLiksiarioById(mainStore.loggedUser.id, id);
  const result = await mosapi.generic_entity_delete(Entities.LALiksiario, liksiario_id);
  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }
  onDelete();
};

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  activeLang.value = locale.value as string;
  mainStore.isLoading = true;

  lastPage.value = page;
  const filters: Record<string, string | number> = {};

  if (mainStore.globalLoanAgreementID !== "") {
    SelectedGlobalLa.value = globalLoanAgreementID.value;
    filters.globalLoanAgreementID = mainStore.globalLoanAgreementID;
  }

  //const result = await mosapi.liksiario_get_all(filters, page, 10);
  const result = await mosapi.get_view(UIViews.LaLiksiarioView, {}, lastPage.value, 10);
  if (result.error) {
    showError(result.error);
    return;
  }
  dataList.value = result.data;
  dataCount.value = result.total;

  // activeLang.value = locale.value as string;
  // mainStore.isLoading = true;

  //   "view_laliksiario_view1?select=*" +
  //   addedFilter +
  //   filterUrl, +
  // page
  // );

  for (const item of result.data) {
    if (item.repayment_date) {
      item.repayment_date = DateTime.fromISO(item.repayment_date)
        .setLocale('en-gb')
        .toFormat("dd/MM/yyyy"); // Μετατροπή σε ΗΗ/ΜΜ/ΕΕΕΕ
    }
    if (item.start_date) {
      item.start_date = DateTime.fromISO(item.start_date)
        .setLocale('en-gb')
        .toFormat("dd/MM/yyyy");
    }
    if (item.end_date) {
      item.end_date = DateTime.fromISO(item.end_date)
        .setLocale('en-gb')
        .toFormat("dd/MM/yyyy");
    }
  }

  // dataList.value = result.data;

  // let ResultLockedRow = await .get("la_liksiario?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

  mainStore.isLoading = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Row updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Row Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Row failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onClose = async () => {
  //let result = await .patch("la_liksiario?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  detailsVisible.value = false;
  selectedRow.value = -1;
};

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    SelectedGlobalLa.value = globalLoanAgreementID.value;
});

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t('LiksiarioView.diary') }}
        </h3>
      </el-col>
    </el-row>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="24" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
          <el-row v-if="locked_rows == true">
            <el-col>
              <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('LoanAgreementView.loan_agreement')" />
      <el-table-column sortable fixed prop="total_remaining_balance" :label="$t('LiksiarioView.remaining_balanc')" />
      <el-table-column sortable fixed prop="repayment_date" :label="$t('LiksiarioView.repayment_date')" />
      <el-table-column sortable fixed prop="margin" :label="$t('LiksiarioView.margin')" />
      <el-table-column sortable fixed prop="start_date" :label="$t('LoanAgreementEktokismosView.start_date')" />
      <el-table-column sortable fixed prop="end_date" :label="$t('LoanAgreementEktokismosView.end_date')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button type="primary" circle size="small" :icon="isEditable(scope.row) ? Edit : View"
            @click="handleClick(scope.row)" :disabled="scope.row.lockedrow && locked_rows" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="danger" size="small"
            :icon="Delete" circle @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" />
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible" @update:model-value="onClose" title="Diary Details"
      width="70%">
      <LiksiarioDetails :rowid="selectedRow" :loan_agreement_id="selectedLoanAgreement" @save="onSave" @close="onClose"
        @fail-save="onFailSave" :view_only="viewOnly" />
    </el-dialog>

    <el-dialog v-if="ΑddLiksiarioVisible" v-model="ΑddLiksiarioVisible" title="Add diary row">
      <AddLiksiario :globalla="SelectedGlobalLa" @close="ΑddLiksiarioVisible = false" @save="onAdd" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Diary Delete" width="50%">
      <span>Are you sure you want to delete this row?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
