import ITableName from "../../interfaces/ITableName";

class Interest_Rates implements ITableName {
  id: number = -1;
  interest_category: number = 0;
  interest_value: number = 0;
  interest_value_date: string = '';

  config_interest?: any;

  static getTableNameStatic(): string {
    return "interest_rates";
  }

  getTableName(): string {
    return Interest_Rates.getTableNameStatic();
  }
}

export default Interest_Rates;