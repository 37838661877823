<script lang="ts" setup>
import { ref, onMounted, reactive } from "vue";
import type { TabsPaneContext, FormRules, FormInstance } from "element-plus";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";
import {
  Delete,
} from "@element-plus/icons-vue";
import { Money3Component } from 'v-money3';
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";

const { showError } = useGenericMethodsVariables();

const activeName = ref("agreement_details");
const interests = ref<any>([]);
const contractStatusList = ref<any>([]);
const paymentTypeList = ref<any>([]);
const { t } = useI18n({ useScope: "global" });
const emits = defineEmits(["save", "close", "fail-save"]);
const ruleFormRef = ref<FormInstance>();
const agents = ref<any>([]);
const products = ref<any>([]);
const currencies = ref<any>([]);
const StoreDebtors = ref<any>([]);
const isDenounced = ref(false);
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const data = ref<any>({
  debtor_id: null,
  ar_sumbashs: null,
  ar_sumbashs_fao: null,
  katast_sumbashs: "performing",
  product: null,
  currency: null,
  synol_poso_sumbashs: "",
  hmer_sumbashs: null,
  hmer_tropop_sumbashs: {
    field: "hmer_poshmer_tropop_sumbashso_ofeilhs",
    originalValue: [],
  },
  agent: null,
  comments: null,
  status: "unchecked",
  tropos_apopl: null,
  hmer_enark_period_xaritos: null,
  hmer_liksis_period_xaritos: null,
  program_apopl_kefal: null,
  hmer_prwths_doshs_kefal: null,
  program_apopl_tokon: null,
  hmer_protis_doshs_tokon: null,
  plithos_doseon_kefal: null,
  hmer_poso_ofeilhs: { field: "hmer_poso_ofeilhs", originalValue: [] },
  dikaioma_propl: null,
  hmer_enarx_dikast_energ: null,
  hmer_epidosis_dikast_energ: null,
  exoda_epidosis: null,
  ekdosi_diatagis: false,
  diatagi_pl_hmek_hmep_ex_per: {
    field: "diatagi_pl_hmek_hmep_ex_per",
    originalValue: [],
  },
  aitisi_anastolis: false,
  katasxesi: false,
  ektimisi: null,
  exoda_ektimisis: null,
  hmer_epid_ekth_katasx: null,
  exoda_epidosis_ekth_katasx: null,
  poso_katasxesis: null,
  eis_xeiras_tritou: null,
  hmer_eperx_pleist: null,
  perithorio_anakopis: null,
  perithorio_anastolis_apo: null,
  perithorio_anastolis_eos: null,
  kefaleo: null,
  log_tokoi: null,
  mh_log_tokoi: null,
  promithies_exoda: null,
  tokoi_promithion_exodon: null,
  denouncement_amount: null,
  auction: false,
  arxikh_hmer_lukshs: null,
  nea_hmer_lukshs: null,
  vasi_ektokismou: null,
  kathgor_epitok_bashs: null,
  perithorio: null,
  epitok_uperhm: null,
  eisfora_n128_1975: null,
  parakra_forou: null,
  oros_propliromis: null,
  approve_status: 0
});

interface HmerPoso {
  hmeres: string;
  poso: string;
}

interface DateItem {
  hmer_tropop: string;
}

interface DiatagiPliromis {
  hmek: string;
  hmep: string;
  exoda: string;
}

const dynamicValidateFormHmerPoso = reactive<{
  hmer_poso_ofeilhs: HmerPoso[];
}>({
  hmer_poso_ofeilhs: [],
});

const removeHmeresPosoOrder = (item: HmerPoso) => {
  const index = data.value.hmer_poso_ofeilhs.originalValue.indexOf(item);
  if (index !== -1) {
    data.value.hmer_poso_ofeilhs.originalValue.splice(index, 1);
  }
};

const removeHmerTropopSymbashs = (item: DateItem) => {
  const index = data.value.hmer_tropop_sumbashs.originalValue.indexOf(item);
  if (index !== -1) {
    data.value.hmer_tropop_sumbashs.originalValue.splice(index, 1);
  }
};

const addArrangementDate = () => {
  data.value.hmer_tropop_sumbashs.originalValue.push({
    hmer_tropop: "",
  });
};

const addHmeresPoso = () => {
  data.value.hmer_poso_ofeilhs.originalValue.push({
    hmeres: "",
    poso: "",
  });
};

const addPaymentOrder = () => {
  data.value.diatagi_pl_hmek_hmep_ex_per.originalValue.push({
    hmek: "",
    hmep: "",
    exoda: "",
  });
};

const removePaymentOrder = (item: DiatagiPliromis) => {
  const index = data.value.diatagi_pl_hmek_hmep_ex_per.originalValue.indexOf(item);
  if (index !== -1) {
    data.value.diatagi_pl_hmek_hmep_ex_per.originalValue.splice(index, 1);
  }
};

const loadInterests = async () => {
  interests.value = [];

  //result = await .get("config_interest?orderFIX=interest");
  const result = await mosapi.generic_entity_get(Entities.ConfigInterest, {orderBy:'interest'});
  if (result.error) {
    showError(result.error);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    interests.value.push({
      value: result.data[i].id,
      label: result.data[i].interest,
    });
  }
};

const loadSelects = async () => {
  paymentTypeList.value = [
    {
      value: "xreolitiko",
      label: t("payment_type_list.xreolitiko"),
    },
    {
      value: "tokoxreolitiko",
      label: t("payment_type_list.tokoxreolitiko"),
    },
    {
      value: "balloon",
      label: t("payment_type_list.balloon"),
    },
  ];
  contractStatusList.value = [
    {
      value: "performing",
      label: t("loan_agreement.performing"),
    },
    {
      value: "non_performing",
      label: t("loan_agreement.non_performing"),
    },
    {
      value: "denounced",
      label: t("loan_agreement.denounced"),
    },
  ];
};

// const Perc_values = (rule: any, value: string, callback: any) => {
//   console.log(value.match(/^(100\.00|100\.0|100)|([0-9]{1,2}){0,1}(\.[0-9]{1,2}){0,1}$/))
//   if (value.match(/^(15\.00|15\.0|15)|([0-9]{1,2}){0,1}(\.[0-9]{1,2}){0,1}$/)) {
//     console.log("all ok?");
//     callback();
//   } else {
//     console.log("---ERROR---");
//     return callback(new Error("This input must contain values between 0% and 15%"));
//   }
// };

const year_base_rates = [
  {
    value: 360,
    label: "360",
  },
  {
    value: "365",
    label: "365",
  },
  {
    value: "366",
    label: "366",
  },
];
const rules = reactive<FormRules>({
  debtor_id: [
    {
      required: true,
      trigger: "blur",
      message: t("global_validation.debtor_is_required"),
    },
  ],
  ar_sumbashs: [
    {
      required: true,
      trigger: "blur",
      message: t("global_validation.loan_agreement_is_required"),
    },
  ],
  product: [
    {
      required: true,
      trigger: "blur",
      message: t("global_validation.product_is_required"),
    },
  ],

  // parakra_forou: [
  //   {
  //     validator: Perc_values,
  //     trigger: "blur",
  //     type: "number",
  //   }
  // ]
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log("submit!");
      save();
    } else {
      // console.log("error submit!", fields);
      for (const key of Object.keys(fields as any)) {
        showError((fields as any)[key][0].message, 2000);
      }
    }
  });
};

onMounted(async () => {
  console.log('new loan agreement');
  data.value.vasi_ektokismou = 360;
  data.value.parakra_forou = 0;
  data.value.eisfora_n128_1975 = 0.6;
  data.value.epitok_uperhm = 2.5;
  await loadInterests();
  await loadSelects();
  await GetAllDebtors();
  await loadAgents();
  await loadProducts();
  await loadCurrencies();
});

const GetAllDebtors = async () => {
//   let query = `view_mosdebtors_view1?approve_status=eq.1&orderFIX=fullname.asc,vat_number.asc`;

//   if (mainStore.loggedUser.user_type === UserType.Agent) {
//     query += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
//   } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
//     query += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
//   }

const result = await mosapi.get_view(UIViews.DebtorsView, {approve_status:1, orderBy:'fullname.asc,vat_number.asc'} , 1,10000)
  StoreDebtors.value = result.data;
};

const loadAgents = async () => {
  

  //result = await .get("config_agent?orderFIX=agent");
	const result  =await mosapi.generic_entity_get(Entities.ConfigAgent, {orderBy:'agent'})
  if (result.error) {
    showError(result.error);

    return;
  }

  agents.value = result.data;
};

const loadProducts = async () => {


//   result = await .get("config_product?orderFIX=product");
  const result = await mosapi.generic_entity_get(Entities.ConfigProduct, {orderBy:'product'})

  if (result.error) {
    showError(result.error);

    return;
  }

  products.value = result.data;
};

const loadCurrencies = async () => {


//   result = await .get("config_currency?orderFIX=currency");
const result = await mosapi.generic_entity_get(Entities.ConfigCurrency, {orderBy:'currency'})

  if (result.error) {
    showError(result.error);

    return;
  }

  currencies.value = result.data;
};

const close = async () => {
  emits("close");
};

const resetData = async () => {
  data.value.debtor_id = null;
  data.value.ar_sumbashs = null;
  data.value.ar_sumbashs_fao = null;
  data.value.katast_sumbashs = "performing";
  data.value.product = null;
  data.value.currency = null;
  data.value.synol_poso_sumbashs = "";
  data.value.hmer_sumbashs = null;
  data.value.hmer_tropop_sumbashs = { field: "hmer_tropop_sumbashs", originalValue: [] };
  data.value.agent = null;
  data.value.comments = null;
  data.value.status = "unchecked";
  data.value.tropos_apopl = null;
  data.value.hmer_enark_period_xaritos = null;
  data.value.hmer_liksis_period_xaritos = null;
  data.value.program_apopl_kefal = null;
  data.value.hmer_prwths_doshs_kefal = null;
  data.value.program_apopl_tokon = null;
  data.value.hmer_protis_doshs_tokon = null;
  data.value.plithos_doseon_kefal = null;
  data.value.hmer_poso_ofeilhs = { field: "hmer_poso_ofeilhs", originalValue: [] };
  data.value.dikaioma_propl = null;
  data.value.hmer_enarx_dikast_energ = null;
  data.value.hmer_epidosis_dikast_energ = null;
  data.value.exoda_epidosis = null;
  data.value.ekdosi_diatagis = false;
  data.value.diatagi_pl_hmek_hmep_ex_per = {
    field: "diatagi_pl_hmek_hmep_ex_per",
    originalValue: [],
  };
  data.value.aitisi_anastolis = false;
  data.value.katasxesi = false;
  data.value.ektimisi = null;
  data.value.exoda_ektimisis = null;
  data.value.hmer_epid_ekth_katasx = null;
  data.value.exoda_epidosis_ekth_katasx = null;
  data.value.poso_katasxesis = null;
  data.value.eis_xeiras_tritou = null;
  data.value.hmer_eperx_pleist = null;
  data.value.perithorio_anakopis = null;
  data.value.perithorio_anastolis_apo = null;
  data.value.perithorio_anastolis_eos = null;
  data.value.kefaleo = null;
  data.value.log_tokoi = null;
  data.value.mh_log_tokoi = null;
  data.value.promithies_exoda = null;
  data.value.tokoi_promithion_exodon = null;
  data.value.denouncement_amount = null;
  data.value.auction = false;
  data.value.arxikh_hmer_lukshs = null;
  data.value.nea_hmer_lukshs = null;
  data.value.vasi_ektokismou = null;
  data.value.kathgor_epitok_bashs = null;
  data.value.perithorio = null;
  data.value.epitok_uperhm = null;
  data.value.eisfora_n128_1975 = null;
  data.value.parakra_forou = null;
  data.value.oros_propliromis = null;
};

const save = async () => {

  data.value.insert_user = mainStore.loggedUser.id;

  if (mainStore.loggedUser.user_type === UserType.Administrator) {
    //const timestamp: string = await (await .get("rpc/customnew")).data;

    data.value.approve_status = 1;
    data.value.approve_user = mainStore.loggedUser.id;
    //data.value.approve_timestamp = timestamp;
  }

  const CreateSummaryCheckRow = ref<any>();
  data.value.hmer_poso_ofeilhs = data.value.hmer_poso_ofeilhs.originalValue;
  data.value.hmer_tropop_sumbashs = data.value.hmer_tropop_sumbashs.originalValue;
  data.value.diatagi_pl_hmek_hmep_ex_per =
    data.value.diatagi_pl_hmek_hmep_ex_per.originalValue;

  // Convert empty strings to NULL for DB
  for (const key of Object.keys(data.value)) {
    if (data.value[key] === '')
      data.value[key] = null;
  }

  const obj = JSON.parse(JSON.stringify(data.value));

  delete obj.perithorio_anakopis;
  delete obj.perithorio_anastolis_apo;
  delete obj.perithorio_anastolis_eos;


  //const result = await .post_data("loan_agreement", obj);
  const result = await mosapi.generic_entity_post(Entities.LoanAgreement, obj);
  console.log(JSON.stringify(result))

  if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {

    CreateSummaryCheckRow.value = {
      loan_agreement_id: result.data.id,
      skopos_ekdosis: null,
      paratasi_diarkeias: null,
      ekxoriseis_elegxos_sumvasis: null,
      ekxoriseis_elegxos_epidosis: null,
      ekxoriseis_eispraxi_gegen_apait: null,
      lixi_ekxorimenon_asf: null,
      promitheies_adraneias: null,
      promitheies_anadoxis: null,
      promitheies_diaxeirisis: null,
      promitheies_tropopoihshs: null,
      promitheies_waiver_fee_ekpr_omolog: null,
      promitheies_waiver_fee_omolog: null,
      upoxreoseis_plirof_oikon_stoixeia: null,
      gegonota_kataggelias: null,
      xronos_paragrafis: null,
      paratiriseis: null,
      summary_check_date: null,
    };

    // const result2 = await .post_data(
    //   "summary_check_xtra",
    //   CreateSummaryCheckRow.value
    // );
	const result2 = await mosapi.generic_entity_post(Entities.SummaryCheck, CreateSummaryCheckRow.value);

    if (result2.error) {
      showError(result2.error.message); // emits("fail-save");
      return;
    }

  }

  if (result.error) {
    showError(result.error); // emits("fail-save");
    return;
  }

  resetData();
  emits("save");
};

const handleClick = (tab: TabsPaneContext, event: Event) => { };
</script>

<template>
  <div>
    <el-form :model="data" :rules="rules" ref="ruleFormRef">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Agreement Details" name="agreement_details">
          <el-row>
            <el-col :span="24">
              <el-form-item :label="$t('ApprovalDetailsView.choose_debtor')" prop="debtor_id">
                <el-select v-model="data.debtor_id" class="m-2 header-filter" placeholder="Debtor" size="small" clearable
                  filterable>
                  <el-option v-for="item in StoreDebtors" :key="item.id"
                    :label="item.vat_number + ' (' + item.fullname + ' / ' + item.portf_name + ')'" :value="item.id"
                    :disabled="isDenounced" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('LoanAgreementDetails.loan_agreement')" prop="ar_sumbashs">
                <el-input v-model="data.ar_sumbashs" autocomplete="off" :disabled="isDenounced" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.loan_agreement_fao')">
                <el-input v-model="data.ar_sumbashs_fao" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.legal_status')">
                <el-select v-model="data.katast_sumbashs" placeholder=" " :value="data.katast_sumbashs" disabled>
                  <el-option :label="'Performing'" :value="'performing'" />
                  <el-option :label="'Non performing'" :value="'non_performing'" />
                  <el-option :label="'Denounced'" :value="'denounced'" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.product')" prop="product">
                <el-select v-model="data.product" placeholder=" " :value="data.product">
                  <el-option v-for="product in products" :label="product.product" :value="product.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.currency')">
                <el-select v-model="data.currency" placeholder=" " :value="data.currency">
                  <el-option v-for="currency in currencies" :label="currency.currency" :value="currency.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.total_amount')">
                <Money3Component class="el-input__inner" v-model.number="data.synol_poso_sumbashs" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item>
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_sumbashs" type="date"
                  :placeholder="$t('LoanAgreementDetails.date_of_agreement')" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <table id="customers">
                <tbody>
                  <tr>
                    <th>
                      {{ $t("LoanAgreementDetails.date_of_additional_arrangement") }}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="json_additional_arrangement" label=" " :object="data.hmer_tropop_sumbashs"
                        :denounced="isDenounced" style="padding: 5px" />
                      <el-form-item v-for="(item, index) in data.hmer_tropop_sumbashs.originalValue" prop="hmer_tropop"
                        style="border: 0px !important;">
                        <el-col :span="20">
                          <el-date-picker type="date" v-model="item.hmer_tropop" value-format="YYYY-MM-DD"
                            :placeholder="$t('LoanAccountDetails.hmer_tropop')" style="width: 100%" format="DD-MM-YYYY"
                            required="true" />
                        </el-col>
                        <el-col :span="4">
                          <el-button link type="primary" size="small" class="json-btn-delete"
                            @click.prevent="removeHmerTropopSymbashs(item)" :icon="Delete" />
                        </el-col>
                      </el-form-item>
                      <el-button style="float: right" @click="addArrangementDate">{{
                        $t('LoanAgreementDetails.new_amendment_date') }}</el-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Agent">
                <el-select v-model="data.agent" placeholder=" " :value="data.agent">
                  <el-option v-for="agent in agents" :label="agent.agent + ' (' + agent.short + ')'" :value="agent.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.arxikh_hmer_lukshs')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.arxikh_hmer_lukshs"
                  type="date" :placeholder="$t('LoanAgreementDetails.arxikh_hmer_lukshs')" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.nea_hmer_lukshs')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.nea_hmer_lukshs" type="date"
                  :placeholder="$t('LoanAgreementDetails.nea_hmer_lukshs')" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="">
                <el-col :span="11">
                  <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_enark_period_xaritos"
                    type="date" :placeholder="$t('LoanAccountDetails.start_date_of_grace_period')" style="width: 100%" />
                </el-col>
                <el-col :span="2" class="text-center">
                  <span class="text-gray-500">-</span>
                </el-col>
                <el-col :span="11">
                  <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_liksis_period_xaritos"
                    :placeholder="$t('LoanAccountDetails.end_date_of_grace_period')" style="width: 100%" />
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.payment_type')">
                <el-select v-model="data.tropos_apopl" class="m-2 header-filter" size="small" placeholder=" " clearable
                  filterable>
                  <el-option v-for="item in paymentTypeList" :key="item.value" :label="item.label" :value="item.label" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.principal_repayment_schedule')">
                <el-input v-model="data.program_apopl_kefal" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.1st_principal_payment_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" type="date"
                  v-model="data.hmer_prwths_doshs_kefal" autocomplete="off" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="data.tropos_apopl == 'balloon'">
              <el-form-item :label="$t('LoanAccountDetails.number_of_principal_payments')">
                <el-input v-model="data.plithos_doseon_kefal" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="data.tropos_apopl == 'balloon'">
              <table id="customers">
                <tbody>
                  <tr>
                    <th>
                      {{ $t("LoanAgreementDetails.hmer_kai_poso_ofeilhs") }}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="json_hmer_poso_ofeilhs" label=" " :object="data.hmer_poso_ofeilhs"
                        style="padding: 5px" />
                      <el-form-item v-for="(item, index) in data.hmer_poso_ofeilhs.originalValue" prop="hmer_poso_ofeilhs"
                        style="border: 0px !important;">
                        <el-col :span="11">
                          <el-date-picker v-model="item.hmeres" value-format="YYYY-MM-DD"
                            :placeholder="$t('LoanAccountDetails.date_of_principal_payment')" style="width: 100%"
                            format="DD-MM-YYYY" />
                        </el-col>
                        <el-col :span="11">
                          <el-input v-model="item.poso" :placeholder="$t('LoanAccountDetails.amount_of_principal_payment')"
                            style="width: 100%" />
                        </el-col>

                        <el-col :span="2">
                          <el-button link type="primary" size="small" class="json-btn-delete"
                            @click.prevent="removeHmeresPosoOrder(item)" :icon="Delete" />
                        </el-col>
                      </el-form-item>
                      <el-button style="float: right" @click="addHmeresPoso">{{
                        $t('LoanAgreementDetails.date_amount_repayment') }}</el-button>

                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_repayment_schedule')">
                <el-input v-model="data.program_apopl_tokon" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.1st_interest_payment_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_protis_doshs_tokon"
                  type="date" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.vasi_ektokismou')">
                <el-select clearable v-model="data.vasi_ektokismou" autocomplete="off" class="m-2 client-input"
                  placeholder=" " value-key="id" style="width: 100%">
                  <el-option v-for="item in year_base_rates" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$t('LoanAgreementDetails.kathgor_epitok_bashs')">
                <el-select v-model="data.kathgor_epitok_bashs" :value="data.kathgor_epitok_bashs" placeholder=" ">
                  <el-option v-for="item in interests" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.perithorio')">
                <el-input v-model="data.perithorio" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.epitok_uperhm')">
                <el-input v-model="data.epitok_uperhm" autocomplete="off" type="number" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.eisfora_n128_1975')">
                <el-input v-model="data.eisfora_n128_1975" autocomplete="off" type="number" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.parakra_forou')" prop="parakra_forou">
                <el-input v-model="data.parakra_forou" autocomplete="off" type="number" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.prepayment_right')">
                <el-switch v-model="data.dikaioma_propl" :active-text="$t('components.switch.yes')"
                  :inactive-text="$t('components.switch.no')" />
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="data.dikaioma_propl == true">
              <el-form-item :label="$t('LoanAgreementDetails.oros_propliromis')">
                <el-input v-model="data.oros_propliromis" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.comments')" class="textarea-wrapper">
                <el-input v-model="data.comments" type="textarea" rows="10" cols="50" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane v-if="data.katast_sumbashs === 'denounced'" label="Denounced" name="denounced">
          <el-row>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.initiation_of_legal_actions')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_enarx_dikast_energ"
                  type="date" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.denouncedamount')">
                <el-input v-model="data.denouncement_amount" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.kefaleo')">
                <el-input v-model="data.kefaleo" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.log_tokoi')">
                <el-input v-model="data.log_tokoi" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.mh_log_tokoi')">
                <el-input v-model="data.mh_log_tokoi" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.promithies_exoda')">
                <el-input v-model="data.promithies_exoda" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.tokoi_promithion_exodon')">
                <el-input v-model="data.tokoi_promithion_exodon" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.serving_of_legal_actions')">
                <el-input v-model="data.hmer_epidosis_dikast_energ" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.serving_fees')">
                <el-input v-model="data.exoda_epidosis" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.payment_order')">
                <el-switch v-model="data.ekdosi_diatagis" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div>
                <table id="customers">
                  <tbody>
                    <tr>
                      <th>{{ $t("LoanAgreementDetails.paymentorders") }}</th>
                    </tr>
                    <tr>
                      <td>
                        <EntityField type="jsonbdiatagipliromis" label=" " :object="data.diatagi_pl_hmek_hmep_ex_per" />
                        <div v-for="(item, index) in data.diatagi_pl_hmek_hmep_ex_per
                          .originalValue">
                          <el-row style="padding: 10px">
                            <p>Payment Order {{ index + 1 }}</p>
                          </el-row>
                          <el-row :gutter="10" style="padding: 10px">
                            <el-col :span="6">
                              <el-date-picker v-model="item.hmek" value-format="YYYY-MM-DD" placeholder="Serving Date"
                                style="width: 100%" format="DD-MM-YYYY" />
                            </el-col>
                            <el-col :span="6">
                              <el-date-picker v-model="item.hmep" value-format="YYYY-MM-DD" placeholder="Serving Fees"
                                style="width: 100%" format="DD-MM-YYYY" />
                            </el-col>
                            <el-col :span="6">
                              <el-input v-model="item.exoda" placeholder="Process Interruption Margin"
                                style="width: 100%" />
                            </el-col>
                            <el-col :span="6">
                              <el-button class="mt-2" style="width: 100%" @click.prevent="removePaymentOrder(item)">
                                Delete</el-button>
                            </el-col>
                          </el-row>
                        </div>
                        <el-button style="float: right" @click="addPaymentOrder">New Payment Order</el-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.suspension_request')">
                <el-switch v-model="data.aitisi_anastolis" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.confiscation')">
                <el-switch v-model="data.katasxesi" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="data.katasxesi === true">
            <el-col :span="24">
              <el-form-item :label="$t('LoanAgreementDetails.appraisal_by_a_certified_appraiser')">
                <el-input v-model="data.ektimisi" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.appraisal_fees')">
                <el-input v-model="data.exoda_ektimisis" />
              </el-form-item>
              <el-form-item :label="$t('LoanAgreementDetails.date_of_serving_of_confiscation_report')">
                <el-date-picker type="date" v-model="data.hmer_epid_ekth_katasx" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.serving_fees')">
                <el-input v-model="data.exoda_epidosis_ekth_katasx" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.confiscation_amount')">
                <el-input v-model="data.poso_katasxesis" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.in_the_hands_of_a_third_party')">
                <el-input v-model="data.eis_xeiras_tritou" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.date_of_upcoming_auction')">
                <el-date-picker type="date" v-model="data.hmer_eperx_pleist" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.process_interruption_margin')">
                <el-date-picker type="date" v-model="data.perithorio_anakopis" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.suspension_request_from')">
                <el-date-picker type="date" v-model="data.perithorio_anastolis_apo" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.suspension_request_to')">
                <el-date-picker type="date" v-model="data.perithorio_anastolis_eos" /> </el-form-item><el-form-item
                :label="$t('LoanAgreementDetails.auction')">
                <el-switch v-model="data.auction" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #98a4cb;
  color: white;
}
</style>
