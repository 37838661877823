import ISelectEntry from "../interfaces/ISelectEntry";
import { useMOSAPIStore } from "@/store/mos_api";
import { Entities } from "@/enums/Entities";
import { GenericEntity } from "@/types/GenericEntity";

export function useDataHelpers() {

	const mosapi = useMOSAPIStore();

	// const getObjectFromTableWithID = async <T extends ITableName>(table: Newable<T>, id: number): Promise<T> => {
	const getObjectFromTableWithID = async (entity_type: Entities, id: number): Promise<GenericEntity | undefined> => {

		const res = await mosapi.generic_entity_get(entity_type, { id });
		// const res = await .get(`${obj.getTableName()}?id=eq.${id}`);

		if (res.data.length > 0) {
			return res.data[0];
		}

		return undefined;
	}

	// const  = async <T extends ITableName>(table: Newable<T>): Promise<T[]> => {
	const getAllObjectsFromTable = async (entity_type: Entities): Promise<GenericEntity[] | undefined> => {
		const res = await mosapi.generic_entity_get(entity_type, {});
		// const res = await .get(`${obj.getTableName()}`);

		return res.data;
	}

	// const  = async <T extends ITableName>(table: Newable<T>, valuePicker: (dbObj: any) => string, labelPicker: (dbObj: any) => string): Promise<ISelectEntry[]> => {
	const getAllObjectsFromTableForSelect = async (entity_type: Entities, valuePicker: (dbObj: any) => string, labelPicker: (dbObj: any) => string): Promise<ISelectEntry[]> => {
		const res = await mosapi.generic_entity_get(entity_type, {});
		// const res = await .get(`${obj.getTableName()}`);
		const fv: ISelectEntry[] = [];

		for (let i = 0; i < res.data.length; i++) {
			fv.push({
				value: valuePicker(res.data[i]),
				label: labelPicker(res.data[i])
			});
		}

		return fv;
	}

	const deleteEntityFromTableWithID = async (entity_type: Entities, id: number): Promise<boolean> => {

		const res = await mosapi.delete_entity(entity_type, id);

		if (res.error) {
			// TODO Add error display?
			return false;
		}

		return true;
	}

	return {
		deleteEntityFromTableWithID,
		getAllObjectsFromTable,
		getAllObjectsFromTableForSelect,
		getObjectFromTableWithID,
	};
}
