<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useMOSAPIStore } from "../store/mos_api";
import { ElMessage } from "element-plus";
import {
  Edit,
  Delete,
  Notebook,
  Plus,
  RefreshLeft,
  Lock,
} from "@element-plus/icons-vue";
import AddEdit_Client from "../components/AddEdit_Client.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";
import { Entities } from "../enums/Entities";
import { UIViews } from "@/enums/UIViews";

const { showError, showSuccess } = useGenericMethodsVariables();

type Views_mosclients_view2 = {
  id: number;
  clientname: string;
  pending_approvals: number;
  lockedrow: boolean;
  allowed_users: number[];
  portfolio_allowed_users: number[];
};

const mainStore = useMainStore();
const router = useRouter();
const mosapi = useMOSAPIStore();

const dataList = ref<Views_mosclients_view2[]>([]);
const dataCount = ref(0);
const lastPage = ref(0);
const selectedRow = ref(-1);
const selectedDescription = ref("");
const deleteDialogVisible = ref(false);
const addEditVisible = ref(false);
const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const { onSortChange, sortColumn, sortOrder } = useSort();

const handleClickEdit = async (row_id: number) => {
  selectedRow.value = row_id;
  /* let ResultLockedRow = await .get("mosclients?select=*&id=eq." + row_id);
	 LockedRow = ResultLockedRow.data[0].lockedrow;
	 LockedRow= false; //να κρυφτει για να παιξει μαζι με καποια &&false 
	 if(LockedRow){
	   await loadPage(lastPage.value);
	   return
	 } else if (LockedRow == false){
	   let result = await .patch("mosclients?id=eq." + row_id, { lockedrow: 'true' });
	   await loadPage(lastPage.value);*/
  addEditVisible.value = true;
  /*}*/
};

const handleClickToUnlock = async (row_id: number) => {
  //await .patch("mosclients?id=eq." + row_id, { lockedrow: "false" });
  //await mosapi.generic_entity_patch(Entities.MOSClients, row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
};

const UnlockAllLockedRows = async () => {
  //await .patch("mosclients?", { lockedrow: "false" });
  //await mosapi.generic_entity_patch***ALLL****(Entities.MOSClients, row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  UnlockAllRowsVisible.value = false;
};

const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const handleClickDelete = (row_id: number, description: string) => {
  selectedRow.value = row_id;
  selectedDescription.value = description;
  deleteDialogVisible.value = true;
};

const onDelete = async () => {
  showSuccess("MOS Client deleted successfully");
  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const handlePortfolioClick = (client_id: number, client_name: string) => {
  router.push({
    name: "Portfolios",
    query: { client_id, client_name },
  });
};

onMounted(async () => {
  // const ResultLockedRow = await .get("mosclients?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  // 	UnlockAllRowsVisible.value = true;
  // 	UnlockAllRowsVisible.value = false;
  // } else {
  // 	UnlockAllRowsVisible.value = false;
  // }
  sortColumn.value = "clientname";
  sortOrder.value = "asc";
  await loadPage(1);
});

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  lastPage.value = page;

  const result = await mosapi.get_view<Views_mosclients_view2>(UIViews.ClientsView, { orderBy: `${sortColumn.value}.${sortOrder.value}` }, lastPage.value);

  if (result.error) {
    mainStore.isLoading = false;
    showError(result.error);
    return;
  }

  dataList.value = result.data as Views_mosclients_view2[];
  dataCount.value = result.total as number;

  // const ResultLockedRow = await .get("mosclients?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

  mainStore.isLoading = false;
};

const onSave = async () => {
  ElMessage({
    showClose: true,
    message: "Client updated",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;
  selectedRow.value = -1;

  await loadPage(lastPage.value);
};

const localSortChange = async (sortObj: unknown) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = async () => {
  // await .patch("mosclients?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
};
</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("ClientsView.clients") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button
            link
            type="primary"
            size="small"
            class="btn-add"
            @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px"
            :icon="RefreshLeft"
          />
          <el-button
            link
            v-if="mainStore.loggedUser.user_type === UserType.Administrator"
            type="primary"
            size="small"
            class="btn-add"
            style="float: right; margin: 2.5px"
            @click="handleClickAdd()"
            :icon="Plus"
          />
          <el-tooltip
            :content="$t('global.unlockalllockedrow')"
            v-if="UnlockAllRowsVisible && false"
          >
            <el-button
              link
              type="primary"
              size="small"
              class="btn-add"
              style="
                float: right;
                margin: 2.5px;
                background-color: #f56c6c !important;
              "
              @click="UnlockAllLockedRows"
              :icon="Lock"
            />
          </el-tooltip>
        </el-col>
      </el-row>
    </div>

    <el-table
      v-loading="mainStore.isLoading"
      :data="dataList"
      stripe
      border
      table-layout="fixed"
      sortable="custom"
      @sort-change="localSortChange"
      size="small"
    >
      <el-table-column
        sortable
        fixed
        prop="clientname"
        :label="$t('ClientsView.company_name')"
      />

      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            class="btn-detail"
            @click="handleClickEdit(scope.row.id)"
            :icon="Edit"
            :disabled="scope.row.lockedrow && false"
            style="background-color: #d6d6d6 !important"
          />
          <el-button
            v-if="mainStore.loggedUser.user_type === UserType.Administrator"
            link
            type="primary"
            size="small"
            class="btn-delete"
            @click="handleClickDelete(scope.row.id, scope.row.clientname)"
            :icon="Delete"
          />
          <el-tooltip
            class="box-item"
            effect="dark"
            content="Portfolios"
            placement="top"
          >
            <el-button
              link
              type="primary"
              class="btn-portfolios"
              size="small"
              @click="handlePortfolioClick(scope.row.id, scope.row.clientname)"
              :icon="Notebook"
            />
          </el-tooltip>
          <el-tooltip
            :content="$t('global.lockedrow')"
            placement="top"
            v-if="scope.row.lockedrow == true && false"
          >
            <el-button
              type="danger"
              :icon="Lock"
              circle
              @click="handleClickToUnlock(scope.row.id)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination
        size="small"
        layout="prev, pager, next"
        :page-size="10"
        :total="dataCount"
        @current-change="loadPage"
      />
    </div>

    <el-dialog
      v-if="addEditVisible"
      v-model="addEditVisible"
      @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Client' : 'Client Details'"
    >
      <AddEdit_Client
        :row_id="selectedRow"
        @close="onCloseAddEdit"
        @save="onSave"
      />
    </el-dialog>

    <DeleteEntityDialog
      v-if="deleteDialogVisible"
      :row_id="selectedRow"
      entity_name="Client"
      :description="selectedDescription"
      :table="Entities.MOSClients"
      title="Delete MOS Client"
      width="50%"
      @close="deleteDialogVisible = false"
      @delete="onDelete"
    />
  </div>
</template>

<style scoped></style>
