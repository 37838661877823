<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import { ElMessage } from "element-plus";
import type { TabsPaneContext, FormRules, FormInstance } from "element-plus";
import Liksiario from "../classes/DB_Entities/Liksiario";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "../store/main";
import { DateTime } from "luxon";
import { useMOSAPIStore } from "@/store/mos_api";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();

const mainStore = useMainStore();
const props = defineProps({
  rowid: Number,
  loan_agreement_id: Number,
  view_only: Boolean
});

const rules = reactive<FormRules>({
  loan_agreement_id: [
    {
      required: true,
      trigger: "blur",
      message: "Loan agreement is required",
    },
  ],
  ektokismos_id: [
    {
      required: true,
      trigger: "blur",
      message: "Interest period is required",
    },
  ],
});

const StoreEktokismos = ref<any>([]);
const StoreLoanAgreements = ref<any>([]);
const StoreMosOfficers = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new Liksiario());
const activeName = ref("first");
const OldLoanAgreement = ref<any>();
const ruleFormRef = ref<FormInstance>();
const isLoading = ref(false);
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.rowid,
  async (newVal, oldVal) => {
    // console.log("Loading debtor with id " + props.debtorid);
    await loadRow(props.rowid as number);
  }
);
watch(
  () => data.value.interest_amount,
  async (newVal, oldVal) => {
    let interest_amount_value = 0;
    let withholding_tax_15 = 0;
    if (data.value.interest_amount !== null) interest_amount_value = data.value.interest_amount;
    if (data.value.withholdind_tax == true) withholding_tax_15 = interest_amount_value * 0.15;
    data.value.interest_amount_due = interest_amount_value - withholding_tax_15;
  }
);

watch(
  () => data.value.withholdind_tax,
  async (newVal, oldVal) => {
    let interest_amount_value = 0;
    let withholding_tax_15 = 0;
    if (data.value.interest_amount !== null) interest_amount_value = data.value.interest_amount;
    if (data.value.withholdind_tax == true) withholding_tax_15 = interest_amount_value * 0.15;
    data.value.interest_amount_due = interest_amount_value - withholding_tax_15;
  }
);
onMounted(async () => {
  await loadRow(props.rowid as number);
  OldLoanAgreement.value = data.value.loan_agreement_id;
  await loadLoanAgreements();
  await loadEktokismos();
});

const loadLoanAgreements = async () => {
  

  if (props.view_only) {
    //result = await .get(`loan_agreement?id=eq.${data.value.loan_agreement_id}`);
    const result = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: data.value.loan_agreement_id });
    if (result.error) {
      showError(result.error);
      return;
    }

    StoreLoanAgreements.value = result.data[0];
    return;
  }

  //result = await .get("loan_agreement?approve_status=eq.1");
  // if (mainStore.loggedUser.user_type === 2) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // } else if (mainStore.loggedUser.user_type === 1) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // } else if (mainStore.loggedUser.user_type === 0) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  // }

  const result = await mosapi.generic_entity_get(Entities.LoanAgreement);

  if (result.error) {
    showError(result.error);
    return;
  }

  StoreLoanAgreements.value = result.data;
};

const close = async () => {
  emits("close");
};

const save = async () => {
  

  const id = Number(data.value.id);
  delete data.value.id;

  //result = await .patch("la_liksiario?id=eq." + id, data.value);
  const result = await mosapi.generic_entity_patch(Entities.LALiksiario, id, data.value);
  if (result.error) {
    showError(result.error); // emits("fail-save");
    return;
  }
  emits("save");
};

const loadEktokismos = async () => {

  if (data.value.loan_agreement_id) {
    
    //result = await .get("ektokismoi_loan_agreement?select=*,loan_agreement!inner(ar_sumbashs)&order=start_date.desc&loan_agreement_id=eq." + data.value.loan_agreement_id);
    const result = await mosapi.GenericgetEktokismoiLoanAgreementWithRelation(data.value.loan_agreement_id.toString());
    if (result.error) {
      ElMessage({
        showClose: true,
        message: result.error,
        type: "error",
      });

      return;
    }

    StoreEktokismos.value = result;
    OldLoanAgreement.value = data.value.loan_agreement_id;
  } else {

    
    //result = await .get("ektokismoi_loan_agreement?select=*,loan_agreement!inner(ar_sumbashs)");
    const result = await mosapi.GenericgetEktokismoiLoanAgreementWithRelation("");
    if (result.error) {
      ElMessage({
        showClose: true,
        message: result.error,
        type: "error",
      });

      return;
    }

    StoreEktokismos.value = result;
    OldLoanAgreement.value = data.value.loan_agreement_id;
  }
};

watch(
  () => data.value.loan_agreement_id,
  async (newVal, oldVal) => {
    await loadEktokismos();
    if (props.view_only) {
      //let result = await .get(`loan_agreement?id=eq.${data.value.loan_agreement_id}`);
      const result = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: data.value.loan_agreement_id });
      if (result.error) {
        showError(result.error);
        return;
      }

      StoreLoanAgreements.value = result.data[0];
      return;
    }
  }
);

const loadRow = async (rowid: number) => {
  mainStore.isLoading = true;
  if (rowid === -1) return;

  

  //result = await .get("la_liksiario?id=eq." + rowid);
  const result = await mosapi.generic_entity_get(Entities.LALiksiario, { id: rowid });

  if (result.error) {
    showError(result.error);

    return;
  }

  data.value = result.data[0];
  mainStore.isLoading = false;
};
</script>

<template>
  <div>
    <el-form :model="data" :rules="rules" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('LoanAccountDetails.loan_agreement')" prop="loan_agreement_id">
            <el-select v-model="data.loan_agreement_id" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" " @change="data.ektokismos_id = undefined as any" :disabled="props.view_only">
              <el-option v-for="item in StoreLoanAgreements" :key="item.id" :label="item.ar_sumbashs" :value="item.id"
                :disabled="props.view_only" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.ektokismos')" prop="ektokismos_id">
            <el-select v-model="data.ektokismos_id" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" " :disabled="props.view_only">
              <el-option v-for="item in StoreEktokismos" :key="item.id"
                :label="item.loan_agreement.ar_sumbashs + ' (' + $t('LiksiarioView.from') + ' ' + DateTime.fromFormat((item.start_date), 'yyyy-MM-dd').setLocale('en-gb').toLocaleString() + ' ' + $t('LiksiarioView.to') + ' ' + DateTime.fromFormat((item.end_date), 'yyyy-MM-dd').setLocale('en-gb').toLocaleString() + ')'"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.total_remaining_balance')">
            <el-input v-model="data.total_remaining_balance" autocomplete="off" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.our_participation')">
            <el-input v-model="data.our_participation" autocomplete="off" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.repayment_date')" :width="100">
            <el-date-picker v-model="data.repayment_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_calc_start_date')" :width="100">
            <el-date-picker v-model="data.interest_calc_start_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_calc_end_date')" :width="100">
            <el-date-picker v-model="data.interest_calc_end_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_rate')">
            <el-input v-model="data.interest_rate" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.margin')">
            <el-input v-model="data.margin" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_amount')">
            <el-input v-model="data.interest_amount" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.withholdind_tax')">
            <el-switch v-model="data.withholdind_tax" :active-text="$t('components.switch.yes')"
              :inactive-text="$t('components.switch.no')" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_amount_due')">
            <el-input disabled v-model="data.interest_amount_due" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.comments')">
            <el-input v-model="data.comments" autocomplete="off" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.tax_certificate')">
            <el-switch v-model="data.tax_certificate" :active-text="$t('components.switch.yes')"
              :inactive-text="$t('components.switch.no')" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.days_overdue')">
            <el-input v-model="data.days_overdue" autocomplete="off" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.cert_sent')">
            <el-input v-model="data.cert_sent" autocomplete="off" :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.alarm_date')" :width="100">
            <el-date-picker v-model="data.alarm_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD"
              :disabled="props.view_only" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.due_date')" :width="100">
            <el-date-picker v-model="data.due_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD"
              :disabled="props.view_only" />
          </el-form-item>
        </el-col>
      </el-row>
      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button v-if="!props.view_only" type="primary" @click="submitForm(ruleFormRef)">{{
            $t('generic.save')
          }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
