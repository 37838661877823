/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, getCurrentInstance, inject, onMounted, onUnmounted } from "vue";
import { ElMessage } from "element-plus";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { useDataHelpers } from "../composables/dataHelpers";

import ChangesetHelper from "changeset-helper";

import Decision from "../classes/Decision";
import { Newable } from "../classes/Newable";
import ITableName from "../interfaces/ITableName";
import { useI18n } from "vue-i18n";
import { EntityChangeAddRequestDto, EntityChangeUpdateRequestDto } from "@/models";
import { Entities } from "@/enums/Entities";
import { Emitter, EventType } from "mitt";
import { GenericEntity } from "@/types/GenericEntity";

export function useGenericMethodsVariables() {
	const emitter = inject('emitter') as Emitter<Record<EventType, unknown>>;
	const { emit } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;
	const { t } = useI18n({ useScope: "global" });

	const mainStore = useMainStore();
	const mosapi = useMOSAPIStore();
	const dataHelpers = useDataHelpers();

	const table_name = ref<string>("");
	const fullObj = ref<any>("");
	const data = ref<any>("");
	const cleanObject = ref<any>(null);
	const hasPendingChanges = ref<boolean>(false);

	const dataList = ref([]);
	const page_size = ref(10);
	const last_page = ref(0);
	const dataCount = ref(0);

	let decision_listener_active: boolean = false;
	let global_filter_listener_active: boolean = false;

	type CallbackWithoutPameters = () => void;
	type CallbackWithNumberParam = (id: number) => void;


	// Must pass as parameter the function that is called to refresh the data on the component
	const setup_global_filter_listener = (callback: CallbackWithoutPameters) => {
		global_filter_listener_active = true;
		console.log('setting up global filter change listener');
		emitter.on('global_filter_change', async () => {   // Listen for global filter event
			console.log('global filter change detected');
			callback();
		});
	}

	const setup_decision_listener = (fix_selects_callback: CallbackWithoutPameters | undefined = undefined, load_data_callback: CallbackWithNumberParam | undefined = undefined, id: number = 0, table_name: string | undefined = undefined) => {
		decision_listener_active = true;
		console.log('setting up decision listener');
		emitter.on('decision', async () => {   // Listen for decision event
			if(table_name !== undefined){
				await mosapi.updateEntityApproveStatus(table_name, id as number);
			}
			if (load_data_callback) {
				await load_data_callback(id);
			}
			else {
				await loadData(data.value.id);
			}

			if (fix_selects_callback) {
				await fix_selects_callback();
			}

			mainStore.isLoading = false;
		});
	}

	const disableListeners = () => {
		if (decision_listener_active) {
			console.log('disabling decision listener');
			emitter.off('decision');
		}

		if (global_filter_listener_active) {
			console.log('disabling global filter listener');
			emitter.off('global_filter_change');
		}
	}

	onMounted(() => {
		disableListeners(); // In case something was left over due to dev reload
	});

	onUnmounted(() => {
		disableListeners();
	});

	const updateDecision = async (decision: Decision) => {
		console.log(decision);
		mainStore.isLoading = true;

		const entity_change_update_request: EntityChangeUpdateRequestDto = {
			id: decision.db_change_id as number,
			entity_type: decision.table_name as Entities, // entity_type: table_name.value as Entities,
			field: decision.field,
			value: decision.value,
			approval_status: decision.accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected,
		}
		//console.log(entity_change_update_request);
		await mosapi.update_entity_change(entity_change_update_request); // approval status is handled on the backend

		// set to approve_status = 1 if no more pending changes // TODO: add this to the backend
		await mosapi.updateEntityApproveStatus(table_name.value, data.value.id);

		mainStore.isLoading = false;
	};

	const TransformFullObj = () => {
		for (const field of Object.keys(fullObj.value)) {
			(fullObj as any).value[field] = {
				field,
				originalValue: (data as any).value[field],
			};
		}
	};

	const loadPendingChanges = async (entity_id: number) => {
		// const result = await .get(
		// 	`entity_${table_name.value}_changes?entity_id=eq.${entity_id}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)`
		// );
		const result: any = await mosapi.get_pending_changes(table_name.value as Entities, entity_id);

		TransformFullObj();

		for (const pending of result.data) {
			pending["originalValue"] = JSON.parse(JSON.stringify((data as any).value[pending.field]));
			pending["table_name"] = table_name.value;
			pending["entity_id"] = entity_id;
			(fullObj as any).value[pending.field].pendingValue = pending;
		}

		hasPendingChanges.value = (result.data.length > 0);
	};

	const loadDataView = async (entity_type: Entities): Promise<boolean> => {
		console.log('loadDataView', entity_type, 'page_size', page_size.value, 'page', last_page.value);
		mainStore.isLoading = true;

		const result = await mosapi.generic_entity_get(entity_type, {}, last_page.value, page_size.value);

		if (result.error) {
			mainStore.isLoading = false;
			showLocalizedError(result.error);
			return false;
		}

		dataList.value = (result as any).data;
		dataCount.value = (result as any).total;

		mainStore.isLoading = false;

		return true;
	}

	const loadData = async (entity_id: number) => {
		if (entity_id === -1) return;

		console.log('loadData', table_name.value, entity_id);
		const result = await mosapi.generic_entity_get(table_name.value as Entities, { id: entity_id });
		console.log('loadData result', JSON.stringify(result));

		if (result.error) {
			mainStore.isLoading = false;
			showLocalizedError(result.error);
			return;
		}

		data.value = (result as any).data[0];
		fullObj.value = JSON.parse(JSON.stringify((result as any).data[0]));

		await loadPendingChanges(entity_id);
	};

	const isNewEntity = (): boolean => {
		return data.value.id === -1;
	}

	const save = async () => {
		mainStore.isLoading = true;
		const newFull: any = {};

		for (const field of Object.keys(fullObj.value)) {
			if (data.value[field] === null) {
				data.value[field] = "";
			}
			if ((fullObj as any).value[field].originalValue === null)
				(fullObj as any).value[field].originalValue = "";

			newFull[field] = (fullObj as any).value[field].originalValue;
		}

		if (!isNewEntity()) {

			if (table_name.value === Entities.RealEstate && "debtor" in data.value) {
				delete data.value.debtor;
				delete newFull.debtor;
			} else if (table_name.value === Entities.RegisterOfBonds && "loan_agreement" in data.value) {
				delete data.value.loan_agreement;
				delete newFull.loan_agreement;
			} else if (table_name.value === Entities.RECollateral && "real_estate" in data.value) {
				delete data.value.real_estate;
				delete newFull.real_estate;
			}

			//console.log('coocoo1a', JSON.stringify(data.value));
			// console.log('coocoo1b', JSON.stringify(newFull));

			const report = ChangesetHelper.compare(data.value, newFull);

			for (const changedField of report.changes) {
				if (newFull[changedField] === null || newFull[changedField] === undefined) {
					newFull[changedField] = 'null';
				}
				if (newFull[changedField].toString() === data.value[changedField]) {
					continue
				}
				// First time there's a change on this field, create a new entity change
				if (fullObj.value[changedField].pendingValue === undefined) {
					const entity_change_create_request: EntityChangeAddRequestDto = {
						entity_id: data.value.id,
						entity_type: table_name.value as Entities,
						field: changedField,
						value: newFull[changedField].toString(),
						approval_status: ChangeApprovalStatus.Approved // Set to approved in case admin/supervisor is inserting, gets checked on the backend
					}
					await mosapi.add_entity_change(entity_change_create_request); // approval status is handled on the backend
				}
				else { // If the field already has a pending change, update it
					const entity_change_update_request: EntityChangeUpdateRequestDto = {
						id: fullObj.value[changedField].pendingValue.id,
						entity_type: table_name.value as Entities,
						field: changedField,
						value: newFull[changedField].toString(),
						approval_status: ChangeApprovalStatus.Approved // Set to approved in case admin/supervisor is updating, gets checked on the backend
					}
					await mosapi.update_entity_change(entity_change_update_request); // approval status is handled on the backend
				}
			}

			mainStore.isLoading = false;
			emit("save");
			return;
		} // End update existing entity

		delete newFull.id; // Remove id from new entity

		// Set all fields that have an empty string to null
		for (const field of Object.keys(newFull)) {
			if (newFull[field] === "")
				newFull[field] = null;
		}
		// console.log(newFull);

		const result = await mosapi.generic_entity_post(table_name.value as Entities, newFull);
		// console.log(JSON.stringify(result));

		if (result.error) {
			showLocalizedError(result.error);
			mainStore.isLoading = false;
			return;
		}

		mainStore.isLoading = false;
		emit("save");
	}

	const saveDirect = async () => {
		mainStore.isLoading = true;

		const newFull: any = {};

		for (const field of Object.keys(fullObj.value)) {
			if (data.value[field] === null) {
				data.value[field] = "";
			}
			if ((fullObj as any).value[field].originalValue === null)
				(fullObj as any).value[field].originalValue = "";

			newFull[field] = (fullObj as any).value[field].originalValue;
		}

		if (isNewEntity()) {// Do nothing if new entity (shouldn't happen)
			emit("close");
			return;
		}

		const report = ChangesetHelper.compare(data.value, newFull);

		if (report.changes.length === 0) { // Do nothing if no changes
			emit("close");
			return;
		}

		for (const field of Object.keys(newFull)) {
			if (newFull[field] === "")
				newFull[field] = null;
		}

		const result = await mosapi.generic_entity_patch(table_name.value as Entities, data.value.id, newFull);
		// const result = await .patch(table_name.value + "?id=eq." + data.value.id, newFull);

		if (result.error) {
			emit("fail-save");
			return;
		}

		emit("save");
	}

	const close = async () => {
		emit("close");
	};

	const resetObjects = () => {
		data.value = JSON.parse(JSON.stringify(cleanObject.value));
		fullObj.value = JSON.parse(JSON.stringify(cleanObject.value));
		TransformFullObj();
	};

	const entityApprove = async (entity_type: Entities, entity_id: number, accepted: boolean): Promise<boolean> => {
		// console.log("entity id", entity_id, "approved", accepted);

		const result = await mosapi.generic_entity_patch(entity_type, entity_id, { approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected });
		// const result = await .patch(`${entity_table}?id=eq.${entity_id}`, { 
		// 	approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected,
		// 	approve_user: mainStore.loggedUser.id,
		// 	approve_timestamp: timestamp,
		// });

		return result.error === undefined;
	}

	const showSuccess = (message: string, duration: number = 1500) => {
		ElMessage({
			showClose: true,
			message: message,
			type: "success",
			duration: duration,
		});
	}

	const showWarning = (message: string, duration: number = 5000) => {
		ElMessage({
			showClose: true,
			message: message,
			type: "warning",
			duration: duration,
		});
	}

	const showError = (message: string, duration: number = 5000) => {
		ElMessage({
			showClose: true,
			message: message,
			type: "error",
			duration: duration,
		});
	}

	const clickAcceptAll = async (entity_id: number) => {
		mainStore.isLoading = true;
		console.log(fullObj.value);
		try {
			for (const key of Object.keys(fullObj.value)) {
				if ((fullObj as any).value[key].pendingValue !== undefined) {
					await updateDecision({ accepted: true, table_name: (fullObj as any).value[key].pendingValue.table_name, db_change_id: (fullObj as any).value[key].pendingValue.id, field: key, value: (fullObj as any).value[key].pendingValue.value } as Decision);
				}
			}
		} catch (ex) {
			console.log(ex);
		}

		await loadData(entity_id);

		mainStore.isLoading = false;
	}

	const hasPendingChangeForField = (field: string): boolean => {
		if (field in (fullObj as any).value) {
			return (fullObj as any).value[field].pendingValue !== undefined;
		}

		return false;
	};

	//
	// Used when replacing select fields and there's a pending change
	//
	// const setDisplayValues = async <T extends ITableName & { id?: number }>(tableType: Newable<T>, field: string, formatFunction: (dbObj: any) => string): Promise<void> => {
	const setDisplayValues = async (entity_type: Entities, field: string, formatFunction: (dbObj: any) => string): Promise<void> => {
		// let dbObj = await dataHelpers.getObjectFromTableWithID<T>(tableType, fullObj.value[field].pendingValue.value);
		let dbObj = await dataHelpers.getObjectFromTableWithID(entity_type, fullObj.value[field].pendingValue.value) as GenericEntity;

		// Right side (Pending value)
		fullObj.value[field].pendingValue["pendingDisplayValue"] = (dbObj.id !== -1) ? formatFunction(dbObj) : fullObj.value[field].pendingValue.value;

		// Left side (Current DB value)
		if (fullObj.value[field].originalValue !== null) {
			dbObj = await dataHelpers.getObjectFromTableWithID(entity_type, fullObj.value[field].originalValue) as GenericEntity;
			fullObj.value[field].pendingValue["originalDisplayValue"] = (dbObj.id !== -1) ? formatFunction(dbObj) : fullObj.value[field].originalValue;
		}
	}

	const showLocalizedError = (message: string) => {
		showError(t(`errors.${message}`))
	}

	const printBarcode = async (barcode: string) => {
		await mosapi.notify({
			channel: "print_barcode",
			data: `~MDEL
^W70
^Q40,2
^H1
^P1
^L
BP, 20, 115, 2, 10, 70, 0, 1,${barcode.trim()}
E`,
		});
	}

	//BB,20,100,3,3,100,0,1,1234567
	// BP,30,57,2,5,80,0,1,22408785Godex
	// BP, 75, 115, 2, 10, 70, 0, 1, ${barcode}

	return {
		cleanObject,
		data,
		dataCount,
		dataList,
		fullObj,
		hasPendingChanges,
		last_page,
		page_size,
		table_name,
		clickAcceptAll,
		close,
		entityApprove,
		hasPendingChangeForField,
		isNewEntity,
		loadPendingChanges,
		loadData,
		loadDataView,
		printBarcode,
		resetObjects,
		save,
		saveDirect,
		setDisplayValues,
		showError,
		showLocalizedError,
		showSuccess,
		showWarning,
		TransformFullObj,
		updateDecision,
		setup_decision_listener,
		setup_global_filter_listener
	};
}
