<script lang="ts" setup>
import { ref, watch, onMounted, inject, onUnmounted, reactive } from "vue";
import { useMOSAPIStore } from "../store/mos_api";
import type { FormRules, FormInstance } from "element-plus";
import LoanAccount from "../classes/DB_Entities/LoanAccount";
import Decision from "../classes/Decision";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import { useMainStore } from "../store/main";
import ChangesetHelper from "changeset-helper";
import EntityField from "./EntityField.vue";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import UserType from "../enums/UserType";
import DebitCreditType from "../enums/DebitCreditType";
import DebitCreditList from "./DebitCreditList.vue";
import { Warning } from "@element-plus/icons-vue";
import LoanRepaymentType from "../enums/LoanRepaymentType";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import { orderBy } from "lodash";
const { showLocalizedError, showError, table_name, setup_decision_listener } = useGenericMethodsVariables();
const props = defineProps({
  loanaccountid: Number,
  view_only: Boolean
});
const TrueFalseValues = ref<any>([]);
const { locale } = useI18n({ useScope: "global" });
const { t } = useI18n({ useScope: "global" });
const emits = defineEmits(["save", "close", "fail-save"]);
const fullObj = ref<any>({});
const originalObj = ref<any>({});
const mosapi = useMOSAPIStore();
const interests = ref<any>([]);
const data = ref(new LoanAccount());
const products = ref<any>([]);
const currencies = ref<any>([]);
const activeName = ref("loan_agreement_details");
const loanAgreementLegalStatus = ref("");
const loanAgreementArSumb = ref("");
const ruleFormRef = ref<FormInstance>();
const mainStore = useMainStore();
const StoreLoanAgreements = ref<any>([]);
const hasPendingChanges = ref<boolean>(false);
const paymentTypeList = ref<any>([]);
const legalStatusList = ref<any>([]);
const showDebitsCredits = ref<boolean>(false);
const selectedDebitCreditType = ref<DebitCreditType>(DebitCreditType.Credit);
const pendingDebitsCredits: any = ref([]);

const rules = reactive<FormRules>({
  loan_agreement_id: [
    {
      required: true,
      message: "Loan agreement is required",
      trigger: "blur",
    },
  ],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => fullObj.value?.loan_agreement_id?.originalValue,
  async () => {
    // console.log('changed loan account\'s loan agreement id to' + fullObj.value?.loan_agreement_id?.originalValue)

    if (fullObj.value?.loan_agreement_id?.originalValue === null || fullObj.value?.loan_agreement_id?.originalValue === '') return;

    if (fullObj.value.loan_agreement_id.originalValue !== undefined) {
      //const result = await .get(`loan_agreement?id=eq.${fullObj.value.loan_agreement_id.originalValue}`);
      const result = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: fullObj.value.loan_agreement_id.originalValue });

      if (result.error) {
        showLocalizedError(result.error);
        return;
      }

      // console.log(JSON.stringify(result.data))
      //console.log('fullObj.value=' + JSON.stringify(fullObj.value))
      if (fullObj.value.kathgor_epitok_bashs.originalValue === null || fullObj.value.kathgor_epitok_bashs.originalValue === undefined) {
        fullObj.value.kathgor_epitok_bashs.originalValue = result.data[0].kathgor_epitok_bashs;
      }
      if (fullObj.value.perithorio.originalValue === null || fullObj.value.perithorio.originalValue === undefined) {
        fullObj.value.perithorio.originalValue = result.data[0].perithorio;
      }
      if (fullObj.value.epitok_uperhm.originalValue === null || fullObj.value.epitok_uperhm.originalValue === undefined) {
        fullObj.value.epitok_uperhm.originalValue = result.data[0].epitok_uperhm;
      }
      if (fullObj.value.parakra_forou.originalValue === null || fullObj.value.parakra_forou.originalValue === undefined) {
        fullObj.value.parakra_forou.originalValue = result.data[0].parakra_forou;
      }
      if (fullObj.value.eisfora_n128_1975.originalValue === null || fullObj.value.eisfora_n128_1975.originalValue === undefined) {
        fullObj.value.eisfora_n128_1975.originalValue = result.data[0].eisfora_n128_1975;
      }
    }
  }
);

const loadPendingDebitsCredits = async () => {
  //const result = await .get(`view_debits_credits_list?loan_account_id=eq.${props.loanaccountid}`);
  const result = await mosapi.get_view(UIViews.DebitsCredits, { loan_account_id: props.loanaccountid }, 1, 999);
  pendingDebitsCredits.value = result.data;
}

onMounted(async () => {
  setup_decision_listener(undefined, loadLoanAccount, props.loanaccountid, 'loan_accounts');
  table_name.value = Entities.LoanAccount;

  await loadLoanAgreements();

  //console.log('loading loan account with id ' + props.loanaccountid);
  await loadLoanAccount(props.loanaccountid as number);
  await loadPendingDebitsCredits();

  if (fullObj.value.enhm_kefal.originalValue == null) {
    fullObj.value.enhm_kefal.originalValue = 0;
  }
  if (fullObj.value.kathister_kefal.originalValue == null) {
    fullObj.value.kathister_kefal.originalValue = 0;
  }
  if (fullObj.value.logistikop_tokoi.originalValue == null) {
    fullObj.value.logistikop_tokoi.originalValue = 0;
  }
  if (fullObj.value.sumbatikoi_log_tokoi.originalValue == null) {
    fullObj.value.sumbatikoi_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.yperhmerias_log_tokoi.originalValue == null) {
    fullObj.value.yperhmerias_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.n2601_1998_log_tokoi.originalValue == null) {
    fullObj.value.n2601_1998_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.mh_logistikop_tokoi.originalValue == null) {
    fullObj.value.mh_logistikop_tokoi.originalValue = 0;
  }
  if (fullObj.value.sumbatikoi_mh_log_tokoi.originalValue == null) {
    fullObj.value.sumbatikoi_mh_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.yperhmerias_mh_log_tokoi.originalValue == null) {
    fullObj.value.yperhmerias_mh_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.n2601_1998_mh_log_tokoi.originalValue == null) {
    fullObj.value.n2601_1998_mh_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.eksoda.originalValue == null) {
    fullObj.value.eksoda.originalValue = 0;
  }
  if (fullObj.value.synoliko_poso.originalValue == null) {
    fullObj.value.synoliko_poso.originalValue = 0;
  }
  // if (fullObj.value.loan_capital_repayment_type.originalValue == null) {
  //   fullObj.value.loan_capital_repayment_type.originalValue = -1;
  // }

});

const close = async () => {
  emits("close");
};

const loadProducts = async () => {
  products.value = [];

  //result = await .get("config_loan_account_product?orderFIX=product");
  const result = await mosapi.generic_entity_get(Entities.ConfigLoanAccountProduct, {orderBy:'product'}, 1, 1000);
  //let result = await mosapi.ConfigLoanAccountProducts_get_all(mainStore.loggedUser.id, 1, 10);

  if (result.error) {
    showLocalizedError(result.error);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    if (locale.value === "en") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].english_trans,
      });
    } else if (locale.value === "el") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].greek_trans,
      });
    }
  }
};

const loadCurrencies = async () => {
  currencies.value = [];

  //result = await .get("config_currency?orderFIX=currency");
  const result = await mosapi.generic_entity_get(Entities.ConfigCurrency, {orderBy:'currency'}, 1, 1000);
  // let result = await mosapi.getAllConfigCurrencies(mainStore.loggedUser.id, 1, 10);

  if (result.error) {
    showLocalizedError(result.error);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    currencies.value.push({
      value: result.data[i].id,
      label: result.data[i].currency,
    });
  }
};

const save = async () => {
  mainStore.isLoading = true;
  await saveLoanAccountChanges();
  mainStore.isLoading = false;

  emits("save");
};

const saveLoanAccountChanges = async () => {
  //const timestamp: string = await (await .get("rpc/customnew")).data;
  const newFull: any = {};

  for (const field of Object.keys(fullObj.value)) {
    if ((originalObj as any).value[field] === null) {
      (originalObj as any).value[field] = "";

    }
    if ((fullObj as any).value[field].originalValue === null) {
      (fullObj as any).value[field].originalValue = "";
    }
    newFull[field] = fullObj.value[field].originalValue;
  }

  // console.log(JSON.stringify(originalObj.value));
  const report = ChangesetHelper.compare(originalObj.value, newFull);

  let madeChange = false;
  for (const changedField of report.changes) {
    madeChange = true;
    // console.log(
    //   `${changedField} -> Old:${originalObj.value[changedField]}, New:${newFull[changedField]}`
    // );
    if (
      fullObj.value[changedField].pendingValue === null ||
      fullObj.value[changedField].pendingValue === undefined
    ) {
      // result = await .post_data("entity_loan_accounts_changes", {
      //   entity_id: originalObj.value.id,
      //   editor_user_id: mainStore.loggedUser.id,
      //   field: changedField,
      //   value: newFull[changedField],
      // });

      let result = await mosapi.add_entity_change({ entity_id: originalObj.value.id, entity_type: Entities.LoanAccount, field: changedField, value: newFull[changedField], });
      await mosapi.updateEntityApproveStatus("loan_accounts", data.value[0].id as number);
      // if (mainStore.loggedUser.user_type !== UserType.Agent) {
      //   //await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision);
      //   await mosapi.update_entity_change({ id: result.data[0].id, entity_type: Entities.LoanAccount, field: changedField, value: newFull[changedField], });
      // }
    } else {
      if (mainStore.loggedUser.user_type === UserType.Agent) {
        // result = await .patch(
        //   "entity_loan_accounts_changes?id=eq." +
        //   fullObj.value[changedField].pendingValue.id,
        //   {
        //     entity_id: originalObj.value.id,
        //     editor_user_id: mainStore.loggedUser.id,
        //     field: changedField,
        //     value: newFull[changedField],
        //     insert_timestamp: timestamp,
        //   }
        // );
        let result = await mosapi.update_entity_change({ id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.LoanAccount, field: changedField, value: newFull[changedField], });
      } else {
        // result = await .post_data("entity_loan_accounts_changes", {
        //   entity_id: originalObj.value.id,
        //   editor_user_id: mainStore.loggedUser.id,
        //   field: changedField,
        //   value: newFull[changedField],
        // });
        let result = await mosapi.add_entity_change({ entity_id: originalObj.value.id, entity_type: Entities.LoanAccount, field: changedField, value: newFull[changedField], });
        await mosapi.updateEntityApproveStatus("loan_accounts", data.value.id as number);
        //await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision);
        //await mosapi.update_entity_change({ id: result.data[0].id, entity_type: Entities.LoanAccount, field: changedField, value: newFull[changedField], approval_status: ChangeApprovalStatus.Approved});
      }
    }
  }

  //
  // Set to approve status PendingReview if changes were made by an agent
  //
  if (madeChange && originalObj.value.approve_status !== ChangeApprovalStatus.PendingApprove && mainStore.loggedUser.user_type === UserType.Agent) {
    //await .patch(`loan_accounts?id=eq.${originalObj.value.id}`, { approve_status: ChangeApprovalStatus.PendingReview });
    await mosapi.generic_entity_patch(Entities.LoanAccount, originalObj.value.id, { approve_status: ChangeApprovalStatus.PendingReview });
  }
};

const loadLoanAccount = async (loanaccountid: number) => {
  await loadProducts();
  await loadSelects();
  if (loanaccountid === -1) return;

  mainStore.isLoading = true;

  
  //result = await .get(`loan_accounts?id=eq.${loanaccountid}`);
  const result = await mosapi.generic_entity_get(Entities.LoanAccount, { id: loanaccountid });
  // console.log(
  //   `loan_accounts?id=eq.${loanaccountid}&select=*,loan_agreement:loan_agreement(*)`
  // );

  if (result.error) {
    showError(result.error);

    mainStore.isLoading = false;

    return;
  }

  originalObj.value = JSON.parse(JSON.stringify(result.data[0]));
  fullObj.value = JSON.parse(JSON.stringify(result.data[0]));

  //result2 = await .get(`loan_agreement?select=katast_sumbashs,ar_sumbashs&id=eq.${fullObj.value.loan_agreement_id}`);
  const result2 = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: fullObj.value.loan_agreement_id });
  loanAgreementLegalStatus.value = result2.data[0].katast_sumbashs;
  loanAgreementArSumb.value = result2.data[0].ar_sumbashs;
  await fixPendingSelects();
  await loadInterests();
  // await loadProducts();
  await loadCurrencies();
  data.value = result.data;
  mainStore.isLoading = false;
};

const fixPendingSelects = async () => {
  // let result = await .get(
  //   `entity_loan_accounts_changes?entity_id=eq.${props.loanaccountid}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)`
  // );

  let result = await mosapi.get_pending_changes(Entities.LoanAccount, props.loanaccountid as number);


  for (const field of Object.keys(fullObj.value)) {
    fullObj.value[field] = { field, originalValue: fullObj.value[field] };
  }

  // console.log(fullObj.value);

  hasPendingChanges.value = false;
  for (const pending of result.data) {
    pending["table_name"] = table_name.value;
    hasPendingChanges.value = true;


    //Loan Agreement
    if (pending.field === "loan_agreement_id") {
      if (pending.value) {
        let loanAgreement = (await mosapi.generic_entity_get(Entities.LoanAgreement, { id: pending.value }));
        pending["pendingDisplayValue"] = loanAgreement.data[0].ar_sumbashs;
      }
      if ((originalObj as any).value[pending.field]) {
        let loanAgreement = (await mosapi.generic_entity_get(Entities.LoanAgreement, { id: (originalObj as any).value[pending.field] }));
        pending["originalDisplayValue"] = loanAgreement.data[0].ar_sumbashs;
      }
    }

    //Currency
    if (pending.field === "currency") {
      if (pending.value) {
        let currency = (await mosapi.generic_entity_get(Entities.ConfigCurrency, { id: pending.value }));
        pending["pendingDisplayValue"] = currency.data[0].currency;
      }
      if ((originalObj as any).value[pending.field]) {
        let currency = (await mosapi.generic_entity_get(Entities.ConfigCurrency, { id: (originalObj as any).value[pending.field] }));
        pending["originalDisplayValue"] = currency.data[0].currency;
      }
    }


    //Products
    if (pending.field === "product") {
      if (pending.value) {
        let product = (await mosapi.generic_entity_get(Entities.ConfigLoanAccountProduct, { id: pending.value }));
        pending["pendingDisplayValue"] = product.data[0].product;
      }
      if ((originalObj as any).value[pending.field]) {
        let product = (await mosapi.generic_entity_get(Entities.ConfigLoanAccountProduct, { id: (originalObj as any).value[pending.field] }));
        pending["originalDisplayValue"] = product.data[0].product;
      }
    }


    if (pending.field === "loan_capital_repayment_type") {
      pending["pendingDisplayValue"] = capitalRepaymentTypes.find((element: any) => {
        console.log( JSON.stringify(element.value) + ' === ' + JSON.stringify(pending.value));
        return (element.value + '') === pending.value;
      })?.label;
      pending["originalDisplayValue"] = capitalRepaymentTypes.find((element: any) => {
        return (element.value + '') === (originalObj as any).value[pending.field];
      })?.label;
    }

    //Interests
    if (pending.field === "kathgor_epitok_bashs") {
      if (pending.value) {
        // interest = await .get(
        //   `config_interest?select=interest&id=eq.` + pending.value
        // );
        let interest = await mosapi.generic_entity_get(Entities.ConfigInterest, {id: pending.value});
        pending["pendingDisplayValue"] = interest.data[0].interest;
      }
      if ((originalObj as any).value[pending.field]) {
        // interest = await .get(
        //   `config_interest?select=interest&id=eq.` +
        //   (originalObj as any).value[pending.field]
        // );
        let interest = await mosapi.generic_entity_get(Entities.ConfigInterest, {id: (originalObj as any).value[pending.field]});
        pending["originalDisplayValue"] = interest.data[0].interest;
      }
    }

    //Legal Status
    if (pending.field === "legal_status") {
      pending["pendingDisplayValue"] = legalStatusList.value.find((element: any) => {
        return element.value === pending.value;
      })?.label;
      pending["originalDisplayValue"] = legalStatusList.value.find((element: any) => {
        return element.value === (originalObj as any).value[pending.field];
      })?.label;
    }

    //Presentation of Certificate
    if (pending.field === "proskom_bebeos") {
      if (pending.value === "true") {
        pending["pendingDisplayValue"] = t("global.true");
      } else if (pending.value === "false") {
        pending["pendingDisplayValue"] = t("global.false");
      }

      if ((originalObj as any).value[pending.field] === true) {
        pending["originalDisplayValue"] = t("global.true");
      } else if ((originalObj as any).value[pending.field] === false) {
        pending["originalDisplayValue"] = t("global.false");
      }
    }

    //Status
    if (pending.field === "status") {
      pending["pendingDisplayValue"] = StatusList.find((element: any) => {
        return element.value === pending.value;
      })?.label;
      pending["originalDisplayValue"] = StatusList.find((element: any) => {
        return element.value === (originalObj as any).value[pending.field];
      })?.label;
    }

    if (pending.field === "loan_capital_repayment_epeteiako" || pending.field === "arnitika_epitokia" || pending.field === "loan_interest_change_epeteiako" || pending.field === "kathisterimeno" || pending.field === "loan_interest_repayment_epeteiako") {
      if (pending.value === "true") {
        pending["pendingDisplayValue"] = t("global.true");
      } else if (pending.value === "false") {
        pending["pendingDisplayValue"] = t("global.false");
      }

      if ((originalObj as any).value[pending.field] === true) {
        pending["originalDisplayValue"] = t("global.true");
      } else if ((originalObj as any).value[pending.field] === false) {
        pending["originalDisplayValue"] = t("global.false");
      }
    }

    pending["originalValue"] = originalObj.value[pending.field];
    fullObj.value[pending.field].pendingValue = pending;
  }
};

const loadLoanAgreements = async () => {
  
  StoreLoanAgreements.value = [];

  const result = await mosapi.generic_entity_get(Entities.LoanAgreement, { approve_status: 1 });
  //result = await .get("loan_agreement?approve_status=eq.1");

  if (result.error) {
    showLocalizedError(result.error);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    StoreLoanAgreements.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs,
    });
  }
};

const loadInterests = async () => {
  
  interests.value = [];

  //result = await .get("config_interest?orderFIX=interest");
  const result = await mosapi.generic_entity_get(Entities.ConfigInterest, {orderBy:'interest'}, 1, 1000); //.getAllConfigInterests(mainStore.loggedUser.id, 1, 10);

  if (result.error) {
    showLocalizedError(result.error);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    interests.value.push({
      value: result.data[i].id,
      label: result.data[i].interest,
    });
  }
};

// const onDecision = async (decision: Decision) => {


//   const timestamp: string = await (await .get("rpc/customnew")).data;

//   if (decision.accepted) {
//     result = await .patch(
//       "entity_loan_accounts_changes?id=eq." + decision.db_change_id,
//       {
//         approval_status: ChangeApprovalStatus.Approved,
//         approver_user_id: mainStore.loggedUser.id,
//         decision_timestamp: timestamp,
//       }
//     );

//     const obj: any = {};
//     const field = decision.field as string;
//     obj[field] = decision.value;

//     if (obj[field] === "")
//       obj[field] = null;

//     result = await .patch("loan_accounts?id=eq." + originalObj.value.id, obj);
//   } else {
//     result = await .patch(
//       "entity_loan_accounts_changes?id=eq." + decision.db_change_id,
//       {
//         approval_status: ChangeApprovalStatus.Rejected,
//         approver_user_id: mainStore.loggedUser.id,
//         decision_timestamp: timestamp,
//       }
//     );
//   }

//   // set to approve_status = 1 if no more pending changes
//   await mosapi.updateEntityApproveStatus("loan_accounts", data.value.id as number);

//   loadLoanAccount(props.loanaccountid as number);
// };

const loadSelects = async () => {
  paymentTypeList.value = [
    {
      value: "xreolitiko",
      label: t("payment_type_list.xreolitiko"),
    },
    {
      value: "tokoxreolitiko",
      label: t("payment_type_list.tokoxreolitiko"),
    },
    {
      value: "balloon",
      label: t("payment_type_list.balloon"),
    },
  ];
};

const clickAcceptAll = async () => {
  mainStore.isLoading = true;
  try {
    for (const key of Object.keys(fullObj.value)) {
      if (fullObj.value[key].pendingValue !== undefined) {
        //console.log('accepting pending change for', key);
        //await onDecision({ accepted: true, db_change_id: (fullObj as any).value[key].pendingValue.id, field: key, value: (fullObj as any).value[key].pendingValue.value } as Decision);
        await mosapi.update_entity_change({ id: (fullObj as any).value[key].pendingValue.id, entity_type: Entities.LoanAccount, field: key, value: (fullObj as any).value[key].pendingValue.value, approval_status: ChangeApprovalStatus.Approved });
      }
    }
    await mosapi.updateEntityApproveStatus("loan_accounts", data.value.id as number);
  } catch (ex) {
    //console.log('Exception when accepting all:')
    console.log(ex);
  }
  await loadLoanAccount(props.loanaccountid as number);
  mainStore.isLoading = false;
}

const PresentationofCertificateList = [
  {
    value: true,
    label: t("global.true"),
  },
  {
    value: false,
    label: t("global.false"),
  },
];

const StatusList = [
  {
    value: "active",
    label: t("global.active"),
  },
  {
    value: "inactive",
    label: t("global.inactive"),
  },
];
TrueFalseValues.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];
const capitalRepaymentTypes = [
  {
    value: null,
    label: "",
  },
  {
    value: LoanRepaymentType.Tokoxreolytiko,
    label: t("payment_type_list.tokoxreolitiko"),
  },
  {
    value: LoanRepaymentType.XreolytikoIsoposo,
    label: t("payment_type_list.xreolitiko"),
  },
  {
    value: LoanRepaymentType.XreolytikoAnisoposo,
    label: t("payment_type_list.balloon"),
  },
];

const openDebitCreditType = (type: DebitCreditType) => {
  selectedDebitCreditType.value = type;
  showDebitsCredits.value = true;
}

const onCloseCredits = () => {
  showDebitsCredits.value = false;
}

const getDebitCreditTypeName = (type: DebitCreditType) => {
  switch (type) {
    case DebitCreditType.Debit:
      return "Debit";
    case DebitCreditType.Credit:
      return "Credit";
    case DebitCreditType.Installment:
      return "Installment";
    case DebitCreditType.InterestTypeChanges:
      return "Interest Type Change";
    case DebitCreditType.Exoda:
      return "Expense";
    case DebitCreditType.TokoiTokwn:
      return "Initial Interest";
    case DebitCreditType.HmerTokou:
      return " Interest Payment Date";
  }
}

const onCloseDebitCredit = async () => {
  await loadPendingDebitsCredits();
}
</script>

<template>
  <div v-loading="mainStore.isLoading">
    <div>
      <el-button @click="openDebitCreditType(DebitCreditType.Credit)">{{ $t('LoanAccountDetails.pistwseis') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_credits > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button v-if="fullObj?.product?.originalValue === 5" @click="openDebitCreditType(DebitCreditType.Debit)">{{
        $t('LoanAccountDetails.xrewseis') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_debits > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.Installment)">{{ $t('LoanAccountDetails.doseis') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_installments > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.InterestTypeChanges)">{{
        $t('LoanAccountDetails.interest_type_change') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_interests > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.HmerTokou)">{{ $t('LoanAccountDetails.hmertokou') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_hmertokou > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.Exoda)">{{ $t('LoanAccountDetails.exoda') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_exoda > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
    </div>

    <p />

    <el-form :model="data" :rules="rules" ref="ruleFormRef">

      <el-tag class="ml-2" style="margin: 12px; text-align:right; float: right;">
        {{ $t('LoanAccountDetails.legal_status') }} of {{ loanAgreementArSumb }}: {{ loanAgreementLegalStatus }}
      </el-tag>

      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll" />

      <el-tabs type="card" class="demo-tabs" v-model="activeName">
        <el-tab-pane :label="$t('LoanAccountDetails.loan_account_details')" name="loan_agreement_details">
          <el-row :gutter="10">
            <el-col :span="24">
              <EntityField type="select" :label="$t('LoanAccountDetails.loan_agreement')"
                :object="fullObj.loan_agreement_id" :options="StoreLoanAgreements" 
                :is-disabled="props.view_only" />
            </el-col>
            <!--sssss-->
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.loan_account')"
                :object="fullObj.ar_trexontos_logariasmou"  :is-disabled="props.view_only" />
            </el-col>
            <!--sssss-->
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.original_loan_accound')"
                :object="fullObj.ar_arxikou_logariasmou"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.previous_loan_accound')"
                :object="fullObj.palaios_logariasmos"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.currency" :label="$t('LoanAccountDetails.currency')"
                :options="currencies"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.original_end_date')"
                :object="fullObj.arxikh_hmer_lukshs"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.end_date')"
                :object="fullObj.nea_hmer_lukshs"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.disbursment_date')"
                :object="fullObj.hmer_ektam"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_enarxis_xaritos')"
                :object="fullObj.hmer_enarxis_xaritos"  :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_lixis_xaritos')"
                :object="fullObj.hmer_lixis_xaritos"  :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_1is_dosis_tokon')"
                :object="fullObj.hmer_1is_dosis_tokon"  :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.disbursment_amount')"
                :object="fullObj.poso_ektam"  :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="select" :object="fullObj.status" :label="$t('LoanAccountDetails.status')"
                :options="StatusList"  :is-disabled="props.view_only" />
            </el-col>



            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.base_rate_value')" :object="fullObj.epitok_bashs"
                 :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.tax_withholding')"
                :object="fullObj.parakra_forou"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.proskom_bebeos"
                :label="$t('LoanAccountDetails.presentation_of_certificate')" :options="PresentationofCertificateList"
                 :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24" v-if="fullObj.proskom_bebeos?.originalValue">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_proskom')"
                :object="fullObj.hmer_proskom"  :is-disabled="props.view_only" />
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane :label="$t('LoanAccountDetails.balance')" name="balance">
          <el-row>
            <div>
              <div v-if="(parseFloat(fullObj.trexon_upol?.originalValue) !== (
                parseFloat(fullObj.enhm_kefal?.originalValue) +
                parseFloat(fullObj.kathister_kefal?.originalValue) +
                parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue) +
                parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.eksoda?.originalValue)


              ) && (

                  (parseFloat(fullObj.trexon_upol?.originalValue) > 0) ||
                  (parseFloat(fullObj.enhm_kefal?.originalValue) > 0) ||
                  (parseFloat(fullObj.kathister_kefal?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.eksoda?.originalValue) > 0)


                ))" class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error") }}
              </div>
              <div v-if="(parseFloat(fullObj.logistikop_tokoi?.originalValue)) !== (
                parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue)
              )
                && (
                  (parseFloat(fullObj.logistikop_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue) > 0)
                )

              " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error_logistik") }}
              </div>
              <div v-if="(parseFloat(fullObj.mh_logistikop_tokoi?.originalValue)) !== (
                parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue)
              )

                && (
                  (parseFloat(fullObj.mh_logistikop_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue) > 0)
                )
              " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error_mh_logistik") }}
              </div>
            </div>
            <el-row>
              <el-col :span="24">
                <EntityField type="datepicker" :label="$t('LoanAccountDetails.date_of_current_balance')"
                  :object="fullObj.date_of_current_balance"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.current_balance')"
                  :object="fullObj.trexon_upol"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.due_principal')" :object="fullObj.enhm_kefal"
                   :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.overdue_principal')"
                  :object="fullObj.kathister_kefal"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <h3>{{ $t('LoanAccountDetails.logistikopoihmenoi') }}</h3>
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.on_balance_sheet_interest')"
                  :object="fullObj.logistikop_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <!--  sumbatikoi_log_tokoi
                    yperhmerias_log_tokoi
                    n2601_1998_log_tokoi -->
              <!-- TODO Check -->
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_due_balance')"
                  :object="fullObj.sumbatikoi_log_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <!-- TODO Check -->
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_overdue_balance')"
                  :object="fullObj.yperhmerias_log_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <!-- TODO Check -->
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_interest')"
                  :object="fullObj.n2601_1998_log_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <h3>{{ $t('LoanAccountDetails.mhlogistikopoihmenoi') }}</h3>
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.off_balance_sheet_interest')"
                  :object="fullObj.mh_logistikop_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_due_balance')"
                  :object="fullObj.sumbatikoi_mh_log_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_overdue_balance')"
                  :object="fullObj.yperhmerias_mh_log_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_interest')"
                  :object="fullObj.n2601_1998_mh_log_tokoi"  :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.commissions_expenses')"
                  :object="fullObj.eksoda"  :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.conditional_write_off_amount')"
                :object="fullObj.diegram_poso"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.accruals')" :object="fullObj.dedoul_tokoi"
                 :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.accruals_kath_kef')"
                :object="fullObj.dedoul_tokoi_kath_kef"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.accruals_kath_tok')"
                :object="fullObj.dedoul_tokoi_kath_tok"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.1st_delay_date')"
                :object="fullObj.hmer_prwths_kathister"  :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.days_of_delay')"
                :object="fullObj.hmeres_kathister"  :is-disabled="props.view_only" />
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('LoanAccountDetails.pedia_upologismou')" name="pedia_upologismou">
          <el-col :span="24">
            <EntityField type="select" :label="$t('LoanAccountDetails.kathisterimeno')" :object="fullObj.kathisterimeno"
               :is-disabled="props.view_only" :options="TrueFalseValues"/>
          </el-col>
          <el-col :span="24">
            <EntityField type="money" :label="$t('LoanAccountDetails.synoliko_poso')" :object="fullObj.synoliko_poso"
               :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :object="fullObj.loan_capital_repayment_type"
              :label="$t('LoanAccountDetails.loan_capital_repayment_type')" :options="capitalRepaymentTypes"
               :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('LoanAccountDetails.loan_capital_repayment_frequency')"
              :object="fullObj.loan_capital_repayment_frequency" 
              :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :label="$t('LoanAccountDetails.loan_capital_repayment_epeteiako')"
              :object="fullObj.loan_capital_repayment_epeteiako" 
              :is-disabled="props.view_only" :options="TrueFalseValues"/>
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('LoanAccountDetails.loan_interest_repayment_frequency')"
              :object="fullObj.loan_interest_repayment_frequency" 
              :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :label="$t('LoanAccountDetails.loan_interest_repayment_epeteiako')"
              :object="fullObj.loan_interest_repayment_epeteiako" 
              :is-disabled="props.view_only" :options="TrueFalseValues"/>
          </el-col>

          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('LoanAccountDetails.start_date')" :object="fullObj.hmer_enarkshs"
               :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :object="fullObj.product" :label="$t('LoanAccountDetails.product')"
              :options="products"  :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :object="fullObj.kathgor_epitok_bashs"
              :label="$t('LoanAccountDetails.base_rate_initial')" :options="interests" 
              :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('LoanAccountDetails.loan_interest_change_frequency')"
              :object="fullObj.loan_interest_change_frequency"  :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :label="$t('LoanAccountDetails.loan_interest_change_epeteiako')"
              :object="fullObj.loan_interest_change_epeteiako"  :is-disabled="props.view_only" :options="TrueFalseValues"/>
          </el-col>
          <el-col :span="24">
            <EntityField type="select" :label="$t('LoanAccountDetails.arnitika_epitokia')"
              :object="fullObj.arnitika_epitokia"  :is-disabled="props.view_only" :options="TrueFalseValues"/>
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('LoanAccountDetails.margin_initial')" :object="fullObj.perithorio"
               :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('LoanAccountDetails.overude_rate_initial')"
              :object="fullObj.epitok_uperhm"  :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('LoanAccountDetails.levy_N128/75_initial')"
              :object="fullObj.eisfora_n128_1975"  :is-disabled="props.view_only" />
          </el-col>
          <hr>

        </el-tab-pane>
        <el-tab-pane v-if="fullObj.kathisterimeno?.originalValue" :label="$t('LoanAccountDetails.kathisterimeno')"
          name="kathisterimeno">
          <el-col :span="24">
            <EntityField type="money" :label="$t('LoanAccountDetails.kathisterimeno_enim_arx')"
              :object="fullObj.kathisterimeno_enim_arx"  :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="money" :label="$t('LoanAccountDetails.kathisterimenoKEF')"
              :object="fullObj.kathisterimenokef"  :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('LoanAccountDetails.kathisterimenodate')"
              :object="fullObj.kathisterimenodate"  :is-disabled="props.view_only" />
          </el-col>
          <el-button @click="openDebitCreditType(DebitCreditType.TokoiTokwn)">{{ $t('LoanAccountDetails.tokoitokwn') }}
            <el-icon
              v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_tokoi_tokwn > 0"
              :size="16" color="orange" style="padding-left: 3px;">
              <Warning />
            </el-icon>
          </el-button>
        </el-tab-pane>
        <el-row>
          <el-col :span="24">
            <el-form-item class="btn-wrapper-components">
              <el-button v-if="!props.view_only" type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save')
              }}</el-button>
              <el-button @click="close">{{ $t('generic.close') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-tabs>
    </el-form>

    <el-dialog v-if="showDebitsCredits" v-model="showDebitsCredits" @update:model-value="onCloseCredits"
      :title="getDebitCreditTypeName(selectedDebitCreditType) + 's List'" width="80%" @close="onCloseDebitCredit">
      <DebitCreditList :loan_account_id="(props.loanaccountid as number)" :view_only="false"
        :type="selectedDebitCreditType" />
    </el-dialog>


  </div>
</template>

<style scoped></style>
