<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "@/store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { GroupRequestDto, GroupResponseDto, PrivilegeResponseDto } from "@/models";
import { useMOSAPIStore } from "@/store/mos_api";
import { useI18n } from "vue-i18n";
import { Entities } from "@/enums/Entities";
import APIReply from "@/classes/APIReply";

const { showError, showLocalizedError } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });

const props = defineProps({ groupId: Number });
const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();

const formData = ref<GroupRequestDto>({ name: '', privileges: [] });

const group = ref<GroupResponseDto>({} as GroupResponseDto);
const privileges = ref<{ key: number, label: string }[]>([]);
// const groupPrivileges = ref<GroupsPrivileges[]>([]);

onMounted(async () => {
	mainStore.isLoading = true;
	try {
		await loadPrivileges();
		if (props.groupId) {
			await loadGroup(props.groupId);
		}
	} catch (error) {
		if (error instanceof Error) {
			showError(error.message); // Access message safely
		} else {
			showError(String(error)); // Handle other cases gracefully
		}
	}
	mainStore.isLoading = false;
});

const close = () => emits("close");

const save = async () => {
	try {
		const { id } = group.value;
		const result = id
			? await mosapiStore.updateGroup(id, formData.value)
			: await mosapiStore.createGroup(formData.value);

		if (result.error) throw new Error(t(`errors.${result.error}`));

		emits("save");
	} catch (error) {
		if (error instanceof Error) {
			showError(error.message); // Access message safely
		} else {
			showError(String(error)); // Handle other cases gracefully
		}
	}
};

const loadGroup = async (groupId: number) => {
	mainStore.isLoading = true;

	const result = await mosapiStore.getGroup(groupId) as APIReply<GroupResponseDto>;
	console.log('result', JSON.stringify(result));
	if (result.error) throw new Error(t(`errors.${result.error}`));


	group.value = result.data ?? {} as GroupResponseDto;
	console.log('group', JSON.stringify(group.value));
	formData.value.name = group.value?.name || '';
	formData.value.privileges = group.value?.privileges || [];
	mainStore.isLoading = false;
};

const loadPrivileges = async () => {
	const result = await mosapiStore.generic_entity_get(Entities.Privileges);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}
	if (result.data)
		privileges.value = result.data?.map((privilege: PrivilegeResponseDto) => ({
			key: privilege.id,
			label: privilege.name,
		}));
};
</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form :model="formData">
			<el-row>
				<el-col :span="8">
					<el-form-item label=" Name">
						<el-input v-model="formData.name" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="Privileges">
						<el-transfer v-model="formData.privileges" :data="privileges"
							:titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="12">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped>
::v-deep(.el-transfer-panel) {
	width: 400px;
	/* Adjust width of each panel */
}
</style>
