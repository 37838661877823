<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import Guarantee from "../classes/DB_Entities/Guarantee";
import { ElMessage } from "element-plus";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import { useMOSAPIStore } from "../store/mos_api";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import APIReply from "../classes/APIReply";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
const props = defineProps<{
  guaranteeid: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref(new Array<any>());

const {
  setup_decision_listener,
  showError,
  hasPendingChanges,
  fullObj,
  table_name,
  clickAcceptAll,
  loadData,
  save,
  close,
  cleanObject,
  resetObjects,
} = useGenericMethodsVariables();

onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  table_name.value = Guarantee.getTableNameStatic();
  cleanObject.value = new Guarantee();

  //console.log("guaranteeid", props.guaranteeid, "newEntity", props.newEntity);

  await loadLoanAgreements();

  if (props.newEntity === true) {
    resetObjects();
    return;
  }

  mainStore.isLoading = true;
  await loadData(props.guaranteeid);
  await fixPendingSelects();
  mainStore.isLoading = false;
});

const loadLoanAgreements = async () => {
  loanAgreementsList.value = [];
  const result = await mosapi.get_view(UIViews.LoanAgreementView, {approve_status: 1, debtor_approve_status: 1});
  // let query = `view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`;
  if (result.error) {
    showError(result.error);
    return;
  }
  console.log(result.data);
  for (let i = 0; i < result.data.length; i++) {
    loanAgreementsList.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs + " (" + result.data[i].fullname + ")",
    });
  }
};

const onPreSave = async () => {
  //   console.log(JSON.stringify(fullObj.value));
  if (props.newEntity === true) {
    if (fullObj.value.loan_agreement_id.originalValue === 0) {
      ElMessage({
        showClose: true,
        message: t("errors.missing_loan_agreement"),
        type: "warning",
        duration: 1500,
      });
      return;
    }

    if (fullObj.value.debtor_cid.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_debtor_cid"),
        type: "warning",
        duration: 1500,
      });
      return;
    }
    else {
      //const result = await .get(`debtor?select=id&cid=eq.${fullObj.value.debtor_cid.originalValue}`)
      //const result = await mosapi.getDebtorByCid(fullObj.value.id.originalValue, fullObj.value.debtor_cid.originalValue);
      const result = await mosapi.generic_entity_get(Entities.Debtors, {cid: fullObj.value.debtor_cid.originalValue});
      if (result.data.length === 0) {
        ElMessage({
          showClose: true,
          message: t("errors.no_debtor_with_this_cid"),
          type: "warning",
          duration: 1500,
        });
        return;
      }
    }
  }

  delete fullObj.value.loan_agreement;

  await save();
  resetObjects();
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value
    // Loan Agreement
    if (field === "loan_agreement_id") {
      const loan1 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: fullObj.value[field].pendingValue.value}) as APIReply<LoanAgreement[]>;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${loan1.data[0].ar_sumbashs} (${loan1.data[0].debtor?.fullname || ''})`;

      const loan2 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: fullObj.value[field].originalValue}) as APIReply<LoanAgreement[]>;
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${loan2.data[0].ar_sumbashs} (${loan2.data[0].debtor?.fullname || ''})`;
    }

    if (field === "egguhsh_sunolou_sumbas") {
      if (fullObj.value[field].pendingValue.value) {
        fullObj.value[field].pendingValue["pendingDisplayValue"] =
          fullObj.value[field].pendingValue.value == "true"
            ? t("global.true")
            : t("global.false");
        fullObj.value[field].pendingValue["originalDisplayValue"] =
          Boolean(fullObj.value[field].originalValue) === true
            ? t("global.true")
            : t("global.false");
      }
    }

  }
};

const fullAgreementGuaranteeList = [
  {
    value: true as boolean,
    label: t("global.true"),
  },
  {
    value: false as boolean,
    label: t("global.false"),
  },
];
</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll(props.guaranteeid)" />
      <p />
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.loan_agreement_id"
            :label="$t('GuaranteesDetails.loan_agreement_number')" :options="loanAgreementsList"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('GuaranteesDetails.debtor_cid')" :object="fullObj.debtor_cid"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :label="$t('GuaranteesDetails.full_agreement_guarantee')"
            :object="fullObj.egguhsh_sunolou_sumbas" :options="fullAgreementGuaranteeList"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24" v-if="
          fullObj.egguhsh_sunolou_sumbas !== undefined &&
          fullObj.egguhsh_sunolou_sumbas.originalValue === false
        ">
          <EntityField type="money" :label="$t('GuaranteesDetails.guaranteed_amount')" :object="fullObj.poso_egg"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('GuaranteesDetails.comments')" :object="fullObj.comments"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
