<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import { useMainStore } from "../store/main";
import ConfigKad from "../classes/DB_Entities/ConfigKad";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
const props = defineProps({
  kadid: Number,
});
const klados = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new ConfigKad());
const ruleFormRef = ref<FormInstance>();
const mainStore = useMainStore();
const isLoading = ref(false);

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.kadid,
  async (newVal, oldVal) => {
    await loadKAD(props.kadid as number);
  }
);

onMounted(async () => {
  await loadKAD(props.kadid as number);
  await loadKlados();
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const kad_id = data.value.id as number;
  delete data.value.id;
  const result = await mosapi.generic_entity_patch(Entities.ConfigKAD, kad_id, data.value);
  if (result.error) {
    showError(result.error);
    return;
  }
  emits("save");
};

const loadKAD = async (kad_id: number) => {
  mainStore.isLoading = true;
  if (kad_id === -1) return;
  const result = await mosapi.generic_entity_get(Entities.ConfigKAD, { id: kad_id });
  if (result.error) {
    showError(result.error);
    return;
  }
  data.value = result.data[0];
  mainStore.isLoading = false;
};

const loadKlados = async () => {
  const result = await mosapi.generic_entity_get(Entities.ConfigKlados, {}, 1, 999);
  if (result.error) {
    showError(result.error);
    return;
  }
  klados.value = result.data;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.kad_details')">
            <el-input v-model="data.kodikos_kad" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.description_details')">
            <el-input type="textarea" v-model="data.perigrafi" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('PlatformSettingsView.klados')">
            <el-select v-model="data.klados_id" placeholder=" ">
              <el-option v-for="klados_item in klados" :key="klados_item.id"
                :label="klados_item.kodikos + ' (' + klados_item.perigrafi + ')'" :value="klados_item.id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
