<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { Bell, Edit, Delete, Document, User, Plus, RefreshLeft, CircleCheck, CircleClose, Watch, Lock } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { storeToRefs } from "pinia";
import UserType from "../enums/UserType";
import Option from "../interfaces/Option";
import AddEdit_Debtor from "../components/AddEdit_Debtor.vue";
import { useSort } from "../composables/sort";
import DocumentsList from "../components/DocumentsList.vue";
import DocumentFolders from "../enums/DocumentFolders";
import { Entities } from "@/enums/Entities";
import DeleteEntityDialog from "@/components/DeleteEntityDialog.vue";
import { UIViews } from "@/enums/UIViews";

const users = ref<any>([]);
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const { showError, showSuccess, entityApprove } = useGenericMethodsVariables();
const locked_rows = ref(false);
const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const filters = ref<any>({
  pending: false,
  pendingNew: false,
  hideRejected: true,
  fullname: "",
  cid: "",
  mos_officer: "",
  vat: "",
});

const dataList = ref([]);
const dataCount = ref(0);
const lastPage = ref(0);
const detailsAgentsVisible = ref(false);
const selectedRowForAgents = ref(0);
const selectedRow = ref(-1);
const deleteDialogVisible = ref(false);

const availableAgents = ref<Option[]>([]);
const assignedAgents = ref<number[]>([]);
let timer = 0;
const addEditVisible = ref(false);

const showDocumentsList = ref(false);
const selectedDocumentEntity = ref<any>(undefined);

const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

// TODO show only users that are assigned to the visible debtors
const loadUsers = async () => {

  const resultFull = await mosapi.get_view(UIViews.DebtorsView, filters.value, 1, 1000);
  const usersCSV = [];
  resultFull.data.forEach((res) => {
    const array = res.allowed_users_usernames.split(',');
    array.forEach(element => {
      // console.log('element ' + element)
      if ((usersCSV.indexOf(element.trim()) === -1) && element !== '') {
        // console.log(usersCSV.indexOf(element), element)
        usersCSV.push(element.trim())
      }
    });
  });
  usersCSV.sort();
  users.value = usersCSV;
  // console.log(JSON.stringify(usersCSV))
  //const result = await mosapi.users_get_all_active(UserType.Agent);
  //users.value = result.data;

}

const loadAgents = async (row_id: number) => {
  const result = await mosapi.users_get_all_active(UserType.Agent, false);

  availableAgents.value = [];

  for (const agent of result.data as any) {
    availableAgents.value.push({
      key: agent.id,
      label: agent.username,
      disabled: false,
    });
  }

  const result2 = await mosapi.get_users_for_debtor(row_id);
  assignedAgents.value = result2.data.map((x: { user_id: number, debtor_id: number }) => x.user_id);
  // assignedAgents.value = (debtor.data as Debtor).allowed_users;
};

const closeAgentPicking = async () => {
  detailsAgentsVisible.value = false;

  mainStore.isLoading = true;
  await mosapi.post_users_for_debtor(selectedRowForAgents.value, assignedAgents.value);

  await loadPage(lastPage.value);
};

const handleAgents = async (row_id: number) => {
  mainStore.isLoading = true;
  selectedRowForAgents.value = row_id;
  detailsAgentsVisible.value = true;
  await loadAgents(row_id);
  mainStore.isLoading = false;
};

const handleClickEdit = async (row_id: number) => {
  selectedRow.value = row_id;
  addEditVisible.value = true;
  // const ResultLockedRow = await .get("debtor?select=*&id=eq." + row_id);
  // LockedRow.value = ResultLockedRow.data[0].lockedrow;

  // if (LockedRow.value) {
  //   await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow.value == false) {
  //   await .patch("debtor?id=eq." + row_id, { lockedrow: 'true' });
  //   await loadPage(lastPage.value);
  // }
};

const handleClickToUnlock = async (row_id: number) => {
  // await .patch("debtor?id=eq." + row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
}

const UnlockAllLockedRows = async () => {
  // await .patch("debtor?", { lockedrow: 'false' });
  await loadPage(lastPage.value);
  UnlockAllRowsVisible.value = false;
}

const deleteHandleClick = (row_id: number) => {
  deleteDialogVisible.value = true;
  selectedRow.value = row_id;
};

onMounted(async () => {

  //MiscSettings
  // const result = await .get("config_misc_settings?select=*");
  // locked_rows.value = result.data[0].locked_rows;

  // console.log('locked_rows ' + locked_rows.value);

  // const ResultLockedRow = await .get("debtor?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }
  mainStore.isLoading = true;
  await loadUsers();
  await loadPage(1);
  mainStore.isLoading = false;
});

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  lastPage.value = page;

  // let filterUrl = "";

  // if (sortOrder.value !== "") {
  //   if (filterUrl === "") {
  //     filterUrl = "?";
  //   }
  //   filterUrl += `&orderFIX=${sortColumn.value}.${sortOrder.value},id.asc`;
  // } else {
  //   filterUrl += `&orderFIX=fullname.asc,id.asc`;
  // }
	if (sortColumn.value ==="") sortColumn.value = 'fullname';
  const result = await mosapi.get_view(UIViews.DebtorsView, {...filters.value, orderBy:`${sortColumn.value}.${sortOrder.value}`}, lastPage.value, 10);

  // result = await .getPaginated(
  //   "view_mosdebtors_view1?select=*" +
  //   addedFilter2 +
  //   filterUrl +
  //   addedFilter,
  //   page
  // );

  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total;

  // const ResultLockedRow = await .get("debtor?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

  mainStore.isLoading = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  showSuccess("Debtor updated");
  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  showError("Debtor failed to update");
  addEditVisible.value = false;
};

const filterChanged = async () => {
  console.log(JSON.stringify(filters.value));
  if (timer) {
    window.clearTimeout(timer);
    timer = 0;
  }

  timer = window.setTimeout(() => {
    loadPage(1);

    window.clearTimeout(timer);
    timer = 0;
  }, 500);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const approve = async (entity_id: number, state: boolean) => {
  const fv = await entityApprove(Entities.Debtors, entity_id, state);

  if (fv) {
    showSuccess(`Debtor updated`);
  } else {
    showError(`Debtor update failed`);
  }

  await loadPage(lastPage.value);
};

const onCloseAddEdit = async () => {
  // await .patch("debtor?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
}

const handleDocumentsListClick = (row: any) => {
  selectedRow.value = row.id;
  selectedDocumentEntity.value = row;
  showDocumentsList.value = true;
}

const onDelete = async () => {
  showSuccess("Debtor deleted successfully");
  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
}

</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("DebtorView.debtors") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input type="text" v-model="filters.cid" class="client-input" :placeholder="$t('DebtorDetailsView.cid')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.fullname" class="client-input" :placeholder="$t('DebtorView.company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-select clearable v-model="filters.mos_officer" class="m-2 client-input" placeholder="MOS Officer"
            value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in users" :key="item" :label="item" :value="item" />
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.vat" class="client-input" :placeholder="$t('DebtorView.tax_registration_nbr')"
            @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.pendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.hideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
          <el-row v-if="locked_rows == true">
            <el-col>
              <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column fixed sortable prop="cid" :label="$t('DebtorDetailsView.cid')" />
      <el-table-column fixed sortable prop="fullname" :label="$t('DebtorView.company_name')" />
      <el-table-column fixed sortable prop="vat_number" :label="$t('DebtorView.tax_registration_nbr')" />
      <el-table-column fixed sortable prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" />
      <el-table-column fixed sortable prop="allowed_users_usernames" :label="$t('DebtorDetailsView.mos_officer')" />
      <el-table-column fixed sortable prop="pending_approvals" :label="$t('global.pending_approvals')" width="100">
        <template #default="scope">
          {{ scope.row.pending_approvals }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button v-if="mainStore.loggedUser.user_type < 2" link type="primary" size="small" class="btn-portfolios"
            @click="handleAgents(scope.row.id)" :icon="User" />
          <el-tooltip class="box-item" effect="dark" content="Documents List" placement="top">
            <el-button link type="primary" size="small" class="btn-detail" @click="handleDocumentsListClick(scope.row)"
              :icon="Document" />
          </el-tooltip>
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" :disabled="scope.row.lockedrow && locked_rows" style="background-color: #d6d6d6 !important;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
            class="btn-delete" @click="deleteHandleClick(scope.row.id)" :icon="Delete" />
          <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip>
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
          ">
            <el-button class="btn-accept_square" @click="approve(scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="approve(scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Debtor' : 'Debtor Details'" width="50%">
      <AddEdit_Debtor @close="onCloseAddEdit" @save="onSave" @fail-save="onFailSave" :row_id="selectedRow" />
    </el-dialog>

    <DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Debtor" :table="Entities.Debtors"
      title="Delete Debtor" width="50%" @close="deleteDialogVisible = false" @delete="onDelete" />

    <el-dialog v-model="detailsAgentsVisible" title="Agent assignment" width="60%">
      <div v-loading="mainStore.isLoading">
        <el-transfer v-model="assignedAgents" :data="availableAgents" :titles="['Available', 'Assigned']" />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeAgentPicking">{{ $t('generic.close') }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- TODO: Translations -->
    <el-dialog v-if="showDocumentsList" v-model="showDocumentsList"
      :title="`Debtor / ${selectedDocumentEntity.cid} - Documents List`" width="700">
      <DocumentsList v-if="showDocumentsList" :entity_type="Entities.Debtors" :entity_id="selectedDocumentEntity.id"
        :row_id="selectedRow"
        :allowed_folders="[DocumentFolders.LegalizationDocuments, DocumentFolders.MiscDocuments]" />
    </el-dialog>

    <div>
      <el-pagination size="small" layout=" prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped></style>
