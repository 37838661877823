<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useRouter } from "vue-router";
import LoginRequest from "../classes/LoginRequest";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
//import { useReCaptcha } from 'vue-recaptcha-v3';
// import { useReCaptchaV3 } from '../composables/useReCaptchaV3';
import axios from "axios";
const { locale } = useI18n({ useScope: "global" });

const router = useRouter();
const form = ref(new LoginRequest());
const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();
const { showError, showLocalizedError, showWarning } = useGenericMethodsVariables();
const version = import.meta.env.VITE_APP_VERSION;
const showResetPasswordDialog = ref(false);
const newPassOne = ref("");
const newPassTwo = ref("");
const changingPassword = ref(false);
//const useReCaptchaInstance = useReCaptcha()!;
let reCaptcha: { token: string } | undefined = undefined;
const verifiedRecaptcha = ref(false);
const verifiedRecaptchaCheck = ref(false);
const generateCaptcha = () => {
	const num1 = Math.floor(Math.random() * 10) + 1;
	const num2 = Math.floor(Math.random() * 10) + 1;
	return { num1, num2, answer: num1 + num2 };
};
const captcha = ref(generateCaptcha());
const userInput = ref("");
const isValid = ref(false);

// We logged in from another tab, reset display
const handleStorageChange = (e: StorageEvent) => {
	mainStore.menuOrder = [];
	router.push("/oops");
};

onMounted(async () => {
	window.addEventListener('storage', handleStorageChange);
	console.log('Mode:', import.meta.env.MODE);
	// console.log(import.meta.env.APP_VERSION);
	// try {
	// 	reCaptcha = await useReCaptchaV3(useReCaptchaInstance);
	// 	verifiedRecaptcha.value = (await axios.get('http://localhost:3001/reCaptchaVerify/' + reCaptcha.token)).data
	// 	if (verifiedRecaptcha.value === true) {
	// 		verifiedRecaptchaCheck.value = true;
	// 	} else {
	// 		verifiedRecaptchaCheck.value = false;
	// 	}
	// } catch (ex) {
	// 	console.log('error', ex)
	// }
});

onUnmounted(() => {
	window.removeEventListener('storage', handleStorageChange);
});

const login = async () => {
	try {
		//await useReCaptchaV3(useReCaptchaInstance);
		if (parseInt(userInput.value) === captcha.value.answer) {
			isValid.value = true;
		} else {
			isValid.value = false;
			captcha.value = generateCaptcha();
			userInput.value = "";
			return
		}

		// await useReCaptchaV3(useReCaptchaInstance);
		mainStore.isLoading = true;

		const reply = await mosapiStore.login(form.value.username, form.value.password);
		// console.log(JSON.stringify(reply));
		if (reply.error) {
			showLocalizedError(reply.error);
			mainStore.isLoading = false;
			return;
		}

		if (reply.data[0].screens.length === 0) {
			showError("No screens available for this user");
			await mosapiStore.logout();
			mainStore.isLoading = false;
			return;
		}

		mainStore.setup_menu(reply.data[0].screens);
		mainStore.setUser(reply.data[0].user);
		mosapiStore.setToken(reply.data[0].token);

		// Use default English language if user has not set a language
		if (reply.data[0].user.language === null)
			locale.value = "en";
		else
			locale.value = reply.data[0].user.language;

		mainStore.isLoading = false;

		// Notify all other tabs to redirect in order to refresh user data
		localStorage.setItem("login_event", Date.now().toString());

		// Check if user is flagged for password change
		if (reply.data[0].user.reset_password_on_login) {
			showResetPasswordDialog.value = true;
			newPassOne.value = "";
			newPassTwo.value = "";
			return;
		}

		const daysWithoutPasswordChange = DateTime.now().diff(
			DateTime.fromISO(reply.data[0].user.last_password_change),
			"days"
		).days;

		// console.log("days:", daysWithoutPasswordChange);

		// Check if user needs to change password after 180 days
		if (daysWithoutPasswordChange >= 180) {
			showResetPasswordDialog.value = true;
			newPassOne.value = "";
			newPassTwo.value = "";
			return;
		}
		//console.log(menuIcons);
		router.push("/dashboard");
	} catch (ex) {
		console.log(ex.message);
		mainStore.isLoading = false;
	}
};

const checkPassword = () => {
	if (newPassOne.value !== newPassTwo.value) {
		showWarning("Passwords must match");
		return false;
	}

	if (newPassOne.value.length < 8) {
		showError("Password must be at least 8 characters long.");
		return;
	}

	const hasUpperCase = /[A-Z]/.test(newPassOne.value) ? 1 : 0;
	const hasLowerCase = /[a-z]/.test(newPassOne.value) ? 1 : 0;
	const hasNumbers = /\d/.test(newPassOne.value) ? 1 : 0;
	const hasNonalphas = /\W/.test(newPassOne.value) ? 1 : 0;

	if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
		showError(
			"Password must contain at least one uppercase letter, one lowercase letter, one digit and one non-alphanumeric character (e.g. !, @, #, $)",
			6500
		);
		return false;
	}

	return true;
};

const changePass = async () => {
	if (!checkPassword()) return;

	mainStore.isLoading = true;

	const result = await mosapiStore.updatePassword(
		mainStore.loggedUser.id as number,
		newPassOne.value
	);

	mainStore.isLoading = false;

	if (result.error) {
		showError(result.error);
		return;
	}

	showResetPasswordDialog.value = false;

	newPassOne.value = "";
	newPassTwo.value = "";

	router.push("/dashboard");
};

const cancelReset = async () => {
	showResetPasswordDialog.value = false;

	form.value.password = "";

	mainStore.unsetUser();
	mosapiStore.unsetToken();

	router.push("/login");
};

</script>

<template>

	<div class="login-container">
		<div class="title-icon">
			<el-col :span="12" class="logo-wrapper"><img src="../assets/LOGO_MIDDLE-OFFICE-RGB.png" /></el-col>
		</div>

		<div class="login-logo1"></div>
		<div class="title-icon2">
		</div>

		<div class="title-disclaimer copyrights-wrapper">
			Copyright &copy; 2025 MOS Platform <b>{{ version }}</b>. All Rights Reserved.
			<a title="Optimos" href="https://www.optimos.gr" target="_blank" rel="noopener">Web Design &amp; development
				by
				Optimos</a>
		</div>
		<el-card class="login-card" :body-style="{ padding: '0px 20px' }" shadow="hover"
			v-loading="mainStore.isLoading">
			<el-form class="login-form" label-width="0px">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item>
							<el-input :placeholder="$t('questions.enter_username')" name="login" autocomplete="none"
								v-model="form.username" clearable @keyup.enter="login"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item>
							<el-input placeholder="Password" type="password" name="password" autocomplete="none"
								v-model="form.password" @keyup.enter="login" show-password />
						</el-form-item>
						<!-- <div v-if="!verifiedRecaptcha">Verifying ReCaptcha...</div>
						<el-button v-if="verifiedRecaptchaCheck" class="login-button" type="primary" size="default"
							color="#048" @click="login">Login</el-button> -->
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-form-item>
							<el-row :gutter="10">
								<el-col :span="10">
									Captcha: {{ captcha.num1 }} + {{ captcha.num2 }} =
								</el-col>
								<el-col :span="6">
									<el-input v-model="userInput" style="border: 2px !important; border-style: dashed; border-color: gray !important;" @keyup.enter="login" />
								</el-col>
							</el-row>
							<div class>
								<p v-if="isValid" class="text-green-500 mt-2">Verified</p>
							</div>
						</el-form-item>
						<div v-if="!verifiedRecaptcha && false">Verifying ReCaptcha...</div>
						<el-button v-if="verifiedRecaptchaCheck || true" class="login-button" type="primary" size="default" color="#048" @click="login">Login</el-button>
					</el-col>
				</el-row>
			</el-form>
			<el-dialog v-model="showResetPasswordDialog" title="Reset password" width="50%">
				<template #default>
					<span>Please enter a new password</span>
					<el-form>
						<el-row>
							<el-col :span="24">
								<el-form-item label="New Password">
									<el-input v-model="newPassOne" type="password" placeholder="Please input password"
										autocomplete="off" show-password />
								</el-form-item>
							</el-col>
						</el-row>
						<el-row>
							<el-col :span="24">
								<el-form-item label="Confirm Password">
									<el-input v-model="newPassTwo" type="password"
										placeholder="Please confirm your password" autocomplete="off" show-password />
								</el-form-item>
							</el-col>
						</el-row>
						<span class="dialog-footer" v-loading="changingPassword">
							<el-button @click="cancelReset">Back to login</el-button>
							<el-button type="primary" @click="changePass">Update</el-button>
						</span>
					</el-form>
				</template>
			</el-dialog>
		</el-card>
	</div>
</template>

<style scoped></style>
