<script lang="ts" setup>
import { ref, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import { useMainStore } from "../store/main";
import { ElMessage } from "element-plus";
import { Edit, Delete } from "@element-plus/icons-vue";
import PostalCodeDetails from "../components/ConfigPostalCodeDetails.vue";
import UserType from "../enums/UserType";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";

const { showError } = useGenericMethodsVariables();

const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const formRef = ref<FormInstance>();
const DeleteDialogVisible = ref(false);
const detailsVisible = ref(false);
const deleteVisible = ref(false);
const selectedRow = ref(0);
const DeleteSelectedRow = ref(0);
const dataList = ref([]);
const lastPage = ref(0);
const dataCount = ref(0);

onMounted(async () => {
  loadPage(1);
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeletePostalCode(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeletePostalCode = async (row_id: number) => {
  const result = await mosapi.generic_entity_delete(Entities.ConfigPostalCode, row_id);
  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }
  onDelete();
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Postal Code updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Postal Code saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Postal Code Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Postal Code failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};


const new_entities = ref<PostalCodeItem[]>([]);

interface PostalCodeItem {
  postalcode: string;
}

const removePostalcode = (item: PostalCodeItem) => {
  const index = new_entities.value.indexOf(item);
  if (index !== -1) {
    new_entities.value.splice(index, 1);
  }
};

const addPostalcode = () => {
  new_entities.value.push({
    postalcode: ""
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  const result = await mosapi.generic_entity_post_bulk(Entities.ConfigPostalCode, new_entities.value);
  if (result.error) {
    onFailSave();
    return;
  }
  new_entities.value = [];
  onInsert();
  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;
  lastPage.value = page;

  const result = await mosapi.generic_entity_get(Entities.ConfigPostalCode, {}, page, 10);
  mainStore.isLoading = false;

  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total;
  
};
</script>

<template>
  <div style="height: 100%" v-loading="mainStore.isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="postalcode" :label="$t('PlatformSettingsView.postal_codes')"
            type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;">
                <el-icon>
                  <Delete />
                </el-icon>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination size="small" layout=" prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-model="DeleteDialogVisible" title="Postal Code Delete" width="50%">
          <span>{{ $t("PlatformSettingsView.postalcode_delete_message") }}</span>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="Postal Code Details" width="50%">
          <PostalCodeDetails :postalcodeid="selectedRow" @save="onSave"
            @close="detailsVisible = false; selectedRow = -1; loadPage(lastPage);" @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="new_entities" label-width="220px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(postalcode, index) in new_entities"
                    :label="$t('PlatformSettingsView.postalcode_name') + ' ' + index"
                    :prop="'postalcodes.' + index + '.value'" style="border-bottom: 0px; margin-top: 30px"
                    :key="postalcode.postalcode">
                    <el-col :span="11">
                      <el-input :placeholder="$t('PlatformSettingsView.postalcode_name')"
                        v-model="postalcode.postalcode" autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="2">
                      <el-button style="float: right" @click.prevent="removePostalcode(postalcode)">{{
                        $t('generic.delete') }}
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="new_entities.length != 0" type="primary" @click="submitForm(formRef)">
                      {{ $t('generic.save') }}
                    </el-button>
                    <el-button @click="addPostalcode">{{
                      $t("PlatformSettingsView.new_postalcode")
                    }}</el-button>
                    <!-- <el-button @click="resetForm(formRef)">Reset</el-button> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
