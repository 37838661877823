import ITableName from "../../interfaces/ITableName";
import LoanAgreement from "./LoanAgreement";

class RegisterOfBond implements ITableName {
  id: number = -1;
  loan_agreement_id: string = "";
  payment_representative: string = "";
  replacement_mechanism: string = "";
  lockedrow: boolean = false;

  loan_agreement?: LoanAgreement = undefined;
  
  static getTableNameStatic(): string {
    return "register_of_bonds";
  }

  getTableName(): string {
    return RegisterOfBond.getTableNameStatic();
  }
}

export default RegisterOfBond;
