import ITableName from "../../interfaces/ITableName";
import Debtor from "./Debtor";

class RealEstate implements ITableName {
  id: number = -1;
  debtor_id: string = "";
  arithmos_akinitou_diax: string = "";
  eidos: string = "";
  address: string = "";
  address_number: string = "";
  region: string = "";
  city: string = "";
  district: string = "";
  postalcode: string = "";
  country: string = "";
  google_map_link: string = "";
  description: string = "";
  constr_date: string = "";
  eidos_idioktisias: string = "";
  percentage_property: string = "";
  antikeim_aksia_akin_rev: string = "";
  ektim_aksias_akin: string = "";
  eidos_ektim: string = "";
  hmer_ektim: string = "";
  ektimiths: string = "";
  dikaiouxos: string = "";
  kthmatolog_graf: string = "";
  kaek: string = "";
  ypothikof: string = "";
  tomos: string = "";
  fullo: string = "";
  number: string = "";
  poleod_parabaseis: boolean = false;
  ektim_kostos_taktop: string = "";
  tm_oikopedou: string = "";
  tm_ktismatos: string = "";
  tm_apothikhs: string = "";
  tm_thesis_stathmeushs: string = "";
  construction_status: string = "";
  liquidation_value: string = "";
  orofoi: string = "";
  debtor: Debtor = new Debtor();
  lockedrow: boolean = false;
  static getTableNameStatic(): string {
    return "real_estate";
  }

  getTableName(): string {
    return RealEstate.getTableNameStatic();
  }
}

export default RealEstate;
