<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { DateTime } from "luxon";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useValidation } from "../composables/validation";
import Auction from "../classes/DB_Entities/Auction";
import { ElForm, ElRow, ElCol, ElFormItem, ElButton } from "element-plus";
import AcceptAllPending from "./AcceptAllPending.vue";
import { useMOSAPIStore } from "../store/mos_api";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import { orderBy } from "lodash";
const mosapi = useMOSAPIStore();
const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();

const {
  onValidationChange,
  checkValidationsForError,
} = useValidation();
const TrueFalseValues = ref<any>([]);
const { t } = useI18n({ useScope: "global" });
const LockedRow = ref(false);
const debtorsList = ref(new Array<any>());
const realEstatesList = ref(new Array<any>());
const RECollateralsList = ref(new Array<any>());
const PreviousStatusList = ref(new Array<any>());
const agentsList = ref<any>([]);
const viewOnly = ref<boolean>(false);

const {
  setup_decision_listener,
  cleanObject,
  data,
  fullObj,
  hasPendingChanges,
  table_name,
  clickAcceptAll,
  loadData,
  resetObjects,
  save,
  showError,
  updateDecision,
} = useGenericMethodsVariables();

watch(
  () => fullObj.value.debtor_id?.originalValue,
  async (newVal, oldVal) => {
    if (data.value.debtor_id !== fullObj.value.debtor_id?.originalValue && fullObj.value.debtor_id.originalValue !== undefined) {
      fullObj.value.real_estate_id.originalValue = "";
    }

    if (newVal === undefined) return;

    await loadRealEstates(newVal);
  }
);

watch(
  () => fullObj.value.real_estate_id?.originalValue,
  async (newVal, oldVal) => {
    if (data.value.real_estate_id !== fullObj.value.real_estate_id?.originalValue && fullObj.value.debtor_id.originalValue !== undefined)
      fullObj.value.re_collateral_id.originalValue = "";

    if (newVal === undefined) return;

    await loadRECollaterals(newVal);
  }
);

onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  mainStore.isLoading = true;
  table_name.value = Auction.getTableNameStatic();
  cleanObject.value = new Auction();

  //console.log("auction_id", route.params.auction_id);
  //console.log("view_only", route.params.view_only);

  await loadSelects();
  await loadDebtors();
  await loadAgents();

  resetObjects();

  if (route.params.auction_id === "new") {
    mainStore.isLoading = false;
    return;
  }

  if (route.params.view_only === "true")
    viewOnly.value = true;

  const auction_id = parseInt(route.params.auction_id as string);

  await loadData(auction_id);
  await fixPendingSelects();
  mainStore.isLoading = false;
  //const ResultLockedRow = await .get("auctions?select=*&id=eq." + route.params.auction_id);
  //LockedRow.value = ResultLockedRow.data[0].lockedrow;
  // if (LockedRow.value) {
  //   //await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow.value == false) {
  //   const result = await .patch("auctions?id=eq." + route.params.auction_id, { lockedrow: 'true' });
  //   //await loadPage(lastPage.value);
  // }

  mainStore.isLoading = false;
});

const loadDebtors = async () => {
  debtorsList.value = [];
  // let query = `view_mosdebtors_view1?approve_status=eq.1&orderFIX=fullname.asc,vat_number.asc`;
  //const result:  = await .get(query);

  const result = await mosapi.get_view(UIViews.DebtorsView, { approve_status: 1, orderBy:'fullname.asc,vat_number.asc' }, 1, 1000);
  for (let i = 0; i < result.data.length; i++) {
    debtorsList.value.push({
      value: result.data[i].id,
      label: result.data[i].cid + " (" + result.data[i].fullname + ")",
    });
  }
};

const loadRealEstates = async (debtor_id: number) => {
  // console.log("loading real estates for debtor_id", debtor_id);
  realEstatesList.value = [];
  if (
    fullObj.value.debtor_id === undefined ||
    fullObj.value?.debtor_id?.originalValue === "" ||
    fullObj.value?.debtor_id?.originalValue === 0
  )
    return;
  // result = await .get(
  //   "real_estate?select=id,arithmos_akinitou_diax&approve_status=eq.1&orderFIX=arithmos_akinitou_diax.asc&debtor_id=eq." +
  //   debtor_id
  // ); // Only approved entities
  const result = await mosapi.generic_entity_get(Entities.RealEstate, { debtor_id : debtor_id, orderBy:'arithmos_akinitou_diax.asc' });
  console.log(JSON.stringify(result));
  if (result.error) {
    showError(result.error);
    return;
  }
  // console.log(JSON.stringify(result.data));
  for (let i = 0; i < result.data.length; i++) {
    // console.log("pushing RE", JSON.stringify(result.data[i]));
    realEstatesList.value.push({
      value: result.data[i].id,
      label: result.data[i].arithmos_akinitou_diax,
    });
  }
};

const loadRECollaterals = async (real_estate_id: number) => {
  // console.log("loading RECollaterals for real_state_id", real_estate_id);
  RECollateralsList.value = [];

  if (fullObj.value?.real_estate_id?.originalValue === 0 || fullObj.value?.real_estate_id?.originalValue === "")
    return;

  // result = await .get(
  //   "re_collaterals?select=id,ar_kalummatos&approve_status=eq.1&orderFIX=ar_kalummatos.asc&real_estate_id=eq." +
  //   real_estate_id
  // ); // Only approved entities
  const result = await mosapi.generic_entity_get(Entities.RECollateral, { real_estate_id : real_estate_id, orderBy:'ar_kalummatos' });
  if (result.error) {
    showError(result.error);
    return;
  }
  for (let i = 0; i < result.data.length; i++) {
    // console.log("pushing", JSON.stringify(result.data[i]));
    RECollateralsList.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_kalummatos,
    });
  }
};

const loadAgents = async () => {
  agentsList.value = [];

  // result = await s.get("config_agent?orderFIX=agent");
  const result = await mosapi.generic_entity_get(Entities.ConfigAgent, {orderBy:'agent'}, 1, 1000);
  if (result.error) {
    showError(result.error);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    agentsList.value.push({
      value: result.data[i].id,
      label: result.data[i].agent,
    });
  }
};

const onPreSave = async () => {
  // if (fullObj.value.hmer_prohg_pl?.originalValue === "") {
  //   showWarning(t("errors.missing_hmer_prohg_pl"));
  //   return;
  // }

  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  mainStore.isLoading = true;

  // Remove empty fields
  const fields = [
    "anag_apetisis",
    "yp_parat",
    "suntak_pinaka_katat",
    "hmer_anakophs",
    "hmer_ekd",
    "ekd_trapeza_ib",
  ];

  Object.keys(fullObj.value).forEach((key) => {
    if (fields.includes(key)) {
      if (
        fullObj.value[key].originalValue === "" ||
        fullObj.value[key].originalValue === undefined ||
        fullObj.value[key].originalValue === 0
      ) {
        delete fullObj.value[key];
      }
    }
  });

  delete fullObj.value.debtor;
  delete fullObj.value.real_estate;

  await save();
  mainStore.isLoading = false;
  onClose();
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue;

    const originalValue = fullObj.value[field].originalValue;
    const pendingValue = fullObj.value[field].pendingValue.value;

    // Debtor
    // if (field === "debtor_id") {
    //   let debtor = await mosapi.generic_entity_get(Entities.Debtors, { id: pendingValue });
    //   fullObj.value[field].pendingValue["pendingDisplayValue"] = debtor.data[0].vat_number + " (" + debtor.data[0].fullname + ")";

    //   debtor = await mosapi.generic_entity_get(Entities.Debtors, { id: originalValue });
    //   fullObj.value[field].pendingValue["originalDisplayValue"] = debtor.data[0].vat_number + " (" + debtor.data[0].fullname + ")";
    // }

    if (field === "debtor_id") {
      if(fullObj.value[field].pendingValue.value !== null && fullObj.value[field].pendingValue.value !== ""){
        let debtor = await mosapi.generic_entity_get(Entities.Debtors, { id: pendingValue });
        fullObj.value[field].pendingValue["pendingDisplayValue"] = debtor.data[0].vat_number + " (" + debtor.data[0].fullname + ")";
      } else {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = "";
      }
      if(fullObj.value[field].originalValue !== null){
        let debtor = await mosapi.generic_entity_get(Entities.Debtors, { id: originalValue });
        fullObj.value[field].pendingValue["originalDisplayValue"] = debtor.data[0].vat_number + " (" + debtor.data[0].fullname + ")";
      } else {
        fullObj.value[field].pendingValue["originalDisplayValue"] = "";
      }
    }

    if (field === "eisp_me_egg_lf" || field === "eparkes_pl") {
      if (fullObj.value[field].pendingValue.value === "true") {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = t("global.true");
      } else if (fullObj.value[field].pendingValue.value === "false") {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = t("global.false");
      }

      if (fullObj.value[field].originalValue === true) {
        fullObj.value[field].pendingValue["originalDisplayValue"] = t("global.true");
      } else if (fullObj.value[field].originalValue === false) {
        fullObj.value[field].pendingValue["originalDisplayValue"] = t("global.false");
      }
    }

    //Real Estate Nbr
    // if (field === "real_estate_id") {
    //   let RealEstate = await mosapi.generic_entity_get(Entities.RealEstate, { id: pendingValue });
    //   fullObj.value[field].pendingValue["pendingDisplayValue"] = RealEstate.data[0].arithmos_akinitou_diax;

    //   RealEstate = await mosapi.generic_entity_get(Entities.RealEstate, { id: originalValue });
    //   fullObj.value[field].pendingValue["originalDisplayValue"] = RealEstate.data[0].arithmos_akinitou_diax;
    // }

    if (field === "real_estate_id") {
      if(fullObj.value[field].pendingValue.value !== null && fullObj.value[field].pendingValue.value !== ""){
        let RealEstate = await mosapi.generic_entity_get(Entities.RealEstate, { id: pendingValue });
        fullObj.value[field].pendingValue["pendingDisplayValue"] = RealEstate.data[0].arithmos_akinitou_diax;
      } else {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = "";
      }
      if(fullObj.value[field].originalValue !== null){
        let RealEstate = await mosapi.generic_entity_get(Entities.RealEstate, { id: originalValue });
        fullObj.value[field].pendingValue["originalDisplayValue"] = RealEstate.data[0].arithmos_akinitou_diax;
      } else {
        fullObj.value[field].pendingValue["originalDisplayValue"] = "";
      }
    }

    //Re Collateral
    // if (field === "re_collateral_id") {
    //   let ReCollateral = await mosapi.generic_entity_get(Entities.RECollateral, { id: pendingValue });
    //   fullObj.value[field].pendingValue["pendingDisplayValue"] = ReCollateral.data[0].ar_kalummatos;

    //   ReCollateral = await mosapi.generic_entity_get(Entities.RECollateral, { id: originalValue });
    //   fullObj.value[field].pendingValue["originalDisplayValue"] = ReCollateral.data[0].ar_kalummatos;
    // }

    if (field === "re_collateral_id") {
      if(fullObj.value[field].pendingValue.value !== null && fullObj.value[field].pendingValue.value !== ""){
        let ReCollateral = await mosapi.generic_entity_get(Entities.RECollateral, { id: pendingValue });
        fullObj.value[field].pendingValue["pendingDisplayValue"] = ReCollateral.data[0].ar_kalummatos;
      } else {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = "";
      }
      if(fullObj.value[field].originalValue !== null){
        let ReCollateral = await mosapi.generic_entity_get(Entities.RECollateral, { id: originalValue });
        fullObj.value[field].pendingValue["originalDisplayValue"] = ReCollateral.data[0].ar_kalummatos;
      } else {
        fullObj.value[field].pendingValue["originalDisplayValue"] = "";
      }
    }

    //Status
    if (field === "katastash") {
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = PreviousStatusList.value.find((element: any) => {
        return element.value === pendingValue;
      })?.label;
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = PreviousStatusList.value.find((element: any) => {
        return element.value === originalValue;
      })?.label;
    }

    //Agents
    // if (field === "ekd_trapeza_ib") {
    //   let agent = await mosapi.generic_entity_get(Entities.ConfigAgent, { id: pendingValue });
    //   fullObj.value[field].pendingValue["pendingDisplayValue"] = agent.data[0].agent;

    //   agent = await mosapi.generic_entity_get(Entities.ConfigAgent, { id: originalValue });
    //   fullObj.value[field].pendingValue["originalDisplayValue"] = agent.data[0].agent;
    // }

    if (field === "ekd_trapeza_ib") {
      if(fullObj.value[field].pendingValue.value !== null && fullObj.value[field].pendingValue.value !== ""){
        let agent = await mosapi.generic_entity_get(Entities.ConfigAgent, { id: pendingValue });
        fullObj.value[field].pendingValue["pendingDisplayValue"] = agent.data[0].agent;
      } else {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = "";
      }
      if(fullObj.value[field].originalValue !== null){
        let agent = await mosapi.generic_entity_get(Entities.ConfigAgent, { id: originalValue });
        fullObj.value[field].pendingValue["originalDisplayValue"] = agent.data[0].agent;
      } else {
        fullObj.value[field].pendingValue["originalDisplayValue"] = "";
      }
    }

    //Previous Auction Status

    if (field === "katastash_prohg_pl") {
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = PreviousStatusList.value.find((element: any) => {
        return element.value === pendingValue;
      })?.label;
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = PreviousStatusList.value.find((element: any) => {
        return element.value === originalValue;
      })?.label;
    }

    // Convert to boolean
    if (field === "eparkes_pl" || field === "eisp_me_egg_lf") {
      fullObj.value[field].pendingValue.value =
        fullObj.value[field].pendingValue.value === "true";
    }
  }
};

const loadSelects = async () => {
  PreviousStatusList.value = [
    {
      value: "successful",
      label: t("AuctionsStatusList.successful"),
    },
    {
      value: "fruitless",
      label: t("AuctionsStatusList.fruitless"),
    },
    {
      value: "suspended",
      label: t("AuctionsStatusList.suspended"),
    },
    {
      value: "frustrated",
      label: t("AuctionsStatusList.frustrated"),
    },
    {
      value: "canceled",
      label: t("AuctionsStatusList.cancelled"),
    },
  ];
  TrueFalseValues.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];
};

const checkAnagApaitisis = () => {
  let fv = false;

  try {
    fv =
      DateTime.fromFormat(fullObj.value.anag_apetisis.originalValue, "yyyy-MM-dd")
        .plus({ days: 5 })
        .diff(DateTime.fromFormat(fullObj.value.yp_parat.originalValue, "yyyy-MM-dd"), "days")
        .days > 5 ||
      DateTime.fromFormat(fullObj.value.anag_apetisis.originalValue, "yyyy-MM-dd")
        .plus({ days: 5 })
        .diff(DateTime.fromFormat(fullObj.value.yp_parat.originalValue, "yyyy-MM-dd"), "days")
        .days < 0;
  } catch (ex) {
    //console.log(ex);
    return false;
  }

  return fv;
};

const checkYpParat = () => {
  let fv = false;

  try {
    fv =
      DateTime.fromFormat(fullObj.value.yp_parat.originalValue, "yyyy-MM-dd")
        .plus({ days: 10 })
        .diff(
          DateTime.fromFormat(fullObj.value.suntak_pinaka_katat.originalValue, "yyyy-MM-dd"),
          "days"
        ).days > 10 ||
      DateTime.fromFormat(fullObj.value.yp_parat.originalValue, "yyyy-MM-dd")
        .plus({ days: 10 })
        .diff(
          DateTime.fromFormat(fullObj.value.suntak_pinaka_katat.originalValue, "yyyy-MM-dd"),
          "days"
        ).days < 0;
  } catch (ex) {
    //console.log(ex);
    return false;
  }

  return fv;
};

const checkHmerAnak = () => {
  let fv = false;

  try {
    fv = DateTime.fromFormat(fullObj.value.suntak_pinaka_katat.originalValue, "yyyy-MM-dd")
      .plus({ days: 15 })
      .diff(
        DateTime.fromFormat(fullObj.value.hmer_anakophs.originalValue, "yyyy-MM-dd"),
        "days"
      ).days > 15 ||
      DateTime.fromFormat(fullObj.value.suntak_pinaka_katat.originalValue, "yyyy-MM-dd")
        .plus({ days: 15 })
        .diff(
          DateTime.fromFormat(fullObj.value.hmer_anakophs.originalValue, "yyyy-MM-dd"),
          "days"
        ).days < 0;
  } catch (ex) {
    //console.log(ex);
    return false;
  }

  return fv;
};

const onClose = async () => {
  //const result = await .patch("auctions?id=eq." + route.params.auction_id, { lockedrow: 'false' });
  router.back();
};
</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading" style="margin: 30px; padding: 50px">
    <el-form>
      <!-- <el-row v-if="!isLoading">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="onPreSave">{{$t('generic.save')}}</el-button>
            <el-button @click="onClose">{{$t('generic.close')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row> -->
      <AcceptAllPending v-show="hasPendingChanges && !viewOnly"
        @accept="clickAcceptAll(parseInt(route.params.auction_id as string))" />
      <p />
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.debtor_id" label="Debtor" :options="debtorsList"
             :validation-rules="[['^[0-9]*[1-9][0-9]*$', 'validation.select_debtor']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.real_estate_id" label="Real Estate" :options="realEstatesList"
             :validation-rules="[['^[0-9]*[1-9][0-9]*$', 'validation.select_real_estate']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.re_collateral_id" label="RE Collateral"
            :options="RECollateralsList"  :validation-rules="[
              ['^[0-9]*[1-9][0-9]*$', 'validation.select_re_collateral'],
            ]" @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="input" label="Third Party ID" :object="fullObj.third_part_id"
            :validation-rules="[['^[0-9]+$', 'validation.select_third_part_id']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="input" :label="$t('AuctionsDetails.auction_program_nbr')" :object="fullObj.ar_prog_pl"
             :validation-rules="[['^[0-9]+$', 'validation.select_ar_prog_pl']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('AuctionsDetails.auction_date')" :object="fullObj.hmer_pl"
             :validation-rules="[['.+', 'validation.select_hmer_pl']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('AuctionsDetails.declaration_of_claim')"
            :object="fullObj.anag_apetisis"
            :validation-rules="[['.+', 'validation.select_anag_apetisis']]" @validation-change="onValidationChange"
            :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="input" :label="$t('AuctionsDetails.claimant')" :object="fullObj.epispeudon"
             :validation-rules="[['^[0-9A-Za-zΑ-Ωα-ω ]+$', 'validation.select_epispeudon']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('AuctionsDetails.auction_initial_price')" :object="fullObj.timh_pl"
             :validation-rules="[['^[0-9]+(\,\d{1,2})?', 'validation.select_timh_pl']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="select" :object="fullObj.katastash" :label="$t('AuctionsDetails.auction_status')"
            :options="PreviousStatusList"  :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="money" :label="$t('AuctionsDetails.auction_price')" :object="fullObj.timh_katak"
             :validation-rules="[['^[0-9]+(\,\d{1,2})?', 'validation.select_timh_katak']]"
            @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="input" :label="$t('AuctionsDetails.bidder')" :object="fullObj.pleiodotis"
             @validation-change="onValidationChange" :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :label="$t('AuctionsDetails.sufficient_auction_amount')"
            :object="fullObj.eparkes_pl"  :is-disabled="viewOnly" :options="TrueFalseValues"/>
        </el-col>

        <!-- <el-col :v-if="fullObj.eparkes_pl.originalValue"> -->
        <el-col v-if="fullObj.eparkes_pl?.originalValue">
          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('AuctionsDetails.submission_of_comments')"
              :object="fullObj.yp_parat"
              :validation-rules="[['.+', 'validation.select_yp_parat']]" @validation-change="onValidationChange"
              :is-disabled="viewOnly" />
            <div v-if="checkAnagApaitisis()" class="el-tag el-tag--danger">
              {{ $t("errors.ypovoli_paratiriseon") }}
            </div>
          </el-col>
          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('AuctionsDetails.compiling_of_ranking_table')"
              :object="fullObj.suntak_pinaka_katat"
              :validation-rules="[['.+', 'validation.select_yp_parat']]" @validation-change="onValidationChange"
              :is-disabled="viewOnly" />
            <div v-if="checkYpParat()" class="el-tag el-tag--danger">
              {{ $t("errors.suntaxi_pinaka_kat") }}
            </div>
          </el-col>
          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('AuctionsDetails.date_of_suspension')"
              :object="fullObj.hmer_anakophs"
              :validation-rules="[['.+', 'validation.select_auction_date']]" @validation-change="onValidationChange"
              :is-disabled="viewOnly" />
            <div v-if="checkHmerAnak()" class="el-tag el-tag--danger">
              {{ $t("errors.hmer_anakophs") }}
            </div>
          </el-col>
        </el-col>

        <el-col :span="24">
          <EntityField type="money" :label="$t('AuctionsDetails.amount_attributed_to_client')"
            :object="fullObj.poso_ana_client"  :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :label="$t('AuctionsDetails.collection_by_Letter_of_Guarantee')"
            :object="fullObj.eisp_me_egg_lf"  :is-disabled="viewOnly" :options="TrueFalseValues"/>
        </el-col>

        <el-col v-if="fullObj.eisp_me_egg_lf?.originalValue">
          <el-col :span="24">
            <EntityField type="select" :object="fullObj.ekd_trapeza_ib" :label="$t('AuctionsDetails.issuing_bank')"
              :options="agentsList"
              :validation-rules="[['.+', 'validation.select_ekd_trapeza_ib']]" @validation-change="onValidationChange"
              :is-disabled="viewOnly" />
          </el-col>
          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('AuctionsDetails.issue_date')" :object="fullObj.hmer_ekd"
               :validation-rules="[['.+', 'validation.select_hmer_ekd']]"
              @validation-change="onValidationChange" :is-disabled="viewOnly" />
          </el-col>
          <el-col :span="24">
            <EntityField type="money" :label="$t('AuctionsDetails.lg_amount')" :object="fullObj.lg_amount"
               :is-disabled="viewOnly" />
          </el-col>
          <el-col :span="24">
            <EntityField type="money" :label="$t('AuctionsDetails.lg_commission')" :object="fullObj.lg_comission"
               :is-disabled="viewOnly" />
          </el-col>

          <el-col :span="24">
            <EntityField type="input" :label="$t('AuctionsDetails.beneficiary')" :object="fullObj.dikaiouxos_text"
               :is-disabled="viewOnly" />
          </el-col>
          <el-col :span="24">
            <EntityField type="input" :label="$t('AuctionsDetails.maturity')" :object="fullObj.prothesmia_liksis"
               :is-disabled="viewOnly" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('AuctionsDetails.attorney_fees')" :object="fullObj.eksoda_dikhgorou"
             :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('AuctionsDetails.certified_appraisers_fee')"
            :object="fullObj.eksoda_pistop_ektim"  :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('AuctionsDetails.date_of_previous_auction')"
            :object="fullObj.hmer_prohg_pl"
            :validation-rules="[['.+', 'validation.select_hmer_ekd']]" @validation-change="onValidationChange"
            :is-disabled="viewOnly" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('AuctionsDetails.price_of_previous_auction')"
            :object="fullObj.timh_prohg_pl"  :is-disabled="viewOnly" />
        </el-col>

        <el-col :span="24">
          <EntityField type="select" :object="fullObj.katastash_prohg_pl"
            :label="$t('AuctionsDetails.status_of_previous_auction')" :options="PreviousStatusList"
             :is-disabled="viewOnly" />
        </el-col>
      </el-row>
      <el-row v-if="!mainStore.isLoading">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!viewOnly" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="onClose">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
