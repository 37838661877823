<script lang="ts" setup>
import { onMounted } from "vue";
import { useMainStore } from "../store/main";
import Client from "../classes/DB_Entities/Client";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import UserType from "../enums/UserType";
import { useValidation } from "../composables/validation";

const props = defineProps<{
	row_id: number;
}>();

const mainStore = useMainStore();

const {
	data,
	fullObj,
	table_name,
	loadData,
	close,
	cleanObject,
	resetObjects,
	save,
	setup_decision_listener,
	showError,
} = useGenericMethodsVariables();

const {
	onValidationChange,
	checkValidationsForError,
} = useValidation();

onMounted(async () => {
	setup_decision_listener();
	table_name.value = Client.getTableNameStatic();
	cleanObject.value = new Client();

	console.log("client_id", props.row_id);

	if (props.row_id === -1) {
		resetObjects();
		return;
	}

	mainStore.isLoading = true;
	await loadData(props.row_id);
	mainStore.isLoading = false;
});

const onPreSave = async () => {

	if (checkValidationsForError()) {
		showError("Please correct shown errors.");
		return;
	}
	await save();
};
</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form>
			<EntityField type="input" :label="$t('ClientDetails.clientname')" :object="fullObj.clientname"
				:is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
				:validation-rules="[['.+', 'validation.insert_client_name']]" @validation-change="onValidationChange" />
		</el-form>
		<el-row :gutter="10">
			<el-col :span="24">
				<el-form-item class="btn-wrapper-components">
					<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
					<el-button @click="close">{{ $t('generic.close') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</div>
</template>

<style scoped></style>
