import { ref, } from "vue";
import { defineStore } from "pinia";
import User from "../classes/DB_Entities/User";
import MenuEntry from "../classes/DB_Entities/MenuEntry";

export const useMainStore = defineStore("main", () => {
	const loggedUser = ref<User>(new User());
	const menu = ref<{ [key: string]: MenuEntry[] }>({});
	const filteredRoutes = ref<string[]>([]);
	const menuOrder = ref<string[]>([]);
	const menuIcon = ref<string[]>([]);
	const iscollapsed = ref<boolean>(false);
	const globalPortfolioID = ref<string>("");
	const globalDebtorID = ref<string>("");
	const globalLoanAgreementID = ref<string>("");
	const isLoading = ref<boolean>(false);
	const remaining_time = ref<number>(0);
	const remaining_time_received_at = ref<number>(0);

	const moneyConfig = ref({
		masked: false,
		prefix: '',
		suffix: ' €',
		thousands: '.',
		decimal: ',',
		precision: 2,
		disableNegative: true,
		disabled: false,
		min: 0,
		max: 2000000000,
		allowBlank: true,
		minimumNumberOfCharacters: 0,
	});

	const viewOptions = ref<unknown>({});

	async function setUser(user: User) {
		loggedUser.value = user;
	}

	async function setMenu(menu_: { [key: string]: MenuEntry[] }) {
		menu.value = menu_;
	}

	async function setFilteredRoutes(routes: string[]) {
		filteredRoutes.value = routes;
	}

	async function setMenuOrder(menuOrder_: string[]) {
		menuOrder.value = menuOrder_;
	}

	async function setMenuIcons(menuIcon_: string[]) {
		menuIcon.value = menuIcon_;
	}

	async function unsetUser() {
		loggedUser.value = new User();
		menuIcon.value = [];
		menuOrder.value = [];
		menu.value = {};
		filteredRoutes.value = [];
	}

	const getMenuScreenPaths = (screens: Array<MenuEntry>): Array<string> => {
		const paths = new Set(screens.map(screen => screen.path));
		// console.log('Paths:', JSON.stringify(Array.from(paths)));
		return Array.from(paths);
	};
	
	const transformMenuResult = (menu: Array<MenuEntry>): { [key: string]: MenuEntry[] } => {
		const fv: { [key: string]: MenuEntry[] } = {};
		let lastMenuName = "";
	
		menuOrder.value = [];
		menuIcon.value = [];

		for (let i = 0; i < menu.length; ++i) {
			if (menu[i].hidden_screen) {
				continue;
			}

			if (menu[i].menu_name !== lastMenuName) {
				lastMenuName = menu[i].menu_name;
				fv[lastMenuName] = [];
				menuOrder.value.push(lastMenuName);
				if (menu[i].menu_icon !== null)
					menuIcon.value.push(menu[i].menu_icon);
			}	
	
			fv[lastMenuName].push({
				menu_name: menu[i].menu_name,
				title: menu[i].title,
				path: menu[i].path,
				icon: menu[i].icon,
				component: menu[i].component,
				menu_icon: "",
				hidden_screen: false
			});
	
			// Don't add menu_icon in case of null, to make cookie smaller
			if (menu[i].menu_icon !== null) {
				fv[lastMenuName][fv[lastMenuName].length - 1].menu_icon = menu[i].menu_icon;
			}
		}
	
		return fv;
	};

	const setup_menu = (screen_list: Array<MenuEntry>): void => {
		// console.log('coocoo screen_list:', JSON.stringify(screen_list));
		const menu_paths = getMenuScreenPaths(screen_list);
		setMenu(transformMenuResult(screen_list));
		// console.log('menu_paths:', JSON.stringify(menu_paths));
		setFilteredRoutes(menu_paths);
	}

	return {
		isLoading,
		loggedUser,
		menu,
		menuOrder,
		iscollapsed,
		menuIcon,
		moneyConfig,
		globalPortfolioID,
		globalDebtorID,
		globalLoanAgreementID,
		viewOptions,
		filteredRoutes,
		remaining_time,
		remaining_time_received_at,
		setUser,
		setMenu,
		setMenuOrder,
		setMenuIcons,
		setup_menu,
		unsetUser,
		setFilteredRoutes,
	};
});
