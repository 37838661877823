<script lang="ts" setup>
import { useMainStore } from "../store/main";
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import ROB_Participating_Bank from "../classes/DB_Entities/ROB_Participating_Bank";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useRoute } from "vue-router";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
const mosapi = useMOSAPIStore();
const route = useRoute();
const skipApprove = ref<boolean>(false);
const isNewROB = ref<boolean>(false);
const mainStore = useMainStore();
const emits = defineEmits(["save", "close", "fail-save"]);
const { t } = useI18n({ useScope: "global" });
const representativesList = ref<any[]>([]);
const TrueFalseValues = ref<any>([]);
const props = defineProps<{
  rob_id: number;
  rob_participating_bank_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const {
  setup_decision_listener,
  updateDecision,
  isLoading,
  fullObj,
  table_name,
  data,
  loadData,
  save,
  close,
  showError,
  showWarning,
  TransformFullObj,
} = useGenericMethodsVariables();

watch(
  () => props.rob_participating_bank_id,
  async (newVal, oldVal) => {
    resetObjects();
    await loadRepresentatives();

    if (props.newEntity === true) {
      await getDefaultRep();
      return;
    }

    await loadData(props.rob_participating_bank_id);
    await fixPendingSelects();
  }
);

const resetObjects = () => {
  data.value = new ROB_Participating_Bank();
  fullObj.value = new ROB_Participating_Bank();
  data.value.register_of_bond_id = props.rob_id;
  fullObj.value.register_of_bond_id = props.rob_id;
  TransformFullObj();
};

const getDefaultRep = async () => {
  //let robAgent = await .get(`register_of_bonds?select=payment_representative&id=eq.${props.rob_id}`);
  const robAgent = await mosapi.generic_entity_get(Entities.RegisterOfBonds, {id: props.rob_id});
  //let result = await .get(`config_agent?agent=eq.${robAgent.data[0].payment_representative}`);
  const result = await mosapi.generic_entity_get(Entities.ConfigAgent, {agent: robAgent.data[0].payment_representative});
  if (result.data.length === 0) return;

  // console.log('representative is =>', JSON.stringify(result.data));
  fullObj.value.bank_name.originalValue = result.data[0].agent;
  data.value.bank_name = result.data[0].agent;
}
const loadSelects = async () => {
  TrueFalseValues.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];
};
onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  table_name.value = ROB_Participating_Bank.getTableNameStatic();

  await loadRepresentatives();

  if (props.newEntity === true) {
    resetObjects();
    await getDefaultRep();
    return;
  }

  mainStore.isLoading = true;
  await loadData(props.rob_participating_bank_id);
  await loadSelects();
  await fixPendingSelects();
  mainStore.isLoading = false;
});

const onPreSave = async () => {
  //   `register_of_bonds?id=eq.${props.rob_id}`
  const result = await mosapi.generic_entity_get(Entities.RegisterOfBonds, {id: props.rob_id});

  //   `loan_agreement?select=config_product(koinopraktiko)&id=eq.${result.data[0].loan_agreement_id}`
  const result2 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: result.data[0].loan_agreement_id});

  // Assume omologiako loan agreement has been selected (filtered at loan agreement select)

  if (result2.data[0].config_product.koinopraktiko === false) {
    //   `rob_participating_banks?select=id&register_of_bond_id=eq.${props.rob_id}`
    const result3 = await mosapi.generic_entity_get(Entities.ROBParticipatingBank, {register_of_bond_id: props.rob_id});
    
     //console.log('result3.data')
     //console.log(result3.data)
    // console.log('props.newEntity')
    // console.log(props.newEntity)
    // console.log('fullObj')
    // console.log(fullObj.value.participation_percent.originalValue)

    if (result3.data.length > 1 || (props.newEntity && result3.data.length > 0)) {
      showWarning(t("errors.aplo_omologiako_mia_trapeza"), 2000);
      return;
    }
    if (fullObj.value.participation_percent.originalValue !== '100') {
      showWarning(t("errors.aplo_omologiako_mia_trapeza_pososto"), 2000);
      return
    }
  }

  if (props.newEntity as boolean) {
    if (fullObj.value.bank_name.originalValue === "") {
      showWarning(t("errors.missing_bank_name"));
      return;
    }

    if (fullObj.value.participation_percent.originalValue === "") {
      showWarning(t("errors.missing_participation_percent"));
      return;
    }

    if (fullObj.value.contact_details.originalValue === "") {
      showWarning(t("errors.missing_contact_details"))
      return;
    }
  }

  // isNewROB.value = false;
  //   "view_mosregisterofbonds_view1?&id=eq." + props.rob_id
  // );

  // if (pendingresult.data[0].approve_status === 0) {
  //   isNewROB.value = true;
  // } else {
  //   isNewROB.value = false;
  // }

  // if (isNewROB) {
  //   skipApprove.value = true;
  // } else {
  //   skipApprove.value = false;
  // }
  //await save(skipApprove.value);
  await save();
  resetObjects();
};

const loadRepresentatives = async () => {
  representativesList.value = [];
  const result = await mosapi.generic_entity_get(Entities.ConfigAgent, {});

  if (result.error) {
    showError('Error retrieving representatives: ' + result.error)
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    representativesList.value.push({
      value: result.data[i].agent,
      label: result.data[i].agent,
    });
  }
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    if (field === "portfolio_account") {
      if (fullObj.value[field].pendingValue.value === "true") {
        fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = t("global.true");
      } else if (fullObj.value[field].pendingValue.value === "false") {
        fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = t("global.false");
      }

      if ((fullObj as any).value[field].originalValue === true) {
        fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = t("global.true");
      } else if ((fullObj as any).value[field].originalValue === false) {
        fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = t("global.false");
      }

    }

  }
};

</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <EntityField type="select" :object="fullObj.bank_name" label="Bank/Servicer Name" :options="representativesList"
        :is-disabled="props.view_only"  />

      <EntityField type="input" label="Participation Percent" :object="fullObj.participation_percent"
         :is-disabled="props.view_only" />

      <EntityField type="select" label="Portfolio account" :object="fullObj.portfolio_account" 
        :is-disabled="props.view_only" :options="TrueFalseValues"/>

      <EntityField type="textarea" label="Contact Details" :object="fullObj.contact_details" 
        :is-disabled="props.view_only" />

      <EntityField type="input" label="IBAN" :object="fullObj.iban" 
        :is-disabled="props.view_only" />

      <EntityField type="input" label="IBAN's Bank" :object="fullObj.iban_bank" 
        :is-disabled="props.view_only" />

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
