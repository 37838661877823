import ITableName from "../../interfaces/ITableName";

class ConfigInterest implements ITableName {
  id: number = -1;
  interest: string = "";
  currency: number = 0;
  automato_epitokio:boolean = false;

  static getTableNameStatic(): string {
    return "config_interest";
  }

  getTableName(): string {
    return ConfigInterest.getTableNameStatic();
  }
}

export default ConfigInterest;
