<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { Avatar, Bell, Edit, Delete, Document, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch, Lock } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import { useRouter } from "vue-router";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import AddEdit_ROB from "../components/AddEdit_ROB.vue";
import RegisterOfBonds from "../classes/DB_Entities/RegisterOfBond";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import { UIViews } from "@/enums/UIViews";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
import { ROBFilters } from "@/interfaces/ROBFilters";
import { ViewMosRegisterOfBondsView2 } from "@/interfaces/ViewMosRegisterOfBondsView2";
import ConfigProduct from "@/classes/DB_Entities/ConfigProduct";

const { showError, showSuccess, last_page } = useGenericMethodsVariables();
const { locale } = useI18n({ useScope: "global" });
const mainStore = useMainStore();
const router = useRouter();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const { onSortChange } = useSort();
const products = ref<{ value: number, label: string }[]>([]);
const mosapi = useMOSAPIStore();
const isNewEntity = ref(false);
const dataList = ref<ViewMosRegisterOfBondsView2[]>([]);
const dataCount = ref(0);
const addRegisterOfBond = ref(false);
const detailsVisible = ref(false);
const selectedRow = ref(0);
const deleteVisible = ref(false);
const DeleteSelectedRow = ref(0);
const DeleteDialogVisible = ref(false);
const activeLang = ref<string>("en");
const viewOnly = ref(false);
// const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const locked_rows = ref(false);
let timer = 0;

const filters = ref<ROBFilters>({
  ROB_fullname: "",
  ROB_VatNumber: "",
  ROB_PaymentRepresentative: "",
  ROB_LAProduct: "",
  pending: false,
  pendingNew: false,
  hideRejected: false
});

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') {
    await loadProducts();
    await loadPage(1);
  }
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') {
    await loadPage(1);
  }
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') {
    await loadPage(1);
  }
});

watch(locale, async () => {
  await loadProducts();
  await loadPage(1);
});

const handleClick = async (row: ViewMosRegisterOfBondsView2) => {
  const viewOnly = !isEditable(row);
  router.push({ name: "Register of Bonds Selection", state: { id: row.id, view_only: viewOnly } });
};

const handleClickToUnlock = async (row_id: number) => { // eslint-disable-line
  //await .patch("register_of_bonds?id=eq." + row_id, { lockedrow: 'false' });
  await loadPage(last_page.value);
}

const UnlockAllLockedRows = async () => {
  // await .patch("register_of_bonds?", { lockedrow: 'false' });
  // await loadPage(lastPage.value);
  // UnlockAllRowsVisible.value = false;
}

onMounted(async () => {

  //MiscSettings
  // const result = await .get("config_misc_settings?select=*");
  // locked_rows.value = result.data[0].locked_rows;

  // console.log('locked_rows ' + locked_rows.value);

  // const ResultLockedRow = await .get("register_of_bonds?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteRegisterOfBond(row_id);
};

const onAdd = async () => {
  showSuccess("Register of bond added Successfully", 1000);
  addRegisterOfBond.value = false;
  loadPage(last_page.value);
};

const DeleteRegisterOfBond = async (row_id: number) => {
 

  const id = row_id;

  //result = await .delete_data("register_of_bonds?id=eq." + id);
	const result = await mosapi.generic_entity_delete(Entities.RegisterOfBonds, id);

  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  await loadProducts();
  loadPage(1);
});

const loadPage = async (page: number) => {
  activeLang.value = locale.value as string;
  mainStore.isLoading = true;

  last_page.value = page;

  const result = await mosapi.get_view<ViewMosRegisterOfBondsView2>(UIViews.RegisterOfBonds, filters.value, last_page.value);
  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total;

  // if (result.error) {
  //   showError(result.error);

  //   return;
  // }

  // if (result.headers)
  //   dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  // dataList.value = result.data;

  // const ResultLockedRow = await .get("register_of_bonds?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

  mainStore.isLoading = false;
};

const onDelete = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Bond Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(last_page.value);
};

const onFailSave = async () => {
  showError("Register of Bond failed to update", 2000);
  detailsVisible.value = false;
};

const filterChanged = async () => {
	if (timer) {
		window.clearTimeout(timer);
		timer = 0;
	}

	timer = window.setTimeout(() => {
		loadPage(1);

		window.clearTimeout(timer);
		timer = 0;
	}, 500);
};

const localSortChange = async (sortObj: { column: unknown, prop: string, order: string }) => {
  onSortChange(sortObj);
  await loadPage(last_page.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const loadProducts = async () => {
  
  //result = await .get("config_product?orderFIX=product");
	const result = await mosapi.generic_entity_get<ConfigProduct[]>(Entities.ConfigProduct, {orderBy:'product'}, 1, 999);
  if (result.error) {
    showError(result.error);
    return;
  }

  products.value = [];
  for (let i = 0; i < result.data.length; i++) {
    if (locale.value == "en") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].english_trans,
      });
    } else if (locale.value == "el") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].greek_trans,
      });
    }
  }

  // console.log(products);
};

const handleClickAdd = () => {
  addRegisterOfBond.value = true;
  isNewEntity.value = true;
  selectedRow.value = -1;
};

const onCloseAddEdit = async () => {
  //await .patch("register_of_bonds?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(last_page.value);
  addRegisterOfBond.value = false;
  //selectedRow.value = -1;
};

const entityApprove = async (entity_id: number, accepted: boolean) => {
  console.log("entity id", entity_id, "approved", accepted);
  if (entity_id === undefined || entity_id === null) {
    return;
  }

  const result = await mosapi.generic_entity_patch(Entities.RegisterOfBonds, entity_id, { approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected });
  // const result = await .patch(`${RegisterOfBonds.getTableNameStatic()}?id=eq.${entity_id}`, { approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected });

  console.log("entity_approve", RegisterOfBonds.getTableNameStatic(), entity_id);

  if (result.error) {
    showError(`Failed to update Register of Bond: ${result.error}`, 2000);
  } else {
    showSuccess(`Register of Bond${!accepted ? " not " : " "}approved`, 1000);
  }

  await loadPage(last_page.value);
};

const isEditable = (row: ViewMosRegisterOfBondsView2) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("RegisterOfBondsView.register_of_bonds") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.ROB_fullname" class="client-input" :placeholder="$t('DebtorView.company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.ROB_VatNumber" class="client-input" :placeholder="$t('DebtorView.tax_registration_nbr')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.ROB_PaymentRepresentative" class="client-input"
            :placeholder="$t('RegisterOfBondsView.payment_representative')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-select clearable v-model="filters.ROB_LAProduct" class="m-2 client-input"
            :placeholder="$t('SummaryCheckView.product')" value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in products" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.pendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.hideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(last_page)"
            style="float: right; margin: 2.5px">
            <el-icon>
              <RefreshLeft />
            </el-icon>
          </el-button>
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
          <el-row v-if="locked_rows == true">
            <el-col>
              <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('RegisterOfBondsView.loan_agreement')" />
      <el-table-column fixed prop="fullname" :label="$t('DebtorView.company_name')" sortable />
      <el-table-column fixed prop="vat_number" :label="$t('DebtorView.tax_registration_nbr')" sortable />

      <el-table-column sortable fixed prop="payment_representative"
        :label="$t('RegisterOfBondsView.payment_representative')" />
      <el-table-column fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" sortable />
      <el-table-column v-if="activeLang === 'el'" fixed prop="greek_trans" :label="$t('LoanAgreementView.product')"
        sortable />
      <el-table-column v-if="activeLang === 'en'" fixed prop="english_trans" :label="$t('LoanAgreementView.product')"
        sortable />
      <el-table-column :label="$t('global.pending_approvals')" align="center">
        <el-table-column sortable fixed prop="pending_approvals" :label="$t('RegisterOfBondsView.register')" />
        <el-table-column sortable fixed prop="pending_approvals_banks" :label="$t('RegisterOfBondsView.banks')" />
        <el-table-column sortable fixed prop="pending_approvals_titles" :label="$t('RegisterOfBondsView.titles')" />
        <el-table-column sortable fixed prop="pending_approvals_bonds" :label="$t('RegisterOfBondsView.bonds')" />
      </el-table-column>

      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row)"
            :icon="isEditable(scope.row) ? Edit : View" :disabled="scope.row.lockedrow && locked_rows"
            style="background-color: #d6d6d6 !important;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
          <span v-if="
            mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
          ">
            <el-button class="btn-accept_square" @click="entityApprove(scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="entityApprove(scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="addRegisterOfBond" v-model="addRegisterOfBond" @update:model-value="onCloseAddEdit"
      title="Add Register Of Bonds">
      <AddEdit_ROB :rob_id="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit" @save="onAdd"
        @fail-save="onFailSave" :view_only="viewOnly" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Register of Bond Delete" width="50%">
      <span>Are you sure you want to delete this Bond ?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
