<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import UserType from "../enums/UserType";
import UpdateUserReply from "../classes/UpdateUserReply";
import Printer from "../classes/DB_Entities/Printer";
import { GroupResponseDto, PrivilegeResponseDto, UserRequestDto, UserResponseDto } from "@/models";
import { Entities } from "@/enums/Entities";

const props = defineProps<{
	userid: number;
	allowTypeChange: boolean;
}>();

const mosapi = useMOSAPIStore();
const emits = defineEmits(["save", "close", "fail-save"]);

const user = ref<UserResponseDto>();
const printersList = ref<{ value: string, label: string }[]>([]);
const privileges = ref<{ key: number, label: string }[]>([]);
const groups = ref<{ key: number, label: string }[]>([]);

const datapass = ref<{ passwordconfirm: string, errors: string[] }>({
	passwordconfirm: "",
	errors: [],
});

const formData = ref<UserRequestDto>({
	active: false,
	username: "",
	first_name: "",
	last_name: "",
	company: "",
	reset_password_on_login: false,
	password: "",
	email: "",
	user_type: 0,
	digitization: false,
	assigned_printer: "",
	privileges: [],
	groups: [],
	failed_login_attempts: 0,
});

const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();
const mainstore = useMainStore();

const {
	showError,
	showLocalizedError,
} = useGenericMethodsVariables();

watch(
	() => props.userid,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async (newVal, _oldVal) => {
		// console.log("Reloading userid -> " + newVal);
		loadUser(newVal as number);
	}
);

onMounted(async () => {
	mainStore.isLoading = true;
	try {
		await Promise.all([loadPrivileges(), loadGroups(), loadPrinters(), loadUser(props.userid as number)]);
	} catch (ex) {
		console.log(ex);
	}

	mainStore.isLoading = false;
});

const close = async () => {
	emits("close");
};

const checkPassword = (newPassOne: string, newPassTwo:string): string => {
	if (newPassOne !== newPassTwo) {
		return "Passwords must match";
	}

	if (newPassOne.length < 8) {
		return "Password must be at least 8 characters long.";
	}

	const hasUpperCase = /[A-Z]/.test(newPassOne) ? 1 : 0;
	const hasLowerCase = /[a-z]/.test(newPassOne) ? 1 : 0;
	const hasNumbers = /\d/.test(newPassOne) ? 1 : 0;
	const hasNonalphas = /\W/.test(newPassOne) ? 1 : 0;

	if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
		return "Password must contain at least one uppercase letter, one lowercase letter, one digit and one non-alphanumeric character (e.g. !, @, #, $)";
	}

	return ""; // All ok
};


const save = async () => {
	const { password, first_name, last_name, user_type } = formData.value;
	const { passwordconfirm } = datapass.value;
	const userId = user?.value?.id;
	if (!userId) return;

	datapass.value.errors = [];

	if (password !== '' || passwordconfirm !== '') {
		const pass_check = checkPassword(password, passwordconfirm);
		if (pass_check !== '') {
			datapass.value.errors.push(pass_check);
		}

		if (first_name == "")
			datapass.value.errors.push("First name can't be empty.");

		if (last_name == "")
			datapass.value.errors.push("Last name can't be empty.");
	}

	if (datapass.value.errors.length > 0) {
		for (const error of datapass.value.errors) {
			showError(error);
		}

		return;
	}

	if (user_type === UserType.Digitizer || user_type === UserType.Administrator) {
		formData.value.digitization = true;
	} else if (user_type === UserType.Agent) {
		formData.value.digitization = false;
	}

	const body = formData.value;
	let result = new UpdateUserReply();
	result = await mosapiStore.updateUser(userId, body);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	emits("save");
};

const loadUser = async (userId: number) => {
	if (userId === -1) return;

	const result = await mosapiStore.getUser(userId);

	if (result.error) {
		showLocalizedError(result.error);

		emits("close");
		return;
	}

	if (result.data) {
		user.value = result.data;
		datapass.value.passwordconfirm = "";
		formData.value = { ...result.data, password: "" } as UserRequestDto;
	}
};

const loadPrinters = async () => {
	// result = await .get("printers?orderFIX=printer_name.asc");
	const result = await mosapi.generic_entity_get(Entities.Printers, { orderBy: 'printer_name.asc' })
	console.log('coco2 ' + JSON.stringify(result));
	if (result.error) {
		showError(result.error);
		return;
	}

	printersList.value = [];
	result.data.forEach((element: Printer) => {
		printersList.value.push({
			value: element.printer_name,
			label: `${element.printer_name} (${element.printer_ip})`,
		});
	});
};

const loadPrivileges = async () => {
	const result = await mosapiStore.generic_entity_get(Entities.Privileges);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}
	if (result.data)
		privileges.value = result.data?.map((privilege: PrivilegeResponseDto) => ({
			key: privilege.id,
			label: privilege.name,
		}));
};

const loadGroups = async () => {
	const result = await mosapiStore.getGroups();

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	groups.value = result.data?.map((group: GroupResponseDto) => ({
		key: group.id,
		label: group.name
	})) ?? [];
};

const reset_attempts = async () => {
	mainStore.isLoading = true;
	const result = await mosapi.reset_attempts(props.userid as number);

	if (result.error) {
		mainStore.isLoading = false;
		showLocalizedError(result.error);
		return;
	}

	await loadUser(props.userid as number);
	mainStore.isLoading = false;
};

</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form :model="formData">

			<el-row :gutter="10" v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Active">
						<el-switch v-model="formData.active" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="Failed Login Attempts">
						<el-input v-model="formData.failed_login_attempts" autocomplete="off" :disabled="true" />
					</el-form-item>
				</el-col>
				<el-col :span="4" v-if="mainStore.loggedUser.user_type === UserType.Administrator && formData.failed_login_attempts > 0">
					<el-form-item label="	">
						<el-button type="primary" @click="reset_attempts">{{ $t('generic.reset_attempts') }}</el-button>
					</el-form-item>
				</el-col>

			</el-row>

			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item label="Username">
						<el-input v-model="formData.username" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Name">
						<el-input v-model="formData.first_name" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item label="Surname">
						<el-input v-model="formData.last_name" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Company">
						<el-input v-model="formData.company" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col>
					<el-form-item label="Change password on next login">
						<el-switch v-model="formData.reset_password_on_login" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="New Password">
						<el-input v-model="formData.password" type="password" placeholder="Please input password"
							autocomplete="off" show-password />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="24">
					<el-form-item label="Confirm Password">
						<el-input v-model="datapass.passwordconfirm" type="password"
							placeholder="Please confirm your password" autocomplete="off" show-password />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="E-mail">
						<el-input v-model="formData.email" autocomplete="off"
							:disabled="mainstore.loggedUser.user_type !== 0" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-form-item label="User Type">
				<el-select v-model="formData.user_type" placeholder="Please Select User Type"
					:disabled="!props.allowTypeChange">
					<el-option label="Administrator" :value="UserType.Administrator" />
					<el-option label="Supervisor" :value="UserType.Supervisor" />
					<el-option label="Agent" :value="UserType.Agent" />
					<el-option label="Digitizer" :value="UserType.Digitizer" />
				</el-select>
			</el-form-item>
			<el-row :gutter="10" v-if="formData.user_type === UserType.Supervisor">
				<el-col :span="12">
					<el-form-item label="Has digitization rights">
						<el-switch v-model="formData.digitization" :active-text="$t('components.switch.yes')"
							:inactive-text="$t('components.switch.no')" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10" v-if="formData.digitization || formData.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Barcode Printer">
						<el-select v-model="formData.assigned_printer" placeholder="Select printer">
							<el-option v-for="(printer, index) in printersList" :label="printer.label"
								:value="printer.value" :key="index" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10" v-if="mainStore.loggedUser.user_type === UserType.Administrator">
				<el-col :span="12">
					<el-form-item label="Privileges">
						<el-transfer v-model="formData.privileges" :data="privileges"
							:titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Groups">
						<el-transfer v-model="formData.groups" :data="groups" :titles="['Available', 'Assigned']" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
