import ITableName from "../../interfaces/ITableName";
import LoanAgreement from "./LoanAgreement";

class Guarantee implements ITableName {
  id: number = -1;
  loan_agreement_id: string = "";
  egguhsh_sunolou_sumbas: boolean = false;
  poso_egg: string = "";
  comments: string = "";
  loan_agreement: LoanAgreement = new LoanAgreement();
  debtor_cid: string = "";
  tautopoihsh: string = "";
  epipleonnomeggrafa: string = "";
  lockedrow: boolean = false;
  static getTableNameStatic(): string {
    return "guarantees";
  }

  getTableName(): string {
    return Guarantee.getTableNameStatic();
  }
}

export default Guarantee;
