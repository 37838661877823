<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { CopyDocument, Edit, Delete, Plus, RefreshLeft, Lock } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import CustomField from "../classes/DB_Entities/CustomField";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";

const props = defineProps<{
  customfieldid: number;
  newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const { t } = useI18n({ useScope: "global" });
const data = ref<CustomField>(new CustomField);
const newDropdownItem = ref<string>("");
const mosapi = useMOSAPIStore();

const {
  showError,
  close,
} = useGenericMethodsVariables();

onMounted(async () => {
  console.log("customfieldid", props.customfieldid, "newEntity", props.newEntity);

  if (props.newEntity === true) {
    return;
  }

  mainStore.isLoading = true;
  await loadData();
  mainStore.isLoading = false;
});

const onPreSave = async () => {
  //   console.log(JSON.stringify(fullObj.value));
  //VALIDATION CHECKS
  await save();
};
const loadData = async () => {
  mainStore.isLoading = true;

  //result = await .get("custom_fields?id=eq." + props.customfieldid);
  const result = await mosapi.generic_entity_get(Entities.CustomFields, { id: props.customfieldid });
  if (result.error) {
    showError(result.error);

    return;
  }

  data.value = result.data[0];
  mainStore.isLoading = false;
};

const save = async () => {
  if (props.newEntity) {
    if (data.value.name_el === '' || data.value.name_el === null || data.value.name_el === undefined || data.value.name_el === ' ') {
      data.value.name_el = data.value.name_en;
    }
    if (data.value.name_en === '' || data.value.name_en === null || data.value.name_en === undefined || data.value.name_en === ' ') {
      data.value.name_en = data.value.name_el;
    }
    const obj = JSON.parse(JSON.stringify(data.value));
    delete obj.id;
    //const result = await .post_data("custom_fields", obj);
    const result = await mosapi.generic_entity_post(Entities.CustomFields, obj);
  } else {
    if (data.value.name_el === '' || data.value.name_el === null || data.value.name_el === undefined || data.value.name_el === ' ') {
      data.value.name_el = data.value.name_en;
    }
    if (data.value.name_en === '' || data.value.name_en === null || data.value.name_en === undefined || data.value.name_en === ' ') {
      data.value.name_en = data.value.name_el;
    }
    /*const result = await .patch("custom_fields?id=eq." + props.customfieldid, {
      name_el: data.value.name_el,
      name_en: data.value.name_en,
      type: data.value.type,
      dropdown_list_values: data.value.dropdown_list_values
    });*/
    const result = await mosapi.generic_entity_patch(Entities.CustomFields, props.customfieldid, {
      name_el: data.value.name_el,
      name_en: data.value.name_en,
      type: data.value.type,
      dropdown_list_values: data.value.dropdown_list_values
}) 
  }
  emits("save");
}
const CustomFieldTypeList = [
  {
    value: "text",
    label: t("CustomField.text"),
  },
  {
    value: "date",
    label: t("CustomField.date"),
  },
  {
    value: "number",
    label: t("CustomField.number"),
  },
  {
    value: "list",
    label: t("CustomField.list"),
  },
  {
    value: "boolean",
    label: t("CustomField.boolean"),
  },
  {
    value: "status_list",
    label: t("CustomField.status_list"),
  },
  {
    value: "money",
    label: t("CustomField.money"),
  }
];

const addToDropdownList = () => {
  if (data.value.dropdown_list_values.includes(newDropdownItem.value)) {
    return;
  }

  data.value.dropdown_list_values.push(newDropdownItem.value);
  newDropdownItem.value = "";
}

const onTypeChanged = (value: string) => {
  data.value.dropdown_list_values = [];

  if (value === 'status_list') {
    data.value.dropdown_list_values.push("Ολοκληρωμένο")
  }
}

const removeDropdownItem = (item: string) => {
  data.value.dropdown_list_values = data.value.dropdown_list_values.filter((val) => val !== item);
}
</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('CustomField.name_el')">
            <el-input v-model="data.name_el" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('CustomField.name_en')">
            <el-input v-model="data.name_en" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item style="margin-top: 0px">
            <el-select v-model="data.type" :placeholder="$t('CustomField.type')" @change="onTypeChanged">
              <el-option v-for="custom_field_type in CustomFieldTypeList" :key="custom_field_type.value"
                :label="custom_field_type.label" :value="custom_field_type.value" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="data.type === 'list' || data.type === 'status_list'">
        <el-col :span="6">

          <el-input class="simpleInput" v-model="newDropdownItem"></el-input>
        </el-col>
        <el-col :span="2"><el-button @click="addToDropdownList">+</el-button></el-col>
        <el-col :span="16">
          <el-card style="max-width: 480px">
            <template #header>
              <div class="card-header">
                <span>{{ t("CustomField." + data.type) }}</span>
              </div>
            </template>
            <p v-for="o in data.dropdown_list_values" :key="o" class="text item">{{ o }}<el-button
                v-if="o !== 'Ολοκληρωμένο'" link size="large" :icon="Delete" @click="removeDropdownItem(o)" /></p>
          </el-card></el-col>

        <p></p><br><br><br><br><br>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>../classes/DB_Entities/CustomField../classes/DB_Entities/CustomField
