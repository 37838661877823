<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import type { TabsPaneContext, FormRules, FormInstance } from "element-plus";
import ConfigPrinter from "../classes/DB_Entities/ConfigPrinter";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { useMainStore } from "../store/main";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();

const props = defineProps({
  printerid: Number,
});

const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new ConfigPrinter());
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const ruleFormRef = ref<FormInstance>();
const isLoading = ref(false);
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.printerid,
  async (newVal, oldVal) => {
    await loadPrinters(props.printerid as number);
  }
);

onMounted(async () => {
  await loadPrinters(props.printerid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const printer_id = data.value.id as number;
  delete data.value.id;
  const result = await mosapi.generic_entity_patch(Entities.Printers, printer_id, data.value);
  if (result.error) {
    showError(result.error);
    return;
  }
  emits("save");
};

const loadPrinters = async (printer_id: number) => {
  mainStore.isLoading = true;
  if (printer_id === -1) return;
  console.log('aaaaaaaaa');
  console.log(printer_id);
  const result = await mosapi.generic_entity_get(Entities.Printers, { id: printer_id });
  if (result.error) {
    showError(result.error);
    return;
  }
  data.value = result.data[0];
  mainStore.isLoading = false;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Printer name">
            <el-input v-model="data.printer_name" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Printer ip">
            <el-input v-model="data.printer_ip" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Port">
            <el-input v-model="data.port" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
