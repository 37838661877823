<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "@/store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useDataHelpers } from "../composables/dataHelpers";
import { Entities } from "../enums/Entities";

const props = defineProps<{
    table: Entities;
    entity_name: string;
    row_id: number;
    title: string;
    width: string;
    description?: string;
}>();

const emits = defineEmits(["delete", "close"]);

const mainStore = useMainStore();

const { deleteEntityFromTableWithID } = useDataHelpers();

const show = ref(true);

const {
    showError,
} = useGenericMethodsVariables();

onMounted(async () => {
    console.log('DeleteEntityDialog -', props.row_id);
});

const onAcceptDelete = async () => {
    mainStore.isLoading = true;
    const res = await deleteEntityFromTableWithID(props.table, props.row_id);
    console.log('res', res);
    mainStore.isLoading = false;
    if (!res) {
        showError('Failed to delete ' + props.entity_name + ' entry');
        return;
    }

    emits('delete');
}

</script>

<template>
    <el-dialog v-model="show" :title="props.title" :width="props.width" @update:model-value="emits('close')">
        <span>Are you sure you want to delete this {{ props.entity_name }} {{ props.description ? '(' + props.description + ')' : '' }} entry?</span>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="emits('close')">{{ $t('generic.cancel') }}</el-button>
                <el-button type="primary" @click="onAcceptDelete()">
                    {{ $t('generic.delete') }}
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<style scoped></style>
