<script lang="ts" setup>
import { Menu as IconMenu, Minus, More, Document, Setting, MessageBox, Histogram } from "@element-plus/icons-vue";
import MenuEntry from "../classes/DB_Entities/MenuEntry";
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import UserType from "../enums/UserType";
import { UIViews } from "@/enums/UIViews";

interface Props {
  menu: { [key: string]: MenuEntry[] };
  menuOrder: string[];
  menuIcons: string[];
}

let retrievingPending = false;
const pendingData = ref<any[]>([]);
const pendingNewData = ref<any[]>([]);
const props = defineProps<Props>();
const router = useRouter();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const refreshPendingTimer = ref<any>(0);

// const handleOpen = (key: string, keyPath: string[]) => {
//   // console.log(key, keyPath);
// };

// const handleClose = (key: string, keyPath: string[]) => {
//   // console.log(key, keyPath);
// };

const logout = async () => {
	mainStore.unsetUser();
	mosapi.unsetToken();

	await mosapi.logout();

	router.push("/login");
}

const getPendingChanges = async () => {

  if (mainStore.loggedUser.user_type === UserType.Agent) return; // Menu doesn't need pending changes for agents

  retrievingPending = true;

  // let queryChanges = `view_pending_changes`;
  // let queryNew = `view_pending_new_entities`;

  const result = await mosapi.get_view(UIViews.PendingChanges, {});// (await .get(queryChanges);

  // 401 -> Session expired
  if (result.status_code === 401) {
    mainStore.unsetUser();
    await logout();
    return;
  }

  const result_new = await mosapi.get_view(UIViews.PendingNewEntities, {}); // .get(queryNew);

    // 401 -> Session expired
  if (result_new.status_code === 401) {
    mainStore.unsetUser();
    await logout();
    return;
  }

  retrievingPending = false;

  if (!result.data) return;
  if (!result_new.data) return;

  pendingData.value = result.data as any[];
  pendingNewData.value = result_new.data as any[];
};

onUnmounted(async () => {
  retrievingPending = false;
  clearInterval(refreshPendingTimer.value);
});

onMounted(async () => {
  getPendingChanges();

  refreshPendingTimer.value = setInterval(async () => {
    if (retrievingPending) return;

    await getPendingChanges();
  }, 60000);
});

const hasEntityChanges = (entity: string): boolean => {
  for (const item of pendingData.value) {
    if (item.entity == entity) {
      return true;
    }
  }

  for (const item of pendingNewData.value) {
    if (item.entity == entity) {
      return true;
    }
  }
  return false;
};
</script>

<template>
  <!-- <el-button class="togglebtn" :icon="IconMenu" @click="togglemenu" /> -->

  <el-menu background-color="transparent" :router="true" :default-active="$route.path" :default-openeds="['1']"
    :collapse="mainStore.iscollapsed" :collapse-transition="true">
    <el-sub-menu :index="index + 1 + ''" v-for="(menuEntry, index) in props.menuOrder" :key="menuEntry">
      <template #title>
        <el-icon v-if="props.menuIcons[index] == 'icon-menu'">
          <icon-menu />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'document'">
          <Document />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'setting'">
          <Setting />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'messagebox'">
          <MessageBox />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == 'histogram'">
          <Histogram />
        </el-icon>
        <el-icon v-if="props.menuIcons[index] == null || props.menuIcons[index] == ''">
          <More />
        </el-icon>
        <span>{{ $t("menu." + menuEntry) }}</span>
      </template>
      <el-menu-item :class="{ 'has-pending2': hasEntityChanges(menuItem.title) }" :index="menuItem.path"
        v-for="menuItem of menu[menuEntry]" :key="menuItem.path + '_' + menuItem.title">
        <el-icon>
          <Minus />
        </el-icon>{{ $t("menu." + menuItem.title) }}
        <span class="has-pending" v-if="hasEntityChanges(menuItem.title)">(*)</span>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<style scoped></style>
