import ITableName from "../../interfaces/ITableName";

class liksiario implements ITableName {
  id?: number = -1;
  loan_agreement_id: number = 0;
  ektokismos_id: number = 0;
  total_remaining_balance: number = 0;
  our_participation: number = 0;
  repayment_date: string = "";
  interest_calc_start_date: string = "";
  interest_calc_end_date: string = "";
  interest_rate: number = 0;
  margin: number = 0;
  interest_amount: number = 0;
  withholdind_tax: boolean = false;
  interest_amount_due: number = 0;
  comments: string = "";
  tax_certificate: boolean = false;
  days_overdue: number = 0;
  cert_sent: string = "";
  alarm_date: string = "";
  due_date: string = "";
  lockedrow: boolean = false;
  
  static getTableNameStatic(): string {
    return "la_liksiario";
  }

  getTableName(): string {
    return liksiario.getTableNameStatic();
  }
}

export default liksiario;
