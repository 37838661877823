<script lang="ts" setup>
import MainMenu from "../components/MainMenu.vue";
import MainHeader from "../components/MainHeader.vue";
import { useMainStore } from "../store/main";

const mainStore = useMainStore();
</script>

<template>
  <div class="common-layout">
    <el-container class="main-container">
      <el-container>
        <el-aside style="margin-left: 0px" class="side-menu" :class="{
          iscollapsed: mainStore.iscollapsed,
          isnotcollapsed: !mainStore.iscollapsed,
        }">
          <div>
            <div class="logo-wrapper mos_logo">
              <router-link to="/dashboard"><img src="../assets/LOGO_MIDDLE-OFFICE-RGB.png" /></router-link>
            </div>
            <MainMenu :menu="mainStore.menu" :menuOrder="mainStore.menuOrder" :menuIcons="mainStore.menuIcon" />
          </div>
        </el-aside>
        <el-main class="main-body">
          <div>
            <el-header class="top-header">
              <MainHeader />
            </el-header>
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style scoped></style>
