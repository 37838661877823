<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { Avatar, Bell, Edit, Delete, House, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch, Lock } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import { useRouter } from "vue-router";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useViewOptions } from "../composables/viewOptions";
import Auction from "../classes/DB_Entities/Auction";
import { useMOSAPIStore } from "../store/mos_api";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";

const { entityApprove, showError, showLocalizedError, showSuccess } = useGenericMethodsVariables();

const { onSortChange, sortColumn, sortOrder } = useViewOptions(
	Auction.getTableNameStatic(),
	{
		auction_id: "",
		debtor_cid: "",
		property_number: "",
		vat_number: "",
		pending: false,
		pending_new: false,
		hide_rejected: true,
	}
);
const filters = ref<any>({
	pending: false,
	pendingNew: false,
	hideRejected: true,
	auction_id: "",
	cid: "",
	property_number: "",
	vat: "",
});
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const router = useRouter();
const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const locked_rows = ref(false);
const DeleteDialogVisible = ref(false);
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const DeleteSelectedRow = ref(0);
const dataList = ref([]);
const dataCount = ref(0);
const lastPage = ref(0);

const deleteVisible = ref(false);
let timer = 0;

watch(globalPortfolioID, async () => {
	if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
		await loadPage(1);
});

watch(globalDebtorID, async () => {
	if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
		await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
	if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
		await loadPage(1);
});

const handleClick = (row: any) => {
	const view_only = (row.debtor_approve_status !== 1 || row.real_estate_approve_status !== 1);
	router.push(`/auctions/${row.id}/${view_only}`);
};

const handleClickToUnlock = async (row_id: number) => {
	//let result = await .patch("auctions?id=eq." + row_id, { lockedrow: 'false' });
	await loadPage(lastPage.value);
}

const UnlockAllLockedRows = async () => {
	// let result = await .patch("auctions?", { lockedrow: 'false' });
	// await loadPage(lastPage.value);
	// UnlockAllRowsVisible.value = false;
}

onMounted(async () => {

	//MiscSettings
	// let result = await .get("config_misc_settings?select=*");
	// locked_rows.value = result.data[0].locked_rows;

	// console.log('locked_rows ' + locked_rows);

	// const ResultLockedRow = await .get("auctions?select=*&lockedrow=eq.true");
	// if (ResultLockedRow.data.length > 0) {
	//   UnlockAllRowsVisible.value = true;
	// } else {
	//   UnlockAllRowsVisible.value = false;
	// }
});

onMounted(async () => {
	loadPage(1);
});

const loadPage = async (page: number) => {
	mainStore.isLoading = true;

	lastPage.value = page;

	// if (sortOrder.value !== "") {
	//   filterUrl += `&orderFIX=${sortColumn.value}.${sortOrder.value}`;
	// }
	if (sortColumn.value==='') sortColumn.value = 'arithmos_akinitou_diax';
	const result = await mosapi.get_view(UIViews.AuctionsView, {...filters.value, orderBy: `${sortColumn.value}.${sortOrder.value}`}, lastPage.value, 10);

	if (result.error) {
		showError(result.error);
		return;
	}

	dataList.value = result.data;
	dataCount.value = result.total;

	if (result.error) {
		showLocalizedError(result.error);
	}

	// let ResultLockedRow = await .get("auctions?select=*&lockedrow=eq.true");
	// if (ResultLockedRow.data.length > 0) {
	//   UnlockAllRowsVisible.value = true;
	// } else {
	//   UnlockAllRowsVisible.value = false;
	// }

	mainStore.isLoading = false;
};

const filterChanged = async () => {
	//console.log(JSON.stringify(filters.value));
	if (timer) {
		window.clearTimeout(timer);
		timer = 0;
	}

	timer = window.setTimeout(() => {
		loadPage(1);

		window.clearTimeout(timer);
		timer = 0;
	}, 500);
};

const deleteHandleClick = (row_id: number) => {
	DeleteSelectedRow.value = row_id;
	deleteVisible.value = true;
	DeleteAuction(row_id);
};

const DeleteAuction = async (row_id: number) => {
	const auction_id = row_id;
	const result = await mosapi.generic_entity_delete(Entities.Auctions, auction_id);
	if (result.error) {
		showError(result.error);
		DeleteDialogVisible.value = false;
		return;
	}
	onDelete();
};

const onDelete = async () => {
	showSuccess("Auction Deleted Successfully");
	DeleteDialogVisible.value = false;
	loadPage(lastPage.value);
};

const localSortChange = async (sortObj: any) => {
	onSortChange(sortObj);
	await loadPage(lastPage.value);
};
const pendingFilterChange = async () => {
	await loadPage(1);
};

const approve = async (entity_id: number, state: boolean) => {
	const fv = await entityApprove(Entities.Auctions, entity_id, state);

	if (fv) {
		showSuccess(`Auction updated`);
	} else {
		showError(`Auction update failed`);
	}

	await loadPage(lastPage.value);
};

const handleClickAdd = () => {
	router.push("/auctions/new");
};

const escapePressed = () => {
	//console.log("escape");
};

const isEditable = (row: any) => {
	return (row.debtor_approve_status === 1 && row.real_estate_approve_status === 1);
}
</script>

<template>
	<div class="content-wrapper" v-loading="mainStore.isLoading" @keydown.esc="escapePressed">
		<el-row :gutter="10">
			<el-col :span="12">
				<h3 class="heading-page">{{ $t("AuctionsView.auctions") }} ({{ dataCount }})</h3>
			</el-col>
		</el-row>

		<div style="margin: 30px">
			<el-row :gutter="20">
				<el-col :span="6">
					<el-input v-model="filters.cid" class="client-input" :placeholder="$t('AuctionsView.debtor_cid')"
						@input="filterChanged" />
				</el-col>
				<el-col :span="6">
					<el-input v-model="filters.property_number" class="client-input"
						:placeholder="$t('AuctionsView.real_estate_nbr')" @input="filterChanged" />
				</el-col>
				<el-col :span="6">
					<el-input v-model="filters.vat" class="client-input"
						:placeholder="$t('AuctionsView.tax_registration_nbr')" @input="filterChanged" />
				</el-col>
			</el-row>
		</div>

		<div>
			<el-row :gutter="10" style="margin: 10px; margin-top: 15px">
				<el-col :span="12" class="realestate-add-new-btn">
					<el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')"
						inactive-text="" @change="pendingFilterChange()" style="float: left" />
					<el-switch v-model="filters.pending_new" size="small"
						:active-text="$t('global.pending_changes_new_entities')" inactive-text=""
						@change="pendingFilterChange()" style="float: left" />
					<el-switch v-model="filters.hide_rejected" size="small"
						:active-text="$t('global.hide_rejected_entities')" inactive-text=""
						@change="pendingFilterChange()" style="float: left" />
				</el-col>
				<el-col :span="12" class="realestate-add-new-btn">
					<el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
						style="float: right; margin: 2.5px" :icon="RefreshLeft" />
					<el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
						@click="handleClickAdd()" :icon="Plus" />
					<!-- <el-row v-if="locked_rows == true">
            <el-col>
              <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip>
            </el-col>
          </el-row> -->
				</el-col>
			</el-row>
		</div>

		<el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
			@sort-change="localSortChange" size="small">
			<el-table-column sortable fixed prop="cid" :label="$t('AuctionsView.debtor_cid')" />
			<el-table-column sortable fixed prop="arithmos_akinitou_diax" :label="$t('AuctionsView.real_estate_nbr')" />
			<el-table-column sortable fixed prop="vat_number" :label="$t('AuctionsView.tax_registration_nbr')" />
			<el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
			<el-table-column fixed="right" :label="$t('global.operations')" width="280">
				<template #default="scope">
					<el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row)"
						:icon="isEditable(scope.row) ? Edit : View" :disabled="scope.row.lockedrow && locked_rows"
						style="background-color: #d6d6d6 !important;" />
					<el-tooltip
						v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
						effect="dark" content="Pending Supervisor approval" placement="top">
						<el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
					</el-tooltip>
					<el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
						size="small" class="btn-delete"
						@click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
					<span v-if="mainStore.loggedUser.user_type < UserType.Agent && scope.row.approve_status === 0">
						<el-button class="btn-accept_square" @click="approve(scope.row.id, true)" :icon="CircleCheck" />
						<el-button class="btn-reject_square" @click="approve(scope.row.id, false)"
							:icon="CircleClose" />
					</span>
					<el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155"
						trigger="hover" :content="$t('global.pending_changes')">
						<template #reference>
							<el-button :icon="Bell" circle size="small"
								style="background-color: coral; border: 0px; color: white" />
						</template>
					</el-popover>
					<el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
						trigger="hover" :content="$t('global.debtor_not_approved')">
						<template #reference>
							<el-button :icon="Avatar" circle size="small"
								style="background-color: blueviolet; border: 0px; color: white" />
						</template>
					</el-popover>
					<el-popover v-if="scope.row.real_estate_approve_status !== 1" placement="top-start" title=""
						:width="265" trigger="hover" :content="$t('global.real_estate_not_approved')">
						<template #reference>
							<el-button :icon="House" circle size="small"
								style="background-color: coral; border: 0px; color: white" />
						</template>
					</el-popover>
					<!-- <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip> -->
				</template>
			</el-table-column>
		</el-table>

		<div>
			<el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
				@current-change="loadPage" />
		</div>

		<el-dialog v-model="DeleteDialogVisible" title="Auction Delete" width="50%">
			<span>Are you sure you want to delete this Property?</span>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
					<el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<style scoped></style>
