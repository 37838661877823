<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { Printer } from "@element-plus/icons-vue";
import { useMainStore } from "@/store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import DigitizedFolder from "../classes/DB_Entities/DigitizedFolder";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { ElRow } from "element-plus";
import { Entities } from "@/enums/Entities";
const { showError, printBarcode } = useGenericMethodsVariables();

const props = defineProps<{
    row_id: number; // digitized_folder
    digitized_la_id: number;
    portfolio_code: string;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const data = ref(new DigitizedFolder());

onMounted(async () => {
    mainStore.isLoading = true;

    console.log('loading digitized_folder id', props.row_id);

    if (props.row_id !== -1) {
        try {
            await loadData(props.row_id);
        } catch (ex: any) {
            showError(ex.message);
        }
    } else {
        data.value.d_debtor_id = props.digitized_la_id;
    }

    mainStore.isLoading = false;
});

const loadData = async (entity_id: number) => {
    if (entity_id === -1) return;

    //const result = await .get(`digitized_folder?id=eq.${entity_id}`);
    const result = await mosapi.generic_entity_get(Entities.DigitizedFolders , {id: entity_id});
    console.log('res12333 ' + JSON.stringify(result))

    if (result.error) {
        showError(result.error);
        return;
    }

    if (result.data.length === 0) {
        showError(`Digitized Folder ${entity_id} not found`);
        emits('close');
        return;
    }

    data.value = result.data[0];
}

const close = () => {
    emits("close");
};

const save = async () => {
    let res: any = null;

    if (data.value.folder_barcode.length !== 24) {
        showError('Folder barcode must be 24 characters exactly');
        return;
    }

    if (data.value.folder_barcode.substring(20, 22) !== '00') {
        showError('Folder barcode character 21 and 22 must be 00 => Folder type');
        return;
    }


    if (props.row_id === -1) {
        const obj = JSON.parse(JSON.stringify(data.value));
        delete obj.id;
        //res = await .post_data(`digitized_folder`, obj);
        res = await mosapi.generic_entity_post(Entities.DigitizedFolders, obj)
    } else {
        //res = await .patch(`digitized_folder?id=eq.${data.value.id}`, data.value);
        res = await mosapi.generic_entity_patch(Entities.DigitizedFolders, data.value.id, data.value);
        console.log('123-here')
    }

    if (res.error) {
        console.log(res.error);
        if (res.error.message == 'Request failed with status code 409')
            showError('Folder barcode already exists');
        else
            showError(res.error.message);

        return;
    }

    emits("save");
}

</script>

<template>
    <div>

        <el-form>
            <el-form-item :label="$t('DDebtorDetailsView.folder_barcode')">
                <el-input v-model="data.folder_barcode" autocomplete="off" />
            </el-form-item>

            <el-form-item :label="$t('DDebtorDetailsView.container_barcode')">
                <el-input v-model="data.container_barcode" autocomplete="off" />
                <el-icon v-if="data.container_barcode !== ''" style="padding-left: 10px;" color="blue" :size="16"
                    @click="printBarcode(data.container_barcode)">
                    <Printer />
                </el-icon>

            </el-form-item>
        </el-form>

        <el-row :gutter="10">
            <el-col :span="24">
                <el-form-item class="btn-wrapper-components">
                    <el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
                    <el-button @click="close">{{ $t('generic.close') }}</el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped></style>
