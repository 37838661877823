<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import ConfigLoanAccountProduct from "../classes/DB_Entities/ConfigLoanAccountProduct";
import { useMOSAPIStore } from "../store/mos_api";
import { useMainStore } from "../store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();

const props = defineProps({
  larowid: Number,
});
const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new ConfigLoanAccountProduct());
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const ruleFormRef = ref<FormInstance>();
const isLoading = ref(false);

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.larowid,
  async (newVal, oldVal) => {
    await loadLoanAccountProducts(props.larowid as number);
  }
);

onMounted(async () => {
  await loadLoanAccountProducts(props.larowid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const LoanAccountProduct_id = data.value.id as number;
  delete data.value.id;
  const result = await mosapi.generic_entity_patch(Entities.ConfigLoanAccountProduct, LoanAccountProduct_id, data.value);
  if (result.error) {
    showError(result.error);
    return;
  }
  emits("save");
};

const loadLoanAccountProducts = async (LoanAccountProduct_id: number) => {
  mainStore.isLoading = true;
  if (LoanAccountProduct_id === -1) return;

  const result = await mosapi.generic_entity_get(Entities.ConfigLoanAccountProduct, { id: LoanAccountProduct_id });

  if (result.error) {
    showError(result.error);
    return;
  }

  data.value = result.data[0];
  mainStore.isLoading = false;
};
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.loan_account_product_details')">
            <el-input v-model="data.product" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_greek_trans')">
            <el-input v-model="data.greek_trans" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_english_trans')">
            <el-input v-model="data.english_trans" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('PlatformSettingsView.calculateArgies')">
            <el-switch v-model="data.calculate_argies" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('PlatformSettingsView.omologiako')">
            <el-switch v-model="data.omologiako" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('PlatformSettingsView.allhloxreo')">
            <el-switch v-model="data.allhloxreo" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
