<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Edit, Plus, Delete } from "@element-plus/icons-vue";
import AddEdit_Group from "../components/AddEdit_Group.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "@/store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { useI18n } from "vue-i18n";
import { GroupResponseDto } from "@/models";

const { showError } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });

const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();

// State variables
const dataList = ref<GroupResponseDto[]>([]);
const dataCount = ref(0);
const currentPage = ref(1);
const detailsVisible = ref(false);
const selectedGroupId = ref(0);

// Dialog controls
const openGroupDialog = (groupId: number) => {
	selectedGroupId.value = groupId;
	detailsVisible.value = true;
};

// Delete group
const deleteGroup = async (groupId: number) => {
	try {
		const result = await mosapiStore.deleteGroup(groupId);
		if (result.error) throw new Error(t(`errors.${result.error}`));
		ElMessage.success("Group deleted successfully");
		await loadPage(currentPage.value);
	} catch (error) {
		handleError(error, "Failed to delete group");
	}
};

// Load groups data
const loadPage = async (page: number) => {
	try {
		mainStore.isLoading = true;
		currentPage.value = page;

		const offsetPage = page - 1; // Backend pagination adjustment
		const result = await mosapiStore.getGroupsPaginated(true, offsetPage);

		if (result.error) throw new Error(t(`errors.${result.error}`));

		dataList.value = result.data?.groups || [];
		dataCount.value = result.data?.total || 0;
	} catch (error) {
		handleError(error, "Failed to load data");
	} finally {
		mainStore.isLoading = false;
	}
};

// Notification helpers
const showSuccessMessage = (message: string) => {
	ElMessage.success({ showClose: true, message, duration: 1000 });
};

// Handlers for dialog save and fail-save events
const onSave = async () => {
	showSuccessMessage("Group updated successfully");
	detailsVisible.value = false;
	await loadPage(currentPage.value);
};

const onFailSave = () => {
	detailsVisible.value = false;
};

// Error handling
const handleError = (error: unknown, fallbackMessage: string) => {
	if (error instanceof Error) {
		showError(error.message);
	} else {
		showError(fallbackMessage);
	}
};

// Initial data load
onMounted(() => loadPage(1));
</script>

<template>
	<div class="content-wrapper">
		<!-- Header -->
		<div>
			<h3 class="heading-page">Groups</h3>
		</div>

		<!-- Add Group Button -->
		<div style="margin: 5px">
			<el-row justify="end">
				<el-col :span="1" class="user-add-new-btn">
					<el-button link type="primary" size="small" class="btn-add" @click="openGroupDialog(0)">
						<el-icon>
							<Plus />
						</el-icon>
					</el-button>
				</el-col>
			</el-row>
		</div>

		<!-- Groups Table -->
		<el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed">
			<el-table-column fixed prop="name" label="Name" />
			<el-table-column fixed="right" label="Operations">
				<template #default="scope">
					<el-button link type="primary" size="small" class="btn-detail"
						@click="openGroupDialog(scope.row.id)" :icon="Edit" />
					<el-button link type="primary" size="small" class="btn-delete" :icon="Delete"
						@click="deleteGroup(scope.row.id)" />
				</template>
			</el-table-column>
		</el-table>

		<!-- Pagination -->
		<el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
			@current-change="loadPage" />

		<!-- Group Dialog -->
		<el-dialog v-if="detailsVisible" v-model="detailsVisible" title="Group Details" width="80%">
			<AddEdit_Group :groupId="selectedGroupId" @save="onSave" @close="detailsVisible = false"
				@fail-save="onFailSave" />
		</el-dialog>
	</div>
</template>

<style scoped></style>
