<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { ElMessage } from "element-plus";
import Portfolio from "../classes/DB_Entities/Portfolio";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import RegisterOfBond from "../classes/DB_Entities/RegisterOfBond";
import SummaryCheck from "../classes/DB_Entities/SummaryCheck";
import RestOfCollaterals from "../classes/DB_Entities/RestOfCollaterals";
import Decision from "../classes/Decision";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import ChangesetHelper from "changeset-helper";
import EntityField from "../components/EntityField.vue";
import type { TabsPaneContext } from "element-plus";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
import { useRouter } from "vue-router";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { ViewSummaryCheckXtraView2 } from "@/interfaces/ViewSummaryCheckXtraView2";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import Debtor from "@/classes/DB_Entities/Debtor";
import Guarantee from "@/classes/DB_Entities/Guarantee";
import { ViewMosBondsView2 } from "@/interfaces/ViewMosBondsView2";
import LoanAccount from "@/classes/DB_Entities/LoanAccount";
import ConfigProduct from "@/classes/DB_Entities/ConfigProduct";
import ConfigInterest from "@/classes/DB_Entities/ConfigInterest";
import ConfigCurrency from "@/classes/DB_Entities/ConfigCurrency";
import APIReply from "@/classes/APIReply";
import { Console } from "console";

const {
	cleanObject,
	fullObj,
	table_name,
	loadData,
	// save,
	setup_decision_listener,
	showError,
 } = useGenericMethodsVariables();

const router = useRouter();
const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const SummaryCheckLoanAgreementID = ref<string>("");
const { globalLoanAgreementID } = storeToRefs(mainStore);

const active_loan_account_id: number | undefined = undefined;
const selectedLoanAgreementID = ref<string>("");

const Epitages = ref<string>("");
const Metoxes = ref<string>("");
const EkxorisiApaitiseon = ref<string>("NO");
const isDenounced = ref<string>('');
const has_ekdosi_diatagis = ref<string>('');
const hmer_ekd_datag_pliromis = ref<string[]>([]);
const product = ref<any>([]);
const currency = ref<any>([]);
const interest = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const StoreSummaryCheckDebtor = ref<any>([]);
const StoreSummaryCheckLoanAgreement = ref(new LoanAgreement());
const StoreSummaryCheckLoanAccount = ref<any>([]);
const StoreSummaryCheckPortfolio = ref(new Portfolio());
const StoreSummaryCheckRegisterOfBond = ref(new RegisterOfBond());
const StoreSummaryCheckRegisterOfBondPartBanks = ref<any>([]);
const StoreSummaryRobBonds = ref<any>([]);
const StoreSummaryCheckGreekStateGuarantees = ref<any>([]);
const StoreSummaryCheckDebtorsGuarantees = ref<any>([]);
const StoreSummaryCheckRealEstateCollaterals = ref<any>([]);
const StoreSummaryCheckDebtorGuaranteesIndividual = ref<any>([]); //ref<any>([]);
const StoreSummaryCheckDebtorGuaranteesLegalEntity = ref<any>([]); //ref<any>([]);
const StoreSummaryCheckRestOfCollaterals = ref(new RestOfCollaterals());
const StoreRealEstateSummary = ref<any>([]);
const SaveSummaryCheck = ref(new SummaryCheck());

// const fullObj = ref<any>({}); //2 check μηπως θελει new Summary Check?
const originalObj = ref<any>({}); //2 check μηπως θελει new Summary Check?
const product_type = ref<any>();
const listed_shares = ref<any>([]);
const unlisted_shares = ref<any>([]);
const deposits = ref<any>([]);
const pledged_claims = ref<any>([]);
const warehouse_receipt = ref<any>([]);
const checks = ref<any>([]);
const bill_of_exchange = ref<any>([]);
const movable_collaterals = ref<any>([]);
const hmeromhnies_tropopoihshs = ref<string[]>([]);

const activeName = ref("summary_check");

const parentBorder = ref(false);

const loadLoanAgreement = async (loan_agreement_id: string | number) => {
	if (loan_agreement_id === "") return;

	mainStore.isLoading = true;

	//empty object
	StoreSummaryCheckRegisterOfBondPartBanks.value = [];
	StoreSummaryCheckDebtorsGuarantees.value = [];
	StoreSummaryCheckDebtorGuaranteesIndividual.value = [];
	StoreSummaryCheckDebtorGuaranteesLegalEntity.value = [];
	StoreRealEstateSummary.value = [];

	//const result:  = await .get(`loan_agreement?id=eq.` + loan_agreement_id);
	const result = await mosapi.generic_entity_get<LoanAgreement[]>(Entities.LoanAgreement, { id: loan_agreement_id, });

	StoreSummaryCheckLoanAgreement.value = result.data[0];
	await loadInterest(StoreSummaryCheckLoanAgreement.value.kathgor_epitok_bashs);
	await loadDebtor(StoreSummaryCheckLoanAgreement.value.debtor_id);
	await loadActiveLoanAccount(loan_agreement_id);
	await loadRegisterOfBonds(loan_agreement_id);
	await loadGuarantees(loan_agreement_id);
	await loadGuaranteesIndividual(loan_agreement_id);
	await loadGuaranteesLegalEntity(loan_agreement_id);
	await loadProduct(StoreSummaryCheckLoanAgreement.value.product);

	if (StoreSummaryCheckLoanAgreement.value.currency !== undefined && StoreSummaryCheckLoanAgreement.value.currency !== null)
		await loadCurrency(StoreSummaryCheckLoanAgreement.value.currency);

	await loadRealEstateCollaterals(StoreSummaryCheckLoanAgreement.value.id as number);
	await loadGreekStateGuarantees(loan_agreement_id);
	await loadRestOfCollaterals(loan_agreement_id);
	await loadRealEstateSummary(loan_agreement_id);
	await loadSummaryCheck(loan_agreement_id);
	mainStore.isLoading = false;


	if (StoreSummaryCheckLoanAgreement.value.katast_sumbashs === 'denounced') { isDenounced.value = 'YES'; } else { isDenounced.value = 'NO'; }
	if (StoreSummaryCheckLoanAgreement.value.ekdosi_diatagis === true) { has_ekdosi_diatagis.value = 'YES'; } else { has_ekdosi_diatagis.value = 'NO'; }

	hmer_ekd_datag_pliromis.value = [];

	if (StoreSummaryCheckLoanAgreement.value.diatagi_pl_hmek_hmep_ex_per !== null) {
		for (const diatagi_pliromis of StoreSummaryCheckLoanAgreement.value.diatagi_pl_hmek_hmep_ex_per) {
			hmer_ekd_datag_pliromis.value.push((diatagi_pliromis as any).hmek);
		}
	}

	hmeromhnies_tropopoihshs.value = [];

	if (StoreSummaryCheckLoanAgreement.value.hmer_tropop_sumbashs !== null) {
		for (const hmeromhnia_trop of StoreSummaryCheckLoanAgreement.value.hmer_tropop_sumbashs) {
			hmeromhnies_tropopoihshs.value.push((hmeromhnia_trop as any).hmer_tropop);
		}
	}
};

const loadRealEstateSummary = async (loan_agreement_id: string) => {
	// TODO mosapi call
  const result = await mosapi.generic_entity_get(Entities.RealEstate, {
    re_collaterals: JSON.stringify({
      some: {
        approve_status: 1,
        loan_agreement: {
          id: parseInt(loan_agreement_id),
          approve_status: 1,
            debtor: {
                approve_status: 1,
            },
        },
      }
    }),
    debtor: JSON.stringify({
      approve_status: 1,
    }),
    approve_status: 1,
    orderBy: "id.asc"
  });
	// const result:  = await .get(`real_estate
  // ?select=*,config_prefecture(*),config_postalcode(*),config_country(*),
  // re_collaterals!inner(*, loan_agreement!inner(*, debtor!inner(cid))),debtor!inner(*)
  // &approve_status=eq.1
  // &re_collaterals.approve_status=eq.1
  // &re_collaterals.loan_agreement.debtor.approve_status=eq.1
  // &re_collaterals.loan_agreement_id=eq.` +loan_agreement_id);
  // &debtor.approve_status=eq.1

	if (result.error) {
		showError(result.error);
	}

	StoreRealEstateSummary.value = result.data;
	// console.log(StoreRealEstateSummary.value);
};

const loadSummaryCheck = async (loan_agreement_id: string) => {
	// const result:  = await .get(`summary_check_xtra?loan_agreement_id=eq.` + loan_agreement_id);
	const result = await mosapi.generic_entity_get<SummaryCheck[]>(Entities.SummaryCheck, { loan_agreement_id: loan_agreement_id, });

	if (result.error) {
		showError(result.error);

		return;
	}

	originalObj.value = JSON.parse(JSON.stringify(result.data[0]));
	fullObj.value = JSON.parse(JSON.stringify(result.data[0]));

	// console.log(JSON.stringify(fullObj.value))
	// console.log(fullObj);
	// console.log('1')
	// if ((fullObj.value.paratasi_diarkeias === null) || (fullObj.value.paratasi_diarkeias === undefined) || (fullObj.value.paratasi_diarkeias === '')) {
	//   console.log('2')
	//   if (StoreSummaryCheckLoanAccount.value.arxikh_hmer_lukshs !== StoreSummaryCheckLoanAccount.value.nea_hmer_lukshs) {
	//     console.log('3')
	//     fullObj.value.paratasi_diarkeias = 'YES'
	//   }
	// }
	// Θα συμπληρώνεται τιμή ΝΑΙ αν στο φύλλο Loan Aggreement υπάρχει διαφορετική τιμή στα πεδία Αρχική ημερομηνία λήξης και Νέα ημερομηνία λήξης* Το πεδίο θα πρέπει να μπορεί να τροποποιείται και από τον χρήστη.
	// if (StoreSummaryCheckLoanAccount.value.arxikh_hmer_lukshs !== StoreSummaryCheckLoanAccount.value.nea_hmer_lukshs) {
	//   fullObj.value.paratasi_diarkeias = 'YES'
	// } else {
	//   fullObj.value.paratasi_diarkeias = 'NO'
	// }

	// if (StoreSummaryCheckLoanAccount.value.hmer_ektam !== undefined) {
	//   hmer_ektam.value = DateTime.fromFormat((StoreSummaryCheckLoanAccount.value.hmer_ektam as string), 'yyyy-MM-dd').toString();
	//   originalObj.value.hmer_ektam = hmer_ektam.value;
	//   fullObj.value.hmer_ektam = hmer_ektam.value;
	// }

	//ΠΡΟΣΩΠΙΚ/ΕΤΑΙΡΙΚΗ ΕΓΓΥΗΣΗ (ΤΑΥΤΟΠΟΙΗΣΗ, ΕΠΙΠΛΕΟΝ ΝΟΜΙΜΟΠΟΙΗΤΙΚΑ ΕΓΓΡΑΦΑ)
	for (let i = 0; i < StoreSummaryCheckDebtorsGuarantees.value.length; i++) {
		originalObj.value['ChecklistGuarantees_tautopoihsh_' + i] = StoreSummaryCheckDebtorsGuarantees.value[i].tautopoihsh;
		originalObj.value['ChecklistGuarantees_epipleonnomeggrafa_' + i] = StoreSummaryCheckDebtorsGuarantees.value[i].epipleonnomeggrafa;
		originalObj.value['ChecklistGuarantees_rowid_' + i] = StoreSummaryCheckDebtorsGuarantees.value[i].guarantees_row_id;

		fullObj.value['ChecklistGuarantees_tautopoihsh_' + i] = StoreSummaryCheckDebtorsGuarantees.value[i].tautopoihsh;
		fullObj.value['ChecklistGuarantees_epipleonnomeggrafa_' + i] = StoreSummaryCheckDebtorsGuarantees.value[i].epipleonnomeggrafa;
		fullObj.value['ChecklistGuarantees_rowid_' + i] = StoreSummaryCheckDebtorsGuarantees.value[i].guarantees_row_id;
	}

	for (let i = 0; i < StoreSummaryCheckRealEstateCollaterals.value.length; i++) {
		originalObj.value['ChecklistReCollaterals_perilhps_eggrafhs_prosim_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].perilhps_eggrafhs_prosim;
		originalObj.value['ChecklistReCollaterals_pistop_ypoth_ktimatol_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].pistop_ypoth_ktimatol;
		originalObj.value['ChecklistReCollaterals_lhksh_asfalistiriou_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].lhksh_asfalistiriou;
		originalObj.value['ChecklistReCollaterals_rowid_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].id;

		fullObj.value['ChecklistReCollaterals_perilhps_eggrafhs_prosim_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].perilhps_eggrafhs_prosim;
		fullObj.value['ChecklistReCollaterals_pistop_ypoth_ktimatol_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].pistop_ypoth_ktimatol;
		fullObj.value['ChecklistReCollaterals_lhksh_asfalistiriou_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].lhksh_asfalistiriou;
		fullObj.value['ChecklistReCollaterals_rowid_' + i] = StoreSummaryCheckRealEstateCollaterals.value[i].id;
	}

	await loadPendingChanges(result.data[0].id);
	SaveSummaryCheck.value = result.data[0];
};

const loadPendingChanges = async (summary_id: number) => {
	let CheckListGuaranteesResult: APIReply<unknown> | undefined = undefined;
	let lacresult: APIReply<unknown> | undefined = undefined;
	const result = await mosapi.get_pending_changes(Entities.SummaryCheck, summary_id);
	// result = await .get(`entity_summary_check_xtra_changes?entity_id=eq.${summary_id}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)&order=id`);

	for (const field of Object.keys(fullObj.value)) {
		fullObj.value[field] = { field, originalValue: fullObj.value[field] };
	}

	for (const pending of result.data) {
		pending["originalValue"] = originalObj.value[pending.field];
    pending["table_name"] = table_name.value;
		fullObj.value[pending.field].pendingValue = pending;
	}

	if (active_loan_account_id !== undefined) {

		lacresult = await mosapi.get_pending_changes(Entities.LoanAccount, active_loan_account_id);
		lacresult.data = lacresult.data.filter((x: any) => x.field === 'hmer_ektam');
		// lacresult = await .get(`entity_loan_accounts_changes?entity_id=eq.${active_loan_account_id}&approval_status=eq.0&field=in.("hmer_ektam")&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)&order=id`);

		for (const pending of lacresult.data) {
			pending["originalValue"] = originalObj.value[pending.field];
			fullObj.value[pending.field].pendingValue = pending;
		}
	}

	for (let i = 0; i < StoreSummaryCheckDebtorsGuarantees.value.length; i++) {

		CheckListGuaranteesResult = await mosapi.get_pending_changes(Entities.Guarantees, StoreSummaryCheckDebtorsGuarantees.value[i].guarantees_row_id);
		CheckListGuaranteesResult.data = CheckListGuaranteesResult.data.filter((x: any) => x.field === 'tautopoihsh' || x.field === 'epipleonnomeggrafa');
		// CheckListGuaranteesResult = await .get(`entity_guarantees_changes?entity_id=eq.${StoreSummaryCheckDebtorsGuarantees.value[i].guarantees_row_id}&approval_status=eq.0&field=in.("tautopoihsh","epipleonnomeggrafa")&select=id,entity_id,field,value,insert_timestamp,editor:users!fk_user_editor(username)&order=id`);
		for (const pending of CheckListGuaranteesResult.data) {
			pending["field"] = 'ChecklistGuarantees_' + pending.field + '_' + i
			pending["originalValue"] = originalObj.value['ChecklistGuarantees_' + pending.field + '_' + i];
			fullObj.value[pending.field].pendingValue = pending;
		}
	}

	for (let i = 0; i < StoreSummaryCheckRealEstateCollaterals.value.length; i++) {
		const CheckListReCollateralsResult = await mosapi.get_pending_changes(Entities.RECollateral, StoreSummaryCheckRealEstateCollaterals.value[i].id);
		CheckListReCollateralsResult.data = CheckListReCollateralsResult.data.filter((x: any) => x.field === 'perilhps_eggrafhs_prosim' || x.field === 'pistop_ypoth_ktimatol' || x.field === 'lhksh_asfalistiriou');
		// const CheckListReCollateralsResult = await .get(`entity_re_collaterals_changes?entity_id=eq.${StoreSummaryCheckRealEstateCollaterals.value[i].id}&approval_status=eq.0&field=in.("perilhps_eggrafhs_prosim","pistop_ypoth_ktimatol","lhksh_asfalistiriou")&select=id,entity_id,field,value,insert_timestamp,editor:users!fk_user_editor(username)&order=id`);

		for (const pending of CheckListReCollateralsResult.data) {
			pending["field"] = 'ChecklistReCollaterals_' + pending.field + '_' + i
			pending["originalValue"] = originalObj.value['ChecklistReCollaterals_' + pending.field + '_' + i];
			fullObj.value[pending.field].pendingValue = pending;
		}
	}
};

const loadDebtor = async (debtor_id: number) => {
	// const result:  = await .get(`debtor?select=*,config_klados(*),config_kad(*),config_prefecture!debtor_fk_7(*),config_postalcode!debtor_fk_8(*),config_country!debtor_fk_9(*)&approve_status=eq.1&id=eq.` + debtor_id);
	const result = await mosapi.generic_entity_get<Debtor[]>(Entities.Debtors, { id: debtor_id, approve_status: 1 });

	if (result.data[0]) {
		StoreSummaryCheckDebtor.value = result.data[0];
		await loadPortfolio(parseInt(StoreSummaryCheckDebtor.value.portfolio as string));
	} else {
		ElMessage({
			showClose: true,
			message: 'Σφάλμα: Ο οφειλέτης που συνδέετε με αυτό το Summary check έχει κατάσταση έγκρισης σε εκκρεμότητα.',
			type: "error",
			duration: 0
		});
	}
};

const loadGuaranteesIndividual = async (loan_agreement_id: string) => {
	StoreSummaryCheckDebtorGuaranteesIndividual.value = [];
	console.log('individual-ekso-apoto-query');
	console.log(StoreSummaryCheckDebtorGuaranteesIndividual);
	// const result:  = await .get(`guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`);
	const result = await mosapi.generic_entity_get<Guarantee[]>(Entities.Guarantees, { loan_agreement_id: loan_agreement_id, approve_status: 1 });

	for (let i = 0; i < result.data.length; i++) {
		// const result2:  = await .get(`debtor?approve_status=eq.1&customer_type=eq.individual&cid=eq.${result.data[i].debtor_cid}`);
		const result2 = await mosapi.generic_entity_get<Debtor[]>(Entities.Debtors, { cid: result.data[i].debtor_cid, approve_status: 1, customer_type: 'individual' });

		if (result2.data[0] === undefined) {
			continue;
		}

		StoreSummaryCheckDebtorGuaranteesIndividual.value.push(result2.data[0]);
	}

	console.log('meta to query');
	console.log(StoreSummaryCheckDebtorGuaranteesIndividual);
};

const loadGuaranteesLegalEntity = async (loan_agreement_id: string) => {
	StoreSummaryCheckDebtorGuaranteesLegalEntity.value = [];
	// const result:  = await .get(`guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`);
	const result = await mosapi.generic_entity_get<Guarantee[]>(Entities.Guarantees, { loan_agreement_id: loan_agreement_id, approve_status: 1 });

	for (let i = 0; i < result.data.length; i++) {
		// const result2:  = await .get(`debtor?approve_status=eq.1&customer_type=eq.legal_entity&cid=eq.${result.data[i].debtor_cid}`);
		const result2 = await mosapi.generic_entity_get<Debtor[]>(Entities.Debtors, { cid: result.data[i].debtor_cid, approve_status: 1, customer_type: 'legal_entity' });

		if (result2.data[0] === undefined) {
			continue;
		}

		StoreSummaryCheckDebtorGuaranteesLegalEntity.value.push(result2.data[0]);
	}
};

const loadPortfolio = async (portfolio_id: number | undefined) => {
	// const result:  = await .get(`portfolio?id=eq.` + portfolio_id);
	const result = await mosapi.generic_entity_get<Portfolio[]>(Entities.Portfolios, { id: portfolio_id, });
	StoreSummaryCheckPortfolio.value = result.data[0];
};

const loadRealEstateCollaterals = async (loan_agreement_id: number) => {
	// TODO mosapi call
  const result = await mosapi.generic_entity_get(Entities.RECollateral, {
    real_estate: JSON.stringify({
      approve_status: 1,
      debtor: {
        approve_status: 1,
      }
    }),
    approve_status: 1,
    loan_agreement_id: loan_agreement_id,
    orderBy: "id.asc"
  });
    // real_ rob_participating_banks: JSON.stringify({ some: { register_of_bond_id: register_of_bond_id  } }) });
	// const result:  = await .get(`re_collaterals?select=*,real_estate!inner(*,debtor!inner(*))&approve_status=eq.1&real_estate.approve_status=eq.1&real_estate.debtor.approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}&order=id.asc`);
	StoreSummaryCheckRealEstateCollaterals.value = result.data;
};

const loadRestOfCollaterals = async (loan_agreement_id: string) => {
	// const result:  = await .get(`rest_of_collaterals?approve_status=eq.1&loan_agreement_id=eq.` + loan_agreement_id);
	const result = await mosapi.generic_entity_get<RestOfCollaterals[]>(Entities.NRECollateral, { loan_agreement_id: loan_agreement_id, approve_status: 1 });

	if (result.error) {
		showError(result.error);

		return;
	}
	for (let i = 0; i < result.data.length; i++) {
		if (
			result.data[i].type == "deposits" ||
			result.data[i].type == "pledged_claims" ||
			result.data[i].type == "warehouse_receipt" ||
			result.data[i].type == "bill_of_exchange" ||
			result.data[i].type == "movable_collaterals"
		) {
			EkxorisiApaitiseon.value = t('global.true');
			break;
		}
	}
	for (let i = 0; i < result.data.length; i++) {
		if (result.data[i].e_sumbatika_eggrafa || result.data[i].s_ypog_sumbash) {
			Epitages.value = t('global.true');
		}
		if (result.data[i].em_sumbash_enexurou || result.data[i].mem_sumbash_enexurou) {
			Metoxes.value = t('global.true');
		}

		if (result.data[i].type == "listed_shares") {
			listed_shares.value.push(result.data[i]);
		} else if (result.data[i].type == "unlisted_shares") {
			unlisted_shares.value.push(result.data[i]);
		} else if (result.data[i].type == "deposits") {
			deposits.value.push(result.data[i]);
		} else if (result.data[i].type == "pledged_claims") {
			pledged_claims.value.push(result.data[i]);
		} else if (result.data[i].type == "warehouse_receipt") {
			warehouse_receipt.value.push(result.data[i]);
		} else if (result.data[i].type == "checks") {
			checks.value.push(result.data[i]);
		} else if (result.data[i].type == "bill_of_exchange") {
			bill_of_exchange.value.push(result.data[i]);
		} else if (result.data[i].type == "movable_collaterals") {
			movable_collaterals.value.push(result.data[i]);
		}
	}

	StoreSummaryCheckRestOfCollaterals.value = result.data[0];
};

const loadGreekStateGuarantees = async (loan_agreement_id: string) => {
	StoreSummaryCheckGreekStateGuarantees.value = [];
	// const result:  = await .get(`greek_state_guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`);
	const result = await mosapi.generic_entity_get<Guarantee[]>(Entities.Guarantees, { loan_agreement_id: loan_agreement_id, approve_status: 1 });

	if (result.error) {
		showError(result.error);

		return;
	}

	if (result.data.length === 0) return;

	for (let i = 0; i < result.data.length; i++) {
		StoreSummaryCheckGreekStateGuarantees.value.push(result.data[i]);
	}
};

const loadRegisterOfBonds = async (loan_agreement_id: string) => {
	// const result:  = await .get(`register_of_bonds?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`);
	const result = await mosapi.generic_entity_get<RegisterOfBond[]>(Entities.RegisterOfBonds, { loan_agreement_id: loan_agreement_id, approve_status: 1 });

	if (result.error) {
		showError(result.error);

		return;
	}

	if (result.data.length === 0) return;

	StoreSummaryCheckRegisterOfBond.value = result.data[0];
	await loadBondsPartBanks(
		loan_agreement_id
	);
};

const loadBondsPartBanks = async (loan_agreement_id: string) => {
	// const result:  = await .get(`view_mosbonds_view1?loan_agreement_id=eq.` + loan_agreement_id);
	const result = await mosapi.get_view<ViewMosBondsView2>(UIViews.SummaryCheckBonds, { loan_agreement_id: loan_agreement_id, });

	if (result.error) {
		showError(result.error);
		return;
	}

	if (result.data.length === 0) return;

	StoreSummaryRobBonds.value = result.data;
};

const loadActiveLoanAccount = async (loan_agreement_id: string) => {
	// const result:  = await .get(`loan_accounts?select=*,config_loan_account_product(*),config_interest(*)&loan_agreement_id=eq.${loan_agreement_id}&status=eq.active`);
	const result = await mosapi.generic_entity_get<LoanAccount[]>(Entities.LoanAccount, { loan_agreement_id: loan_agreement_id, status: 'active' });
	StoreSummaryCheckLoanAccount.value = result.data;
};

watch(
	() => selectedLoanAgreementID.value,
	async (newVal) => {
		console.log("selected loan agreement changed to " + newVal);
	}
);

const saveData = async () => {
	mainStore.isLoading = true;
	await save();
	mainStore.isLoading = false;
};

const loadGuarantees = async (loan_agreement_id: string) => {
	// const result:  = await .get(`guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}&order=id.asc`);
	const result = await mosapi.generic_entity_get<Guarantee[]>(Entities.Guarantees, { loan_agreement_id: loan_agreement_id, approve_status: 1, orderBy: "id" });

	for (let i = 0; i < result.data.length; i++) {
		// const result2:  = await .get(`debtor?select=*,config_kad(*),config_prefecture!debtor_fk_7(*),config_postalcode!debtor_fk_8(*),config_country!debtor_fk_9(*)&approve_status=eq.1&cid=eq.${result.data[i].debtor_cid}`); // TODO: Add VAT number also in check?
		const result2 = await mosapi.generic_entity_get<Debtor[]>(Entities.Debtors, { cid: result.data[i].debtor_cid, approve_status: 1 });

		StoreSummaryCheckDebtorsGuarantees.value[i] = result2.data[0];
		StoreSummaryCheckDebtorsGuarantees.value[i].guarantees_row_id = result.data[i].id;
		StoreSummaryCheckDebtorsGuarantees.value[i].tautopoihsh = result.data[i].tautopoihsh;
		StoreSummaryCheckDebtorsGuarantees.value[i].epipleonnomeggrafa =
			result.data[i].epipleonnomeggrafa;
	}
};

const hasDebtorContactDetails = (): boolean => {
	if (StoreSummaryCheckDebtor.value.address !== null || StoreSummaryCheckDebtor.value.address_number !== null
		|| StoreSummaryCheckDebtor.value.region !== null || StoreSummaryCheckDebtor.value.city !== null
		|| StoreSummaryCheckDebtor.value.district !== null || StoreSummaryCheckDebtor.value.config_postalcode !== null
		|| StoreSummaryCheckDebtor.value.country !== null)
		return true;
	return false;
};

const loadProduct = async (product_id: number) => {
	// result = await .get("config_product?order=product&id=eq." + product_id);
	const result = await mosapi.generic_entity_get<ConfigProduct[]>(Entities.ConfigProduct, { id: product_id, orderBy: "product" });
	product.value = result.data[0];
};

const loadInterest = async (interest_id: number) => {
	if (interest_id === null) return { interest: '' };

	// result = await .get("config_interest?id=eq." + interest_id);
	const result = await mosapi.generic_entity_get<ConfigInterest[]>(Entities.ConfigInterest, { id: interest_id, });
	interest.value = result.data[0];
};

const loadCurrency = async (currency_id: number) => {
	// result = await .get("config_currency?id=eq." + currency_id);
	const result = await mosapi.generic_entity_get<ConfigCurrency[]>(Entities.ConfigCurrency, { id: currency_id, });
	currency.value = result.data[0];
};

watch(globalLoanAgreementID, async () => {
	if (mainStore.loggedUser.username === '') return;

	if (globalLoanAgreementID.value === "") return;

	selectedLoanAgreementID.value = globalLoanAgreementID.value.toString();

	// const result:  = await .get("view_summary_check_xtra_view1?select=product_type&loan_agreement_id=eq." + globalLoanAgreementID.value);
	const result = await mosapi.get_view<ViewSummaryCheckXtraView2>(UIViews.SummaryCheck, { loan_agreement_id: globalLoanAgreementID.value, });

	if (result) {
		product_type.value = result.data[0].product_type;
	}

	if (product_type.value === "summary_check_KOD") {
		router.push({
			name: "Summary Check KOD",
			params: { loan_agreement_id: globalLoanAgreementID.value },
		});
		return;
	}

	if (product_type.value === "summary_check_OD") {
		router.push({
			name: "Summary Check OD",
			params: { loan_agreement_id: globalLoanAgreementID.value },
		});
		return;
	}
	if (product_type.value === "summary_check_another_loan") {
		router.push({
			name: "Summary Check AL",
			params: { loan_agreement_id: globalLoanAgreementID.value },
		});
		return;
	}

	await loadLoanAgreement(globalLoanAgreementID.value.toString());
});

onMounted(async () => {
	mainStore.isLoading = true;
	table_name.value = SummaryCheck.getTableNameStatic();
	cleanObject.value = new SummaryCheck();

	console.log("summary_check_id",route.params.loan_agreement_id);

	mainStore.isLoading = false;

	if (route.params.loan_agreement_id && globalLoanAgreementID.value.toString()) {
		SummaryCheckLoanAgreementID.value = route.params.loan_agreement_id as string;
	} else if (route.params.loan_agreement_id && !globalLoanAgreementID.value.toString()) {
		SummaryCheckLoanAgreementID.value = route.params.loan_agreement_id as string;
	} else if (!route.params.loan_agreement_id && globalLoanAgreementID.value.toString()) {
		SummaryCheckLoanAgreementID.value = globalLoanAgreementID.value.toString();
	}

	setup_decision_listener(undefined, loadLoanAgreement, parseInt(SummaryCheckLoanAgreementID.value), Entities.SummaryCheck);
	await loadLoanAgreement(SummaryCheckLoanAgreementID.value);
});

const localSave = async () => {
	await saveSummaryCheckChanges();
	//await loadData(parseInt(SummaryCheckLoanAgreementID.value));
}

// TODO Η προηγούμενη έκδοση ενημέρωνε με patch κάποια πεδία σε άλλα tables.
//
// const save = async () => {
// 	let result:  = new ();
// 	const obj: any = {};

// 	if (mainStore.loggedUser.user_type === 2) {
// 		await saveSummaryCheckChanges();
// 		emits("save");
// 		await loadData();
// 		return;
// 	}

// 	const newFull: any = {};
// 	for (const field of Object.keys(fullObj.value)) {
// 		if ((originalObj as any).value[field] === null) {
// 			originalObj.value[field] = '';
// 			if ((fullObj as any).value[field].originalValue === null) {
// 				(fullObj as any).value[field].originalValue = "";
// 			}
// 		}
// 	}

// 	for (const field of Object.keys(fullObj.value)) {

// 		if (field !== 'hmer_ektam' && field.includes('ChecklistGuarantees') == false && field.includes('ChecklistReCollaterals') == false) {
// 			newFull[field] = (fullObj as any).value[field].originalValue;
// 		} else if (field.includes('ChecklistGuarantees') == true || field.includes('ChecklistReCollaterals') == true) {
// 			obj[field] = (fullObj as any).value[field].originalValue;
// 			const report = ChangesetHelper.compare(originalObj.value, obj);
// 			for (const changedField of report.changes) {
// 				if (field === changedField) {

// 					if (changedField.includes('epipleonnomeggrafa')) {
// 						result = await .patch(
// 							"guarantees?id=eq." + (fullObj as any).value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")].originalValue,
// 							{ epipleonnomeggrafa: (fullObj as any).value[field].originalValue }
// 						);
// 					} else if (changedField.includes('tautopoihsh')) {
// 						result = await .patch(
// 							"guarantees?id=eq." + (fullObj as any).value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")].originalValue,
// 							{ tautopoihsh: (fullObj as any).value[field].originalValue }
// 						);
// 					} else if (changedField.includes('perilhps_eggrafhs_prosim')) {

// 						result = await .patch(
// 							"re_collaterals?id=eq." + (fullObj as any).value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")].originalValue,
// 							{ perilhps_eggrafhs_prosim: (fullObj as any).value[field].originalValue }

// 						);
// 					} else if (changedField.includes('pistop_ypoth_ktimatol')) {

// 						result = await .patch(
// 							"re_collaterals?id=eq." + (fullObj as any).value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")].originalValue,
// 							{ pistop_ypoth_ktimatol: (fullObj as any).value[field].originalValue }
// 						);
// 					} else if (changedField.includes('lhksh_asfalistiriou')) {

// 						result = await .patch(
// 							"re_collaterals?id=eq." + (fullObj as any).value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")].originalValue,
// 							{ lhksh_asfalistiriou: (fullObj as any).value[field].originalValue }
// 						);
// 					}
// 				}
// 			}
// 		}
// 	}

// 	for (const key of Object.keys(newFull)) {
// 		if (newFull[key] === "")
// 			newFull[key] = null;
// 	}

// 	await .patch("summary_check_xtra?loan_agreement_id=eq." + SummaryCheckLoanAgreementID.value, newFull);

// 	emits("save");
// 	await loadData();
// };

const saveSummaryCheckChanges = async () => {
	const newFull: any = {};

	for (const field of Object.keys(fullObj.value)) {
		if ((originalObj as any).value[field] === null) {
			(originalObj as any).value[field] = "";
			if ((fullObj as any).value[field].originalValue === null) {
				(fullObj as any).value[field].originalValue = "";
			}
		}
		newFull[field] = fullObj.value[field].originalValue;
	}

	console.log(newFull['ChecklistReCollaterals_lhksh_asfalistiriou_1']);

	const report = ChangesetHelper.compare(originalObj.value, newFull);
	for (const changedField of report.changes) {

		if (changedField.includes("ChecklistGuarantees") == false && changedField.includes("ChecklistReCollaterals") == false && changedField !== "hmer_ektam") {
			if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
        		await mosapi.add_entity_change({ entity_id: originalObj.value.id, entity_type: Entities.SummaryCheck, field: changedField, value: newFull[changedField], })
				// await .post_data("entity_summary_check_xtra_changes", { entity_id: originalObj.value.id, editor_user_id: mainStore.loggedUser.id, field: changedField, value: newFull[changedField], });
			} else {
        		await mosapi.update_entity_change({ approval_status: ChangeApprovalStatus.Approved, id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.SummaryCheck, field: changedField, value: newFull[changedField], });
				// await .patch("entity_summary_check_xtra_changes?id=eq." + fullObj.value[changedField].pendingValue.id, { entity_id: originalObj.value.id, editor_user_id: mainStore.loggedUser.id, field: changedField, value: newFull[changedField], decision_timestamp: timestamp, });
			}
		}
		else if (changedField.includes("ChecklistGuarantees_tautopoihsh")) {
			if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
        		await mosapi.add_entity_change({ entity_id: originalObj.value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")], entity_type: Entities.Guarantees, field: 'tautopoihsh', value: newFull[changedField], })
				// await .post_data("entity_guarantees_changes", { entity_id: originalObj.value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")], editor_user_id: mainStore.loggedUser.id, field: 'tautopoihsh', value: newFull[changedField], });
			} else {
        		await mosapi.update_entity_change({ id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.Guarantees, field: 'tautopoihsh', value: newFull[changedField], })
				// await .patch("entity_guarantees_changes?id=eq." + fullObj.value[changedField].pendingValue.id, { entity_id: originalObj.value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")], editor_user_id: mainStore.loggedUser.id, field: 'tautopoihsh', value: newFull[changedField], } );
			}
		} else if (changedField.includes("ChecklistGuarantees_epipleonnomeggrafa")) {
			if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
				await mosapi.add_entity_change({ entity_id: originalObj.value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")], entity_type: Entities.Guarantees, field: 'epipleonnomeggrafa', value: newFull[changedField], })
				// await .post_data("entity_guarantees_changes", { entity_id: originalObj.value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")], editor_user_id: mainStore.loggedUser.id, field: 'epipleonnomeggrafa', value: newFull[changedField], });
			} else {
				await mosapi.update_entity_change({ id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.Guarantees, field: 'epipleonnomeggrafa', value: newFull[changedField], })
				// await .patch("entity_guarantees_changes?id=eq." + fullObj.value[changedField].pendingValue.id, { entity_id: originalObj.value['ChecklistGuarantees_rowid_' + changedField.replace(/\D/g, "")], editor_user_id: mainStore.loggedUser.id, field: 'epipleonnomeggrafa', value: newFull[changedField], });
			}
		} else if (changedField.includes("ChecklistReCollaterals_perilhps_eggrafhs_prosim")) {
			if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
				await mosapi.add_entity_change({ entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")], entity_type: Entities.RECollateral, field: 'perilhps_eggrafhs_prosim', value: newFull[changedField], })
				// await .post_data("entity_re_collaterals_changes", { entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")], editor_user_id: mainStore.loggedUser.id, field: 'perilhps_eggrafhs_prosim', value: newFull[changedField], });
			} else {
        		await mosapi.update_entity_change({ id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.RECollateral, field: 'perilhps_eggrafhs_prosim', value: newFull[changedField], })
				// await .patch("entity_re_collaterals_changes?id=eq." +fullObj.value[changedField].pendingValue.id,{entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")],editor_user_id: mainStore.loggedUser.id,field: 'perilhps_eggrafhs_prosim',value: newFull[changedField],});
			}
		} else if (changedField.includes("ChecklistReCollaterals_pistop_ypoth_ktimatol")) {
			if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
				await mosapi.add_entity_change({ entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")], entity_type: Entities.RECollateral, field: 'pistop_ypoth_ktimatol', value: newFull[changedField], })
				// await .post_data("entity_re_collaterals_changes", {entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")],editor_user_id: mainStore.loggedUser.id,field: 'pistop_ypoth_ktimatol',value: newFull[changedField],});
			} else {
				await mosapi.update_entity_change({ id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.RECollateral, field: 'pistop_ypoth_ktimatol', value: newFull[changedField], })
				// await .patch("entity_re_collaterals_changes?id=eq." +fullObj.value[changedField].pendingValue.id,{entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")],editor_user_id: mainStore.loggedUser.id,field: 'pistop_ypoth_ktimatol',value: newFull[changedField],});
			}
		} else if (changedField.includes("ChecklistReCollaterals_lhksh_asfalistiriou")) {
			if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
				await mosapi.add_entity_change({ entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")], entity_type: Entities.RECollateral, field: 'lhksh_asfalistiriou', value: newFull[changedField], })
				// await .post_data("entity_re_collaterals_changes", {entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")],editor_user_id: mainStore.loggedUser.id,field: 'lhksh_asfalistiriou',value: newFull[changedField],});
			} else {
				await mosapi.update_entity_change({ id: fullObj.value[changedField].pendingValue.id, entity_type: Entities.RECollateral, field: 'lhksh_asfalistiriou', value: newFull[changedField], })
				// await .patch("entity_re_collaterals_changes?id=eq." +fullObj.value[changedField].pendingValue.id,{entity_id: originalObj.value['ChecklistReCollaterals_rowid_' + changedField.replace(/\D/g, "")],editor_user_id: mainStore.loggedUser.id,field: 'lhksh_asfalistiriou',value: newFull[changedField],});
			}
		}
	}
  await loadLoanAgreement(SummaryCheckLoanAgreementID.value);
};

const onDecision = async (decision: Decision) => {
	const obj: any = {};
	const field = decision.field as string;
	obj[field] = decision.value;

	// console.log("id:" + decision.db_change_id);
	// console.log("field:" + field);
	// console.log(obj[field]);

	// console.log("index:" + field.replace(/\D/g, ""));
	// console.log("ChecklistGuarantees_rowid_" + field.replace(/\D/g, ""));
	// console.log((fullObj as any).value['ChecklistGuarantees_rowid_' + field.replace(/\D/g, "")].originalValue);

	if (field !== "hmer_ektam" && field.includes('ChecklistGuarantees') == false && field.includes('ChecklistReCollaterals') == false) {
		//if ((field !== 'hmer_ektam') && (field !=='kapoio_allo_pedio')) {
		if (decision.accepted) {

			await mosapi.update_entity_change({ entity_type: Entities.SummaryCheck, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Approved, });
			// result = await .patch("entity_summary_check_xtra_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Approved,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
			// result = await .patch("summary_check_xtra?id=eq." + originalObj.value.id, obj);
			await loadData(parseInt(SummaryCheckLoanAgreementID.value));
		} else {
			await mosapi.update_entity_change({ entity_type: Entities.SummaryCheck, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Rejected, });
			// result = await .patch("entity_summary_check_xtra_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Rejected,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
		}
	} else if (field === "hmer_ektam") {
		//LOAN ACCOUNT hmer_ektam
		if (decision.accepted) {
			await mosapi.update_entity_change({ entity_type: Entities.LoanAccount, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Approved, });
			// result = await .patch("entity_loan_accounts_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Approved,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
			// result = await .patch("loan_accounts?id=eq." + active_loan_account_id,obj);
			await loadData(parseInt(SummaryCheckLoanAgreementID.value));
		} else {
			await mosapi.update_entity_change({ entity_type: Entities.LoanAccount, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Rejected, });
			// result = await .patch("entity_loan_accounts_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Rejected,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
		}
	} else if (field.includes('ChecklistGuarantees') == true) {
		//LOAN ACCOUNT hmer_ektam
		if (decision.accepted) {
			await mosapi.update_entity_change({ entity_type: Entities.Guarantees, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Approved, });
			// result = await .patch("entity_guarantees_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Approved,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
			if (field.includes('tautopoihsh')) {
				// result = await .patch("guarantees?id=eq." + (fullObj as any).value['ChecklistGuarantees_rowid_' + field.replace(/\D/g, "")].originalValue,{ tautopoihsh: obj[field] });
			} else if (field.includes('epipleonnomeggrafa')) {
				// result = await .patch("guarantees?id=eq." + (fullObj as any).value['ChecklistGuarantees_rowid_' + field.replace(/\D/g, "")].originalValue,{ epipleonnomeggrafa: obj[field] });
			}
			await loadData(parseInt(SummaryCheckLoanAgreementID.value));
		} else {
			await mosapi.update_entity_change({ entity_type: Entities.Guarantees, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Rejected, });
			// result = await .patch("entity_guarantees_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Rejected,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
		}
	} else if (field.includes('ChecklistReCollaterals') == true) {
		if (decision.accepted) {
			await mosapi.update_entity_change({ entity_type: Entities.RECollateral, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Approved, });
			// result = await .patch("entity_re_collaterals_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Approved,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});

			if (field.includes('perilhps_eggrafhs_prosim')) {
				// result = await .patch("re_collaterals?id=eq." + (fullObj as any).value['ChecklistReCollaterals_rowid_' + field.replace(/\D/g, "")].originalValue,{ perilhps_eggrafhs_prosim: obj[field] });
			} else if (field.includes('pistop_ypoth_ktimatol')) {
				// result = await .patch("re_collaterals?id=eq." + (fullObj as any).value['ChecklistReCollaterals_rowid_' + field.replace(/\D/g, "")].originalValue,{ pistop_ypoth_ktimatol: obj[field] });
			} else if (field.includes('lhksh_asfalistiriou')) {
				// result = await .patch("re_collaterals?id=eq." + (fullObj as any).value['ChecklistReCollaterals_rowid_' + field.replace(/\D/g, "")].originalValue,{ lhksh_asfalistiriou: obj[field] });
			}
			await loadData(parseInt(SummaryCheckLoanAgreementID.value));
		} else {
			await mosapi.update_entity_change({ entity_type: Entities.RECollateral, id: decision.db_change_id as number, approval_status: ChangeApprovalStatus.Rejected, });
			// result = await .patch("entity_re_collaterals_changes?id=eq." + decision.db_change_id,{approval_status: ChangeApprovalStatus.Rejected,approver_user_id: mainStore.loggedUser.id,decision_timestamp: timestamp,});
		}
	}

	loadSummaryCheck(SummaryCheckLoanAgreementID.value);
};

const handleClick = (tab: TabsPaneContext, event: Event) => {
	console.log(tab, event);
};

const indexMethod = (index: number) => {
	return index * 1;
};

const getTranslation = (row: any, column: any) => {
	return t("summary_check." + row[column.property]);
};

const goBack = async () => {
	router.push({
		name: "Summary Check"
	});
}

</script>

<template>
  <div class="content-wrapper">
    <div>
      <el-row :gutter="10">
        <el-col :span="20">
          <h3 class="heading-page">Summary Check</h3>
        </el-col>

        <el-col :span="2">
          <el-button @click="goBack()" type="primary" style="float: right">Back</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="localSave()">{{ $t('generic.save') }}</el-button>
        </el-col>
      </el-row>
    </div>
  </div>

  <div v-loading="mainStore.isLoading" class="content-wrapper" style="margin-top: 20px; height: 100%">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="Summary Check" name="summary_check" class="summary-check-wrapper">
        <el-form v-if="SummaryCheckLoanAgreementID" :model="SaveSummaryCheck">
          <div>
            <div class="editable">
              <EntityField type="datepicker" label="Summary Check Date" :object="fullObj.summary_check_date"
                 style="padding: 8px; font-weight: bold;" />
            </div>

            <table style="width: 100%" class="basic-elements-table">
              <tbody>
                <tr>
                  <th colspan="2" class="table-heading">
                    <p>{{ t('SummaryChecks.basic_data') }}</p>
                  </th>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.portfolio') }}</th>
                  <td>
                    {{ StoreSummaryCheckPortfolio?.portf_name }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.cid') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.cid }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.name') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.fullname }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.afm') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.vat_number }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.gemi') }}</th>
                  <td v-if="StoreSummaryCheckDebtor?.gemh != -1">
                    {{ StoreSummaryCheckDebtor?.gemh }}
                  </td>
                  <td v-else>
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.klados') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.config_klados?.perigrafi }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.lixi_nom') }}</th>
                  <td v-if="StoreSummaryCheckDebtor?.hmer_liks_isx_nomim">
                    {{ DateTime.fromISO(StoreSummaryCheckDebtor?.hmer_liks_isx_nomim as string).toFormat("dd/MM/yyyy") }}
                  </td>
                  <td v-else>
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.country') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.config_country_debtor_countryToconfig_country?.country :
					StoreSummaryCheckDebtor?.config_country_debtor_postal_countryToconfig_country?.country }}

                    <!-- {{ StoreSummaryCheckDebtor?.config_country?.country }} -->
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.phone') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.phone_number }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.email') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.email }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.postal_street') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.address : StoreSummaryCheckDebtor?.postal_address }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.postal_nbr') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.address_number : StoreSummaryCheckDebtor?.postal_address_number }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.postal_region') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.region : StoreSummaryCheckDebtor?.postal_region }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.postal_city') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor.city : StoreSummaryCheckDebtor?.postal_city }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.postal_prefecture') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.config_prefecture_debtor_districtToconfig_prefecture?.prefecture :
					StoreSummaryCheckDebtor?.config_prefecture_debtor_postal_districtToconfig_prefecture?.prefecture }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.postal_zip_code') }}</th>
                  <td>
                    {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.config_postalcode_debtor_postcodeToconfig_postalcode?.postalcode :
					StoreSummaryCheckDebtor?.config_postalcode_debtor_postal_postcodeToconfig_postalcode?.postalcode }}
                  </td>
                </tr>
                <tr>
                  <th>{{ t('SummaryChecks.account_manager') }}</th>
                  <td>
                    {{ StoreSummaryCheckDebtor?.mos_clients_acc_off }}
                  </td>
                </tr>

                <tr></tr>
              </tbody>
            </table>
            <div class="responsive-table">
              <table style="width: 100%; margin-top: 20px" class="basic-note-table">
                <tbody>
                  <tr>
                    <th colspan="2" class="table-heading">
                      <p>{{ t('SummaryChecks.basic_check_note') }}</p>
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 20%;">{{ t('SummaryChecks.type_of_financing') }}</th>
                    <td>{{ product?.product }}</td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.loan_agreement') }}</th>
                    <td>
                      {{ StoreSummaryCheckLoanAgreement?.ar_sumbashs }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.guarantees') }}</th>
                    <td>
                      <table style="width: 100%" class="inner-table-basic-note">
                        <tbody>
                          <tr>
                            <td>
                              <h6>{{ t('SummaryChecks.onomateponimo') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.country') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.phone') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.email') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.street') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.street_number') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.region') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.city') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.postal_region') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.postal_zip_code') }}</h6>
                            </td>
                          </tr>
                          <tr v-if="StoreSummaryCheckDebtorsGuarantees.length != 0" v-for="guarantees in StoreSummaryCheckDebtorsGuarantees" :key="guarantees.id">
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.fullname
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.config_country?.country
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.phone_number
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{ guarantees?.email }}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.postal_address
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.postal_address_number
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.postal_region
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.postal_city
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.config_prefecture?.prefecture
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								guarantees?.config_postalcode?.postalcode
							}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%;">
                <tbody>
                  <tr>
                    <th colspan="2" class="table-heading">
                      <p>{{ t('SummaryChecks.orth_plirot_ypografon') }}</p>
                    </th>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%; margin-bottom: 0px; padding: 0px" class="inner-table-basic-note editable">
                <tbody>
                  <tr>
                    <td>
                      <EntityField type="datepicker" :label="t('global.date')" :object="fullObj.plhr_sumb_hmerom"
                         style="padding: 8px; font-weight: bold;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="input" :label="t('SummaryChecks.bank_representative')"
                        :object="fullObj.plhr_sumb_ekpr_trapezas" 
                        style="padding: 8px; font-weight: bold;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="input" :label="t('SummaryChecks.creditor_signatures')"
                        :object="fullObj.plhr_sumb_ypogr_pistouxou" 
                        style="padding: 8px; font-weight: bold;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="input" :label="t('SummaryChecks.signatures_of_guarantors')"
                        :object="fullObj.plhr_sumb_ypogr_egg" 
                        style="padding: 8px; font-weight: bold;" />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%" class="inner-table-basic-note">
                <tbody>
                  <tr>
                    <th>{{ t('SummaryChecks.total_amount') }}</th>
                    <td style="border-top: none">
                      {{ StoreSummaryCheckLoanAgreement?.synol_poso_sumbashs
					}}<span v-if="StoreSummaryCheckLoanAgreement?.synol_poso_sumbashs">{{ currency.symbol }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 20%;">{{ t('SummaryChecks.skopos_ekdosis') }}</th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="input" label="" :object="fullObj.skopos_ekdosis"  />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('LoanAgreementDetails.date_of_agreement') }}</th>
                    <td v-if="StoreSummaryCheckLoanAgreement?.hmer_sumbashs">
                      {{ DateTime.fromISO(StoreSummaryCheckLoanAgreement?.hmer_sumbashs).toFormat("dd/MM/yyyy") }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.tropopoihseis') }}</th>
                    <td>
                      <ul v-if="hmeromhnies_tropopoihshs.length != 0" v-for="hmer_trop in hmeromhnies_tropopoihshs" :key="hmer_trop">
                        <li>{{ DateTime.fromFormat((hmer_trop), 'yyyy-MM-dd').toFormat('dd/MM/yyyy') }} </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.paratasi_diarkeias') }}</th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="input" label="" :object="fullObj.paratasi_diarkeias"
                             />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('LoanAgreementView.legal_status') }}</th>
                    <td v-if="StoreSummaryCheckLoanAgreement?.katast_sumbashs === 'non_performing'">
                      <p>Non Performing</p>
                    </td>
                    <td v-if="StoreSummaryCheckLoanAgreement?.katast_sumbashs === 'performing'">
                      <p>Performing</p>
                    </td>
                    <td v-if="StoreSummaryCheckLoanAgreement?.katast_sumbashs === 'denounced'">
                      <p>Denounced</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- <table style="width: 100%; margin-top: 20px;">
                  <tr>
                    <th colspan="2" class="table-heading">
                      <p>Ομολογίες</p>
                    </th>
                  </tr>
                </table>
                <div style="overflow-y: auto; height: 255px" v-if="StoreSummaryRobBonds">
                  <table style="width: 100%;" class="inner-table-basic-note">
                    <tr v-for="(robBonds, index) in StoreSummaryRobBonds">
                      <td>{{ index }}</td>
                      <td>
                        <p>
                          {{ robBonds.start_bond_number }} ΟΜΟΛΟΓΙΕΣ ΣΤΟΝ ΚΟΜΙΣΤΗ,
                          ΟΝΟΜΑΣΤΙΚΗΣ ΑΞΙΑΣ {{ robBonds.nominal_value
                          }}{{ currency.symbol }}
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="!StoreSummaryRobBonds" style="width: 100%;">
                  <el-row justify="center" style="background-color: #DDDDDD;">
                    <el-col :sm="12" :lg="6">
                      <el-result icon="info" title="Empty" sub-title="This table is empty">

                      </el-result>
                    </el-col>
                  </el-row>
                </div> -->



              <!-- LOAN ACCOUNT DETAILS -->
              <div v-if="StoreSummaryCheckLoanAccount.length != 0" v-for="item in StoreSummaryCheckLoanAccount" :key="item.id">
                <table style="width: 100%; margin-top: 20px;">
                  <tbody>
                    <tr>
                      <th colspan="2" class="table-heading">
                        <p>{{ t('SummaryChecks.account_details') }}: {{ item?.ar_trexontos_logariasmou }}</p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <table style="width: 100%;" class="inner-table-basic-note">
                  <tbody>
                    <tr>
                      <th style="width: 20%;">{{ t('SummaryChecks.imer_kalipsis_ektam') }}</th>
                      <td v-if="item?.hmer_ektam">
                        {{ DateTime.fromISO(item.hmer_ektam).toFormat('dd/MM/yyyy') }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('SummaryChecks.ektam_poso') }}</th>
                      <td>
                        {{ item?.poso_ektam }}<span v-if="item?.poso_ektam">{{ currency.symbol }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('SummaryChecks.product_type') }}</th>
                      <td>
                        {{ item.config_loan_account_product?.product }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('SummaryChecks.loan_account_number') }}</th>
                      <td>
                        {{ item?.ar_trexontos_logariasmou }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('SummaryChecks.repayment') }}</th>
                      <td>
                        <table style="width: 100%" class="inner-table-basic-note">
                          <tbody>
                            <tr>
                              <td>
                                <h6>{{ t('SummaryChecks.capital') }}</h6>
                              </td>
                              <td>
                                <h6>{{ t('SummaryChecks.interests') }}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="inner-table-content">
                                  {{ StoreSummaryCheckLoanAgreement?.program_apopl_kefal }}
                                </span>
                              </td>
                              <td>
                                <span class="inner-table-content">
                                  {{ StoreSummaryCheckLoanAgreement?.program_apopl_tokon }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('SummaryChecks.propliromi') }}</th>
                      <td v-if="StoreSummaryCheckLoanAgreement?.dikaioma_propl == 'false'">
                        {{ t('components.switch.no') }}
                      </td>
                      <td v-else>{{ t('components.switch.yes') }}</td>
                    </tr>
                    <tr>
                      <th>{{ t('LoanAccountDetails.current_balance') }}</th>
                      <td>
                        <table style="width: 100%" class="inner-table-basic-note">
                          <tbody>
                            <tr>
                              <td>
                                <h6>{{ t('SummaryChecks.total_amount') }}</h6>
                              </td>
                              <td>
                                <h6>{{ t('SummaryChecks.capital') }}</h6>
                              </td>
                              <td>
                                <h6>{{ t('LoanAccountDetails.on_balance_sheet_interest') }}</h6>
                              </td>
                              <td>
                                <h6>{{ t('LoanAccountDetails.off_balance_sheet_interest') }}</h6>
                              </td>
                              <td>
                                <h6>{{ t('LoanAccountDetails.commissions_expenses') }}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="inner-table-content">
                                  {{ item?.trexon_upol
								}}<span v-if="item?.trexon_upol">{{ currency.symbol }}</span>
                                </span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{ (item?.enhm_kefal + item?.kathister_kefal) }} <span
                                    v-if="item?.enhm_kefal && item?.kathister_kefal">{{ currency.symbol }}</span></span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									item?.logistikop_tokoi
								}}<span v-if="item?.logistikop_tokoi">{{ currency.symbol }}</span></span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									item?.mh_logistikop_tokoi
								}}<span v-if="item?.mh_logistikop_tokoi">{{ currency.symbol }}</span></span>
                              </td>
                              <td>
                                <span class="inner-table-content">
                                  {{ item?.eksoda }}<span v-if="item?.eksoda">{{ currency.symbol }}</span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('LoanAccountDetails.days_of_delay') }}</th>
                      <td>{{ item?.hmeres_kathister }}</td>
                    </tr>
                    <tr>
                      <th>{{ t('LoanAccountDetails.margin') }}</th>
                      <td>{{ StoreSummaryCheckLoanAgreement.perithorio }}</td>
                    </tr>
                    <tr>
                      <th>{{ t('LoanAccountDetails.base_rate_value') }}</th>
                      <td>
                        <table style="width: 100%" class="inner-table-basic-note">
                          <tbody>
                            <tr>
                              <td>
                                <h6>{{ t('LoanAccountDetails.base_rate') }}</h6>
                              </td>
                              <td>
                                <h6>{{ t('LoanAccountDetails.margin') }}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="inner-table-content">{{ interest.interest }}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">
                                  {{ StoreSummaryCheckLoanAgreement.perithorio }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ t('LoanAccountDetails.overude_rate') }}</th>
                      <td>{{ StoreSummaryCheckLoanAgreement.epitok_uperhm }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table style="width: 100%; margin-top: 20px;">
                <tbody>
                  <tr>
                    <th colspan="2" class="table-heading">
                      <p>{{ t('SummaryChecks.collaterals_control') }}</p>
                    </th>
                  </tr>
                </tbody>
              </table>
              <table style="width: 100%;" class="inner-table-basic-note">
                <tbody>
                  <tr>
                    <th style="width: 20%;">{{ t('SummaryChecks.empragmates') }}</th>
                    <td>
                      <table style="width: 100%" class="inner-table-basic-note">
                        <tbody>
                          <tr>
                            <td>
                              <h6>{{ t('RealEstateDetails.type_of_property') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.address') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('real_estate_collateral.lien') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('real_estate_collateral.prenotation_amount') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.texniki_ektimisi') }}</h6>
                            </td>
                          </tr>
                          <tr v-if="StoreSummaryCheckRealEstateCollaterals.length != 0" v-for="collaterals in StoreSummaryCheckRealEstateCollaterals" :key="collaterals.id">
                            <td>
                              <span class="inner-table-content">
                                {{ collaterals?.real_estate.eidos }}
                              </span>
                            </td>
                            <td>
                              <span class="inner-table-content">
                                <ul style="margin: 0px; list-style: none; padding: 0px">
                                  <li>{{ collaterals.real_estate?.address }} {{ collaterals.real_estate?.address_number
								}}
                                  </li>
                                  <li>{{ collaterals.real_estate?.region }} {{ collaterals.real_estate?.city }}</li>
                                  <li>{{ collaterals.real_estate?.district }} {{ collaterals.real_estate?.postalcode }}
                                  </li>
                                </ul>
                              </span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{
								collaterals?.seira_prosim
							}}</span>
                            </td>
                            <td>
                              <span class="inner-table-content">{{ collaterals?.poso_prosim }}<span
                                  v-if="collaterals?.poso_prosim">{{ currency.symbol }}</span></span>
                            </td>
                            <td>
                              <span class="inner-table-content">
                                {{ collaterals.real_estate?.ektim_aksias_akin
								}}<span v-if="collaterals.real_estate?.ektim_aksias_akin">{{ currency.symbol }}</span>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.enoxikes') }}</th>
                    <td>
                      <table style="width: 100%" class="inner-table-basic-note">
                        <tbody>
                          <tr>
                            <td>
                              <h6>{{ t('SummaryChecks.individual_guarantees') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.legal_entities_guarantees') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.greek_state_guarantees') }}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="inner-table-content">
                                <ul>
                                  <li v-if="StoreSummaryCheckDebtorGuaranteesIndividual.length != 0" v-for="GuaranteesIndividual in StoreSummaryCheckDebtorGuaranteesIndividual" :key="GuaranteesIndividual.id">
                                    {{ GuaranteesIndividual?.fullname }} - AΦΜ:
                                    {{ GuaranteesIndividual?.vat_number }}
                                  </li>
                                </ul>
                              </span>
                            </td>
                            <td>
                              <span class="inner-table-content">
                                <ul>
                                  <li v-if="StoreSummaryCheckDebtorGuaranteesLegalEntity.length != 0" v-for="GuaranteesLegalEntity in StoreSummaryCheckDebtorGuaranteesLegalEntity" :key="GuaranteesLegalEntity.id">
                                    {{ GuaranteesLegalEntity?.fullname }} - AΦΜ:
                                    {{ GuaranteesLegalEntity?.vat_number }}
                                  </li>
                                </ul>
                              </span>
                            </td>
                            <td>
                              <span class="inner-table-content">
                                <ul>
                                  <li v-if="StoreSummaryCheckGreekStateGuarantees.length != 0" v-for="GreekStateG in StoreSummaryCheckGreekStateGuarantees" :key="GreekStateG.id">
                                    {{ GreekStateG?.kua_paroxhs_eed }}
                                  </li>
                                </ul>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%;">
                <tbody>
                  <tr>
                    <th colspan="2" class="table-heading">
                      <p>{{ t('SummaryChecks.ekxoriseis') }}</p>
                    </th>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%; table-layout: fixed" class="inner-table-basic-note editable">
                <tbody>
                  <tr>
                    <td style="border-left: none">
                      <label style="padding: 8px; font-weight: bold;" class="select-label" data-v-e979449a="">{{
						t('SummaryChecks.ekxoriseis')
					}}</label>
                      <p style="padding: 8px;" v-if="EkxorisiApaitiseon === 'YES'">{{
						$t("global.true")
					}}</p>
                      <p style="padding: 8px;" v-else>{{ $t("global.false") }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="input" :label="t('SummaryChecks.elegxos_sumvasis')"
                        :object="fullObj.ekxoriseis_elegxos_sumvasis" 
                        style="padding: 8px; font-weight: bold;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="input" :label="t('SummaryChecks.elegxos_epidosis')"
                        :object="fullObj.ekxoriseis_elegxos_epidosis" 
                        style="padding: 8px; font-weight: bold;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="input" :label="t('SummaryChecks.eispraxi_gegenimenon')"
                        :object="fullObj.ekxoriseis_eispraxi_gegen_apait" style="padding: 8px; font-weight: bold;"
                         />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%; table-layout: fixed" class="inner-table-basic-note">
                <tbody>
                  <tr>
                    <th style="width: 20%;">{{ t('SummaryChecks.lixi_ekx_asf') }}</th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="datepicker" label="" :object="fullObj.lixi_ekxorimenon_asf"
                             />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {{ t('SummaryChecks.ekxorisi_xreogr') }}
                    </th>
                    <td>
                      <table style="width: 100%" class="inner-table-basic-note">
                        <tbody>
                          <tr>
                            <td>
                              <h6>{{ t('SummaryChecks.checks') }}</h6>
                            </td>
                            <td>
                              <h6>{{ t('SummaryChecks.shares') }}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="inner-table-content">
                                {{ Epitages }}
                              </span>
                            </td>
                            <td>
                              <span class="inner-table-content">
                                {{ Metoxes }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%;">
                <tbody>
                  <tr>
                    <th colspan="2" class="table-heading">
                      <p>{{ t('SummaryChecks.promitheies') }}</p>
                    </th>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%" class="inner-table-basic-note editable">
                <tbody>
                  <tr>
                    <td style="width: 20%; font-weight: bold">
                      {{ t('SummaryChecks.idle') }}
                    </td>
                    <td>
                      <EntityField type="input" label="" :object="fullObj.promitheies_adraneias"
                         />
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">
                      {{ t('SummaryChecks.anadoxis') }}
                    </td>
                    <td>
                      <EntityField type="input" label="" :object="fullObj.promitheies_anadoxis"
                         />
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">
                      {{ t('SummaryChecks.diaxeirisis') }}
                    </td>
                    <td>
                      <EntityField type="input" label="" :object="fullObj.promitheies_diaxeirisis"
                         />
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">
                      {{ t('SummaryChecks.tropopoihshs') }}
                    </td>
                    <td>
                      <EntityField type="input" label="" :object="fullObj.promitheies_tropopoihshs"
                         />
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">
                      {{ t('SummaryChecks.waiver') }}
                    </td>
                    <td>
                      <EntityField type="input" label="" :object="fullObj.promitheies_waiver_fee_ekpr_omolog"
                         />
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold">
                      {{ t('SummaryChecks.waiver_gia_kathe') }}
                    </td>
                    <td>
                      <EntityField type="input" label="" :object="fullObj.promitheies_waiver_fee_omolog"
                         />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%" class="inner-table-basic-note">
                <tbody>
                  <tr>
                    <th style="width: 20%">{{ t('SummaryChecks.upoxr_plirof_clauses') }}</th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="input" label="" :object="fullObj.upoxreoseis_plirof_oikon_stoixeia"
                             />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      {{ t('SummaryChecks.gegonota') }}
                    </th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="input" label="" :object="fullObj.gegonota_kataggelias"
                             />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>

                  <tr>
                    <th> {{ t('SummaryChecks.limitation_period') }}
                    </th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="input" label="" :object="fullObj.xronos_paragrafis"
                             />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.ekdosi_diatagis_pliromis') }}</th>

                    <td>
                      <p v-if="((isDenounced === 'YES') && (has_ekdosi_diatagis === 'YES'))">
                      <ul v-if="hmer_ekd_datag_pliromis.length != 0" v-for="diat_plir in hmer_ekd_datag_pliromis" :key="diat_plir">
                        <li>{{ diat_plir }} - {{ DateTime.fromFormat(diat_plir, "yyyy-MM-dd").plus({
							days: 45
						}).toFormat('dd/MM/yyyy')
						}}</li>
                      </ul>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ t('SummaryChecks.paratiriseis') }}</th>
                    <td>
                      <el-row class="display_table editable">
                        <el-col :span="20">
                          <EntityField type="textarea" label="" :object="fullObj.paratiriseis"  />
                        </el-col>
                      </el-row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-form>

        <div v-else>
          <div class="info" style="margin-top: 100px; margin-bottom: 100px">
            <h2>Summary Check</h2>
            <p>Please select a loan agreement.</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Real Estate Summary" name="real_estate_summary">
        <el-table :data="StoreRealEstateSummary" :border="parentBorder" style="width: 100%">
          <el-table-column type="expand">
            <template #default="props">
              <div m="4">
                <table style="width: 100%" class="basic-elements-table summary-check-wrapper">
                  <tbody>
                    <tr>
                      <th colspan="2" class="table-heading">
                        <p>Information</p>
                      </th>
                    </tr>
                    <tr>
                      <th>Debtor ID</th>
                      <td>
                        {{ props.row?.re_collaterals[0]?.loan_agreement?.debtor.cid }}
                      </td>
                    </tr>
                    <tr>
                      <th>Loan ID</th>
                      <td>
                        {{ StoreSummaryCheckLoanAgreement?.ar_sumbashs }}
                      </td>
                    </tr>
                    <tr>
                      <th>Asset Owner</th>
                      <td>
                        {{ props.row?.debtor?.cid }}
                      </td>
                    </tr>
                    <tr>
                      <th>Asset ID</th>
                      <td>
                        {{ props.row?.arithmos_akinitou_diax }}
                      </td>
                    </tr>
                    <tr>
                      <th>Asset Description</th>
                      <td>
                        {{
							$t("re_typeofproperty." + props.row?.eidos)
						}}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('RealEstateDetails.information') }}</th>
                      <td>
                        <table style="width: 100%" class="inner-table-basic-note">
                          <tbody>
                            <tr>
                              <td>
                                <h6>{{ $t('SummaryChecks.address') }}</h6>
                              </td>
                              <td>
                                <h6>{{ $t('RealEstateDetails.number') }}</h6>
                              </td>
                              <td>
                                <h6>{{ $t('RealEstateDetails.region') }}</h6>
                              </td>
                              <td>
                                <h6>{{ $t('RealEstateDetails.city') }}</h6>
                              </td>
                              <td>
                                <h6>{{ $t('RealEstateDetails.prefecture') }}</h6>
                              </td>
                              <td>
                                <h6>{{ $t('RealEstateDetails.zip_code') }}</h6>
                              </td>
                              <td>
                                <h6>{{ $t('RealEstateDetails.country') }}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span class="inner-table-content">{{
									props.row?.address
								}}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									props.row?.address_number
								}}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									props.row?.region
								}}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{ props.row?.city }}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									props.row?.config_prefecture?.prefecture
								}}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									props.row?.config_postalcode?.postalcode
								}}</span>
                              </td>
                              <td>
                                <span class="inner-table-content">{{
									props.row?.config_country?.country
								}}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>Google Maps link</th>
                      <td>
                        {{ props.row?.google_map_link }}
                      </td>
                    </tr>
                    <tr>
                      <th>Buyer's Asset Market Value (REV)</th>
                      <td>{{ props.row?.antikeim_aksia_akin_rev }} <span v-if="props.row?.antikeim_aksia_akin_rev">{{
						currency.symbol }}</span></td>
                    </tr>
                    <tr>
                      <th>Lien Description</th>
                      <td>
                        {{
							$t("TypeofPrenotation." + props.row?.re_collaterals[0]?.eidos_prosim)
						}}
                      </td>
                    </tr>
                    <tr>
                      <th>Rank</th>
                      <td>
                        {{ props.row?.re_collaterals[0]?.seira_prosim }}
                      </td>
                    </tr>
                    <tr>
                      <th>Lien amount</th>
                      <td>
                        {{ props.row?.re_collaterals[0]?.poso_prosim }}
                      </td>
                    </tr>
                    <tr>
                      <th>Ownership type</th>
                      <td>
                        {{ $t("summary_check." + props.row?.eidos_idioktisias) }}
                      </td>
                    </tr>
                    <tr>
                      <th>Ownership %</th>
                      <td>{{ props.row?.percentage_property }} <span v-if="props.row?.percentage_property">%</span></td>
                    </tr>
                    <tr>
                      <th>Beneficiary</th>
                      <td>
                        {{ props.row?.dikaiouxos }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index" label="A/A" :index="indexMethod" />
          <el-table-column label="Ownership Type" prop="eidos_idioktisias" :formatter="getTranslation" />
          <el-table-column label="Address" prop="address" />
          <el-table-column label="Buyer's Asset Market Value (REV)" prop="antikeim_aksia_akin_rev" />
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Checklist" name="checklist" class="summary-check-wrapper">
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p> {{ $t("SummaryChecks.personal_corp_guar") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; height: 100%" v-if="StoreSummaryCheckDebtorsGuarantees.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6> {{ $t("SummaryChecks.guar_name") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.afm") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.gemi") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.tautopoihsh") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.epipleon_nom_egg") }}</h6>
                </td>
              </tr>
              <tr v-if="StoreSummaryCheckDebtorsGuarantees.length != 0" v-for="(guarantees, index) in StoreSummaryCheckDebtorsGuarantees" :key="guarantees.id">
                <td>
                  <span class="inner-table-content">{{ guarantees?.fullname }}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ guarantees?.vat_number }}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ guarantees?.gemh }}</span>
                </td>
                <td>
                  <div class="editable" style="margin-bottom: 0px;">
                    <EntityField type="input" label="" :object="fullObj['ChecklistGuarantees_tautopoihsh_' + index]"
                       id="summary_check_al" style="padding: 30px" />
                  </div>
                </td>
                <td>
                  <div class="editable" style="margin-bottom: 0px;">
                    <EntityField type="input" label=""
                      :object="fullObj['ChecklistGuarantees_epipleonnomeggrafa_' + index]" 
                      style="padding: 30px" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="StoreSummaryCheckDebtorsGuarantees.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.deposits") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; height: 250px" v-if="deposits.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.deposits.beneficiary") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.deposits.pledge_agreement") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.deposits.contractual_document_agreement") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.deposits.bank") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.deposits.iban") }}</h6>
                </td>
              </tr>
              <tr v-for="Collateraldeposits in deposits" :key="Collateraldeposits.id">
                <td>
                  <span class="inner-table-content">{{
					Collateraldeposits.k_dikaiouxos_katath
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					Collateraldeposits.k_sumbash_enex
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					Collateraldeposits.k_sumbatika_eggrafa
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					Collateraldeposits.k_trapeza
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ Collateraldeposits.k_iban }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="deposits.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("SummaryChecks.upothikes_pros") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; height: 100%" v-if="StoreSummaryCheckRealEstateCollaterals.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("SummaryChecks.katoxos_akinitou") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("real_estate_collateral.prenotation_amount") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("RealEstateDetails.re_valuation") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("real_estate_collateral.prenotation_summary") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.pistopoihtika_upoth") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.epipleon_eggrafa") }}</h6>
                </td>
              </tr>
              <tr v-if="StoreSummaryCheckRealEstateCollaterals.length != 0" v-for="(collaterals, index) in StoreSummaryCheckRealEstateCollaterals" :key="collaterals.id">
                <td>
                  <span class="inner-table-content">
                    {{ collaterals.real_estate.debtor.fullname }}
                  </span>
                </td>
                <td>
                  <span class="inner-table-content">
                    {{ collaterals.poso_prosim }} <span v-if="collaterals.poso_prosim">{{ currency.symbol }}</span>
                  </span>
                </td>
                <td>
                  <span class="inner-table-content">{{ collaterals.real_estate.ektim_aksias_akin }}
                    <span v-if="collaterals.real_estate.ektim_aksias_akin">{{ currency.symbol }}</span></span>
                </td>
                <td>
                  <div class="editable">
                    <EntityField type="input" label=""
                      :object="fullObj['ChecklistReCollaterals_perilhps_eggrafhs_prosim_' + index]"
                       />
                  </div>
                </td>
                <td>
                  <div class="editable">
                    <EntityField type="input" label=""
                      :object="fullObj['ChecklistReCollaterals_pistop_ypoth_ktimatol_' + index]"
                       />
                  </div>
                </td>
                <td>
                  <span class="inner-table-content">{{ t('global.' + collaterals.ekxor_asfalist) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="StoreSummaryCheckRealEstateCollaterals.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("SummaryChecks.asfalistiria_sumv") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; height: 250px" v-if="StoreSummaryCheckRealEstateCollaterals.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("SummaryChecks.ekxorisi_asf") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.lixi_asf") }}</h6>
                </td>
              </tr>
              <tr v-if="StoreSummaryCheckRealEstateCollaterals.length != 0" v-for="(CollateralsAsf, index) in StoreSummaryCheckRealEstateCollaterals" :key="CollateralsAsf.id">
                <td>
                  <span class="inner-table-content">{{
					CollateralsAsf.ekxor_asfalist
				}}</span>
                </td>
                <td>
                  <div class="editable">
                    <EntityField type="datepicker" label=""
                      :object="fullObj['ChecklistReCollaterals_lhksh_asfalistiriou_' + index]"  />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="StoreSummaryCheckRealEstateCollaterals.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("SummaryChecks.ekxorisi_apait_tritou") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; height: 250px" v-if="pledged_claims.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("SummaryChecks.sumvasi_enex") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.pledged_claims.evidence_of_the_pledged_claim") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.pledged_claims.notice_of_pledge") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.pledged_claims.servicing_date") }}</h6>
                </td>
              </tr>
              <tr v-for="CollateralPledgedClaims in pledged_claims" :key="CollateralPledgedClaims.id">
                <td>
                  <span class="inner-table-content">{{
					CollateralPledgedClaims.ea_sumbash_enex
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralPledgedClaims.ea_dikaiol_enexur_apait
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralPledgedClaims.ea_gnost_sust_enex
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralPledgedClaims.ea_hmer_epidoshs
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="pledged_claims.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.listed_shares") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-x: auto; overflow-y: auto; height: 250px" v-if="listed_shares.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("ListedSharesView.issuing_stock_company") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("ListedSharesView.stockholder") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("DepositsView.pledge_agreement") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.listed_shares.nbr_of_shares") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.listed_shares.nominal_value") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.listed_shares.declaration_of_intent") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.listed_shares.servicing_date") }}</h6>
                </td>
              </tr>
              <tr v-for="listedShares in listed_shares" :key="listedShares.id">
                <td>
                  <span class="inner-table-content">{{
					listedShares.em_ekd_etair_metox
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ listedShares.em_katox_metox }}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					listedShares.em_sumbash_enexurou
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					listedShares.em_plithos_metoxon
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					listedShares.em_onomastikh_timh
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					listedShares.em_dhlosh_protheshs_metabib
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					listedShares.em_hmer_epidoshs
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="listed_shares.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.unlisted_shares") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-x: auto; overflow-y: auto; height: 250px" v-if="unlisted_shares.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.unlisted_shares.issuing_stock_company") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.unlisted_shares.stockholder") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.unlisted_shares.pledge_agreement") }} </h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.unlisted_shares.nbr_of_shares") }} </h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.unlisted_shares.nominal_value") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.unlisted_shares.total_nominal_value") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("SummaryChecks.episimiosis_met") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("ListedSharesView.servicing_date") }}</h6>
                </td>
              </tr>
              <tr v-for="unlistedShares in unlisted_shares" :key="unlistedShares.id">
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_ekdo_eteria_metox
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_katoxos_metox
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_sumbash_enexurou
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_plithos_metoxon
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_onom_aksia_metox
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_sunol_onomas_aksia_metox
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_epishm_metox_biblio_metox
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					unlistedShares.mem_hmer_epidoshs
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="unlisted_shares.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.warehouse_receipt") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; height: 250px" v-if="warehouse_receipt.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.Warehouse_receipt_(PAEGAE).pledge_agreement") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.Warehouse_receipt_(PAEGAE).servicing_date") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.Warehouse_receipt_(PAEGAE).relevant_act") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.Warehouse_receipt_(PAEGAE).sign_of_titles") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.Warehouse_receipt_(PAEGAE).contractual_document") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.Warehouse_receipt_(PAEGAE).pledge_agreement_of_insurance_policy") }}
                  </h6>
                </td>
              </tr>
              <tr v-for="CollateralWarehouseReceipit in warehouse_receipt" :key="CollateralWarehouseReceipit.id">
                <td>
                  <span class="inner-table-content">{{
					CollateralWarehouseReceipit.tp_sumbash_enex
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralWarehouseReceipit.tp_hmer_epidoshs
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralWarehouseReceipit.tp_sxetikh_praksh
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralWarehouseReceipit.tp_ypogr_titl_paegae
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralWarehouseReceipit.tp_sumbatika_eggrafa
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralWarehouseReceipit.tp_sumbas_enex_apait_
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="warehouse_receipt.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.checks") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; overflow-x: auto; height: 250px" v-if="checks.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.contractual_document") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.checks_table") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.other_documents") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.off_balance_account") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.loan_account") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.commission_account") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.approved_spread_limit_per_issuer") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.legal_issuing_of_check") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.amount_agreement") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.spread_per_issuer") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.existence_of_account_and_debit_balance") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.issuing_date") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.checks.check_details") }}</h6>
                </td>
              </tr>
              <tr v-for="CollateralChecks in checks" :key="CollateralChecks.id">
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_sumbatika_eggrafa
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_pinakio_epitagon
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_sxetika_dikaiol
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_log_takseos
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ CollateralChecks.e_xor_log }}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_log_xre_eks_prom
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_egkekr_orio_diasp_ana_ekdoth
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_nomotipo_ekdos_epitaghs
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_sumf_poson_epitagis
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_diaspora_ana_ekdoth
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_ypar_kartel_xreost_ypol
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_hmer_ekd_epitaghs
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralChecks.e_loipa_stoixeia_epitaghs
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="checks.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.bill_of_exchange") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; overflow-x: auto; height: 250px" v-if="bill_of_exchange.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.commerciality") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.bill_of_exchange_table") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.related_documents") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.signed_request_for_the_receipt") }}
                  </h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.off_balance_account") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.loan_account") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.commission_account") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.spread_limit_per_issuer") }}</h6>
                </td>
                <td>
                  <h6>
                    {{ $t("NRECollateralsDetails.bill_of_exchange.legal_issuing_of_check") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.amount_agreement") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.issue_date_prepei") }}

                  </h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.existence_of_account_and_ebit_balance") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.spread_per_issuer") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.default_credit_behavior") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("NRECollateralsDetails.bill_of_exchange.signed_contract") }}</h6>
                </td>
              </tr>
              <tr v-for="CollateralBillOfExchange in bill_of_exchange" :key="CollateralBillOfExchange.id">
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_eborikotita
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_pinakio_sunal
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_sxetika_dikaol
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_ypo_ait_pa_meta_sunal
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_log_takseos
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_xor_log_sund_takseos
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_log_xre_eks_prom
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_egkek_orio_diasporas_ekd
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_nom_ekd_epit
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_sumf_poson_epit
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_hmer_ekd_epit
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_ypar_kart_xreost_ypol
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_diasp_ana_ekd
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_ypar_dusmenon_stoix
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralBillOfExchange.s_ypog_sumbash
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="bill_of_exchange.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("SummaryChecks.egguhsh_ell_dimosiou") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; overflow-x: auto; height: 250px"
          v-if="StoreSummaryCheckGreekStateGuarantees.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.kya_of_greek_state") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.contractual_document") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.contract_servicing") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.monitoring_of_debt_25th") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.delivery_of_comm_25th") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.claim_limitation_period") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("GreekStateGuarantees.forfeiture_actions") }}</h6>
                </td>
              </tr>
              <tr v-for="GreekStateG in StoreSummaryCheckGreekStateGuarantees" :key="GreekStateG.id">
                <td>
                  <span class="inner-table-content">{{ GreekStateG.kua_paroxhs_eed }}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					$t("global." + GreekStateG.sumbatiko_eggrafo)
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					$t("global." + GreekStateG.epidosh_sumbas_se_dhmos_foreis)
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					GreekStateG.parakolouth_ofilon
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ GreekStateG.apodosh_prom }}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					GreekStateG.prothesmia_paragraf_apet
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{ GreekStateG.energies_katapt }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="StoreSummaryCheckGreekStateGuarantees.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <table style="width: 100%">
          <tbody>
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ $t("nre_collaterals_category.movable_collaterals") }}</p>
              </th>
            </tr>
          </tbody>
        </table>
        <div style="overflow-y: auto; overflow-x: auto; height: 250px" v-if="movable_collaterals.length != 0">
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <h6>{{ $t("MovableCollateralsView.type") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("MovableCollateralsView.property") }}</h6>
                </td>
                <td>
                  <h6>{{ $t("MovableCollateralsView.amount") }}</h6>
                </td>
              </tr>
              <tr v-for="CollateralMovableCollaterals in movable_collaterals" :key="CollateralMovableCollaterals.id">
                <td>
                  <span class="inner-table-content">{{
					CollateralMovableCollaterals.kel_eidos
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralMovableCollaterals.kel_idioktisia
				}}</span>
                </td>
                <td>
                  <span class="inner-table-content">{{
					CollateralMovableCollaterals.kel_poso
				}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="movable_collaterals.length == 0" style="width: 100%;">
          <el-row justify="center" style="background-color: #DDDDDD;">
            <el-col :sm="12" :lg="6">
              <el-result icon="info" title="Empty" sub-title="This table is empty">

              </el-result>
            </el-col>
          </el-row>
        </div>
        <div style="overflow-y: auto; overflow-x: auto; height: 250px">
          <table style="width: 100%">
            <tbody>
              <tr>
                <th colspan="2" class="table-heading">
                  <p>{{ $t("SummaryChecks.epipleon_eggrafa") }}</p>
                </th>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%" class="inner-table-basic-note">
            <tbody>
              <tr>
                <td>
                  <el-row class="display_table editable">
                    <el-col :span="20">
                      <EntityField type="textarea" label="" :object="fullObj.epipleon_eggrafa"  />
                    </el-col>
                  </el-row>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- <el-table :data="movable_collaterals" height="250" style="width: 100%">
        <el-table-column prop="kel_eidos" label="ΕΙΔΟΣ" width="180" />
        <el-table-column prop="kel_idioktisia" label="ΙΔΙΟΚΤΗΣΙΑ" width="180" />
        <el-table-column prop="kel_poso" label="ΠΟΣΟ" />
        <el-table-column prop="kel_eidos" label="ΕΙΔΟΣ" width="180" />
        <el-table-column prop="kel_idioktisia" label="ΙΔΙΟΚΤΗΣΙΑ" width="180" />
        <el-table-column prop="kel_poso" label="ΠΟΣΟ" />
        <el-table-column prop="kel_eidos" label="ΕΙΔΟΣ" width="180" />
        <el-table-column prop="kel_idioktisia" label="ΙΔΙΟΚΤΗΣΙΑ" width="180" />
        <el-table-column prop="kel_poso" label="ΠΟΣΟ" />
        <el-table-column prop="kel_eidos" label="ΕΙΔΟΣ" width="180" />
        <el-table-column prop="kel_idioktisia" label="ΙΔΙΟΚΤΗΣΙΑ" width="180" />
        <el-table-column prop="kel_poso" label="ΠΟΣΟ" />
        <el-table-column prop="kel_eidos" label="ΕΙΔΟΣ" width="180" />
        <el-table-column prop="kel_idioktisia" label="ΙΔΙΟΚΤΗΣΙΑ" width="180" />
        <el-table-column prop="kel_poso" label="ΠΟΣΟ" />
      </el-table> -->
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

#summary_check_al .successbtn_column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.el-tabs__content {
  padding: 0px !important;
}

th,
td {
  padding: 5px;
  text-align: left;
}

.border_table {
  border: 0px !important;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-table__header>thead:nth-child(2)>tr:nth-child(1) th {
  color: red !important;
}
</style>
