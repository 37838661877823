<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "@/store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { PrivilegeResponseDto, } from "@/models";
import { useI18n } from "vue-i18n";
import { Entities } from "@/enums/Entities";
import { GenericEntity } from "@/types/GenericEntity";

const { showError, showLocalizedError } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });

const props = defineProps<{
	screenId: number;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const screen = ref<GenericEntity>({});
const privileges = ref<PrivilegeResponseDto[]>([]);

onMounted(async () => {
	if (props.screenId === 0) return;
	await Promise.all([loadPrivileges(), loadScreen(props.screenId)]);
});

const close = async () => {
	emits("close");
};

const save = async () => {
	const screenId = screen.value?.id;

	if (!screenId || !screen.value.privilege_id)
		return;

	const result = await mosapi.generic_entity_patch(Entities.Screens, screen.value.id, screen.value);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	emits("save");
};

const loadPrivileges = async () => {
	const result = await mosapi.generic_entity_get<Privilege>(Entities.Privileges);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	privileges.value = result.data;
};

const loadScreen = async (screenId: number) => {
	try {
		mainStore.isLoading = true;

		const result = await mosapi.generic_entity_get<Screen[]>(Entities.Screens, { id: screenId });

		if (result.error) {
			throw new Error(t(`errors.${result.error}`));
		}

		screen.value = result.data[0];
	} catch (error) {
		handleError(error, "Failed to load screens");
	} finally {
		mainStore.isLoading = false;
	}
};

const handleError = (error: unknown, defaultMessage: string) => {
	const message = error instanceof Error ? error.message : defaultMessage;
	showError(message);
};
</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="Title">
						<el-input v-model="screen.title" autocomplete="off" />
					</el-form-item>
					<el-form-item label="Privilege">
						<el-select v-model="screen.privilege_id" placeholder="Please Select Privilege">
							<el-option v-for="item in privileges" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
