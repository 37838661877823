<script lang="ts" setup>
import { onMounted, onBeforeUnmount, watch, ref, inject } from "vue";
import { CircleCloseFilled, MapLocation, SuccessFilled, Warning } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import { DateTime } from "luxon";
import { Money3Component } from 'v-money3'
import { useMOSAPIStore } from "@/store/mos_api";
import { Emitter, EventType } from "mitt";
import { Entities } from "@/enums/Entities";
import { orderBy } from "lodash";

const emitter = inject('emitter') as Emitter<Record<EventType, unknown>>;
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const isDate = (item: any) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  return datePattern.test(item);
}

const calc_ergasimes = (hmek: string) => {
  if (hmek === '') return '';
  if (!isDate(hmek as any)) return '';

  let currentErgDate = DateTime.fromFormat(hmek, 'yyyy-MM-dd').plus({ days: 1 })
  let ergasimes = 0;
  while (ergasimes < 15) {
    if (currentErgDate.weekday < 6) {
      let foundArgia = false;
      for (let i = 0; i < offdays.value.length; ++i) {
        const dt = DateTime.fromISO((offdays as any).value[i].hmer_argias).toFormat('yyyy-MM-dd');
        if (dt === currentErgDate.toFormat("yyyy-MM-dd")) {
          foundArgia = true;
          break;
        }
      }
      if (!foundArgia) {
        ergasimes++;
      }
    }

    currentErgDate = currentErgDate.plus({ days: 1 });
  }
  currentErgDate = currentErgDate.minus({ days: 1 });
  return currentErgDate.toFormat('dd-MM-yyyy');
}

const props = defineProps<{
  object: any;
  label: string;
  type?: string;
  options?: any[];
  validationRules?: any[]; // each object is an array with two elements => ["^[0-9]$", "validation.only_numbers" ]
  isDisabled?: boolean;
  denounced?: boolean;
  isLink?: boolean;
}>();

const hasValidationError = ref(false);
const displayedErrorTemplate = ref("");
const offdays = ref([]);

const moneyConfig = ref({
  masked: false,
  prefix: '',
  suffix: ' €',
  thousands: '.',
  decimal: ',',
  precision: 2,
  disableNegative: true,
  disabled: false,
  min: 0,
  max: 2000000000,
  allowBlank: false,
  minimumNumberOfCharacters: 1,
})

const moneyConfigRight = ref({
  masked: false,
  prefix: '',
  suffix: ' €',
  thousands: '.',
  decimal: ',',
  precision: 2,
  disableNegative: true,
  disabled: true,
  min: 0,
  max: 2000000000,
  allowBlank: false,
  minimumNumberOfCharacters: 1,
})

// type:
//    input
//    select
//    datepicker
//    switch
//    jsonbdiatagipliromis
//    json_additional_arrangement
//    json_hmer_poso_ofeilhs
//    textarea
//    money

const emits = defineEmits(["decision", "validationChange", "selectChange", "clear"]);

watch(
  () => props?.isDisabled,
  (newValue) => {
    moneyConfig.value.disabled = newValue;
  }
);

watch(
  () => props.object,
  async (newVal) => {
    if (newVal === null || newVal === undefined) return;

    if (props?.type === 'switch' && props?.object?.pendingValue !== undefined && props?.object?.pendingValue !== null) {
      props.object.pendingValue.value = (props.object.pendingValue.value === 'true');
    }

    // console.log(
    //   "full object old: " + JSON.stringify(oldVal) + ", New: " + JSON.stringify(newVal)
    // );
  }
);
// watch(
//   () => props.isDisabled,
//   async () => {
//     console.log('isdisabled');
//     console.log(props.isDisabled)
//   }
// );
watch(
  () => props.object?.originalValue,
  (newValue, oldValue) => {
    // console.log(JSON.stringify(props.object));
    // console.log("old val", oldValue);

    // if (props.object?.originalValue === null) {
    //   props.object.originalValue = "";
    // }
    // console.log('original value changed from', oldValue, 'to', newValue);
    doValidation();
  }
);

const doValidation = () => {
  if (props.validationRules === undefined || props?.object?.originalValue === undefined)
    return;

  if (props.object.originalValue === null)
    props.object.originalValue = "";

  for (const rule of props.validationRules as any[]) {
    const re = new RegExp(rule[0]);
    // console.log("checking rule", rule[0], props.object.originalValue);
    if (!re.test(props.object.originalValue)) {
      displayedErrorTemplate.value = rule[1];
      hasValidationError.value = true;
      emits("validationChange", props.object.field, true);
      return;
    }
  }
  emits("validationChange", props.object.field, false);
  hasValidationError.value = false;
};

const updateDecision = async (accepted: boolean) => {
  //console.log(`${accepted ? 'Accept' : 'Reject'} change - ${JSON.stringify(props.object)}`);
  mainStore.isLoading = true;
  // console.log(JSON.stringify({
  //   accepted,
  //   db_change_id: props.object!.pendingValue.id,
  //   field: props.object!.field,
  //   value: props.object!.pendingValue.value,
  //   table_name: props.object!.pendingValue.table_name,
  // }));
  if(props.object!.field === 'liquidation_value'){props.object!.pendingValue.value = props.object!.pendingValue.value.toString();}
  await mosapi.updateDecision({
    accepted,
    db_change_id: props.object!.pendingValue.id,
    field: props.object!.field,
    value: props.object!.pendingValue.value,
    table_name: props.object!.pendingValue.table_name,
  });

  emitter.emit('decision', accepted);
};

onMounted(async () => {
  doValidation();
  //console.log(props.isDisabled);
  //console.log(props.object);
  // console.log(props.object.pendingValue);
  // console.log(JSON.parse(props.object.pendingValue.value));
  if (props.type === 'jsonbdiatagipliromis') {
//    offdays.value = (await .get('config_argies?orderFIX=hmer_argias.asc')).data;
    const result = await mosapi.generic_entity_get(Entities.ConfigArgies, {orderBy:'hmer_argias.asc'});
    offdays.value = result.data;
    console.log('helllllo' + JSON.stringify(offdays.value));

  }
});

onBeforeUnmount(async () => {
  if (props.object !== undefined) emits("validationChange", props.object.field, false);
  //console.log(props.isDisabled);
  //console.log(props.object);
  // console.log(props.object.pendingValue);
  // console.log(JSON.parse(props.object.pendingValue.value));
})

const selectChanged = (val: any) => {
  emits("selectChange", val);
}

const clear = () => {
  emits("clear");
}

const clickLink = (href: string) => {
  window.open(href, '_blank');
}

</script>

<template>
  <div v-if="props.object !== undefined && props.object !== null">
    <label class="select-label">
      {{ props.label }}

      <span v-if="hasValidationError" class="error">
        <el-icon :size="10">
          <Warning />
        </el-icon>
        {{ $t(displayedErrorTemplate) }}
      </span></label>
    <el-row :gutter="10">
      <!-- -->
      <!-- Left column (original value) -->
      <!-- -->

      <el-col :span="11">
        <el-form-item v-if="props.type === 'datepicker'">
          <el-date-picker value-format="YYYY-MM-DD" v-model="props.object.originalValue" type="date" style="width: 100%"
            :disabled="(props.object.pendingValue !== undefined &&
              mainStore.loggedUser.username !==
              props.object.pendingValue.editor.username) ||
              props.denounced === true || props?.isDisabled
              " format="DD-MM-YYYY" />
        </el-form-item>
        <el-form-item v-else-if="props.type === 'select'">
          <el-select v-if="props.object.pendingValue === undefined ||
            mainStore.loggedUser.username === props.object.pendingValue.editor.username
          " v-model="props.object.originalValue" :value="props.object.originalValue" :disabled="(props.denounced &&
            props.object.field !== 'katast_sumbashs' &&
            mainStore.loggedUser.user_type == 0) ||
            (props.denounced && mainStore.loggedUser.user_type > 0) || props?.isDisabled
            " filterable @change="selectChanged((props.object as any).originalValue)" placeholder=" " clearable
            @clear="clear">
            <el-option v-for="item in props.options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-input v-else disabled v-model="props.object.pendingValue.originalDisplayValue" autocomplete="off" />
        </el-form-item>
        <el-form-item v-else-if="props.type === 'switch'">
          <el-switch v-model="props.object.originalValue" :disabled="(props.object.pendingValue !== undefined &&
            mainStore.loggedUser.username !== props.object?.pendingValue?.editor?.username) || props?.isDisabled
            " :active-text="$t('components.switch.yes')" :inactive-text="$t('components.switch.no')" />
        </el-form-item>
        <div v-else-if="props.type === 'jsonbdiatagipliromis'" style="border-right: solid 1px #98a4cb; padding: 10px">
          <el-form-item v-for="(row, index) in props.object.originalValue" style="padding: 10px">
            <el-row>
              <h3 style="padding: 10px">Payment Order #{{ index + 1 }}</h3>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.issue_date') + ' ' + (index + 1)"
                  style="margin-top: 0px !important;">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmek" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="(props.object.pendingValue !== undefined &&
                      mainStore.loggedUser.username !==
                      props.object.pendingValue.editor.username) || true
                      " />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.poso_diatagis_plhr')"
                  style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.poso_diatag_plhr" label="Έξοδα επίδοσης" :disabled="((props.object.pendingValue !== undefined ||
                    props?.isDisabled === true) &&
                    mainStore.loggedUser.username !==
                    props.object.pendingValue.editor.username) || true
                    " />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.first_serving_date')"
                  style="margin-top: 0px !important;">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmep" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="(props.object.pendingValue !== undefined &&
                      mainStore.loggedUser.username !==
                      props.object.pendingValue.editor.username) || true
                      " />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.first_serving_fees')"
                  style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.exoda" label="Έξοδα επίδοσης" :disabled="((props.object.pendingValue !== undefined ||
                    props?.isDisabled === true) &&
                    mainStore.loggedUser.username !==
                    props.object.pendingValue.editor.username) || true
                    " />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.second_serving_date')"
                  style="margin-top: 0px !important;">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.deut_hmep" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="(props.object.pendingValue !== undefined &&
                      mainStore.loggedUser.username !==
                      props.object.pendingValue.editor.username) || true
                      " />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.first_serving_fees')"
                  style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.deut_exoda" label="2nd Serving Fees" :disabled="((props.object.pendingValue !== undefined ||
                    props?.isDisabled === true) &&
                    mainStore.loggedUser.username !==
                    props.object.pendingValue.editor.username) || true
                    " />
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item style="margin-top: 0px !important;">
                  <label class="select-label"><span>{{ $t('LoanAgreementDetails.aitisi_anastolis') }}</span></label>
                  <el-switch v-model="row.aithsh_anastolhs" :active-text="$t('components.switch.yes')"
                    :inactive-text="$t('components.switch.no')" />
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item style="margin-top: 0px !important;">
                  <label class="select-label"><span>Προσωρινή διαταγή για μη εκτέλεση της διαταγής
                      πληρωμής</span></label>
                  <el-switch v-model="row.pros_diatg_ektel_plhr" :active-text="$t('components.switch.yes')"
                    :inactive-text="$t('components.switch.no')" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Τελεσίδικη απόφαση " style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.telesidikh_apof" label="Τελεσίδικη απόφαση" :disabled="((props.object.pendingValue !== undefined ||
                    props?.isDisabled === true) &&
                    mainStore.loggedUser.username !==
                    props.object.pendingValue.editor.username) || true
                    " />
                </el-form-item>
              </el-col>

              <el-col :span="24">
test
                <h4 style="padding: 0 12px 0 12px;"> {{ $t("LoanAgreementDetails.process_interruption_margin") + ' 1'
                }}: <span>{{ calc_ergasimes(row.hmep) }}</span></h4>
                <h4 style="padding: 0 12px 0 12px;"> {{ $t("LoanAgreementDetails.process_interruption_margin") + ' 2'
                }}: <span>{{ calc_ergasimes(row.deut_hmep) }}</span></h4>
                <h4 style="padding: 0 12px 0 12px;"> {{ $t("LoanAgreementDetails.suspension_request_margin") }}:
                  <span>{{ calc_ergasimes(row.hmep) }}</span>
                </h4>

              </el-col>

            </el-row>
          </el-form-item>
        </div>
        <div v-else-if="props.type === 'json_additional_arrangement'" style="border-right: solid 1px #98a4cb">
          <p style="text-align: center; font-weight: bold">{{ $t('global.saved_dates') }}</p>
          <div v-for="(row, index) in props.object.originalValue" style="width: 100% !important; padding: 10px">
            <el-row style="width: 100% !important" v-if="row.hmer_tropop">
              <el-col :span="24">
                <el-row :gutter="10">
                  <el-col :span="15">
                    <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmer_tropop" type="date"
                      style="width: 100% !important" format="DD-MM-YYYY" :disabled="(props.object.pendingValue !== undefined &&
                        mainStore.loggedUser.username !==
                        props.object.pendingValue.editor.username) || true
                        " />
                  </el-col>
                  <!-- <el-col :span="9">
                      <el-button class="mt-2" @click.prevent="props.object.originalValue.splice(index, 1)">{{$t('generic.delete')}}
                      </el-button>
                    </el-col> -->
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else-if="props.type === 'json_hmer_poso_ofeilhs'" style="border-right: solid 1px #98a4cb">
          <p style="text-align: center; font-weight: bold">{{ $t('global.saved_dates') }}</p>
          <div v-for="(row, index) in props.object.originalValue" style="width: 100% !important; padding: 10px">
            <el-row style="width: 100% !important" v-if="row.hmeres">
              <el-col :span="24">
                <el-row :gutter="10">
                  <el-col :span="15">
                    <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmeres" type="date"
                      style="width: 100% !important" format="DD-MM-YYYY" :disabled="(props.object.pendingValue !== undefined &&
                        mainStore.loggedUser.username !==
                        props.object.pendingValue.editor.username) || true
                        " />
                  </el-col>
                  <el-col :span="15">
                    <el-input v-model="row.poso" label="Ποσό" :disabled="((props.object.pendingValue !== undefined ||
                      props?.isDisabled === true) &&
                      mainStore.loggedUser.username !==
                      props.object.pendingValue.editor.username) || true
                      " />
                  </el-col>
                  <!-- <el-col :span="3">
                        <el-button class="mt-2" @click.prevent="props.object.originalValue.splice(index, 1)">{{$t('generic.delete')}}
                        </el-button>
                      </el-col> -->
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-form-item v-else-if="props.type === 'textarea'">
          <el-input type="textarea" autosize :disabled="((props.object?.pendingValue !== undefined) &&
            mainStore.loggedUser.username !==
            props.object.pendingValue.editor.username) ||
            props.denounced === true || props?.isDisabled === true
            " v-model="props.object.originalValue" autocomplete="off" />
        </el-form-item>
        <el-form-item v-else-if="props.type === 'money'">
          <Money3Component v-if="props.object.originalValue !== undefined" class="el-input__inner"
            v-model.number="props.object.originalValue" v-bind="moneyConfig"></Money3Component>
        </el-form-item>
        <el-form-item v-else>
          <el-icon v-if="isLink && props.object.originalValue !== null && props.object.originalValue !== ''"
            @click="clickLink(props.object.originalValue)" :size="20"
            style="color: white !important; background-color: #4E9D4E; border: 0px; padding: 2px; margin-left: 12px; font-size: 16px;">
            <MapLocation />
          </el-icon>

          <el-input
            v-if="props.object.pendingValue !== undefined && props.object.pendingValue.originalDisplayValue !== undefined"
            :disabled="mainStore.loggedUser.username !== props.object.pendingValue.editor.username || props.denounced === true"
            v-model="props.object.pendingValue.originalDisplayValue" autocomplete="off" />
          <el-input v-else :disabled="((props.object.pendingValue !== undefined || props?.isDisabled === true) &&
            mainStore.loggedUser.username !==
            props.object.pendingValue?.editor?.username) ||
            props.denounced === true ||
            props?.isDisabled === true
            " v-model="props.object.originalValue" autocomplete="off" />
        </el-form-item>
      </el-col>
      <!-- -->
      <!-- Right column (pending value) -->
      <!-- -->
      <el-col :span="11" v-if="props.object.pendingValue !== undefined">
        <el-form-item v-if="props.type === 'datepicker'">
          <el-date-picker value-format="YYYY-MM-DD" v-model="props.object.pendingValue.value" type="date"
            style="width: 100%" :disabled="props.object.pendingValue !== undefined" format="DD-MM-YYYY" />
        </el-form-item>
        <el-form-item v-else-if="props.type === 'switch'">
          <el-switch v-model="(props.object.pendingValue.value as boolean)" disabled
            :active-text="$t('components.switch.yes')" :inactive-text="$t('components.switch.no')" />
        </el-form-item>
        <div v-else-if="props.type === 'jsonbdiatagipliromis'" style="padding: 10px">
          <el-form-item v-for="(row, index) in JSON.parse(props.object.pendingValue.value)" style="padding: 10px">
            <el-row>
              <el-col :span="24">
                <h3 style="padding: 10px">Payment Order #{{ index + 1 }}</h3>
                <el-form-item :label="$t('LoanAgreementDetails.issue_date') + ' ' + (index + 1)"
                  style="margin-top: 0px !important;">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmek" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="props.object.pendingValue !== undefined">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.poso_diatagis_plhr')"
                  style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.poso_diatag_plhr" :label="$t('LoanAgreementDetails.poso_diatagis_plhr')"
                    disabled>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.first_serving_date')"
                  style="margin-top: 0px !important;">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmep" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="props.object.pendingValue !== undefined" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.first_serving_fees')"
                  style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.exoda" label="Έξοδα επίδοσης" disabled>
                  </el-input>
                </el-form-item>
                <!-- {{ $t("LoanAgreementDetails.process_interruption_margin") }}:
                <span v-if="row.hmek">{{
                  calc_ergasimes(row.hmek)
                }}
                  <el-button class="mt-2" style="background-color: transparent; border: none; cursor: default">
                  </el-button>
                </span> -->
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.second_serving_date')"
                  style="margin-top: 0px !important;">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.deut_hmep" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="props.object.pendingValue !== undefined" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAgreementDetails.first_serving_fees')"
                  style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.deut_exoda" label="2nd Serving Fees" disabled>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item style="margin-top: 0px !important;">
                  <label class="select-label"><span>{{ $t('LoanAgreementDetails.aitisi_anastolis') }}</span></label>
                  <el-switch v-model="row.aithsh_anastolhs" :active-text="$t('components.switch.yes')"
                    :inactive-text="$t('components.switch.no')" />
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item style="margin-top: 0px !important;">
                  <label class="select-label"><span>Προσωρινή διαταγή για μη εκτέλεση της διαταγής
                      πληρωμής</span></label>
                  <el-switch v-model="row.pros_diatg_ektel_plhr" :active-text="$t('components.switch.yes')"
                    :inactive-text="$t('components.switch.no')" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Τελεσίδικη απόφαση" style="margin-bottom: 18px; margin-top: 0px !important;">
                  <el-input v-model="row.telesidikh_apof" label="Τελεσίδικη απόφαση" disabled>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">

                <h4 style="padding: 0 12px 0 12px;"> {{ $t("LoanAgreementDetails.process_interruption_margin") + ' 1'
                }}: <span>{{ calc_ergasimes(row.hmep) }}</span></h4>
                <h4 style="padding: 0 12px 0 12px;"> {{ $t("LoanAgreementDetails.process_interruption_margin") + ' 2'
                }}: <span>{{ calc_ergasimes(row.deut_hmep) }}</span></h4>
                <h4 style="padding: 0 12px 0 12px;"> {{ $t("LoanAgreementDetails.suspension_request_margin") }}:
                  <span>{{ calc_ergasimes(row.hmep) }}</span>
                </h4>

              </el-col>

            </el-row>
          </el-form-item>
        </div>
        <div v-else-if="props.type === 'json_additional_arrangement'">
          <p style="text-align: center; font-weight: bold">Pending Approvals</p>

          <el-form-item v-for="(row, index) in JSON.parse(props.object.pendingValue.value)"
            style="width: 100% !important;">
            <el-row style="width: 100% !important">
              <el-col :span="24">
                <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmer_tropop" style="width: 100% !important"
                  type="date" format="DD-MM-YYYY" :disabled="props.object.pendingValue !== undefined" />
              </el-col>
            </el-row>
          </el-form-item>
        </div>
        <div v-else-if="props.type === 'json_hmer_poso_ofeilhs'" style="padding: 10px">
          <el-form-item v-for="(row, index) in JSON.parse(props.object.pendingValue.value)" style="padding: 10px">
            <el-row>

              <el-col :span="24">
                <el-form-item :label="$t('LoanAccountDetails.date_of_principal_payment')">
                  <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmeres" type="date" style="width: 100%"
                    format="DD-MM-YYYY" :disabled="props.object.pendingValue !== undefined" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('LoanAccountDetails.amount_of_principal_payment')" style="margin-bottom: 18px">
                  <el-input v-model="row.poso" label="Ποσό" disabled>
                  </el-input>
                </el-form-item>

              </el-col>
            </el-row>
          </el-form-item>
        </div>
        <!--

    <div v-else-if="props.type === 'json_hmer_poso_ofeilhs'" style="border-right: solid 1px #98a4cb">
              <div v-for="(row, index) in props.object.originalValue" style="width: 100% !important; padding: 10px">
                <el-row style="width: 100% !important" v-if="row.hmeres">
                  <el-col :span="24">
                    <el-row :gutter="10">
                      <el-col :span="15">
                        <el-date-picker value-format="YYYY-MM-DD" v-model="row.hmeres" type="date"
                          style="width: 100% !important" format="DD-MM-YYYY" :disabled="
                            props.object.pendingValue !== undefined &&
                            mainStore.loggedUser.username !==
                            props.object.pendingValue.editor.username
                          " />
                      </el-col>
                      <el-col :span="15">
                        <el-input v-model="row.poso" label="Ποσό" :disabled="
                          (props.object.pendingValue !== undefined ||
                            props.isDisabled === true) &&
                          mainStore.loggedUser.username !==
                          props.object.pendingValue.editor.username
                        " />
                      </el-col>
                      <el-col :span="3">
                        <el-button class="mt-2" @click.prevent="props.object.originalValue.splice(index, 1)">{{$t('generic.delete')}}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </div>

            -->
        <el-form-item v-else-if="props.type === 'textarea'">
          <el-input type="textarea" autosize v-if="props.object.pendingValue !== undefined &&
            props.object.pendingValue.pendingDisplayValue !== undefined
          " disabled v-model="props.object.pendingValue.pendingDisplayValue" autocomplete="off" />
          <el-input type="textarea" autosize v-else :disabled="props.object.pendingValue !== undefined"
            v-model="props.object.pendingValue.value" autocomplete="off" />
        </el-form-item>
        <el-form-item v-else-if="props.type === 'money'">
          <Money3Component v-if="props.object.pendingValue !== undefined" class="el-input__inner"
            v-model.number="props.object.pendingValue.value" v-bind="moneyConfigRight"></Money3Component>
        </el-form-item>
        <el-form-item v-else>
          <el-icon v-if="isLink && props.object.pendingValue !== null && props.object.pendingValue !== undefined"
            @click="clickLink(props.object.pendingValue.value)" :size="20"
            style="color: white !important; background-color: #4E9D4E; border: 0px; padding: 2px; margin-left: 12px; font-size: 16px;">
            <MapLocation />
          </el-icon>

          <el-input v-if="props.object.pendingValue !== undefined &&
            props.object.pendingValue.pendingDisplayValue !== undefined
          " disabled v-model="props.object.pendingValue.pendingDisplayValue" autocomplete="off" />
          <el-input v-else :disabled="props.object.pendingValue !== undefined" v-model="props.object.pendingValue.value"
            autocomplete="off" />
        </el-form-item>
      </el-col>
      <el-col :span="1" v-if="props.object.pendingValue !== undefined && mainStore.loggedUser.user_type < 2
      " class="successbtn_column" style="display: flex; justify-content: center; align-items: center">
        <el-tooltip class="box-item" effect="dark" :content="'Accept proposed change by ' +
          props.object.pendingValue.editor.username +
          ', done on ' +
          DateTime.fromISO(props.object.pendingValue.insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
          " placement="bottom">
          <el-icon :size="24" class="successbtn" color="green" @click="updateDecision(true)" style="float: right">
            <SuccessFilled />
          </el-icon>
        </el-tooltip>
      </el-col>
      <el-col :span="1" v-if="props.object.pendingValue !== undefined && mainStore.loggedUser.user_type < 2
      " class="closebtn_column" style="display: flex; justify-content: center; align-items: center">
        <el-tooltip class="box-item" effect="dark" content="Reject change" placement="bottom">
          <el-icon :size="24" class="closebtn" color="red" @click="updateDecision(false)">
            <CircleCloseFilled />
          </el-icon>
        </el-tooltip>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.error {
  color: orange;
}

/* .el-form-item{
  margin-top: 0px !important;
} */
</style>
