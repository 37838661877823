<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "@/store/main";
import { useI18n } from "vue-i18n";
import ProjectTemplate from "../classes/DB_Entities/ProjectTemplates";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "@/store/mos_api";
import UserType from "../enums/UserType";
import APIReply from "@/classes/APIReply";
const props = defineProps<{
  projecttemplateid: number;
  newEntity?: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const data = ref<ProjectTemplate>(new ProjectTemplate());
const tasksList = ref<any>([]);
const usersList = ref<any>([]);

const {
  showError,
  close,
} = useGenericMethodsVariables();

onMounted(async () => {
  mainStore.isLoading = true;
  console.log("projecttemplateid", props.projecttemplateid, "newEntity", props.newEntity);

  await getUsers();
  await getTasks();

  if (props.newEntity === true) {
    mainStore.isLoading = false;
    return;
  }

  await loadData();
  mainStore.isLoading = false;
});

const onPreSave = async () => {
  //   console.log(JSON.stringify(fullObj.value));
  //VALIDATION CHECKS
  await save();
};

const getTasks = async () => {
  //result = await .get("task_templates?status=eq.1");
  const result = await mosapi.generic_entity_get(Entities.TaskTemplates, {status: 1});
  if (result.error) {
    showError(result.error);

    return;
  }

  tasksList.value = result.data;
  tasksList.value = tasksList.value.map((item: any) => {
    return {
      key: item.id,
      label: item.name_en,
      disabled: false
    }
  });
};

const getUsers = async () => {
  //result = await .get("users?select=id,username,first_name,last_name&active=eq.true&user_type=gt.1&order=username.asc");
  const result = await mosapi.users_get_all_active(UserType.Supervisor, false) as APIReply<{ id: number, username: string, first_name: string, last_name:string }[]>;
  if (result.error) {
    showError(result.error);

    return;
  }

  usersList.value = result.data;
  usersList.value = usersList.value.map((item: any) => {
    return {
      key: item.id,
      label: `${item.username} (${item.first_name} ${item.last_name})`,
      disabled: false
    }
  });
}

const loadData = async () => {
  mainStore.isLoading = true;

  //result = await .get("project_templates?id=eq." + props.projecttemplateid);
  const result = await mosapi.generic_entity_get(Entities.ProjectTemplates, {id:props.projecttemplateid});

  if (result.error) {
    showError(result.error);
    return;
  }

  data.value = result.data[0];

  mainStore.isLoading = false;
};

const save = async () => {
  if (props.newEntity) {
    //console.log('posting', JSON.stringify(data.value));
    const obj = JSON.parse(JSON.stringify(data.value));
    delete obj.id;
    //await .post_data("project_templates", obj);
    await mosapi.generic_entity_post(Entities.ProjectTemplates, obj);
  } else {
    //console.log('patching', JSON.stringify(data.value));
    // await .patch("project_templates?id=eq." + props.projecttemplateid, {
    //   name_el: data.value.name_el,
    //   name_en: data.value.name_en,
    //   task_templates_ids: data.value.task_templates_ids,
    //   user_ids: data.value.user_ids
    // });

    await mosapi.generic_entity_patch(Entities.ProjectTemplates, props.projecttemplateid, {
      name_el: data.value.name_el,
      name_en: data.value.name_en,
      task_templates_ids: data.value.task_templates_ids,
      user_ids: data.value.user_ids
    })

  }
  emits("save");
}

const filterMethod = (query: string, item: any) => {
  return item.label.toLowerCase().includes(query.toLowerCase())
}

</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('ProjectsTemplatesList.name_el')">
            <el-input v-model="data.name_el" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('ProjectsTemplatesList.name_en')">
            <el-input v-model="data.name_en" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item style="margin-top: 0px">
            <el-transfer v-model="data.task_templates_ids" filterable :filter-method="filterMethod"
              filter-placeholder="Tasks" :data="tasksList" target-order="push"
              :titles="['Available Tasks', 'Selected Tasks']" class="myCLASS" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item style="margin-top: 0px">
            <el-transfer v-model="data.user_ids" filterable :filter-method="filterMethod" filter-placeholder="Users"
              :data="usersList" target-order="push" :titles="['Available Users', 'Selected Users']" class="myCLASS" />
          </el-form-item>
        </el-col>

        <p></p><br><br><br><br><br>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {
  margin: 0px !important;
}

.el-transfer {
  --el-transfer-panel-width: 400px;
}
</style>
