import ITableName from "../../interfaces/ITableName";

class ConfigProduct implements ITableName {
  id: number = -1;
  product: string = "";
  type: string = "";
  greek_trans: string = "";
  english_trans: string = "";
  omologiako: boolean = false;
  koinopraktiko: boolean = false;

  static getTableNameStatic(): string {
    return "config_product";
  }

  getTableName(): string {
    return ConfigProduct.getTableNameStatic();
  }
}

export default ConfigProduct;
