<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import Interest_Rates from "../classes/DB_Entities/InterestRates";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
const emits = defineEmits(["save", "close", "fail-save"]);
const mainStore = useMainStore();
const data = ref(new Interest_Rates());

const props = defineProps<{
  interestRowID: number;
  interestCategory: number;
}>();

const close = async () => {
  emits("close");
};

onMounted(async () => {
  await loadInterestRate(props.interestRowID);
});

watch(
  () => props.interestRowID,
  async () => {
    await loadInterestRate(props.interestRowID as number);
  }
);

const loadInterestRate = async (row_id: number) => {
  mainStore.isLoading = true;
  //let result = await mosapi.getInterestRateById(mainStore.loggedUser.id, row_id);
  const result = await mosapi.generic_entity_get<Interest_Rates[]>(Entities.InterestRates, { id: row_id });
  console.log(result);
  if (result.error) {
    showError(result.error);
    return;
  }
  data.value = result.data[0];
  mainStore.isLoading = false;
};

const save = async () => {
  const interest_id = data.value.id as number;
  //let result = await mosapi.PatchInterestRate(mainStore.loggedUser.id, props.interestRowID, obj);

  // if ('config_interest' in data.value) {
  //   delete data.value.config_interest;
  // }

  const result = await mosapi.generic_entity_patch(Entities.InterestRates, interest_id, data.value);

  if (result.error) {
    showError(result.error);
    return;
  }

  emits("save");
};
</script>

<template>
  <div>
    <el-form :model="data" v-loading="mainStore.isLoading">
      <el-form-item :label="$t('PlatformSettingsView.interest_rate')">
        <el-input v-model="data.interest_value" autocomplete="off" type="number"
          style="width: 100%; border-bottom: 3px solid #e3e3e3" />
      </el-form-item>
      <el-form-item :label="$t('PlatformSettingsView.interest_value_date')">
        <el-date-picker format="DD-MM-YYYY" value-format="YYYY-MM-DD" v-model="data.interest_value_date" type="date"
          placeholder="Pick a date" style="width: 100%; border-bottom: 3px solid #e3e3e3" />
      </el-form-item>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
