import ITableName from "../../interfaces/ITableName";
import LoanAgreement from "./LoanAgreement";

class GreekStateGuarantees implements ITableName {
  id: number = -1;
  loan_agreement_id: string = "";
  kua_paroxhs_eed: string = "";
  poso_eggyhseos: string = "";
  sumbatiko_eggrafo: boolean = false;
  epidosh_sumbas_se_dhmos_foreis: boolean = false;
  parakolouth_ofilon: string = "";
  apodosh_prom: string = "";
  prothesmia_paragraf_apet: string = "";
  energies_katapt: string = "";
  approve_status:string = "";
  lockedrow: boolean = false;

  loan_agreement?: LoanAgreement= undefined;

  static getTableNameStatic(): string {
    return "greek_state_guarantees";
  }

  getTableName(): string {
    return GreekStateGuarantees.getTableNameStatic();
  }
}

export default GreekStateGuarantees;
