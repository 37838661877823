<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import TaskTemplate from "../classes/DB_Entities/TaskTemplates";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import CustomField from "../classes/DB_Entities/CustomField";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "@/store/mos_api";

const props = defineProps<{
	tasktemplateid: number;
	newEntity?: boolean;
}>();
const mosapi = useMOSAPIStore();
interface Option {
	key: number
	label: string
	disabled: boolean
	type: string
}

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const { t } = useI18n({ useScope: "global" });
const data = ref<TaskTemplate>(new TaskTemplate());
const fieldsList = ref<any>([]);

const {
	showError,
	close,
} = useGenericMethodsVariables();

const getCustomFields = async () => {
	const fv: Option[] = []
	//const fields = await .get("custom_fields?status=eq.1");
	const fields = await mosapi.generic_entity_get(Entities.CustomFields, {status: 1});
	const fieldsData = fields.data;

	fieldsData.forEach((fieldRow: CustomField, index: number) => {
		fv.push({
			label: `${fieldRow.name_en} (${fieldRow.type}) (${fieldRow.id})`,
			key: fieldRow.id,
			disabled: false,
			type: fieldRow.type,
		})
	});

	// console.log('zzz', JSON.stringify(fv));

	return fv;
}

onMounted(async () => {
	console.log("tasktemplateid", props.tasktemplateid, "newEntity", props.newEntity);

	fieldsList.value = await getCustomFields();
	// console.log(JSON.stringify(fieldsList.value));

	if (props.newEntity === true) {
		return;
	}

	mainStore.isLoading = true;
	await loadData();
	mainStore.isLoading = false;
});

const onPreSave = async () => {
	let statusListFound = false;
	console.log('data.value.fields_ids', JSON.stringify(data.value.fields_ids));

	for (const field_id of data.value.fields_ids) {
		console.log('checking field_id', field_id)
		const field = fieldsList.value.find((item: any) => item.key === field_id);
		if (field.type === 'status_list') {
			statusListFound = true;
			break;
		}
	}

	if (!statusListFound) {
		showError('You must select at least one Status List field');
		return;
	}
	//   console.log(JSON.stringify(fullObj.value));
	//VALIDATION CHECKS
	await save();
};

const loadData = async () => {
	mainStore.isLoading = true;

	//result = await .get("task_templates?id=eq." + props.tasktemplateid);
	let result = await mosapi.generic_entity_get(Entities.TaskTemplates, {id: props.tasktemplateid});
	if (result.error) {
		showError(result.error);

		return;
	}

	data.value = result.data[0];

	mainStore.isLoading = false;
};

const save = async () => {
	if (props.newEntity) {
		const obj = JSON.parse(JSON.stringify(data.value));
		delete obj.id;
		//const result = await .post_data("task_templates", obj);
		const result = await mosapi.generic_entity_post(Entities.TaskTemplates, obj);
	} else {
		//console.log('patching', JSON.stringify(data.value))
		// const result = await .patch("task_templates?id=eq." + props.tasktemplateid, {
		// 	name_el: data.value.name_el,
		// 	name_en: data.value.name_en,
		// 	fields_ids: data.value.fields_ids
		// });
		const result = await mosapi.generic_entity_patch(Entities.TaskTemplates, props.tasktemplateid, {
			name_el: data.value.name_el,
			name_en: data.value.name_en,
			fields_ids: data.value.fields_ids
		})
	}
	emits("save");
}

const filterMethod = (query: string, item: any) => {
	// console.log(query + ' ' + JSON.stringify(item))
	return item.label.toLowerCase().includes(query.toLowerCase())
}

</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item :label="$t('TaskTemplatesList.name_el')">
						<el-input v-model="data.name_el" autocomplete="off" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item :label="$t('TaskTemplatesList.name_en')">
						<el-input v-model="data.name_en" autocomplete="off" />
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item style="margin-top: 0px">
						<el-transfer v-model="data.fields_ids" filterable :filter-method="filterMethod"
							filter-placeholder="Fields" :data="fieldsList" target-order="push"
							:titles="['Available Fields', 'Selected Fields']" class="myCLASS" />
					</el-form-item>
				</el-col>

				<p></p><br><br><br><br><br>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped>
.myCLASS .el-transfer-panel__filter {
	margin: 0px !important;
}

.el-transfer {
	--el-transfer-panel-width: 400px;
}
</style>
