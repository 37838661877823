<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "@/store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { MenuResponseDto } from "@/models";
import { useI18n } from "vue-i18n";
import { Entities } from "@/enums/Entities";
import Privilege from "@/classes/DB_Entities/Privilege";
import APIReply from "@/classes/APIReply";
const { showError, showLocalizedError } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });

const props = defineProps({
	menuId: Number,
});

const emits = defineEmits(["save", "close", "fail-save"]);

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

type FormData = {
	privilege_id: number | undefined,
};
const menu = ref<MenuResponseDto>();
const formData = ref<FormData>({
	privilege_id: undefined
});

const privileges = ref<Privilege[]>([]);

onMounted(async () => {
	if (props.menuId === 0) return;
	mainStore.isLoading = true;
	try {
		await loadPrivileges();
		await loadMenu(props.menuId as number);
	} catch (ex) {
		handleError(ex, "Failed to load menu");
	}
	
	mainStore.isLoading = false;
});

const close = async () => {
	emits("close");
};

const save = async () => {
	const menuId = menu.value?.id;
	const { privilege_id } = formData.value;

	if (!menuId || !privilege_id) return;

	const result = await mosapi.updateMenu(menuId, { privilege_id });

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	emits("save");
};

const loadPrivileges = async () => {
	const result = await mosapi.generic_entity_get<Privilege[]>(Entities.Privileges);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}
	if (result.data)
		privileges.value = result.data;
};

const loadMenu = async (menuId: number) => {
	try {
		mainStore.isLoading = true;

		const result = await mosapi.getMenu(menuId) as APIReply<MenuResponseDto[]>;
		console.log(JSON.stringify(result))
		if (result.error) {
			throw new Error(t(`errors.${result.error}`));
		}

		if (result.data) {
			menu.value = result.data[0];
			formData.value.privilege_id = result.data[0].privilege_id as number; //  as unknown as FormData;
		}
	} catch (error) {
		handleError(error, "Failed to load menus");
	} finally {
		mainStore.isLoading = false;
	}
};

const handleError = (error: unknown, defaultMessage: string) => {
	const message = error instanceof Error ? error.message : defaultMessage;
	showError(message);
};
</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form :model="formData">
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="Menu Name">
						{{  JSON.stringify(menu) }}
						<!-- <el-input disabled v-model="menu.name" /> -->
					</el-form-item>
					<el-form-item label="Privilege">
						<el-select v-model="formData.privilege_id" placeholder="Please Select Privilege">
							<el-option v-for="item in privileges" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
