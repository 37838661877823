<script lang="ts" setup>
import { ref, onMounted, onUnmounted, inject, watch, computed } from "vue";
import { useMainStore } from "../store/main";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMOSAPIStore } from "../store/mos_api";
import { UserFilled } from "@element-plus/icons-vue";
import UserType from "../enums/UserType";
import { useI18n } from "vue-i18n";
import UserDetails from "../components/UserDetails.vue";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import { Emitter, EventType } from "mitt";
import { DateTime, Duration } from "luxon";

const emitter = inject('emitter') as Emitter<Record<EventType, unknown>>;
const openMenu = ref(false);
const detailsVisible = ref(false);
const router = useRouter();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const { locale } = useI18n({ useScope: "global" });

const { globalLoanAgreementID, loggedUser } = storeToRefs(mainStore);

const portfolios = ref<any>([]);
const debtors = ref<any>([]);
const loan_agreements = ref<any>([]);

const filterPortfolio = ref<any>("");
const filterDebtor = ref<any>("");
const filterLoanAgreement = ref<any>("");
let loanAgreementsLoaded = false;
const lastEditUserID = ref<number>(-1);
let interval = 0;
const durationCounter = ref(Duration.fromObject({ seconds: 0 }));

async function logout() {
	mainStore.unsetUser();

	await mosapi.logout();

	localStorage.setItem("logout_event", Date.now().toString());

	globalLoanAgreementID.value = "";
	router.push("/login");
}

// TODO:  if current page is one of summary_check_ak/al/kod, redirect to the proper
//        type when loan agreement changes
// watch(globalLoanAgreementID, async (val) => {
// 	if (mainStore.loggedUser.username === '') return;

// 	if (!loanAgreementsLoaded) {
// 		loanAgreementsLoaded = true;
// 		await loadLoanAgreements();
// 	}

// 	// console.log("MainHeader, global loan agreement changed => " + val);
// 	for (const loan_agreement of loan_agreements.value) {
// 		// console.log("checking '" + loan_agreement.id + "'-'" + val + "'");
// 		if (loan_agreement.id + "" === val) {
// 			filterLoanAgreement.value = loan_agreement.ar_sumbashs;
// 			// console.log("MainHeader, set filterLoanAgreement to " + loan_agreement.ar_sumbashs);
// 			break;
// 		}
// 	}

// 	console.log("MainHeader, global loan agreement1 changed => " + val);
// 	emitter.emit('global_filter_change');
// });

// We logged in from another tab, reset display
const handleStorageChange = (e: StorageEvent) => {
	mainStore.menuOrder = [];
	router.push("/oops");
};

onMounted(async () => {
	window.addEventListener('storage', handleStorageChange);

	locale.value = loggedUser.value.language;
	await loadPortfolios();
	await loadDebtors();
	if (!loanAgreementsLoaded) {
		loanAgreementsLoaded = true;
		await loadLoanAgreements();
	}

	interval = window.setInterval(() => {
		durationCounter.value = Duration.fromObject({
			seconds: Math.floor((mainStore.remaining_time_received_at + mainStore.remaining_time - DateTime.now().toMillis()) / 1000),
		});
  	}, 1000);
});

onUnmounted(() => {
	window.removeEventListener('storage', handleStorageChange);
	window.clearInterval(interval);
});

const loadPortfolios = async () => {
	const result2 = await mosapi.get_view(UIViews.PortfoliosView, {}, 1, 1000);
	portfolios.value = result2.data;
};

const loadDebtors = async () => {
	const result2 = await mosapi.get_view(UIViews.DebtorsView, {}, 1, 1000);
	debtors.value = result2.data;
};

const loadLoanAgreements = async () => {
	// result = await .get("loan_agreement?" + addedFilter);
	const result = await mosapi.generic_entity_get(Entities.LoanAgreement)

	loan_agreements.value = result.data;
};

const portfolioChanged = async (val: any) => {
	console.log("portfolio changed to => " + JSON.stringify(val));
	if (filterPortfolio.value === undefined) {
		filterPortfolio.value = "";
	}

	mainStore.globalPortfolioID = filterPortfolio.value;
	// console.log("portfolio changed to => " + JSON.stringify(filterPortfolio.value));
	await loadDebtors();
	await loadLoanAgreements();

	console.log("MainHeader, global portfolio changed => " + val);
	emitter.emit('global_filter_change');
}

const debtorChanged = async (val: any) => {
	console.log("debtor changed to => " + JSON.stringify(val));
	if (filterDebtor.value === undefined) {
		filterDebtor.value = "";
	}

	mainStore.globalDebtorID = filterDebtor.value;
	// console.log("debtor changed to => " + filterDebtor.value);

	await loadPortfolios();
	await loadLoanAgreements();

	console.log("MainHeader, global debtor changed => " + val);
	emitter.emit('global_filter_change');
}

const loanAgreementChanged = async (val: any) => {
	console.log("loan agreement changed to => " + JSON.stringify(val));
	if (filterLoanAgreement.value === undefined) {
		filterLoanAgreement.value = "";
	}
	// console.log("loan agreement changed to => " + filterLoanAgreement.value);

	mainStore.globalLoanAgreementID = filterLoanAgreement.value;

	await loadPortfolios();
	await loadDebtors();

	console.log("MainHeader, global loan agreement2 changed => " + val);
	emitter.emit('global_filter_change');
};

const handleCommand = async (command: string | number | object) => {
	try {
		switch (command) {
			case "logout":
				mainStore.isLoading = true;
				await logout();
				break;
			case "userDetails":
				// console.log("User details");
				break;
		}
	} catch (ex) {
		console.log("Error in handleCommand: " + ex);
	}
	mainStore.isLoading = false;
};
//console.log('user id = ' + JSON.stringify(mainStore.loggedUser.id))
const changeLanguage = async (lang: string) => {
	locale.value = lang;
	await mosapi.updateUserLanguage(lang);
	// await .patch("users?id=eq." + mainStore.loggedUser.id, { language: locale.value, });
	loggedUser.value.language = locale.value;
};

const togglemenu = async () => {
	mainStore.iscollapsed = !mainStore.iscollapsed;
};

const ToggleUserInfo = async () => {
	lastEditUserID.value = mainStore.loggedUser.id as number;
	detailsVisible.value = true;
};

const onSaveUser = () => {
	// console.log("on save clear");
	lastEditUserID.value = -1;
	detailsVisible.value = false;
};

const onCloseUser = () => {
	// console.log("on close clear");
	lastEditUserID.value = -1;
	detailsVisible.value = false;
};

// const testNotify = async () => {
//   console.log('notifying')
//   await mosapiStore.notify({
//     channel: "print_barcode",
//     data: `~MDEL
// ^W70
// ^Q40,2
// ^H1
// ^P1
// ^L
// BQ, 75, 115, 2, 10, 70, 0, 1, 123456789012345678901234
// E`,
//   });
// }

const get_remaining_time = () => {
	return durationCounter.value.toFormat('mm:ss');
};

watch(durationCounter, (newValue) => {
  if (newValue.as("seconds") <= 0) {
	logout();
  }
});

</script>

<template>
	<div>
		<el-row align="middle" justify="center" class="navbar">
			<el-col :span="2" style="padding-left: 20px">
				<!-- Hamburger Menu -->
				<div id="sidemenu">
					<button class="sidemenu__btn" @click="(openMenu = !openMenu), togglemenu()"
						:class="{ active: openMenu }">
						<span class="top"></span>
						<span class="mid"></span>
						<span class="bottom"></span>
					</button>
					<!--<button
            class=""
            @click="testNotify"
          >
            <span class="bottom"></span>
          </button>-->

				</div>
			</el-col>
			<el-col :span="22">
				<el-row align="middle" justify="center" :gutter="15">
					<el-col :span="5">
						<el-select v-model="filterPortfolio" placeholder="Portfolio" size="large"
							@change="portfolioChanged" clearable filterable>
							<el-option v-for="item in portfolios" :key="item.id" :label="item.portf_name"
								:value="item.id" />
						</el-select>
					</el-col>
					<el-col :span="5">
						<el-select v-model="filterDebtor" placeholder="Debtor" size="large" @change="debtorChanged"
							clearable filterable>
							<el-option v-for="item in debtors" :key="item.id"
								:label="item.vat_number + ' (' + item.fullname + ' / ' + item.cid + ')'"
								:value="item.id" />
						</el-select>
					</el-col>
					<el-col :span="5">
						<el-select v-model="filterLoanAgreement" placeholder="Loan Agreement" size="large"
							@change="loanAgreementChanged" clearable filterable>
							<el-option v-for="item in loan_agreements" :key="item.id" :label="item.ar_sumbashs"
								:value="item.id" />
						</el-select>
					</el-col>
					<el-col :span="5">
						<el-dropdown @command="handleCommand">
							<el-button circle type="primary" class="btn-blue">
								<el-icon>
									<UserFilled />
								</el-icon>
							</el-button>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item disabled>{{
										mainStore.loggedUser.username +
										" (" +
										UserType[mainStore.loggedUser.user_type] +
										")"
									}}</el-dropdown-item>
									<el-dropdown-item @click="ToggleUserInfo()" divided command="userDetails">{{
										$t("generic.userdetails")
									}}
									</el-dropdown-item>
									<el-dropdown-item divided>
										<img v-if="locale !== 'en'" height="20" src="../assets/lang_en.png"
											@click="changeLanguage('en')" />
										<img v-if="locale !== 'el'" height="20" src="../assets/lang_el.png"
											@click="changeLanguage('el')" />
									</el-dropdown-item>
									<el-dropdown-item divided command="logout">Logout ({{ get_remaining_time() }})</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>

	<el-dialog v-model="detailsVisible" @update:model-value="onCloseUser" title="User Information" :width="900">
		<UserDetails :userid="lastEditUserID" :allow-type-change="false" @close="onCloseUser" @save="onSaveUser" />
	</el-dialog>
</template>

<style scoped>
.navbar {
	height: 70px;
	background: #98a4cb;
	position: fixed;
	z-index: 99999;
	width: 100%;
}

.menu-wrapper {
	width: 40px;
	height: auto;
	cursor: pointer;
	position: relative;
}

#sidemenu .sidemenu__btn {
	display: block;
	width: 50px;
	height: 50px;
	background: transparent;
	border: none;
	position: relative;
	z-index: 100;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	outline: none;
}

#sidemenu .sidemenu__btn span {
	display: block;
	width: 20px;
	height: 2px;
	margin: auto;
	background: white;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: all 0.4s ease;
}

#sidemenu .sidemenu__btn span.top {
	transform: translateY(-8px);
}

#sidemenu .sidemenu__btn span.bottom {
	transform: translateY(8px);
}

/*
#sidemenu .sidemenu__btn.active .top {
  transform: rotate(-45deg);
}

#sidemenu .sidemenu__btn.active .mid {
  transform: translateX(-20px) rotate(360deg);
  opacity: 0;
}

#sidemenu .sidemenu__btn.active .bottom {
  transform: rotate(45deg);
} */

#sidemenu .sidemenu__wrapper {
	padding-top: 50px;
}

#sidemenu .sidemenu__list {
	padding-top: 50px;
	list-style: none;
	padding: 0;
	margin: 0;
}

#sidemenu .sidemenu__item a {
	text-decoration: none;
	line-height: 1.6em;
	font-size: 1.6em;
	padding: 0.5em;
	display: block;
	color: white;
	transition: 0.4s ease;
}

#sidemenu .sidemenu__item a:hover {
	background: lightgrey;
	color: dimgrey;
}

.translateX-enter {
	transform: translateX(-200px);
	opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
	transform-origin: top left 0;
	transition: 0.2s ease;
}

.translateX-leave-to {
	transform: translateX(-200px);
	opacity: 0;
}
</style>
