<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import Portfolio from "../classes/DB_Entities/Portfolio";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useValidation } from "../composables/validation";
import UserType from "../enums/UserType";
import { useMOSAPIStore } from "@/store/mos_api";
import { Entities } from "@/enums/Entities";
import Client from "@/classes/DB_Entities/Client";
import APIReply from "@/classes/APIReply";

const props = defineProps<{
	row_id: number;
}>();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const {
	onValidationChange,
	checkValidationsForError,
} = useValidation();

const clientsList = ref(new Array<{ value: number, label: string }>());

const {
	data,
	cleanObject,
	fullObj,
	table_name,
	close,
	loadData,
	resetObjects,
	save,
	setup_decision_listener,
	showError,
	showLocalizedError,
} = useGenericMethodsVariables();

onMounted(async () => {
	setup_decision_listener();
	mainStore.isLoading = true;
	table_name.value = Portfolio.getTableNameStatic();
	cleanObject.value = new Portfolio();

	console.log("portfolio_id", props.row_id);

	await loadClients();

	if (props.row_id === -1) {
		resetObjects();
		mainStore.isLoading = false;
		return;
	}

	await loadData(props.row_id);
	mainStore.isLoading = false;
});

const loadClients = async () => {
	clientsList.value = [];

	const result: APIReply<Client[]> = await mosapi.generic_entity_get(Entities.MOSClients, { orderBy: 'clientname.asc' })

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	console.log(JSON.stringify(result.data));
	for (let i = 0; i < result.data.length; i++) {
		console.log('ug')
		clientsList.value.push({
			value: result.data[i].id,
			label: result.data[i].clientname,
		});
	}
	console.log(JSON.stringify(clientsList.value));
};

const onPreSave = async () => {
	console.log('presave-1');

	if (checkValidationsForError()) {
		showError("Please correct shown errors.");
		return;
	}

	// Set digital_code as string with one spacebar (for saving to DB without issues - null is not allowed)
	if ((fullObj.value.digital_code.originalValue === null || fullObj.value.digital_code.originalValue === "")) {
		fullObj.value.digital_code.originalValue = " ";
	}

	// console.log('fullobj:', JSON.stringify(fullObj.value));

	delete fullObj.value.mosclient; // Remove mosclient field, to avoid saving it to the DB
	await save();
};

</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form>
			<EntityField type="input" :label="$t('PortfolioDetails.name')" :object="fullObj.portf_name"
				:is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
				:validation-rules="[['^.+$', 'validation.insert_portfolio_name']]"
				@validation-change="onValidationChange" />
			<EntityField type="input" :label="$t('PortfolioDetails.asset_owner')" :object="fullObj.asset_owner"
				:is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
				:validation-rules="[['^.+$', 'validation.insert_asset_owner']]"
				@validation-change="onValidationChange" />
			<EntityField type="select" :object="fullObj.mosclient_id" label="MOS Client" :options="clientsList"
				:is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator"
				:validation-rules="[['^[0-9]+$', 'validation.select_client']]"
				@validation-change="onValidationChange" />
			<EntityField type="switch" :label="$t('PortfolioDetails.digitization')" :object="fullObj.digitization"
				:is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator" />
			<!-- 
          Να μπει validation
          :validation-rules="[['^[a-z]', 'validation.insert_digital_code']]" @validation-change="onValidationChange"
        -->

			<EntityField v-if="fullObj.digitization?.originalValue == true" type="input"
				:label="$t('PortfolioDetails.digital_code')" :object="fullObj.digital_code"
				:is-disabled="mainStore.loggedUser.user_type !== UserType.Administrator" />

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
