import { ref, onMounted, onBeforeUnmount } from "vue";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";

export function useViewOptions(viewName: string, filters_: any) {
  const mainStore = useMainStore();
  const { onSortChange, sortColumn, sortOrder } = useSort();

  const filters = ref<any>(filters_);
  
  const saveViewOptions = (viewName: string, filters: any, sortOrder: any, sortColumn: any) => {
    mainStore.viewOptions[viewName] = {
      filters: filters,
      sortParams: { sortOrder, sortColumn },
    };
  }

  onMounted(() => {
    // console.log('checking view options for', viewName)
    if (viewName in mainStore.viewOptions) {
      // console.log('getting', JSON.stringify(mainStore.viewOptions[viewName]))
      filters.value = JSON.parse(
        JSON.stringify(mainStore.viewOptions[viewName].filters)
      );
  
      sortOrder.value =
        mainStore.viewOptions[viewName].sortParams.sortOrder;
      sortColumn.value =
        mainStore.viewOptions[viewName].sortParams.sortColumn;
    }
  })
  
  onBeforeUnmount(() => {
    // console.log('saving view options for', viewName, JSON.stringify(filters.value));
    saveViewOptions(viewName, filters.value, sortOrder.value, sortColumn.value);
  })

  return {
    filters,
    sortColumn,
    sortOrder,
    onSortChange
  };
}
