enum EntityType {
	Unknown = 0,
	Client = 1,
	Portfolio = 2,
	Debtor = 3,
	LoanAgreement = 4,
	LoanAccount = 5,
	Approval = 6,
	Auction = 7,
	GreekStateGuarantee = 8,
	Guarantee = 9,
	RECollateral = 10,
	RealEstate = 11,
	RegisterOfBonds = 12,
	NRECollateral = 13,
	ROBParticipatingBank = 14,
	ROBTitle = 15,
	ROBBond = 16,
	SummaryCheck = 17,
}

export default EntityType;
