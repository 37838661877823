<script lang="ts" setup>
import { ref, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import Monitoring from "../classes/DB_Entities/Monitoring";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "@/store/mos_api";
import { Entities } from "@/enums/Entities";
import { useMainStore } from "../store/main";
import LoanAgreement from "@/classes/DB_Entities/LoanAgreement";
import { UIViews } from "@/enums/UIViews";
import UserType from "@/enums/UserType";

const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();

const props = defineProps({
  rowid: Number,
});

const StoreLoanAgreements = ref<any>([]);
const StoreMosOfficers = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new Monitoring());
const ruleFormRef = ref<FormInstance>();
const mosapi = useMOSAPIStore();
const StoreConfigShortDesc = ref<any>([]);
const StoreConfigInsComp = ref<any>([]);
const StoreObjOfIns = ref<any>([]);
const StoreTypeOfAgencyFee = ref<any>([]);
const StoreInsRisk = ref<any>([]);
const StoreFeePaymentFreq = ref<any>([]);
const ar_sumbashs = ref<string>("");
const fullname = ref<string>("");
const portf_name = ref<string>("");
// const monitoring_type = ref<number>(0);

// const submitForm = async (formEl: FormInstance | undefined) => {
//   if (!formEl) return;
//   await formEl.validate((valid, fields) => {
//     if (valid) {
//       console.log("submit!");
//       save();
//     } else {
//       console.log("error submit!", fields);
//     }
//   });
// };

// watch(
//   () => props.rowid,
//   async (newVal, oldVal) => {
//     // console.log("Loading debtor with id " + props.debtorid);
//     await loadRow(props.rowid as number);
//   }
// );

onMounted(async () => {
  mainStore.isLoading = true;
  try {
    await loadRow(props.rowid as number);
    await loadLoanAgreements();
    await loadMosOfficers();
    await loadConfigShortDesc();
    await loadConfigInsComp();
    await loadConfigObjOfIns();
    await loadConfigInsRisk();
    await loadConfigTypeOfAgencyFee();
    await loadConfigFeePaymentFreq();
  } catch (ex) {
    console.log(ex);
  }

  mainStore.isLoading = false;
});

const loadLoanAgreements = async () => {
  

  //result = await .get("loan_agreement?approve_status=eq.1");
  const result = await mosapi.get_view(UIViews.LoanAgreementView, { approve_status: 1, debtor_approve_status: 1 });
  // if (mainStore.loggedUser.user_type === 2) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 1) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 0) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  // }

  if (result.error) {
    showError(result.error);
    return;
  }

  StoreLoanAgreements.value = result.data;
};

const loadMosOfficers = async () => {
  
  //result = await .get("users?user_type=eq.2");
  //let result = await mosapi.getUserMosOfficers();
  const result = await mosapi.users_get_all_active(UserType.Agent, false); // await mosapi.generic_entity_get(Entities.Users, {user_type: 2});
  if (result.error) {
    showError(result.error);
    return;
  }
  StoreMosOfficers.value = result.data;
};

const close = async () => {
  emits("close");
};

const save = async () => {
  let result = await mosapi.generic_entity_patch(Entities.LAMonitoring, props.rowid as number, data.value);
  if (result.error) {
    showError(result.error); // emits("fail-save");
    return;
  }
  emits("save");
}

// const save = async () => {


//   let id = JSON.stringify(data.value.id);
//   delete data.value.id;

//   result = await .patch("la_monitoring?id=eq." + id, data.value);

//   if (result.error) {
//     showError(result.error); // emits("fail-save");
//     return;
//   }
//   emits("save");
// };

const loadRow = async (rowid: number) => {
  mainStore.isLoading = true;
  if (rowid === -1) return;
  
  //result = await .get("la_monitoring?id=eq." + rowid);
  let result = await mosapi.generic_entity_get(Entities.LAMonitoring, { id: props.rowid as number });
  if (result.error) {
    showError(result.error);
    return;
  }
  data.value = result.data[0];
};

const monitoring_types = ref([
  {
    value: 0,
    label: "Monitoring.pending_issues",
  },
  {
    value: 1,
    label: "Monitoring.financials",
  },
  {
    value: 2,
    label: "Monitoring.asfalistiria",
  },
  {
    value: 3,
    label: "Monitoring.promitheies",
  },
]);

const loadConfigShortDesc = async () => {
  
  //result = await .get("config_short_descriptions");
  let result = await mosapi.generic_entity_get(Entities.ConfigShortDescriptions);

  if (result.error) {
    showError(result.error);
    return;
  }
  StoreConfigShortDesc.value = result.data;
};

const loadConfigInsComp = async () => {
  
  //result = await .get("config_insurance_company");
  let result = await mosapi.generic_entity_get(Entities.ConfigInsuranceCompany);
  if (result.error) {
    showError(result.error);
    return;
  }
  StoreConfigInsComp.value = result.data;
};

const loadConfigObjOfIns = async () => {
  
  //result = await .get("config_obj_of_ins");
  let result = await mosapi.generic_entity_get(Entities.ConfigObjectOfInsurances);
  if (result.error) {
    showError(result.error);
    return;
  }
  StoreObjOfIns.value = result.data;
};

const loadConfigTypeOfAgencyFee = async () => {
  
  //result = await .get("config_type_of_agency_fee");
  let result = await mosapi.generic_entity_get(Entities.ConfigTypeOfAgencyFee);
  if (result.error) {
    showError(result.error);
    return;
  }
  StoreTypeOfAgencyFee.value = result.data;
};

const loadConfigInsRisk = async () => {
  
  //result = await .get("config_insurance_risk");
  let result = await mosapi.generic_entity_get(Entities.ConfigInsuranceRisk);
  if (result.error) {
    showError(result.error);
    return;
  }
  StoreInsRisk.value = result.data;
};

const loadConfigFeePaymentFreq = async () => {
  
  // result = await .get("config_fee_payment_freq");
  let result = await mosapi.generic_entity_get(Entities.ConfigFeePaymentFrequency);
  if (result.error) {
    showError(result.error);
    return;
  }
  StoreFeePaymentFreq.value = result.data;
};

const onChange = async (event: any) => {
  
  //result = await .get("loan_agreement?select=ar_sumbashs,debtor(fullname,portfolio(portf_name))&id=eq." + event);
  //let result = await mosapi.getLoanAgreementRelationsById(mainStore.loggedUser.id, event);
  const result = await mosapi.generic_entity_get<LoanAgreement[]>(Entities.LoanAgreement, {id: event});
  //console.log("loan_agreement?select=ar_sumbashs,debtor(fullname,portfolio(portf_name))&id=eq." + event);
  ar_sumbashs.value = result.data[0].ar_sumbashs;
  fullname.value = result.data[0].debtor.fullname;
  portf_name.value = result.data[0].debtor.portfolio.portf_name;
}

// watch(
//   () => data.value?.loan_agreement_id,
//   async (newVal, oldVal) => {
    
//     //result = await .get("loan_agreement?select=ar_sumbashs,debtor(fullname,portfolio(portf_name))&id=eq." + newVal);
//     //let result = await mosapi.getLoanAgreementRelationsById(mainStore.loggedUser.id, newVal);
//     let result = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: newVal});
//     //console.log(result.data[0].debtor.fullname);
//     ar_sumbashs.value = result.data[0].ar_sumbashs;
//     fullname.value = result.data[0].debtor.fullname;
//     portf_name.value = result.data[0].debtor.portfolio.portf_name;
//   }
// );

const onFailSave = async () => {
  emits("close");
};

</script>

<template>
  <div>
    <h3>{{ $t(monitoring_types[data.monitoring_type].label) }}</h3>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <div style="text-align: right; padding-bottom: 10px" v-if="data.loan_agreement_id">
        <el-tag class="ml-2" style="margin: 12px">
          {{ 'Portfolio: ' + portf_name }}
        </el-tag>
        <el-tag class="ml-2" style="margin: 12px">
          {{ 'Loan Agreement: ' + ar_sumbashs }}
        </el-tag>
        <el-tag class="ml-2" style="margin: 12px">
          {{ 'Debtor: ' + fullname }}
        </el-tag>
      </div>
      <el-row :gutter="10" v-if="data.monitoring_type === 0">
        <el-col :span="24">
          <el-form-item :label="$t('LoanAccountDetails.loan_agreement')" prop="loan_agreement_id">
            <el-select v-model="data.loan_agreement_id" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" " @change="onChange($event)">
              <el-option v-for="item in StoreLoanAgreements" :key="item.id" :label="item.ar_sumbashs"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.short_desc')">
            <el-select v-model="data.short_desc" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreConfigShortDesc" :key="item.id" :label="item.short_desc"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.actions_comments_clarifications')">
            <el-input v-model="data.actions_comments_clarifications" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.due_date')" :width="100">
            <el-date-picker v-model="data.due_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.alarm_date')" :width="100">
            <el-date-picker v-model="data.alarm_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.end_date')" :width="100">
            <el-date-picker v-model="data.end_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.mos_officer')" prop="mos_officer">
            <el-select v-model="data.mos_officer" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreMosOfficers" :key="item.id" :label="item.username" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.account_manager')">
            <el-input v-model="data.account_manager" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="data.monitoring_type === 1">
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.due_date')">
            <el-date-picker v-model="data.due_date_financials" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.obligations')">
            <el-input v-model="data.obligations" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.recruit_final_stat')">
            <el-input v-model="data.recruit_final_stat" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.comments')">
            <el-input v-model="data.comments" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.account_manager_financials')">
            <el-input v-model="data.account_manager_financials" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="data.monitoring_type === 2">
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insurance_company')">
            <el-select v-model="data.insurance_company" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreConfigInsComp" :key="item.id" :label="item.ins_comp" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insurance_policy')">
            <el-input v-model="data.insurance_policy" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.start_date')">
            <el-date-picker v-model="data.start_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.end_date_as')">
            <el-date-picker v-model="data.end_date_as" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.alarm_date_as')">
            <el-date-picker v-model="data.alarm_date_as" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.object_of_insurance')">
            <el-select v-model="data.object_of_insurance" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreObjOfIns" :key="item.id" :label="item.obj_of_ins" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insurance_risk')">
            <el-select v-model="data.insurance_risk" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreInsRisk" :key="item.id" :label="item.ins_risk" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insured_capital')">
            <el-input v-model="data.insured_capital" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.cession_of_insurance_policy')">
            <el-switch v-model="data.cession_of_insurance_policy" :active-text="$t('components.switch.yes')"
              :inactive-text="$t('components.switch.no')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.actions_comments_clarifications_as')">
            <el-input v-model="data.actions_comments_clarifications_as" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="data.monitoring_type === 3">
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.type_of_agency_fee')">
            <el-select v-model="data.type_of_agency_fee" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreTypeOfAgencyFee" :key="item.id" :label="item.type_of_agency_fee"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.agency_fee_amount')">
            <el-input v-model="data.agency_fee_amount" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.agency_fee_percentage_first')">
            <el-input v-model="data.agency_fee_percentage_first" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.agency_fee_percentage_second')">
            <el-input v-model="data.agency_fee_percentage_second" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.fee_payment_freq')">
            <el-select v-model="data.fee_payment_freq" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreFeePaymentFreq" :key="item.id" :label="item.fee_payment_freq"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.due_date_p')">
            <el-date-picker v-model="data.due_date_p" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.alarm_date_p')">
            <el-date-picker v-model="data.alarm_date_p" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.payment_date')">
            <el-date-picker v-model="data.payment_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.comments_p')">
            <el-input v-model="data.comments_p" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
