<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import RealEstate from "../classes/DB_Entities/RealEstate";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import { useValidation } from "../composables/validation";
import { useValueLists } from "../composables/valueLists";
import { useDataHelpers } from "../composables/dataHelpers";
import ISelectEntry from "../interfaces/ISelectEntry";
import Debtor from "../classes/DB_Entities/Debtor";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "@/store/mos_api";
import { GenericEntity } from "@/types/GenericEntity";
const mosapi = useMOSAPIStore();
const props = defineProps<{
  row_id: number;
  view_only: boolean;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);
const {
  onValidationChange,
  checkValidationsForError,
} = useValidation();

const { OwnerShipTypeList, TypeofPropertyList } = useValueLists();
const mainStore = useMainStore();
const dataHelpers = useDataHelpers();
const { t } = useI18n({ useScope: "global" });
const debtorsList = ref(new Array<any>());
const TypeofValuationList = ref<any>([]);
const UrbanPlanningViolationsList = ref<any>([]);
const countries = ref<ISelectEntry[]>([]);
const prefectures = ref<any>([]);
const postCodes = ref<any>([]);
const filteredPostCodes = ref<ISelectEntry[]>([]);

const {
  setup_decision_listener,
  cleanObject,
  fullObj,
  hasPendingChanges,
  table_name,
  clickAcceptAll,
  close,
  isNewEntity,
  loadData,
  resetObjects,
  save,
  setDisplayValues,
  updateDecision,
  showError,
  showWarning
} = useGenericMethodsVariables();

const loadRequiredData = async () => {
  await loadSelects();
  await loadDebtors();

  // Countries
  // countries.value = await dataHelpers.<ConfigCountry>(ConfigCountry, (dbObj: any) => dbObj.id, (dbObj: any) => dbObj.country);
  countries.value = await dataHelpers.getAllObjectsFromTableForSelect(Entities.ConfigCountry, (dbObj: any) => dbObj.id, (dbObj: any) => dbObj.country);

  // Prefectures
  (await dataHelpers.getAllObjectsFromTable(Entities.ConfigPrefecture) as GenericEntity[]).forEach((dbObj: any) => {
    prefectures.value.push({
      value: dbObj.id,
      label: dbObj.prefecture,
      min_tk: dbObj.min_tk,
      max_tk: dbObj.max_tk,
    });
  })

  // Post Codes
  postCodes.value = await dataHelpers.getAllObjectsFromTableForSelect(Entities.ConfigPostalCode, (dbObj: any) => dbObj.id, (dbObj: any) => dbObj.postalcode);
  filteredPostCodes.value = JSON.parse(JSON.stringify(postCodes.value));
};

onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  table_name.value = RealEstate.getTableNameStatic();
  cleanObject.value = new RealEstate();
  mainStore.isLoading = true;
  //console.log("real_estate_id", props.row_id);

  await loadRequiredData();

  if (props.row_id === -1) {
    resetObjects();
    mainStore.isLoading = false;
    return;
  }

  await loadData(props.row_id);
  await fixPendingSelects();

  if (fullObj.value.district.originalValue) {

    await filterPostCodes(fullObj.value.district.originalValue);

  }

  mainStore.isLoading = false;
});

const loadDebtors = async () => {
  debtorsList.value = [];

  // let query = `view_mosdebtors_view1?select=id,cid,fullname&approve_status=eq.1`;
  const result = await mosapi.generic_entity_get(Entities.Debtors, { approve_status: 1 });
  if (result.error) {
    showError(result.error);
    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    debtorsList.value.push({
      value: result.data[i].id,
      label: result.data[i].cid + " (" + result.data[i].fullname + ")",
    });
  }
};

const onPreSave = async () => {
  if (checkValidationsForError()) {
    showError("Please correct shown errors.");
    return;
  }

  if (isNewEntity() && fullObj.value.debtor_id.originalValue === 0) {
    showWarning(t("errors.missing_debtor"), 1500);
    return;
  }

  delete fullObj.value.debtor;

  // console.log('coocoo saving', JSON.stringify(fullObj.value));
  fullObj.value.liquidation_value.originalValue = fullObj.value.liquidation_value.originalValue.toString();
  await save();
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    if (field === "debtor_id") {
      setDisplayValues(Debtor, field, (dbObj: any) => { return `${dbObj.cid} (${dbObj.fullname})`; });
    }

    if (field === "eidos") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = TypeofPropertyList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = TypeofPropertyList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }

    if (field === "postalcode") {
      if(fullObj.value[field].originalValue !== null){
        const result1 = await mosapi.generic_entity_get(Entities.ConfigPostalCode, {id: fullObj.value[field].originalValue});
        fullObj.value[field].pendingValue["originalDisplayValue"] = result1.data[0].postalcode;
      } else {
        fullObj.value[field].pendingValue["originalDisplayValue"] = "";
      }
      if(fullObj.value[field].pendingValue.value !== null && fullObj.value[field].pendingValue.value !== ""){
        const result2 = await mosapi.generic_entity_get(Entities.ConfigPostalCode, {id: fullObj.value[field].pendingValue.value});
        fullObj.value[field].pendingValue["pendingDisplayValue"] = result2.data[0].postalcode;
      } else {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = "";
      }
    }

    if (field === "country") {
      if(fullObj.value[field].originalValue !== null){
        const result1 = await mosapi.generic_entity_get(Entities.ConfigCountry, {id: fullObj.value[field].originalValue});
        fullObj.value[field].pendingValue["originalDisplayValue"] = result1.data[0].country;
      } else {
        fullObj.value[field].pendingValue["originalDisplayValue"] = "";
      }
      if(fullObj.value[field].pendingValue.value !== null && fullObj.value[field].pendingValue.value !== ""){
        const result2 = await mosapi.generic_entity_get(Entities.ConfigCountry, {id: fullObj.value[field].pendingValue.value});
        fullObj.value[field].pendingValue["pendingDisplayValue"] = result2.data[0].country;
      } else {
        fullObj.value[field].pendingValue["pendingDisplayValue"] = "";
      }
    }

    if (field === "eidos_ektim") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = TypeofValuationList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = TypeofValuationList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }

    if (field === "eidos_idioktisias") {
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = OwnerShipTypeList.value.find(
        (e: any) => e.value === fullObj.value[field].originalValue
      )?.label;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = OwnerShipTypeList.value.find(
        (e: any) => e.value === fullObj.value[field].pendingValue.value
      )?.label;
    }

    if (field === "poleod_parabaseis") {
      fullObj.value[field].pendingValue["originalDisplayValue"] =
        fullObj.value[field].originalValue === "true"
          ? t("global.true")
          : t("global.false");
      fullObj.value[field].pendingValue["pendingDisplayValue"] =
        fullObj.value[field].pendingValue.value === "true"
          ? t("global.true")
          : t("global.false");
    }
  }
};

const filterPostCodes = (val: any) => {
  const selectedPrefecture = prefectures.value.find((x: any) => x.value === val);

  if (selectedPrefecture === undefined || selectedPrefecture === '') {
    filteredPostCodes.value = JSON.parse(JSON.stringify(postCodes.value));
  } else {
    filteredPostCodes.value = postCodes.value.filter((x: any) => { return parseInt(x.label) >= selectedPrefecture?.min_tk && parseInt(x.label) <= selectedPrefecture?.max_tk });
    //fullObj.value.postalcode.originalValue = "";
  }
}

const setPrefecture = (val: any) => {
  const selectedPostCode = postCodes.value.find((x: any) => x.value === val);

  if (!selectedPostCode) return;

  const matchingPrefecture = prefectures.value.find((x: any) => x.min_tk <= parseInt(selectedPostCode.label) && x.max_tk >= parseInt(selectedPostCode.label));

  if (matchingPrefecture && fullObj.value.district.originalValue !== matchingPrefecture.value) {
    fullObj.value.district.originalValue = matchingPrefecture.value;
  }
}

const loadSelects = async () => {
  UrbanPlanningViolationsList.value = [
    {
      value: true,
      label: t("global.true"),
    },
    {
      value: false,
      label: t("global.false"),
    },
  ];

  TypeofValuationList.value = [
    {
      value: "full_inspection",
      label: "Full Inspection",
    },
    {
      value: "site_visit",
      label: "Site Visit",
    },
    {
      value: "drive_by",
      label: "Drive By",
    },
    {
      value: "desktop",
      label: "Desktop",
    },
    {
      value: "certified",
      label: "Certified",
    },
  ];
};

</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll(props.row_id)" />
      <p />

      <el-row>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.debtor_id" label="CID" :options="debtorsList"
             :is-disabled="props.view_only" />
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.arithmos_akinitou_diax')"
            :object="fullObj.arithmos_akinitou_diax" 
            :validation-rules="[['^[0-9]{1,19}$', 'validation.realestate_nbr']]" @validation-change="onValidationChange"
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos" :label="$t('RealEstateDetails.eidos')"
            :options="TypeofPropertyList"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.address')" :object="fullObj.address"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.address_number')" :object="fullObj.address_number"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.region')" :object="fullObj.region"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.city')" :object="fullObj.city" 
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <EntityField type="select" :label="$t('RealEstateDetails.district')" :object="fullObj.district"
              :options="prefectures"  :is-disabled="props.view_only"
              @select-change="filterPostCodes" @clear="filterPostCodes" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <EntityField type="select" :label="$t('RealEstateDetails.postalcode')" :object="fullObj.postalcode"
              :options="filteredPostCodes"  :is-disabled="props.view_only"
              @select-change="setPrefecture" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <EntityField type="select" :label="$t('RealEstateDetails.country')" :object="fullObj.country"
              :options="countries"  :is-disabled="props.view_only" />
          </el-col>
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.google_map_link')" :object="fullObj.google_map_link"
             :is-disabled="props.view_only" :is-link="true" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.description')" :object="fullObj.description"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('RealEstateDetails.constr_date')" :object="fullObj.constr_date"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.construction_status')"
            :object="fullObj.construction_status"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos_idioktisias"
            :label="$t('RealEstateDetails.eidos_idioktisias')" :options="OwnerShipTypeList" 
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.percentage_property')"
            :object="fullObj.percentage_property"  :is-disabled="props.view_only" />
        </el-col>
      </el-row>
      <!-- </el-tab-pane>
                      <el-tab-pane label="Valuation" name="valuation"> -->
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="money" :label="$t('RealEstateDetails.antikeim_aksia_akin_rev')"
            :object="fullObj.antikeim_aksia_akin_rev"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('RealEstateDetails.liquidation_value')"
            :object="fullObj.liquidation_value"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="money" :label="$t('RealEstateDetails.ektim_aksias_akin')"
            :object="fullObj.ektim_aksias_akin"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.eidos_ektim" :label="$t('RealEstateDetails.eidos_ektim')"
            :options="TypeofValuationList"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('RealEstateDetails.hmer_ektim')" :object="fullObj.hmer_ektim"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.ektimiths')" :object="fullObj.ektimiths"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.dikaiouxos')" :object="fullObj.dikaiouxos"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.kthmatolog_graf')" :object="fullObj.kthmatolog_graf"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.kaek')" :object="fullObj.kaek" 
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.ypothikof')" :object="fullObj.ypothikof"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tomos')" :object="fullObj.tomos"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.fullo')" :object="fullObj.fullo"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.number')" :object="fullObj.number"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="select" :object="fullObj.poleod_parabaseis"
            :label="$t('RealEstateDetails.poleod_parabaseis')" :options="UrbanPlanningViolationsList"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.ektim_kostos_taktop')"
            :object="fullObj.ektim_kostos_taktop"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_oikopedou')" :object="fullObj.tm_oikopedou"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_ktismatos')" :object="fullObj.tm_ktismatos"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_apothikhs')" :object="fullObj.tm_apothikhs"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.tm_thesis_stathmeushs')"
            :object="fullObj.tm_thesis_stathmeushs"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('RealEstateDetails.orofoi')" :object="fullObj.orofoi"
             :is-disabled="props.view_only" />
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
