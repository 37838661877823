<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Edit, Delete, Clock, Finished, Memo, Plus, Printer, RefreshLeft } from "@element-plus/icons-vue";
import Edit_Task from "../components/Edit_Task.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";
import { useI18n } from "vue-i18n";
import AddEdit_Project from "../components/AddEdit_Project.vue";
import ProjectTaskHistory from "../components/ProjectTaskHistory.vue";
import * as ExcelJS from 'exceljs';
import { DateTime } from "luxon";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import APIReply from "@/classes/APIReply";

type project_tasks_list_internal = {
	project_task_id: number;
	task_template_id: number;
	template_name_el: string;
	template_name_en: string;
	officer_user_id: number;
	officer: string;
	hmeromhnia_anathesis_task: string;
	completed_value: string;
}

type view_project_tasks_list = {
	project_id: number;
	project_template_id: number;
	arithmos_aitisis: string;
	customer: string;
	customer_code: string;
	hmeromhnia_anathesis: string;
	olokliromeno: boolean;
	project_tasks_list: project_tasks_list_internal[];
	name_el: string;
	name_en: string;
};

const { dataCount, last_page, showError, showSuccess } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const { locale } = useI18n({ useScope: "global" });

const dataList = ref<view_project_tasks_list[]>([]);
const selectedProjectRow = ref<number>(-1);
const selectedTaskRow = ref<number>(-1);
const deleteDialogVisible = ref<boolean>(false);
const addEditProjectVisible = ref<boolean>(false);
const editTaskVisible = ref<boolean>(false);
const historyDialogVisible = ref<boolean>(false);
const expandedRows = ref<string[]>([]);

const { onSortChange, sortColumn, sortOrder } = useSort();

const handleClickEdit = async (row_id: number) => {
	selectedProjectRow.value = row_id;
	addEditProjectVisible.value = true;
};

const handleClickEditTask = async (row: project_tasks_list_internal) => {
	console.log('edit', JSON.stringify(row))
	selectedTaskRow.value = row.project_task_id;
	editTaskVisible.value = true;
};

const handleClickHistory = async (row: project_tasks_list_internal) => {
	console.log('history', JSON.stringify(row))
	selectedTaskRow.value = row.project_task_id;
	historyDialogVisible.value = true;
};

onMounted(async () => {
	await loadPage(1);
});

const handleClickAdd = () => {
	selectedProjectRow.value = -1;
	addEditProjectVisible.value = true;
};

const handleClickDelete = (row_id: number) => {
	selectedProjectRow.value = row_id;
	deleteDialogVisible.value = true;
};

const onDeleteProject = async () => {
	showSuccess("Project deleted successfully");
	deleteDialogVisible.value = false;
	loadPage(last_page.value);
};

const checkCompleted = async () => {
	let completedTasksCounter = 0;
	for (const dataRow of dataList.value) {
		completedTasksCounter = 0;
		dataRow.olokliromeno = false;

		for (let j = 0; j < dataRow.project_tasks_list.length; j++) {
			if (dataRow.project_tasks_list[j].completed_value === 'Ολοκληρωμένο') { completedTasksCounter++; }
		}
		if (completedTasksCounter === dataRow.project_tasks_list.length) { dataRow.olokliromeno = true; }
	}
}
const sortArrayTaskName = ((a: project_tasks_list_internal, b: project_tasks_list_internal) => {
	if (a['template_name_' + locale.value] < b['template_name_' + locale.value]) { return -1; }
	if (a['template_name_' + locale.value] == b['template_name_' + locale.value]) { return 0; }
	if (a['template_name_' + locale.value] > b['template_name_' + locale.value]) { return 1; }
})

const loadPage = async (page: number) => {
	mainStore.isLoading = true;
	last_page.value = page;
	// let filterUrl = "?orderFIX=customer_code.asc";

	// page = page - 1;

	if (sortColumn.value === "") {
		sortColumn.value = "customer_code"
		// filterUrl = `?orderFIX=${sortColumn.value}.${sortOrder.value}`;
	}

	const result = await mosapi.get_view(UIViews.ProjectTasksList, {orderBy:`${sortColumn.value}.${sortOrder.value}`}, page) as APIReply<view_project_tasks_list[]>;
	// let result:  = await .getPaginated("view_project_tasks_list" + filterUrl, page);

	mainStore.isLoading = false;

	if (result.error) {
		showError(result.error);
		return;
	}

	dataList.value = result.data || [];
	dataCount.value = result.total || 0;

	checkCompleted();

	for (const project of dataList.value) {
		project.project_tasks_list = project.project_tasks_list.filter((task: project_tasks_list_internal) => task.officer_user_id === mainStore.loggedUser.id || (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor));
	}

	dataList.value = dataList.value.filter((project: view_project_tasks_list) => project.project_tasks_list.length > 0);

	if (dataList.value.length === 0) {
		mainStore.isLoading = false;
		return;
	}

	for (let i = 0; i < dataList.value.length; i++) {
		dataList.value[i].project_tasks_list.sort(sortArrayTaskName);
	}

	mainStore.isLoading = false;
};

const onSaveProject = async () => {
	ElMessage({
		showClose: true,
		message: "Project updated",
		type: "success",
		duration: 1000,
	});

	addEditProjectVisible.value = false;
	selectedProjectRow.value = -1;
	await loadPage(last_page.value);
};

const onSaveTask = async (statusParam: string | undefined) => {

	editTaskVisible.value = false;

	mainStore.isLoading = true;

	const refreshedTaskData = await mosapi.generic_entity_get(Entities.ProjectTasks, { id: selectedTaskRow.value });
	// let refreshedTaskData = await .get("project_tasks?id=eq." + selectedTaskRow.value);

	let ok = false;
	for (let i = 0; i < dataList.value.length && !ok; ++i) {
		for (let j = 0; j < dataList.value[i].project_tasks_list.length && !ok; ++j) {
			if (dataList.value[i].project_tasks_list[j].project_task_id === selectedTaskRow.value) {
				if (refreshedTaskData.data[0].user_id) {
					const resultOfficerName = { data: [ { first_name: "", last_name: "" }]}; // TODO mosapi change -  await .get("users?id=eq." + refreshedTaskData.data[0].user_id);
					const officerName = resultOfficerName.data[0].first_name + ' ' + resultOfficerName.data[0].last_name;
					dataList.value[i].project_tasks_list[j].officer = officerName;
				}

				dataList.value[i].project_tasks_list[j].hmeromhnia_anathesis_task = refreshedTaskData.data[0].hmeromhnia_anathesis;

				if (statusParam) {
					dataList.value[i].project_tasks_list[j].completed_value = statusParam;
				}

				ok = true;
			}
		}
	}

	checkCompleted();

	selectedTaskRow.value = -1;

	mainStore.isLoading = false;

	ElMessage({
		showClose: true,
		message: "Task updated",
		type: "success",
		duration: 1000,
	});
};

const localSortChange = async (sortObj: unknown) => {
	onSortChange(sortObj);
	await loadPage(last_page.value);
};

const onCloseAddEdit = async () => {
	await loadPage(last_page.value);
	addEditProjectVisible.value = false;
};

const onCloseEditTask = async () => {
	editTaskVisible.value = false;
};

const parentBorder = ref(false);
const childBorder = ref(false);

const exportExcel = async () => {
	const workbook = new ExcelJS.Workbook();
	const sheet = workbook.addWorksheet('Projects');
	let currentRow = 1;
	let currentCompleted = false;
	// let currentTaskCompleted = false;

	let row = sheet.getRow(currentRow);
	let projectRow = sheet.getRow(currentRow);

	//HEADERS
	row.getCell(1).value = 'ID';
	row.getCell(2).value = 'Project Name';

	row.getCell(3).value = 'Πελάτης';
	row.getCell(4).value = 'ΣΥΔΙΠΕΛ';
	row.getCell(5).value = 'Ημερομηνία Ανάθεσης';
	row.getCell(6).value = 'Κατάσταση';
	row.getCell(7).value = 'Task name';
	row.getCell(8).value = 'Task Officer';
	row.getCell(9).value = 'Ημερομηνία Ανάθεσης Task';
	row.getCell(10).value = 'Κατάσταση Task';
	//FIRST ROW
	row = sheet.getRow(++currentRow);
	projectRow = sheet.getRow(currentRow);

	for (let i = 0; i < dataList.value.length; ++i) {
		let completedTasks = 0;

		row.getCell(1).value = dataList.value[i].project_id;
		if (locale.value === 'el') {
			row.getCell(2).value = dataList.value[i].name_el;
		}
		else {
			row.getCell(2).value = dataList.value[i].name_en;
		}

		row.getCell(3).value = dataList.value[i].customer;
		row.getCell(4).value = dataList.value[i].customer_code;
		row.getCell(5).value = dataList.value[i].hmeromhnia_anathesis;

		for (let j = 0; j < dataList.value[i].project_tasks_list.length; j++) {
			console.log(dataList.value[i].project_tasks_list[j]);
			row.getCell(7).value = dataList.value[i].project_tasks_list[j]['template_name_' + locale.value];
			row.getCell(8).value = dataList.value[i].project_tasks_list[j].officer;
			row.getCell(9).value = dataList.value[i].project_tasks_list[j].hmeromhnia_anathesis_task;

			if (dataList.value[i].project_tasks_list[j].completed_value === 'Ολοκληρωμένο') {
				// currentTaskCompleted = true;
				completedTasks++;
			}

			row.getCell(10).value = dataList.value[i].project_tasks_list[j].completed_value;
			row = sheet.getRow(++currentRow);
			// currentTaskCompleted = false;
		}

		if (completedTasks === dataList.value[i].project_tasks_list.length) {
			currentCompleted = true;
		}
		if (currentCompleted) {
			projectRow.getCell(6).value = 'Ολοκληρωμένο';
		}
		else {
			projectRow.getCell(6).value = 'Μη ολοκληρωμένο';
		}

		projectRow = sheet.getRow(currentRow);
		row = sheet.getRow(currentRow);
		currentCompleted = false;
	}

	const buffer = await workbook.xlsx.writeBuffer();
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	const link = document.createElement("a");
	link.setAttribute("href", window.URL.createObjectURL(blob));
	link.setAttribute(
		"download",
		`testexport.${DateTime.now().toFormat("yyyy-MM-dd_HHmmss")}.xlsx`
	);
	document.body.appendChild(link); // Required for FF

	link.click(); // Trigger file download
}

const onCloseHistory = async () => {
	historyDialogVisible.value = false;
};

const onExpandChange = (row: any, expandedRows: any) => {
	expandedRows = expandedRows.map((item: any) => item.id);
}
</script>

<template>
	<div class="content-wrapper" v-loading="mainStore.isLoading">
		<el-row :gutter="10">
			<el-col :span="12">
				<h3 class="heading-page">{{ $t("ProjectsList.name") }} <!--({{ dataCount }})--></h3>
			</el-col>
		</el-row>

		<div>
			<el-row :gutter="10" style="margin: 10px; margin-top: 15px">
				<el-col :span="12" class="realestate-add-new-btn"> </el-col>
				<el-col :span="12" class="realestate-add-new-btn">
					<el-button link type="primary" size="small" class="btn-add" @click="loadPage(last_page)"
						style="float: right; margin: 2.5px" :icon="RefreshLeft" />
					<el-button link
						v-if="mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor"
						type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
						@click="handleClickAdd()" :icon="Plus" />
					<el-button link
						v-if="mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor"
						type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
						@click="exportExcel()" :icon="Printer" />
				</el-col>
			</el-row>
		</div>
		<el-table :data="dataList" :border="parentBorder" style="width: 100%" stripe table-layout="fixed" sortable="custom"
			row-key="project_id" :expand-row-keys="expandedRows" @expand-change="onExpandChange"
			@sort-change="localSortChange" size="small">
			<el-table-column type="expand">
				<template #default="props">
					<div m="4" style="margin-left:50px">
						<el-table :data="props.row.project_tasks_list" :border="childBorder" sortable>
							<el-table-column label="Task" sortable :sort-by="'template_name_' + locale">
								<template #default="scope">
									<span v-if="locale === 'el'">{{ scope.row.template_name_el }}</span>
									<span v-else>{{ scope.row.template_name_en }}</span>
								</template>
							</el-table-column>
							<el-table-column label="Officer" prop="officer" sortable />
							<el-table-column label="Ημ. Ανάθεσης" prop="hmeromhnia_anathesis_task" sortable />
							<el-table-column label="Κατάσταση">
								<template #default="scope">
									<span v-if="scope.row.completed_value === 'Ολοκληρωμένο'">
										<el-icon>
											<Finished />
										</el-icon>
									</span>
									<span v-else>
										{{ scope.row.status }}
									</span>
								</template>
							</el-table-column>
							<el-table-column fixed="right" :label="$t('global.operations')">
								<template #default="scope">
									<el-button link type="primary" size="small" class="btn-detail"
										@click="handleClickEditTask(scope.row)" :icon="Edit"
										style="background-color: #d6d6d6 !important;" />
									<el-button link type="primary" size="small" class="btn-detail"
										@click="handleClickHistory(scope.row)" :icon="Memo"
										style="background-color: #d6d6d6 !important;" />
								</template>
							</el-table-column>
						</el-table>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="Project">
				<template #default="scope">
					<span v-if="locale === 'el'">{{ scope.row.name_el }}</span>
					<span v-else>{{ scope.row.name_en }}</span>
				</template>
			</el-table-column>
			<el-table-column label="Customer" prop="customer" sortable="custom" />
			<el-table-column :label="$t('ProjectsList.customer_code')" prop="customer_code" sortable="custom" />
			<el-table-column label="Ημερομηνία Ανάθεσης" prop="hmeromhnia_anathesis" sortable="custom" />
			<el-table-column label="Κατάσταση">
				<template #default="scope">
					<el-icon v-if="scope.row.olokliromeno">
						<Finished />
					</el-icon>
					<el-icon v-else>
						<Clock />
					</el-icon>
				</template>
			</el-table-column>
			<el-table-column fixed="right" :label="$t('global.operations')">
				<template #default="scope">
					<el-button
						v-if="mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor"
						link type="primary" size="small" class="btn-detail"
						@click="handleClickEdit(scope.row.project_id)" :icon="Edit"
						style="background-color: #d6d6d6 !important;" />
					<el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
						size="small" class="btn-delete" @click="handleClickDelete(scope.row.project_id)"
						:icon="Delete" />
				</template>
			</el-table-column>
		</el-table>

		<el-dialog width="80%" v-if="addEditProjectVisible" v-model="addEditProjectVisible"
			@update:model-value="onCloseAddEdit" :title="selectedProjectRow === -1 ? 'Add Project' : 'Project Details'">
			<AddEdit_Project :projectid="selectedProjectRow" :newEntity="selectedProjectRow === -1"
				@close="onCloseAddEdit" @save="onSaveProject" />
		</el-dialog>

		<div><!--
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
      -->
		</div>

		<el-dialog v-if="editTaskVisible" v-model="editTaskVisible" @update:model-value="onCloseEditTask"
			:title="'Task Details'">
			<Edit_Task :projecttaskid="selectedTaskRow" @close="onCloseEditTask" @saveTask="onSaveTask"></Edit_Task>
		</el-dialog>

		<el-dialog width="90%" v-if="historyDialogVisible" v-model="historyDialogVisible"
			@update:model-value="onCloseHistory" :title="'Task History'">
			<ProjectTaskHistory :projecttaskid="selectedTaskRow"></ProjectTaskHistory>
		</el-dialog>

		<DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedProjectRow" entity_name="Project"
			:table="Entities.Projects" title="Delete Project" width="50%" @close="deleteDialogVisible = false"
			@delete="onDeleteProject" />
	</div>
</template>

<style scoped></style>
