<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { Avatar, Bell, Edit, Delete, Document, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddEdit_GreekStateGuarantee from "../components/AddEdit_GreekStateGuarantee.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { useViewVariablesMethods } from "../composables/viewVariablesMethods";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import ViewMosGreekStateGuaranteesView2 from "@/interfaces/ViewMosGreekStateGuaranteesView2";

const { showError } = useGenericMethodsVariables();

const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const { addEditVisible, dataCount, dataList, deleteDialogVisible, entityName, lastPage, loadPageHook, reloadOnGlobalFilterChange, selectedRow, viewOnly,
  filterChanged, handleClickEdit, onAdd, onApprove, onDelete, onFailSave } = useViewVariablesMethods();

const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const { onSortChange } = useSort();

export interface GreekStateGuaranteesFilters {
  fullname: string;
  vat: string;
  kua_paroxhs_eed: string;
  pending: boolean;
  pendingNew: boolean;
  hideRejected: boolean;
}

const filters = ref<GreekStateGuaranteesFilters>({
  fullname: "",
  vat: "",
  kua_paroxhs_eed: "",
  pending: false,
  pendingNew: false,
  hideRejected: false
})

const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const locked_rows = ref(false);
const DeleteSelectedRow = ref(0);

const deleteVisible = ref(false);
const isNewEntity = ref(false);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

const onCloseAddEdit = async () => {
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
};

// const handleClickEdit = async (row: any) => { //(row_id: number) => {
//   viewOnly.value = !isEditable(row);
//   selectedRow.value = row.id;
//   isNewEntity.value = false;
//   addEditVisible.value = true;
  // let ResultLockedRow = await .get("greek_state_guarantees?select=*&id=eq." + row.id);
  // LockedRow = ResultLockedRow.data[0].lockedrow;

  // if (LockedRow) {
  //   await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow == false) {
  //   let result = await .patch("greek_state_guarantees?id=eq." + row.id, { lockedrow: 'true' });
  //   await loadPage(lastPage.value);
  // }
// };

// const handleClickToUnlock = async (row_id: number) => {
//   let result = await .patch("greek_state_guarantees?id=eq." + row_id, { lockedrow: 'false' });
//   await loadPage(lastPage.value);
// }

// const UnlockAllLockedRows = async () => {
//   let result = await .patch("greek_state_guarantees?", { lockedrow: 'false' });
//   await loadPage(lastPage.value);
//   UnlockAllRowsVisible.value = false;
// }

onMounted(async () => {
  viewOnly.value = false; // TODO: check for some condition for view only?

  entityName.value = "Greek State Guarantee";
  loadPageHook.value = loadPage;
  reloadOnGlobalFilterChange.value = true;
  await loadPage(1);
  //MiscSettings
  // let result = await .get("config_misc_settings?select=*");
  // locked_rows.value = result.data[0].locked_rows;

  // console.log('locked_rows ' + locked_rows);

  // const ResultLockedRow = await .get("greek_state_guarantees?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

});

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  lastPage.value = page;

  const result = await mosapi.get_view<ViewMosGreekStateGuaranteesView2>(UIViews.GreekStateGuaranteesView, filters.value, lastPage.value, 10);
  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total;

  // let ResultLockedRow = await .get("greek_state_guarantees?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

  mainStore.isLoading = false;
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteGRGuarantee(row_id);
};

const DeleteGRGuarantee = async (row_id: number) => {
  const result = await mosapi.generic_entity_delete(Entities.GreekStateGuarantees, row_id);

  if (result.error) {
    showError(result.error);
    deleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const isEditable = (row: ViewMosGreekStateGuaranteesView2) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}

const handleClickAdd = () => {
  addEditVisible.value = true;
  isNewEntity.value = true;
  selectedRow.value = -1;
};

</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("GreekStateGuaranteesView.greek_state_guarantees") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.kua_paroxhs_eed" class="client-input" :placeholder="$t('GreekStateGuaranteesView.kya_of_greek_state_guarantee_provision')
            " @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.fullname" class="client-input" :placeholder="$t('DebtorView.debtor_company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.vat" class="client-input"
            :placeholder="$t('DebtorView.debor_tax_registration_nbr')" @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.pendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.hideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
          <el-row v-if="locked_rows == true">
            <el-col>
              <!-- <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip> -->
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('GreekStateGuaranteesView.loan_agreement')" />
      <el-table-column sortable fixed prop="kua_paroxhs_eed"
        :label="$t('GreekStateGuaranteesView.kya_of_greek_state_guarantee_provision')" />
      <el-table-column fixed prop="fullname" :label="$t('DebtorView.debtor_company_name')" sortable />
      <el-table-column fixed prop="vat_number" :label="$t('DebtorView.debor_tax_registration_nbr')" sortable />
      <el-table-column sortable fixed prop="poso_eggyhseos" :label="$t('GreekStateGuaranteesView.guaranteed_amount')" />
      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row, true)"
            :icon="isEditable(scope.row) ? Edit : View" :disabled="scope.row.lockedrow && locked_rows"
            style="background-color: #d6d6d6 !important;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="deleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
          <span v-if="
            mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
          ">
            <el-button class="btn-accept_square" @click="onApprove(Entities.GreekStateGuarantees, scope.row.id, true)" :icon="CircleCheck" />
            <el-button style="margin-right: 5px;" class="btn-reject_square" @click="onApprove(Entities.GreekStateGuarantees, scope.row.id, false)" :icon="CircleClose"/>
          </span>

          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white;" />
            </template>
          </el-popover>

          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <!-- <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" /> -->
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Greek State Guarantee' : 'Greek State Guarantee Details'">
      <AddEdit_GreekStateGuarantee :greek_state_guarantee_id="selectedRow"
        @close="onCloseAddEdit" @save="onAdd" @fail-save="onFailSave" :view_only="viewOnly" />
    </el-dialog>

    <el-dialog v-model="deleteDialogVisible" title="Greek State Guarantee Delete" width="50%">
      <span>Are you sure you want to delete this Property?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
