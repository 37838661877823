<script lang="ts" setup>
import { ref, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import { computed } from "vue";
import { DateTime } from "luxon";

const calc_form = ref<FormInstance>();

onMounted(async () => {

});

const enimerokef = ref<any>("");
const hmer_enarxis_ektokistikis_periodou = ref("");
const hmer_lixis_ektokistikis_periodou = ref("");
const kathisterimenokef = ref("");
const kathisterimenoiTokoi = ref("");
const epitokioperiodou = ref<any>("");
const paronomastis = ref<any>(360);

const label = computed(() => {
  const year_base: number = parseInt(paronomastis.value);
  const end_date = DateTime.fromJSDate(hmer_lixis_ektokistikis_periodou.value as any);
  const start_date = DateTime.fromJSDate(hmer_enarxis_ektokistikis_periodou.value as any);
  const imeres = end_date.diff(start_date, "days").toObject().days as number;
  const kefalaio = parseFloat(enimerokef.value);
  const epitokio = parseFloat(epitokioperiodou.value.replace(",", ".")) / 100;
  console.log('kefalaio' + enimerokef.value.text);
  console.log('epitokio' + epitokio);

  const apotelesma = (kefalaio * epitokio * imeres) / year_base;
  console.log('kefalaio' + kefalaio);
  console.log('epitokio' + epitokio);
  console.log('imeres' + imeres);
  console.log('year_base' + year_base);
  console.log('apotelesma' + apotelesma);
  if (isNaN(apotelesma)) {
    return "";
  } else {
    if (apotelesma >= 0) {
      return apotelesma.toFixed(2);
    } else return "";
  }
});

const labelk = computed(() => {
  let kkefalaio: number;
  let ktokoi: number;
  const year_base = parseInt(paronomastis.value);
  
  const end_date = DateTime.fromJSDate(hmer_lixis_ektokistikis_periodou.value as any);
  const start_date = DateTime.fromJSDate(hmer_enarxis_ektokistikis_periodou.value as any);
  const imeres = end_date.diff(start_date, "days").toObject().days as number;

  kkefalaio = parseFloat(kathisterimenokef.value);
  ktokoi = parseFloat(kathisterimenoiTokoi.value);

  if (isNaN(kkefalaio)) {
    kkefalaio = 0;
  }

  if (isNaN(ktokoi)) {
    ktokoi = 0;
  }

  const epitokio = parseFloat(epitokioperiodou.value.replace(",", ".")) / 100;
  const apotelesma = ((kkefalaio + ktokoi) * (epitokio + 0.025) * imeres) / year_base;

  if (isNaN(apotelesma)) {
    return "";
  } else {
    if (apotelesma >= 0) {
      return apotelesma.toFixed(2);
    } else return "";
  }
});

</script>

<template>
  <div class="content-wrapper summary-check-wrapper">
    <div>
      <el-row :gutter="10">
        <el-col :span="12">
          <h3 class="heading-page">{{ $t("ektokismos.ektokismos") }}</h3>
        </el-col>
        <el-col :span="12" class="portfolio-add-new-btn"> </el-col>
      </el-row>
    </div>
    <div>
      <div>
        <el-form ref="calc_form">
          <el-row align="middle" justify="center">
            <el-col :span="10">
              <el-row style="margin: 50px; background-color: rgb(237, 240, 245); padding: 30px; border-radius: 50px;">
                <el-col :span="24">
                  <el-form-item :label="$t('LoanAgreementDetails.vasi_ektokismou')">
                    <el-input-number type="text" name="{{ $t('LoanAgreementDetails.vasi_ektokismou') }}" id=""
                      v-model="paronomastis" size="large" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('ektokismos.hmer_enarxis_ekt_periodou')">
                    <el-date-picker v-model="hmer_enarxis_ektokistikis_periodou" ref="test" type="date"
                      style="width: 100%" size="large" format="DD-MM-YYYY" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('ektokismos.hmer_lixis_ekt_periodou')">
                    <el-date-picker v-model="hmer_lixis_ektokistikis_periodou" type="date" style="width: 100%"
                      size="large" format="DD-MM-YYYY" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('ektokismos.enimero_kef')">
                    <el-input type="text" name="{{ $t('ektokismos.enimero_kef') }}" id="" v-model="enimerokef"
                      size="large" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('ektokismos.kathisterimeno_kef')">
                    <el-input type="text" name="{{ $t('ektokismos.kathisterimeno_kef') }}" id=""
                      v-model="kathisterimenokef" size="large" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('ektokismos.kathisterimenoi_tokoi')">
                    <el-input type="text" name="{{ $t('ektokismos.kathisterimenoi_tokoi') }}" id=""
                      v-model="kathisterimenoiTokoi" size="large" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item :label="$t('ektokismos.epitokio')">
                    <el-input type="text" name="{{ $t('ektokismos.epitokio') }}" id="" v-model="epitokioperiodou"
                      size="large" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="14">
              <el-card shadow="always" :body-style="{ margin: '40px', height: '500px' }"
                style="border-radius: 30px 30px 30px; border: 3px dashed #5c6a9c; background-color: #edf0f5;">
                <el-row align="middle" justify="center">
                  <el-col :span="24">
                    <h1 style="text-align: center; font-size: 30px">
                      Interest Calculator
                    </h1>
                  </el-col>
                  <el-col :span="24">
                    <p style="text-align: center">
                      {{ $t("ektokismos.tokoi_enimeris") }}
                    </p>
                    <p style="font-size: 40px; text-align: center">{{ label }}</p>
                    <br />
                    <p style="text-align: center">{{ $t("ektokismos.tokoi_kath") }}:</p>

                    <p style="font-size: 40px; text-align: center">{{ labelk }}</p>
                  </el-col>
                  <el-col> </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <br />
          <br />
          <br />
          <br />
          <br />

          <br />
        </el-form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
