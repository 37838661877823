<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { useRoute } from "vue-router";
import { useMainStore } from "../store/main";
import { useRouter } from "vue-router";
import { Edit, Delete, Plus } from "@element-plus/icons-vue";
import Add_InterestRate from "./Add_InterestRate.vue";
import Edit_InterestRate from "./Edit_InterestRate.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { DateTime } from "luxon";
import { Entities } from "@/enums/Entities";
import Interest_Rates from "@/classes/DB_Entities/InterestRates";
import APIReply from "@/classes/APIReply";

const emits = defineEmits(["save", "close", "fail-save"]);
const { showError } = useGenericMethodsVariables();

const route = useRoute();
const mosapi = useMOSAPIStore();
const router = useRouter();

const dataCount = ref(0);
const lastPage = ref(0);
const DeleteDialogVisible = ref(false);
const mainStore = useMainStore();
// let detailsVisible = ref(false);
const deleteVisible = ref(false);
const DeleteSelectedRow = ref(0);
const selectedRow = ref(0);
const interest_category = ref<number>(0);
const newEntity = ref<boolean>(false);
const ΑddInterestVisible = ref(false);
const EditInterestVisible = ref(false);
const interest_row_id = ref();

const interest_categ_name = ref<string>();
const StoreInterestValues = ref<Interest_Rates[]>([]);
const config_interest_id = ref<number>(0);

// const props = defineProps<{
//   catg_id: number;
// }>();

onMounted(async () => {
  //await loadInterestRates();
  config_interest_id.value = parseInt(route.params.catg_id as string);
  await loadPage(1);
});

// const loadInterestRates = async () => {
//   mainStore.isLoading = true;

//     "interest_rates?select=*,config_interest!inner(*)&interest_category=eq." +route.params.catg_id

//   if (result.error) {
//     showError(result.error);

//     return;
//   }

//   StoreInterestValues.value = result.data;
//   let result2 = await .get(
//     "config_interest?select=interest&id=eq." + route.params.catg_id
//   );

//   if (result2.data) {
//     interest_categ_name.value = result2.data[0].interest;
//   }

//   mainStore.isLoading = false;
// };

const goBack = () => {
  router.push({
    name: "Platform Settings",
  });
};

const handleClickAdd = (interest_categ: number) => {
  interest_category.value = interest_categ;
  newEntity.value = false;
  ΑddInterestVisible.value = true;
  selectedRow.value = -1;
};

const handleClickEdit = (row_id: number, interest_categ: number) => {
  interest_row_id.value = row_id;
  interest_category.value = interest_categ;
  newEntity.value = false;
  EditInterestVisible.value = true;
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;
  lastPage.value = page;

  //const result = await mosapi.generic_entity_get(Entities.InterestRates, { interest_category: catg_id });
  const result = await mosapi.generic_entity_get(Entities.InterestRates, { interest_category: config_interest_id.value }, page, 10) as APIReply<Interest_Rates[]>;

  if (result.error) {
    showError(result.error);
    return;
  }

  StoreInterestValues.value = result.data;
  interest_categ_name.value = result.data[0].config_interest.interest;
  dataCount.value = result.total as number;

  for (let item of result.data) { // eslint-disable-line
    if (item.interest_value_date) {
      item.interest_value_date = DateTime.fromISO(item.interest_value_date)
        .setLocale('en-gb')
        .toFormat("dd/MM/yyyy");
    }
  }

  mainStore.isLoading = false;
};

const onSave = async () => {
  emits("save");
  selectedRow.value = 0;
  ElMessage({
    showClose: true,
    message: "Interest updated successfully",
    type: "success",
    duration: 1000,
  });
  ΑddInterestVisible.value = false;
  selectedRow.value = -1;
  await loadPage(lastPage.value);
};
const onSaveEdit = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest updated Successfully",
    type: "success",
    duration: 1000,
  });
  EditInterestVisible.value = false;
  selectedRow.value = -1;
  await loadPage(lastPage.value);
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteInterest(row_id);
};

const DeleteInterest = async (row_id: number) => {
  // let id = row_id as number;
  // let result = await mosapi.DeleteConfigInterestRateById(mainStore.loggedUser.id, id);
  const result = await mosapi.generic_entity_delete(Entities.InterestRates, row_id);
  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }
  onDelete();
};

const onDelete = async () => {
  selectedRow.value = 0;
  ElMessage({
    showClose: true,
    message: "Interest Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  await loadPage(lastPage.value);
};
</script>
<template>
  <div class="content-wrapper" style="height: 100%" v-loading="mainStore.isLoading">
    <el-row>
      <div style="margin-bottom: 10px">
        <h3 class="heading-page">Platform Settings / {{ interest_categ_name }}</h3>
      </div>
    </el-row>
    <el-row justify="end">
      <el-button link type="primary" size="small" class="btn-add" @click="handleClickAdd(config_interest_id)">
        <el-icon>
          <Plus />
        </el-icon>
      </el-button>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-table :data="StoreInterestValues" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="interest_value" :label="$t('PlatformSettingsView.interest_rate')"
            type="text" />
          <el-table-column fixed sortable prop="interest_value_date"
            :label="$t('PlatformSettingsView.interest_value_date')" type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail"
                @click="handleClickEdit(scope.row.id, scope.row.config_interest.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button link type="primary" size="small" class="btn-delete" @click="
                DeleteDialogVisible = true;
              DeleteSelectedRow = scope.row.id;
              ">
                <el-icon>
                  <Delete />
                </el-icon>
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div>
          <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>
      </el-col>
    </el-row>
    <el-row justify="end">
      <el-col :span="24">
        <el-button @click="goBack" type="primary" style="float: right">Back</el-button>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-if="ΑddInterestVisible" v-model="ΑddInterestVisible" title="Add Interest Values">
    <Add_InterestRate :interestCategory="interest_category" @close="ΑddInterestVisible = false" @save="onSave" />
  </el-dialog>
  <el-dialog v-if="EditInterestVisible" v-model="EditInterestVisible" title="Edit Interest Values">
    <Edit_InterestRate :interestRowID="interest_row_id" :interestCategory="interest_category"
      @close="EditInterestVisible = false" @save="onSaveEdit" />
  </el-dialog>

  <el-dialog v-model="DeleteDialogVisible" title="Delete Interest Rate?" width="50%">
    <span>Are you sure you want to delete this Interest Rate?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
        <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">Delete
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.demo-tabs>.el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
</style>
