<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Edit } from "@element-plus/icons-vue";
import { useMainStore } from "@/store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "@/store/mos_api";
import { MenuResponseDto } from "@/models";
import Edit_Menu from "@/components/Edit_Menu.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();

// Reactive variables
const dataList = ref<MenuResponseDto[]>([]);
const dataCount = ref(0);
const lastPage = ref(1);
const detailsVisible = ref(false);
const selectedRow = ref<number | undefined>(undefined);

// Methods
const openScreenDialog = (menuId: number) => {
	selectedRow.value = menuId;
	detailsVisible.value = true;
};

const loadPage = async (page: number) => {
	try {
		mainStore.isLoading = true;
		lastPage.value = page;

		const result = await mosapiStore.getMenus(page);
		if (result.error) throw new Error(t(`errors.${result.error}`));


		dataCount.value = result.data?.total || 0;
		dataList.value = result.data?.menus || [];
	} catch (error) {
		handleError(error, "Failed to load menus");
	} finally {
		mainStore.isLoading = false;
	}
};

const handleError = (error: unknown, defaultMessage: string) => {
	const message = error instanceof Error ? error.message : defaultMessage;
	showError(message);
};

const onSave = async () => {
	ElMessage.success("Menu updated successfully");
	detailsVisible.value = false;
	await loadPage(lastPage.value);
};

const onFailSave = () => {
	ElMessage.error("Failed to update menu");
	detailsVisible.value = false;
};

// Lifecycle hook
onMounted(() => {
	loadPage(1);
});
</script>

<template>
	<div class="content-wrapper">
		<h3 class="heading-page">{{ $t('menu.MenuList') }}</h3>

		<el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed">
			<el-table-column fixed prop="menu_name" :label="$t('MenuListView.menu_name')"
				:formatter="(row: any) => $t(`menu.${row.menu_name}`)" />
			<el-table-column fixed prop="privilege_name" :label="$t('MenuListView.privilege_name')"
				:formatter="(row: any) => row.privilege_name ?? ''" />
			<el-table-column fixed="right" :label="$t('global.operations')">
				<template #default="scope">
					<el-button link type="primary" size="small" class="btn-detail"
						@click="openScreenDialog(scope.row.id)" :icon="Edit" />
				</template>
			</el-table-column>
		</el-table>

		<el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
			@current-change="loadPage" />

		<el-dialog v-if="detailsVisible" v-model="detailsVisible" :title="$t('MenuDetails.title')">
			<Edit_Menu :menuId="selectedRow" @save="onSave" @close="detailsVisible = false" @fail-save="onFailSave" />
		</el-dialog>
	</div>
</template>

<style scoped>
.content-wrapper {
	padding: 16px;
}
</style>
