<script lang="ts" setup>
import { ref, onBeforeMount, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Edit, Plus, RefreshLeft, Watch } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import AddEdit_CustomField from "../components/AddEdit_CustomField.vue";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";

const { last_page, showError, showSuccess } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const DisableDialogVisible = ref(false);

const dataList = ref<any>([]);
const dataCount = ref(0);
const selectedRow = ref(0);
const addEditVisible = ref(false);
const DisableSelectedRow = ref(0);
const isNewEntity = ref(false);
const viewOnly = ref(false);

onBeforeMount(() => {
  mainStore.isLoading = true;
})

onMounted(async () => {
  mainStore.isLoading = false;
  loadPage(1);
});

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Custom Fields added Successfully",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(last_page.value);
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  last_page.value = page;
  page = page - 1;


  const result = await mosapi.generic_entity_get(Entities.CustomFields, {}, last_page.value);
  // const result:  = await .getPaginated("custom_fields", page);
  mainStore.isLoading = false;

  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total as number;
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Custom Field failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const handleClickAdd = () => {
  addEditVisible.value = true;
  isNewEntity.value = true;
  selectedRow.value = -1;
};

const handleClickEdit = (row: any) => {
  selectedRow.value = row.id;
  isNewEntity.value = false;
  addEditVisible.value = true;
};

const disableSelectedCustomField = async () => {
  const result = await mosapi.generic_entity_patch(Entities.CustomFields, DisableSelectedRow.value, { status: 0 });
  // result = await .patch("custom_field?id=eq." + DisableSelectedRow.value, { status: 0 } );

  if (result.error) {
    showError(result.error);
    return;
  }

  onDisable();
};

const onDisable = async () => {

  showSuccess("Custom Field Disabled Successfully", 1000);

  DisableDialogVisible.value = false;
  DisableSelectedRow.value = -1;
  loadPage(last_page.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
};


</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("CustomField.CustomField") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>


    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(last_page)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" size="small">
      <el-table-column sortable fixed prop="name_el" :label="$t('CustomField.name_el')" />
      <el-table-column sortable fixed prop="name_en" :label="$t('CustomField.name_en')" />
      <el-table-column sortable fixed prop="type" :label="$t('CustomField.type')" />
      <el-table-column sortable fixed prop="status" :label="$t('CustomField.status')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row)"
            :icon="Edit" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <!--  <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDisable(scope.row.id)" :icon="Delete" />        -->

        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="isNewEntity ? 'Add Custom Field' : 'Custom Field Details'">
      <AddEdit_CustomField :customfieldid="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit" @save="onAdd"
        :view_only="viewOnly" @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-model="DisableDialogVisible" title="Custom Field Disable" width="50%">
      <span>Are you sure you want to disable this Custom Field?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DisableDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="disableSelectedCustomField()">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
