import { ref } from "vue";

export function useValidation() {
  const validationErrors = ref<{ [id: string]: boolean }>({});

  const checkValidationsForError = (): boolean => {
    for (const validationError of Object.values(validationErrors.value)) {
      if (validationError === true) return true;
    }

    return false;
  };

  const onValidationChange = async (field: string, value: boolean) => {
    // console.log('validation change', field, value)
    validationErrors.value[field] = value;
  };

  return { validationErrors, checkValidationsForError, onValidationChange };
}
