import { ref } from 'vue';

export function useSort() {
  const sortColumn = ref<string>("");
  const sortOrder = ref<string>("");

  const onSortChange = async (sortObj: any) => {
    if (sortObj.order === null) {
      sortOrder.value = "";
    } else if (sortObj.order === "ascending") {
      sortColumn.value = sortObj.prop;
      sortOrder.value = "asc";
    } else {
      sortColumn.value = sortObj.prop;
      sortOrder.value = "desc";
    }
  };

  return { sortColumn, sortOrder, onSortChange }
}