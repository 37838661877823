<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { CircleCheck, CloseBold, Edit, Delete, Plus, Files } from "@element-plus/icons-vue";
import UserDetails from "../components/UserDetails.vue";
import AddUser from "../components/AddUser.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import * as ExcelJS from 'exceljs';
import { DateTime } from "luxon";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import { UserFilters } from "@/interfaces/UserFilters";
import { ViewUsers } from "@/interfaces/ViewUsers";

const { last_page, showLocalizedError, showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const { onSortChange, sortColumn, sortOrder } = useSort();
sortColumn.value = 'username';
sortOrder.value = 'asc'
const filters = ref<UserFilters>({
  username: "",
  name: "",
  company: "",
  email: "",
  ignore_inactive: true,
});

let timer = 0;
const dataList = ref<ViewUsers[]>([]);

const dataCount = ref(0);
const detailsVisible = ref(false);
const ΑddUserVisible = ref(false);
const selectedRow = ref(-1);

const handleClick = (userid: number) => {
  selectedRow.value = userid;
  detailsVisible.value = true;
};

const handleClickAdd = () => {
  ΑddUserVisible.value = true;
};

const exportExcel = async () => {

  const result2 = await mosapi.get_view<ViewUsers>(UIViews.Users, {}, 1, 1000);

  if (result2.error) {
    showLocalizedError(result2.error);
    return;
  }

  const data = result2.data;

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Projects');
  let currentRow = 1;

  let row = sheet.getRow(currentRow);

  //HEADERS
  row.getCell(1).value = 'Name';
  row.getCell(2).value = 'Username';
  row.getCell(3).value = 'Status';
  row.getCell(4).value = 'User Type';
  row.getCell(5).value = 'Portfolio';
  row = sheet.getRow(++currentRow);

  for (let i = 0; i < data.length; ++i) {

    row.getCell(1).value = data[i].name;
    row.getCell(2).value = data[i].username;

    if (data[i].active) {
      row.getCell(3).value = 'Active';
    } else {
      row.getCell(3).value = 'Inactive';
    }

    switch (data[i].user_type) {
      case 0: {
        row.getCell(4).value = 'Administrator';
        break;
      }
      case 1: {
        row.getCell(4).value = 'Supervisor';
        break;
      }
      case 2: {
        row.getCell(4).value = 'Agent';
        break;
      }
      case 3: {
        row.getCell(4).value = 'Digitizer';
        break;
      }
      default: {
        row.getCell(4).value = data[i].user_type
      }
    }

    if (data[i].portfolio_ids === null) {
      data[i].portfolio_ids = [];
    }

    // @ts-expect-error portfolio_ids cannot be null
    row.getCell(5).value = data[i].portfolio_ids.join(',');

    row = sheet.getRow(++currentRow);
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(blob));
  link.setAttribute(
    "download",
    `users.${DateTime.now().toFormat("yyyy-MM-dd_HHmmss")}.xlsx`
  );

  document.body.appendChild(link); // Required for FF
  link.click(); // Trigger file download
}

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  last_page.value = page;

  const result = await mosapi.get_view<ViewUsers>(UIViews.Users, { ...filters.value, orderBy: `${sortColumn.value}.${sortOrder.value}` }, last_page.value, 10);
  // const result:  = await .getPaginated("view_users?" + filterUrl, page);

  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;
  dataCount.value = result.total as number;

  mainStore.isLoading = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "User updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(last_page.value);
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "User Added Successfully",
    type: "success",
    duration: 1000,
  });

  ΑddUserVisible.value = false;

  loadPage(last_page.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "User failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const filterChanged = async () => {
	if (timer) {
		window.clearTimeout(timer);
		timer = 0;
	}

	timer = window.setTimeout(() => {
		loadPage(1);

		window.clearTimeout(timer);
		timer = 0;
	}, 500);
};

const inactiveFilterChange = async () => {
  await loadPage(1);
};

const userTypeFormatter = (row: ViewUsers) => {
  switch (row.user_type as UserType) {
    case UserType.Administrator:
      return "Administrator";
    case UserType.Supervisor:
      return "Supervisor";
    case UserType.Agent:
      return "Agent";
    case UserType.Digitizer:
      return "Digitizer";
  }
};

const localSortChange = async (sortObj: { column: unknown, prop: string, order: string }) => {
  onSortChange(sortObj);
  await loadPage(last_page.value);
};

const deactivateUser = async (id: number) => {
  if (id === null || id === undefined) {
    showError('Invalid user id for deactivation.');
    return;
  }

  const result = await mosapi.generic_entity_patch(Entities.Users, id, { active: false });
  // const result = await .patch('users?id=eq.' + id, { active: false })

  if (result.error) {
    showError(result.error);
    return;
  }

  await loadPage(last_page.value);
};
</script>

<template>
  <div class="content-wrapper">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("UsersView.users") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.username" class="client-input" :placeholder="$t('UsersView.username')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.name" class="client-input" :placeholder="$t('UsersView.name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.company" class="client-input" :placeholder="$t('UsersView.company')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.email" class="client-input" placeholder="Email" @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.ignore_inactive" size="small" :active-text="$t('global.ignore_inactive_users')"
            inactive-text="" @change="inactiveFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" :offset="11" class="user-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="handleClickAdd()"
            style="float: right; margin: 5px">
            <el-icon>
              <Plus />
            </el-icon>
          </el-button>
          <el-button link v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="primary" size="small"
            class="btn-add" style="float: right; margin: 5px" @click="exportExcel()" :icon="Files">Export
            Users</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="username" :label="$t('UsersView.username')" />
      <el-table-column sortable fixed prop="name" :label="$t('UsersView.name')" />
      <el-table-column sortable fixed prop="company" :label="$t('UsersView.company')" />
      <el-table-column sortable fixed prop="email" label="E-mail" />
      <el-table-column sortable fixed prop="user_type" :label="$t('UsersView.user_type')"
        :formatter="userTypeFormatter" />
      <el-table-column fixed :label="$t('global.active')">
        <template #default="scope">
          <el-icon v-if="scope.row.active === true" color="green" :size="24">
            <CircleCheck />
          </el-icon>
          <el-icon v-else color="red" :size="24">
            <CloseBold />
          </el-icon>

        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
            <el-icon>
              <Edit />
            </el-icon>
          </el-button>
          <el-popconfirm v-if="scope.row.title_name !== '' && mainStore.loggedUser.user_type === UserType.Administrator"
            :title="`${$t('UsersView.deactive_user_question')} (${scope.row.username})`"
            @confirm="deactivateUser(scope.row.id)" width="600">
            <template #reference>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" :icon="Delete" />
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible"
      @update:model-value="selectedRow = -1; loadPage(last_page);" title="User Details">
      <UserDetails :userid="selectedRow" :allow-type-change="true" @save="onSave"
        @close="selectedRow = -1; detailsVisible = false; loadPage(last_page);" @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-if="ΑddUserVisible" v-model="ΑddUserVisible" title="Add User">
      <AddUser @close="ΑddUserVisible = false" @save="onAdd" />
    </el-dialog>
  </div>
</template>

<style scoped></style>
