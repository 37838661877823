
enum EntitiesWithApproval {
    Approvals = "approvals",
    Auctions = "auctions",
    DebitsCredits = "debits_credits",
    Debtors = "debtor",
    // EktokismoiLoanAcc = "ektokismoi_loan_acc",
    // EktokismoiLoanAgreement = "ektokismoi_loan_agreement",
    GreekStateGuarantees = "greek_state_guarantees",
    Guarantees = "guarantees",
    LoanAccount = "loan_accounts",
    LoanAgreement = "loan_agreement",
    MOSClients = "mosclients",
    Monitoring = "monitoring",
    Portfolios = "portfolio",
    RECollateral = "re_collaterals",
    RealEstate = "real_estate",
    RegisterOfBonds = "register_of_bonds",
    NRECollateral = "rest_of_collaterals",
    ROBBond = "rob_bonds",
    ROBParticipatingBank = "rob_participating_banks",
    ROBTitle = "rob_titles",
    SummaryCheck = "summary_check_xtra",
    // Sunallages = "sunallages",
}

enum Entities {
    Unknown = "Unknown",
    ApografiFrontier = "apografi_frontier",
    Approvals = "approvals",
    Auctions = "auctions",
    ConfigAgent = 'config_agent',
    ConfigArgies = 'config_argies',
    ConfigCountry = 'config_country',
    ConfigCurrency = 'config_currency',
    ConfigFeePaymentFrequency = 'config_fee_payment_freq',
    ConfigGroup = 'config_group',
    ConfigInsuranceCompany = 'config_insurance_company',
    ConfigInsuranceRisk = 'config_insurance_risk',
    ConfigInterest = 'config_interest',
    ConfigKAD = 'config_kad',
    ConfigKlados = 'config_klados',
    ConfigLoanAccountProduct = 'config_loan_account_product',
    ConfigMiscSettings = 'config_misc_settings',
    ConfigObjectOfInsurances = 'config_obj_of_ins',
    ConfigPostalCode = 'config_postalcode',
    ConfigPrefecture = 'config_prefecture',
    ConfigProduct = 'config_product',
    ConfigShortDescriptions = 'config_short_descriptions',
    ConfigTypeOfAgencyFee = 'config_type_of_agency_fee',
    CustomFields = 'custom_fields',
    DDebtor = "d_debtor",
    DebitsCredits = "debits_credits",
    Debtors = "debtor",
    DigitizedFolders = 'digitized_folder',
    DigitizedFrontierUploads = 'digitized_frontier_uploads',
    DigitizedGelatinas = 'digitized_gelatin',
    DigitizedUploads = 'digitized_uploads',
    DocumentsDebtor = 'documents_debtor',
    DocumentsLoanAgreement = 'documents_loan_agreement',
    DocumentsRealEstate = 'documents_real_estate',
    // EktokismoiLoanAcc = "ektokismoi_loan_acc",
    EktokismoiLoanAgreement = "ektokismoi_loan_agreement",
    // ErrorMessages
    // FileUploads
    GreekStateGuarantees = "greek_state_guarantees",
    // Groups
    // GroupsPrivileges
    Guarantees = "guarantees",
    InterestRates = 'interest_rates',
    LALiksiario = "la_liksiario",
    LAMonitoring = "la_monitoring",
    LoanAccount = "loan_accounts",
    LoanAgreement = "loan_agreement",
    MenuList = 'menu_list',
    MOSClients = "mosclients",
    Portfolios = "portfolio",
    Printers = 'printers',
    Privileges = 'privileges',
    ProjectTaskHistory = 'ProjectTaskHistory',
    ProjectTaskValues = 'project_task_values',
    ProjectTasks = 'project_tasks',
    ProjectTasksEditHistory = 'project_tasks_edit_history',
    ProjectTemplates = 'project_templates',
    Projects = 'projects',
    Rates = 'rates',
    RECollateral = "re_collaterals",
    RealEstate = "real_estate",
    RegisterOfBonds = "register_of_bonds",
    NRECollateral = "rest_of_collaterals",
    ROBBond = "rob_bonds",
    ROBParticipatingBank = "rob_participating_banks",
    ROBTitle = "rob_titles",
    Screens = 'screens',
    SummaryCheck = "summary_check_xtra",
    // Sunallages = "sunallages",
    TaskTemplates = 'task_templates',
    // Users = 'users',
    FileUploads = 'file_uploads',

	EntityChangesApprovals = 'entity_approvals_changes',
    EntityChangesAuctions = 'entity_auctions_changes',
    EntityChangesDebitsCredits = 'entity_debits_credits_changes',
    EntityChangesDebtors = 'entity_debtor_changes',
    EntityChangesEktokismoiLoanAcc = 'entity_ektokismoi_loan_acc_changes',
    EntityChangesEktokismoiLoanAgreement = 'entity_ektokismoi_loan_agreement_changes',
    EntityChangesGreekStateGuarantees = 'entity_greek_state_guarantees_changes',
    EntityChangesGuarantees = 'entity_guarantees_changes',
    EntityChangesLoanAccount = 'entity_loan_accounts_changes',
    EntityChangesLoanAgreement = 'entity_loan_agreement_changes',
    EntityChangesMOSClients = 'entity_mosclients_changes',
    EntityChangesPortfolios = 'entity_portfolio_changes',
    EntityChangesRECollateral = 'entity_re_collaterals_changes',
    EntityChangesRealEstate = 'entity_real_estate_changes',
    EntityChangesRegisterOfBonds = 'entity_register_of_bonds_changes',
    EntityChangesNRECollateral = 'entity_rest_of_collaterals_changes',
    EntityChangesROBBond = 'entity_rob_bonds_changes',
    EntityChangesROBParticipatingBank = 'entity_rob_participating_banks_changes',
    EntityChangesROBTitle = 'entity_rob_titles_changes',
    EntityChangesSummaryCheck = 'entity_summary_check_xtra_changes',
    EntityChangesSunallages = 'entity_sunallages_changes',
}

export { Entities, EntitiesWithApproval };
