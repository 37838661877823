<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import Approval from "../classes/DB_Entities/Approval";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import AcceptAllPending from "./AcceptAllPending.vue";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
import APIReply from "../classes/APIReply";

const mosapi = useMOSAPIStore();
const props = defineProps<{
  row_id: number;
  view_only: boolean;
}>();

const mainStore = useMainStore();
const { t } = useI18n({ useScope: "global" });

const loanAgreementsList = ref<{value: number, label: string}[]>([]);

const {
  setup_decision_listener,
  hasPendingChanges,
  fullObj,
  table_name,
  clickAcceptAll,
  isNewEntity,
  loadData,
  save,
  close,
  cleanObject,
  resetObjects,
  showError
} = useGenericMethodsVariables();

onMounted(async () => {
  setup_decision_listener(fixPendingSelects);
  mainStore.isLoading = true;

  table_name.value = Approval.getTableNameStatic();
  cleanObject.value = new Approval();

  //console.log("approval_id", props.row_id);

  await loadLoanAgreements();

  if (props.row_id === -1) {
    resetObjects();
    mainStore.isLoading = false;

    return;
  }

  await loadData(props.row_id);
  await fixPendingSelects();
  mainStore.isLoading = false;
});

const loadLoanAgreements = async () => {
  loanAgreementsList.value = [];
  const result = await mosapi.generic_entity_get(Entities.LoanAgreement) as APIReply<LoanAgreement[]>;
  //let query = `view_mosloanagreement_view1?approve_status=eq.1&debtor_approve_status=eq.1`;
  // result = await .get(query);

  if (result.error) {
    showError(result.error);
    return;
  }

  if (result.data.length === 0) {
    showError(t("errors.no_loan_agreements"), 1500);
  } else {
    for (let i = 0; i < result.data.length; i++) {
      loanAgreementsList.value.push({
        value: result.data[i].id,
        label: result.data[i].ar_sumbashs + " (" + (result.data[i].debtor?.fullname || '') + ")",
      });
    }
  }
};

const onPreSave = async () => {
  if (isNewEntity()) {
    if (fullObj.value.loan_agreement_id.originalValue === 0) {
      showError(t("errors.missing_loan_agreement"), 1500);
      return;
    }
  }

  if (fullObj.value.hmer_apost_egkrishs.originalValue === "") {
    showError(t("errors.missing_hmer_apost_egkrishs"), 1500);
    return;
  }

  if (fullObj.value.hmer_anatheshs_mos.originalValue === "") {
    showError(t("errors.missing_hmer_anatheshs_mos"), 1500);
    return;
  }

  if (fullObj.value.ektim_hmer_ylop.originalValue === "") {
    showError(t("errors.missing_ektim_hmer_ylop"), 1500);
    return;
  }

  if (fullObj.value.hmer_olokl.originalValue === "") {
    showError(t("errors.missing_hmer_olokl"), 1500);
    return;
  }

  delete fullObj.value.loan_agreement;

  await save();
  resetObjects();
};

const fixPendingSelects = async () => {
  for (const field of Object.keys(fullObj.value)) {
    if (fullObj.value[field].pendingValue === undefined) continue; // Skip field if no pending value

    // Loan Agreement
    if (field === "loan_agreement_id") {
      // const loan1 = await .get(
      //   `loan_agreement?select=ar_sumbashs,debtor!inner(fullname)&id=eq.` +
      //   fullObj.value[field].pendingValue.value
      // );
      const loan1 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: fullObj.value[field].pendingValue.value}) as APIReply<LoanAgreement[]>;
      fullObj.value[field].pendingValue[
        "pendingDisplayValue"
      ] = `${loan1.data[0].ar_sumbashs} (${loan1.data[0].debtor?.fullname || ''})`;

      // const loan2 = await .get(
      //   `loan_agreement?select=ar_sumbashs,debtor!inner(fullname)&id=eq.` +
      //   fullObj.value[field].originalValue
      // );
      const loan2 = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: fullObj.value[field].originalValue}) as APIReply<LoanAgreement[]>;
      fullObj.value[field].pendingValue[
        "originalDisplayValue"
      ] = `${loan2.data[0].ar_sumbashs} (${loan2.data[0].debtor?.fullname || ''})`;
    }
  }
};
</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll(props.row_id)" />
      <p />
      <el-row :gutter="10">
        <el-col :span="24">
          <EntityField type="select" :label="$t('ApprovalDetailsView.loan_agreement')"
            :object="fullObj.loan_agreement_id" :options="loanAgreementsList" 
            :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.approval_decision_send_date')"
            :object="fullObj.hmer_apost_egkrishs"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.account_manager')" :object="fullObj.account_manager"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.approval_decision_subject')"
            :object="fullObj.thema_egkrishs"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.mos_assignment_date')"
            :object="fullObj.hmer_anatheshs_mos"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.mos_estimated_imlementation_date')"
            :object="fullObj.ektim_hmer_ylop"  :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.middle_officer')" :object="fullObj.middle_officer"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="datepicker" :label="$t('ApprovalDetailsView.end_date')" :object="fullObj.hmer_olokl"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="input" :label="$t('ApprovalDetailsView.lawyer')" :object="fullObj.lawyer"
             :is-disabled="props.view_only" />
        </el-col>
        <el-col :span="24">
          <EntityField type="textarea" :label="$t('ApprovalDetailsView.comments')" :object="fullObj.comments"
             :is-disabled="props.view_only" />
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
