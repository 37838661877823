<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DebitCredit from "../classes/DB_Entities/DebitCredit";
import CreditType from "../enums/CreditType";
import DebitCreditType from "../enums/DebitCreditType";
import { Entities } from "@/enums/Entities";
import { GenericEntity } from "@/types/GenericEntity";
import ConfigInterest from "@/classes/DB_Entities/ConfigInterest";
import APIReply from "@/classes/APIReply";

const props = defineProps<{
    loan_account_id: number;
    row_id: number;
    view_only: boolean;
    type: DebitCreditType;
}>();

const emits = defineEmits(["save", "close", "save_and_approve"]);

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const { t } = useI18n({ useScope: "global" });

const {
    close,
    showError,
} = useGenericMethodsVariables();

const dbEntry = ref(new DebitCredit());
let originalAmount = 0;
let originalDate = "";
let originalPerigrafi = "";
let originalCreditType = CreditType.DEFAULT;
let originalConfigInterestId: number | null = null;
let originalPerithorio: number | null = null;
let originalEisfora: number | null = null;
let originalPosostoTokwnYperhmerias: number | null = null;

const interest_rate_categories_list = ref<ConfigInterest[]>([]);

onMounted(async () => {
    mainStore.isLoading = true;

    console.log("debit_credit id", props.row_id);

    if (props.type === DebitCreditType.InterestTypeChanges) {
        const config_interest_entries = await mosapi.generic_entity_get(Entities.ConfigInterest, {}, 1, 1000);
        interest_rate_categories_list.value = config_interest_entries.data as ConfigInterest[];
    }

    // Skip loading if it's a new entry
    if (props.row_id === 0) {
        mainStore.isLoading = false;
        return;
    }

    const result = await mosapi.generic_entity_get<DebitCredit[]>(Entities.DebitsCredits, { id: props.row_id });
    console.log(JSON.stringify(result));

    originalAmount = result.data[0].amount;
    originalDate = result.data[0].date;
    originalPerigrafi = result.data[0].perigrafi
    originalCreditType = result.data[0].credit_type;
    originalConfigInterestId = result.data[0].config_interest_id;
    originalPerithorio = result.data[0].perithorio === '' ? null : result.data[0].perithorio;
    originalEisfora = result.data[0].eisfora === '' ? null : result.data[0].eisfora;
    originalPosostoTokwnYperhmerias = result.data[0].pososto_tokwn_yperhmerias === '' ? null : result.data[0].pososto_tokwn_yperhmerias;

    dbEntry.value = result.data[0];
    mainStore.isLoading = false;
});

const onSave = async () => {

    if ((dbEntry.value as any).perithorio === '' || (dbEntry.value as any).perithorio === undefined)
        (dbEntry.value as any).perithorio = null;

    if ((dbEntry.value as any).eisfora === '' || (dbEntry.value as any).eisfora === undefined)
        (dbEntry.value as any).eisfora = null;

    if ((dbEntry.value as any).pososto_tokwn_yperhmerias === '' || (dbEntry.value as any).pososto_tokwn_yperhmerias === undefined)
        (dbEntry.value as any).pososto_tokwn_yperhmerias = null;

    if (props.type === DebitCreditType.InterestTypeChanges) {
        if (dbEntry.value.perithorio === null) {
            showError("Περιθώριο δεν μπορεί να είναι κενό.");
            return;
        }

        if (dbEntry.value.eisfora === null) {
            showError("Εισφορά δεν μπορεί να είναι κενή.");
            return;
        }

        if (dbEntry.value.pososto_tokwn_yperhmerias === null) {
            showError("Επιτόκιο υπερημερίας δεν μπορεί να είναι κενό.");
            return;
        }
    }

    console.log('Gonna save', JSON.stringify(dbEntry.value));

    // await mosapi.generic_entity_post

    // New entry
    if (props.row_id === 0) {
        const newDebitCredit = new DebitCredit();
        newDebitCredit.loan_account_id = props.loan_account_id.toString();
        newDebitCredit.type = props.type;
        newDebitCredit.date = dbEntry.value.date;
        newDebitCredit.amount = dbEntry.value.amount;
        newDebitCredit.perigrafi = dbEntry.value.perigrafi;
        newDebitCredit.credit_type = dbEntry.value.credit_type;
        newDebitCredit.config_interest_id = dbEntry.value.config_interest_id;
        newDebitCredit.perithorio = dbEntry.value.perithorio;
        newDebitCredit.eisfora = dbEntry.value.eisfora;
        newDebitCredit.pososto_tokwn_yperhmerias = dbEntry.value.pososto_tokwn_yperhmerias;

        mainStore.isLoading = true;
        await mosapi.generic_entity_post(Entities.DebitsCredits, newDebitCredit as GenericEntity);
        mainStore.isLoading = false;
        emits("save");
        return;
    }

    //     const obj: any = JSON.parse(JSON.stringify(newDebitCredit));

    //     delete obj.id;

    //     const result = await .post_data("debits_credits", obj);

    //     // Add a new approval request for the new item
    //     const entityObj: any = {};
    //     entityObj.entity_id = result.data[0].id;
    //     entityObj.editor_user_id = mainStore.loggedUser.id;
    //     entityObj.field = 'status';
    //     entityObj.value = '1';

    //     // Auto-approved for Admin/Supervisor
    //     // if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
    //     //     entityObj.status = 1;
    //     // }

    //     if (result.error) {
    //         showError(result.error.code + ' - ' + result.error);
    //         return;
    //     }

    //     const result2 = await .post_data("entity_debits_credits_changes", entityObj);

    //     // In case the entity change request fails, delete the new debit_credit item
    //     if (result2.error) {
    //         showError(result2.error.code + ' - ' + result2.error.message);
    //         await .delete_data("debits_credits?id=eq." + result.data[0].id);
    //         return;
    //     }

    //     showSuccess("Entry added successfully");

    //     if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
    //         emits("save_and_approve", [{ id: result2.data[0].id, entity_id: result2.data[0].id, field: 'status', value: '1' }]);
    //     } else {
    //         emits("save");
    //     }

    //     return;
    // }

    // Edit existing entry

    if (originalAmount === dbEntry.value.amount && originalDate === dbEntry.value.date && originalPerigrafi === dbEntry.value.perigrafi && originalCreditType === dbEntry.value.credit_type
        && originalConfigInterestId === dbEntry.value.config_interest_id && originalPerithorio === dbEntry.value.perithorio && originalEisfora === dbEntry.value.eisfora
        && originalPosostoTokwnYperhmerias === dbEntry.value.pososto_tokwn_yperhmerias
    ) {
        emits('close');
        return;
    }

    mainStore.isLoading = true;

    if (originalAmount !== dbEntry.value.amount) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'amount', value: dbEntry.value.amount?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'amount';//     entityObj.value = dbEntry.value.amount;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);
    // console.log("originalDate", originalDate, "dbEntry.value.date", dbEntry.value.date, 'isEqual', originalDate === dbEntry.value.date);

    if (originalDate !== dbEntry.value.date) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'date', value: dbEntry.value.date?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'date';//     entityObj.value = dbEntry.value.date;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);

    if (originalPerigrafi !== dbEntry.value.perigrafi) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'perigrafi', value: dbEntry.value.perigrafi?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'perigrafi';//     entityObj.value = dbEntry.value.perigrafi;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);

    if (originalCreditType !== dbEntry.value.credit_type) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'credit_type', value: dbEntry.value.credit_type?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'credit_type';//     entityObj.value = dbEntry.value.credit_type;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);

    if (originalConfigInterestId !== dbEntry.value.config_interest_id) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'config_interest_id', value: dbEntry.value.config_interest_id?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'config_interest_id';//     entityObj.value = dbEntry.value.config_interest_id;

    if (originalPerithorio !== dbEntry.value.perithorio) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'perithorio', value: dbEntry.value.perithorio?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'perithorio';//     entityObj.value = dbEntry.value.perithorio;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);

    if (originalEisfora !== dbEntry.value.eisfora) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'eisfora', value: dbEntry.value.eisfora?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'eisfora';//     entityObj.value = dbEntry.value.eisfora;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);

    if (originalPosostoTokwnYperhmerias !== dbEntry.value.pososto_tokwn_yperhmerias) {
        await mosapi.add_entity_change({ entity_type: Entities.DebitsCredits, entity_id: dbEntry.value.id, field: 'pososto_tokwn_yperhmerias', value: dbEntry.value.pososto_tokwn_yperhmerias?.toString() as string });
    }
    //     const entityObj: any = {};//     entityObj.entity_id = dbEntry.value.id;//     entityObj.editor_user_id = mainStore.loggedUser.id;//     entityObj.field = 'pososto_tokwn_yperhmerias';//     entityObj.value = dbEntry.value.pososto_tokwn_yperhmerias;
    //     const result = await .post_data("entity_debits_credits_changes", entityObj);

    mainStore.isLoading = false;

    emits("save");
};

const CreditTypeOptions = [
    {
        value: CreditType.DEFAULT,
        label: t("CreditType.DEFAULT"),
    },
    {
        value: CreditType.EXODO,
        label: t("CreditType.EXODO"),
    },
    {
        value: CreditType.KEFALAIO,
        label: t("CreditType.KEFALAIO"),
    },
    {
        value: CreditType.TOKOI_TOKWN,
        label: t("CreditType.TOKOI_TOKWN"),
    },
];

</script>

<template>
    <div v-loading="mainStore.isLoading">
        <el-form>
            <el-row :gutter="10">
                <el-col :span="24">
                    <b>{{ $t('DebitCreditList.date') }}</b>
                    <el-date-picker v-model="dbEntry.date" type="date" placeholder="Pick a Date" format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD" />
                </el-col>
                <el-col :span="24"
                    v-if="props.type !== DebitCreditType.HmerTokou && props.type !== DebitCreditType.InterestTypeChanges">
                    <p />
                    <b>{{ $t('DebitCreditList.amount') }}</b>
                    <el-input v-model="dbEntry.amount" type="number" step="0.01" placeholder="Amount" />
                </el-col>
                <el-col :span="24">
                    <p />
                    <b>{{ $t('DebitCreditList.perigrafi') }}</b>
                    <el-input v-model="dbEntry.perigrafi" type="string" />
                </el-col>

                <el-col :span="24" v-if="props.type === DebitCreditType.Credit">
                    <p />
                    <b>{{ $t('DebitCreditList.credit_type') }}</b>
                    <el-select v-model="dbEntry.credit_type" class="m-2 header-filter" filterable placeholder=" ">
                        <el-option v-for="item in CreditTypeOptions" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </el-col>

                <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
                    <p />
                    <b>{{ $t('DebitCreditList.interest_category') }}</b>
                    <el-select v-model="dbEntry.config_interest_id" class="m-2 header-filter" filterable
                        placeholder=" ">
                        <el-option v-for="item in interest_rate_categories_list" :key="item.id" :label="item.interest"
                            :value="item.id" />
                    </el-select>

                </el-col>

                <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
                    <p />
                    <b>{{ $t('DebitCreditList.perithorio') }}</b>
                    <el-input v-model="dbEntry.perithorio" type="number" step="0.01" placeholder="Amount" />
                </el-col>

                <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
                    <p />
                    <b>{{ $t('DebitCreditList.eisfora') }}</b>
                    <el-input v-model="dbEntry.eisfora" type="number" step="0.01" placeholder="Amount" />
                </el-col>

                <el-col :span="24" v-if="props.type === DebitCreditType.InterestTypeChanges">
                    <p />
                    <b>{{ $t('DebitCreditList.pososto_tokwn_yperhmerias') }}</b>
                    <el-input v-model="dbEntry.pososto_tokwn_yperhmerias" type="number" step="0.01"
                        placeholder="Amount" />
                </el-col>

            </el-row>

            <el-row :gutter="10">
                <el-col :span="24">
                    <el-form-item class="btn-wrapper-components">
                        <el-button v-if="!props.view_only" type="primary" @click="onSave">{{ $t('generic.save')
                            }}</el-button>
                        <el-button @click="close">{{ $t('generic.close') }}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<style scoped></style>
