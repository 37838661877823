<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMOSAPIStore } from "../store/mos_api";
import { useMainStore } from "@/store/main";
import { ElUpload, UploadFile, UploadFiles } from "element-plus";
import { Memo, RefreshLeft } from "@element-plus/icons-vue";
import type { UploadInstance } from "element-plus";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { DateTime } from "luxon";
import { Entities } from "@/enums/Entities";
import { GenericEntity } from "@/types/GenericEntity";

const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const uploadRef = ref<UploadInstance>();

const dataList = ref<GenericEntity[]>([]);
const dataCount = ref(0);
const lastPage = ref(0);
const fileSelected = ref(false);

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  lastPage.value = page;
  page = page - 1;

  const result = await mosapi.generic_entity_get(Entities.FileUploads, {}, lastPage.value)
  // const result:  = await .getPaginated(`file_uploads?order=insert_timestamp.desc`, page);

  if (result.error) {
    showError(result.error);

    return;
  }

  dataList.value = result.data as GenericEntity[];
  dataCount.value = result.total as number;
  mainStore.isLoading = false;
};

const submitUpload = async () => {
  uploadRef.value!.submit();
};

const uploadComplete = async (response: unknown) => { // , uploadFile: UploadFile, uploadFiles: UploadFiles
  console.log(response);
  loadPage(lastPage.value);
};

const addedFile = async (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
  for (const file of uploadFiles) {
    console.log(file.status)
    if (file.status === "ready") {
      fileSelected.value = true;
    } else if (file.status === "success") {
      fileSelected.value = false;
      uploadRef.value!.clearFiles();
    }
  }
}

const removedFile = async () => {
  fileSelected.value = false;
  console.log('Removed file for upload.');
}

const onError = async () => {
  fileSelected.value = false;
  console.log('There was an error while uploading the file.');
}

const downloadLog = async (saved_filename: string) => {
  const res = await mosapi.downloadLog(saved_filename);

  if (res !== '') {
    showError(res);
  }
};

const statusText = [
  "Waiting for import",
  "Under process",
  "Completed",
  "Error"
]
</script>

<template>
  <div class="content-wrapper">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("UploadView.upload_title") }}</h3>
      </el-col>
    </el-row>
    <!-- action="https://mos-api-dev.web-idea.gr/upload" -->
    <!-- action="https://mos-api.web-idea.gr/upload" -->
    <!-- action="http://localhost:3000/upload" -->
    <el-row :gutter="10">
      <el-col :span="12">
        <el-upload ref="uploadRef" class="upload-demo" :action="`${mosapi.apiURL()}upload`" :limit="1"
          :auto-upload="false" :on-success="uploadComplete" :headers="{ Authorization: 'Bearer ' + mosapi.token }"
          @change="addedFile" @remove="removedFile" @error="onError" accept=".xls,.xlsx">
          <template #trigger>
            <el-button type="primary">Select file</el-button>
          </template>
          <el-button v-if="fileSelected" class="ml-3" type="success" @click="submitUpload">
            Upload to server
          </el-button>
          <template #tip>
            <div class="el-upload__tip text-red">
              Limit 1 file. Extensions allowed: xls, xlsx
            </div>
          </template>
        </el-upload>
      </el-col>
      <el-col>
        <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
          style="float: right; margin: 2.5px" :icon="RefreshLeft" />
      </el-col>
    </el-row>

    <el-table v-loading="mainStore.isLoading" :data="dataList" stripe :border="true" table-layout="fixed"
      :cell-style="{ padding: '0', height: '15px' }" size="small" :header-row-style="{ fontSize: '11px' }">
      <el-table-column fixed prop="id" :label="$t('UploadView.id')" width="55" />
      <el-table-column fixed prop="insert_timestamp" :label="$t('UploadView.insert_timestamp')">
        <template #default="scope">
          {{ DateTime.fromISO(scope.row.insert_timestamp).toFormat("dd/MM/yyyy HH:MM") }}
        </template></el-table-column>
      <el-table-column fixed prop="original_filename" :label="$t('UploadView.original_filename')" />
      <el-table-column fixed prop="saved_filename" :label="$t('UploadView.saved_filename')" />
      <el-table-column fixed label="Sheets" align="center" width="100">
        <el-table-column label="Debtor" width="30">
          <el-table-column prop="debtor_add" label="A" width="30" />
          <el-table-column prop="debtor_update" label="U" width="30" />
        </el-table-column>
        <el-table-column label="Loan Agr." width="40">
          <el-table-column prop="loan_agreement_add" label="A" width="40" />
          <el-table-column prop="loan_agreement_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="Loan Acc." width="40">
          <el-table-column prop="loan_accounts_add" label="A" width="40" />
          <el-table-column prop="loan_accounts_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="ROB P.B." width="40">
          <el-table-column prop="rob_particip_banks_add" label="A" width="40" />
          <el-table-column prop="rob_particip_banks_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="ROB Bonds" width="40">
          <el-table-column prop="rob_bonds_add" label="A" width="40" />
          <el-table-column prop="rob_bonds_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="Real Est." width="40">
          <el-table-column prop="real_estate_add" label="A" width="40" />
          <el-table-column prop="real_estate_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="RE Col." width="40">
          <el-table-column prop="re_collaterals_add" label="A" width="40" />
          <el-table-column prop="re_collaterals_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="Auctions" width="40">
          <el-table-column prop="auctions_add" label="A" width="40" />
          <el-table-column prop="auctions_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="Guarant." width="40">
          <el-table-column prop="guarantees_add" label="A" width="40" />
          <el-table-column prop="guarantees_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="G.S. Guarant." width="40">
          <el-table-column prop="greek_state_guarantees_add" label="A" width="40" />
          <el-table-column prop="greek_state_guarantees_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="NRE Col." width="40">
          <el-table-column prop="nre_collaterals_add" label="A" width="40" />
          <el-table-column prop="nre_collaterals_update" label="U" width="40" />
        </el-table-column>
        <el-table-column label="Approvals" width="40">
          <el-table-column prop="approvals_add" label="A" width="40" />
          <el-table-column prop="approvals_update" label="U" width="40" />
        </el-table-column>
      </el-table-column>
      <el-table-column fixed prop="status" :label="$t('UploadView.status')">
        <template #default="scope">
          {{ statusText[scope.row.status] }}
        </template>
      </el-table-column>
      <el-table-column fixed label="Run time">
        <template #default="scope">
          {{
            Number.isNaN(DateTime.fromISO(scope.row.completed_at).diff(DateTime.fromISO(scope.row.insert_timestamp),
              'seconds').seconds) ? "" :
              DateTime.fromISO(scope.row.completed_at).diff(DateTime.fromISO(scope.row.insert_timestamp), 'seconds').seconds
              + " sec"
          }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="username" :label="$t('UploadView.username')" />
      <el-table-column fixed :label="$t('global.operations')">
        <template #default="scope">
          <el-tooltip class="box-item" effect="dark" content="Download Import Log" placement="top">
            <el-button type="primary" size="small" class="btn-detail" :icon="Memo"
              @click="downloadLog(scope.row.saved_filename)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped></style>
