<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { useMainStore } from "../store/main";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Download } from "@element-plus/icons-vue";
import { DateTime } from "luxon";
import * as ExcelJS from 'exceljs';
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "../store/mos_api";

const mosapi = useMOSAPIStore();
const mainStore = useMainStore();

const { t, locale } = useI18n({ useScope: "global" });

interface ProjectTaskHistory {
  project_task_id: number;
  name_en: string;
  name_el: string;
  user: string;
  field_el: string;
  field_en: string;
  value: string;
  insert_timestamp: string;
}

const {
  isLoading,
  showError
} = useGenericMethodsVariables();

const props = defineProps<{
  projecttaskid: number;
}>();

const data = ref<ProjectTaskHistory[]>([]);
const dataCount = ref(0);

const loadProjectTaskHistory = async () => {
  mainStore.isLoading = true;

  data.value = [];

  
  //result = await .get(`view_project_task_history?project_task_id=eq.${props.projecttaskid}&orderFIX=insert_timestamp.desc`);
 let result = await mosapi.generic_entity_get(Entities.ProjectTaskHistory, {project_task_id: props.projecttaskid, orderBy: 'insert_timestamp.desc'});

  if (result.error) {
    showError("Error loading data:" + result.error, 2500);
    mainStore.isLoading = false;
    return;
  }

  data.value = result.data;

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  mainStore.isLoading = false;
};

onMounted(async () => {
  await loadProjectTaskHistory();
});

const exportExcel = async () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('TaskHistory');
  let currentRow = 1;

  let row = sheet.getRow(currentRow);

  //HEADERS
  row.getCell(1).value = 'Project Task';
  row.getCell(2).value = 'User';
  row.getCell(3).value = 'Field';
  row.getCell(4).value = 'Value';
  row.getCell(5).value = 'Timestamp';

  for (const historyEntry of data.value) {
    row = sheet.getRow(++currentRow);
    row.getCell(1).value = (historyEntry as any)['name_' + locale.value];
    row.getCell(2).value = historyEntry.user;
    row.getCell(3).value = (historyEntry as any)['field_' + locale.value];
    row.getCell(4).value = historyEntry.value;
    row.getCell(5).value = DateTime.fromISO(historyEntry.insert_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(blob));
  link.setAttribute(
    "download",
    `project_task_history.${DateTime.now().toFormat("yyyy-MM-dd_HHmmss")}.xlsx`
  );
  document.body.appendChild(link); // Required for FF

  link.click(); // Trigger file download
}


</script>

<template>
  <div v-loading="mainStore.isLoading" class="content-wrapper">
    <el-button type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px" @click="exportExcel()"
      :icon="Download" />

    <el-form>
      <el-table :data="data" border table-layout="fixed" sortable="custom" size="small" max-height="600"
        class="reporttable">
        <el-table-column :label="t('ProjectTaskHistory.project_task')" width="140">
          <template #default="scope">
            {{ scope.row['name_' + locale] }}
          </template>
        </el-table-column>
        <el-table-column label="User" prop="user" width="140" />
        <el-table-column :label="t('ProjectTaskHistory.field')" width="140">
          <template #default="scope">
            {{ scope.row['field_' + locale] }}
          </template>
        </el-table-column>
        <el-table-column :label="t('ProjectTaskHistory.value')" prop="value" width="140" />
        <el-table-column :label="t('ProjectTaskHistory.timestamp')" width="140">
          <template #default="scope">
            {{ DateTime.fromISO(scope.row.insert_timestamp).toFormat('dd/MM/yyyy HH:mm:ss') }}
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
