<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import Debtor from "../classes/DB_Entities/Debtor";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { usePendingChanges } from "../composables/pendingChanges";
import { useValidation } from "../composables/validation";
import AcceptAllPending from "./AcceptAllPending.vue";
import { DateTime } from "luxon";
import ConfigKlados from "../classes/DB_Entities/ConfigKlados";
import ConfigKad from "../classes/DB_Entities/ConfigKad";
import Portfolio from "../classes/DB_Entities/Portfolio";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import { ViewMosDebtorsView2 } from "@/interfaces/ViewMosDebtorsView2";
import { orderBy } from "lodash";
import APIReply from "../classes/APIReply";
import PostalCountries from "../classes/DB_Entities/ConfigCountry";
import PostalCodes from "../classes/DB_Entities/ConfigPostalCode";
import PostalDistrict from "../classes/DB_Entities/ConfigPrefecture";
import Klados from "../classes/DB_Entities/ConfigKlados";
import Kad from "../classes/DB_Entities/ConfigKad";
import ConfigGroup from "../classes/DB_Entities/ConfigGroup";
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const { t } = useI18n({ useScope: "global" });

const agents = ref<any>([]);
const loanAgreements = ref<any>([]);

const props = defineProps<{
	row_id: number;
}>();

const activeName = ref("first");
const portfolios = ref<any>([]);
const groups = ref<any>([]);
const countriesData = ref<any>([]);
const allPrefectures = ref<any>([]);
const allPostCodes = ref<any>([]);
const allKladoi = ref<any>([]);
const allKAD = ref<any>([]);
const filteredPostCodes = ref<any>([]);
const filteredPostalPostCodes = ref<any>([]);
const linkTable = ref<any>([]);

const { multipleFieldOne, multipleFieldTwo, warningMultipleVisible } = usePendingChanges();
const {
	cleanObject,
	fullObj,
	hasPendingChanges,
	table_name,
	clickAcceptAll,
	hasPendingChangeForField,
	loadData,
	save,
	close,
	resetObjects,
	setup_decision_listener,
	setDisplayValues,
	showError,
	updateDecision,
} = useGenericMethodsVariables();

const {
	onValidationChange,
	checkValidationsForError,
} = useValidation();

const GetPortfolios = async () => {
	const result = await mosapi.generic_entity_get(Entities.Portfolios);

	portfolios.value = [];

	for (const portfolio of result.data) {
		portfolios.value.push({
			value: portfolio.id,
			label: `${portfolio.portf_name} (${portfolio.mosclients.clientname})`,
		});
	}
};

const GetGroups = async () => {
	//const result = await .get(`config_group?select=*`);
	const result = await mosapi.generic_entity_get(Entities.ConfigGroup, {});

	groups.value = [];

	for (const group of result.data) {
		groups.value.push({
			value: group.id,
			label: `${group.group_id} (${group.group_name})`,
		});
	}
};

const GetCountries = async () => {
	//const result = await .get(`config_country?select=*`);
	const result = await mosapi.generic_entity_get(Entities.ConfigCountry, {});


	countriesData.value = [];

	for (const countries of result.data) {
		countriesData.value.push({
			value: countries.id,
			label: `${countries.country}`,
		});
	}
}

const getPostalCodes = async () => {
	//const result = await .get(`config_postalcode?`);
	const result = await mosapi.generic_entity_get(Entities.ConfigPostalCode, {});

	if (result.error) {
		showError(result.error);
		return;
	}

	allPostCodes.value = [];

	for (const postalcodes of result.data) {
		allPostCodes.value.push({
			value: postalcodes.id,
			label: `${postalcodes.postalcode}`,
			tk: parseInt(postalcodes.postalcode),
		});
	}

	filteredPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
	filteredPostalPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
}

const GetPrefecture = async () => {
	//const result = await .get(`config_prefecture?select=*`);
	const result = await mosapi.generic_entity_get(Entities.ConfigPrefecture, {});


	allPrefectures.value = [];

	for (const prefectures of result.data) {
		allPrefectures.value.push({
			value: prefectures.id,
			label: `${prefectures.prefecture}`,
			min_tk: `${prefectures.min_tk}`,
			max_tk: `${prefectures.max_tk}`,
		});
	}
}

onMounted(async () => {
	setup_decision_listener(fixPendingSelects);
	console.log('loading debtor id', props.row_id);

	table_name.value = Debtor.getTableNameStatic();
	cleanObject.value = new Debtor();

	mainStore.isLoading = true;

	await GetPortfolios();
	await GetGroups();
	await GetPrefecture();
	await getPostalCodes();
	await GetCountries();
	await loadKlados();
	await loadSelects();
	//await loadLoanAgreements();

	resetObjects();
	loanAgreements.value = [];
	agents.value = [];

	if (props.row_id === -1) {
		mainStore.isLoading = false;
		return;
	}

	await loadLoanAgreements();
	await loadData(props.row_id);
	await loadAgents();
	await loadKAD();
	await fixPendingSelects();
	if (fullObj.value.district.originalValue) {
		await filterPostCode(fullObj.value.district.originalValue);
	}
	if (fullObj.value.postal_district.originalValue) {
		await filterPostalPostCode(fullObj.value.postal_district.originalValue);
	}
	mainStore.isLoading = false;
});

const getDecisionObjectForField = (field: string): Decision => {
	const decision = new Decision();

	const obj: any = (fullObj as any).value[field];

	decision.db_change_id = obj.pendingValue.id;
	decision.field = field;
	decision.value = obj.pendingValue.value;

	return decision;
};

const getTranslationLegalStatus = (row: any, column: any) => {
	return t("legal_status_list." + row[column.property]);
};

const onDecision = async (decision: Decision) => {
	mainStore.isLoading = true;

	if ((decision.field === "klados" && hasPendingChangeForField("kad")) || (decision.field === "kad" && hasPendingChangeForField("klados"))) {
		multipleFieldOne.value = (fullObj as any).value["klados"];
		multipleFieldTwo.value = fullObj.value["kad"];

		linkTable.value = [
			{ field: t('DebtorDetailsView.' + multipleFieldOne.value.field), pendingValue: multipleFieldOne.value.pendingValue.pendingDisplayValue, username: multipleFieldOne.value.pendingValue.editor.username, timestamp: DateTime.fromISO(multipleFieldOne.value.pendingValue.insert_timestamp).toFormat("dd/MM/yyyy HH:mm") },
			{ field: t('DebtorDetailsView.' + multipleFieldTwo.value.field), pendingValue: multipleFieldTwo.value.pendingValue.pendingDisplayValue, username: multipleFieldTwo.value.pendingValue.editor.username, timestamp: DateTime.fromISO(multipleFieldTwo.value.pendingValue.insert_timestamp).toFormat("dd/MM/yyyy HH:mm") },
		];

		mainStore.isLoading = false;
		warningMultipleVisible.value = true;
		return;
	}

	await updateDecision(decision);

	await loadData(props.row_id);

	await loadSelects();
	await loadKAD();
	await fixPendingSelects();
	await loadLoanAgreements();

	mainStore.isLoading = false;
};

const linkAnswer = async (result: number) => {
	warningMultipleVisible.value = false;

	mainStore.isLoading = true;

	const decision1 = getDecisionObjectForField(multipleFieldOne.value.field);
	const decision2 = getDecisionObjectForField(multipleFieldTwo.value.field);

	decision1.accepted = result === 1 ? true : false;
	decision2.accepted = result === 1 ? true : false;

	await updateDecision(decision1);
	await updateDecision(decision2);

	await loadData(props.row_id);

	await loadSelects();
	await loadKAD();
	await fixPendingSelects();
	await loadLoanAgreements();

	mainStore.isLoading = false;
};

const onPreSave = async () => {
	// console.log(JSON.stringify(fullObj.value));
	if (checkValidationsForError()) {
		showError("Please correct shown errors.");
		return;
	}

	// let exists_error = "";

	// let existingDebtorID = await debtorExistsGlobal();

	// if (existingDebtorID > 0) {
	// 	exists_error = t("errors.debtor_already_exists");
	// } else {
	// 	existingDebtorID = await portfolioHasDebtorWithCID();

	// 	if (existingDebtorID > 0) {
	// 		exists_error = t("errors.debtor_already_exists_portfolio_cid");
	// 	} else {
	// 		existingDebtorID = await portfolioHasDebtorWithVAT();

	// 		if (existingDebtorID > 0) {
	// 			exists_error = t("errors.debtor_already_exists_portfolio_vat");
	// 		}
	// 	}
	// }

	// if (isNewEntity()) {
	// 	if (exists_error !== '') {
	// 		showWarning(exists_error);
	// 		return;
	// 	}
	// } else {
	// 	if (existingDebtorID !== 0 && existingDebtorID !== fullObj.value.id.originalValue) {
	// 		showWarning(exists_error);
	// 		return;
	// 	}
	// }

	// if (isNewEntity() && mainStore.loggedUser.user_type === UserType.Agent) {
	// 	fullObj.value.allowed_users.originalValue.push(mainStore.loggedUser.id);
	// }

	await save();
};

const IdCardList = ref<any>([]);
const CustomerCategoryList = ref<any>([]);

const loadAgents = async () => {
	agents.value = [];

	//const result = await .get(`debtor?select=allowed_users&id=eq.${props.row_id}`);
	const result = await mosapi.get_view<ViewMosDebtorsView2>(UIViews.DebtorsView, { id: props.row_id });
	if (result.error) { 
		showError(result.error);
		return;
	}

	if (result.data[0].allowed_users_usernames.length > 0) {
		const all_usernames = result.data[0].allowed_users_usernames;

		agents.value = all_usernames.split(/\s*,\s*/);
		agents.value.map(username => username.trim());
		agents.value.sort((a, b) => a.localeCompare(b));
	}
};

const loadLoanAgreements = async () => {
	loanAgreements.value = [];
	/*const result = await .get(
		`view_legal_status_view1?select=*&debtor_id=eq.${props.row_id}`
	);*/
	const result = await mosapi.get_view(UIViews.LegalStatusView, { debtor_id: props.row_id });	

	// console.log(`view_legal_status_view1?select=*&debtor_id=eq.${props.row_id}&la_debtor_id=eq.${props.row_id}`);

	if (result.error) {
		showError(result.error)
	}

	loanAgreements.value = result.data;

	// console.log(loanAgreements.value);
};

const loadKlados = async () => {
	allKladoi.value = [];
	//const result = await .get("config_klados?orderFIX=kodikos.asc");
	const result = await mosapi.generic_entity_get(Entities.ConfigKlados, {orderBy:'kodikos.asc' });
	if (result.error) {
		showError(result.error);
		return;
	}
	for (let i = 0; i < result.data.length; i++) {
		allKladoi.value.push({
			value: result.data[i].id,
			label: result.data[i].kodikos + " " + result.data[i].perigrafi,
		});
	}
};

const loadKAD = async () => {
	allKAD.value = [];

	if (fullObj.value.klados.originalValue === undefined || fullObj.value.klados.originalValue === null || fullObj.value.klados.originalValue === '') {
		return;
	}

	//const result = await .get(`config_kad?orderFIX=kodikos_kad.asc&klados_id=eq.${fullObj.value.klados.originalValue}`);
	const result = await mosapi.generic_entity_get(Entities.ConfigKAD, { klados_id: fullObj.value.klados.originalValue, orderBy:'kodikos_kad.asc' });	

	if (result.error) {
		showError(result.error);
		return;
	}

	for (let i = 0; i < result.data.length; i++) {
		allKAD.value.push({
			value: result.data[i].id,
			label: result.data[i].kodikos_kad + " " + result.data[i].perigrafi,
			klados_id: result.data[i].klados_id,
		});
	}
};

//
// Called when Debtor Prefecture changes
//
const filterPostCode = async (val: any) => {
	const selectedPrefecture = allPrefectures.value.find((x: any) => x.value === val);

	if (selectedPrefecture === undefined) {
		filteredPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
	} else {
		filteredPostCodes.value = allPostCodes.value.filter((x: any) => x.tk >= selectedPrefecture?.min_tk && x.tk <= selectedPrefecture?.max_tk);
	}
};

//
// Called when Postal Prefecture changes
//
const filterPostalPostCode = async (val: any) => {
	const selectedPostalPrefecture = allPrefectures.value.find((x: any) => x.value === val);

	if (selectedPostalPrefecture === undefined) {
		filteredPostalPostCodes.value = JSON.parse(JSON.stringify(allPostCodes.value));
	} else {
		filteredPostalPostCodes.value = allPostCodes.value.filter((x: any) => x.tk >= selectedPostalPrefecture.min_tk && x.tk <= selectedPostalPrefecture.max_tk);
	}
};

const GenerateGroupName = async (val: any) => {
	//const result = await .get(`config_group?select=group_name&id=eq.` + val);
	const result = await mosapi.generic_entity_get(Entities.ConfigGroup, { id: val });
	fullObj.value.group_name.originalValue = result.data[0].group_name;
};

const fixPendingSelects = async () => {
	for (const field of Object.keys(fullObj.value)) {
		if (fullObj.value[field].pendingValue === undefined) continue;

		if (field === "portfolio") {
			setDisplayValues(Portfolio, field, (dbObj: any) => { return `${dbObj.portf_name}`; });
		} else if (field === "klados") {
			setDisplayValues(ConfigKlados, field, (dbObj: any) => { return `${dbObj.kodikos} ${dbObj.perigrafi}`; });
		} else if (field === "kad") {
			setDisplayValues(ConfigKad, field, (dbObj: any) => { return `${dbObj.kodikos_kad} ${dbObj.perigrafi}`; });
		}

		if (field === "customer_type") {
			fullObj.value[field].pendingValue[
				"originalDisplayValue"
			] = CustomerCategoryList.value.find(
				(e: any) => e.value === fullObj.value[field].originalValue
			)?.label;
			fullObj.value[field].pendingValue[
				"pendingDisplayValue"
			] = CustomerCategoryList.value.find(
				(e: any) => e.value === fullObj.value[field].pendingValue.value
			)?.label;
		}
		if (field === "id_card_type") {
			fullObj.value[field].pendingValue["originalDisplayValue"] = IdCardList.value.find(
				(e: any) => e.value === fullObj.value[field].originalValue
			)?.label;
			fullObj.value[field].pendingValue["pendingDisplayValue"] = IdCardList.value.find(
				(e: any) => e.value === fullObj.value[field].pendingValue.value
			)?.label;
		}
	
		if (field === "group_id") {
		const group1 = await mosapi.generic_entity_get(Entities.ConfigGroup, {id: fullObj.value[field].pendingValue.value}) as APIReply<ConfigGroup[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${group1.data[0].group_id}` + ' ' + `(${group1.data[0].group_name})`;
		const group2 = await mosapi.generic_entity_get(Entities.ConfigGroup, {id: fullObj.value[field].originalValue}) as APIReply<ConfigGroup[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${group2.data[0].group_id}` + ' ' + `(${group2.data[0].group_name})`;
		}

		if (field === "portfolio") {
		const portf1 = await mosapi.generic_entity_get(Entities.Portfolios, {id: fullObj.value[field].pendingValue.value}) as APIReply<Portfolio[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${portf1.data[0].portf_name}` + ' ' + `(${portf1.data[0].mosclients.clientname})`;
		const portf2 = await mosapi.generic_entity_get(Entities.Portfolios, {id: fullObj.value[field].originalValue}) as APIReply<Portfolio[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${portf2.data[0].portf_name}` + ' ' + `(${portf2.data[0].mosclients.clientname})`;
		}

		if (field === "klados") {
		const klados1 = await mosapi.generic_entity_get(Entities.ConfigKlados, {id: fullObj.value[field].pendingValue.value}) as APIReply<Klados[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${klados1.data[0].kodikos}` + ' ' + `${klados1.data[0].perigrafi}`;
		const klados2 = await mosapi.generic_entity_get(Entities.ConfigKlados, {id: fullObj.value[field].originalValue}) as APIReply<Klados[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${klados2.data[0].kodikos}` + ' ' + `${klados2.data[0].perigrafi}`;
		}

		if (field === "kad") {
		const kad1 = await mosapi.generic_entity_get(Entities.ConfigKAD, {id: fullObj.value[field].pendingValue.value}) as APIReply<Kad[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${kad1.data[0].kodikos_kad}` + ' ' + `${kad1.data[0].perigrafi}`;
		const kad2 = await mosapi.generic_entity_get(Entities.ConfigKAD, {id: fullObj.value[field].originalValue}) as APIReply<Kad[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${kad2.data[0].kodikos_kad}` + ' ' + `${kad2.data[0].perigrafi}`;
		}

		if (field === "district" || field === "postal_district") {
		const district1 = await mosapi.generic_entity_get(Entities.ConfigPrefecture, {id: fullObj.value[field].pendingValue.value}) as APIReply<PostalDistrict[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${district1.data[0].prefecture}`;
		const district2 = await mosapi.generic_entity_get(Entities.ConfigPrefecture, {id: fullObj.value[field].originalValue}) as APIReply<PostalDistrict[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${district2.data[0].prefecture}`;
		}

		if (field === "postcode" || field === "postal_postcode") {
		const postcode1 = await mosapi.generic_entity_get(Entities.ConfigPostalCode, {id: fullObj.value[field].pendingValue.value}) as APIReply<PostalCodes[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${postcode1.data[0].postalcode}`;
		const postcode2 = await mosapi.generic_entity_get(Entities.ConfigPostalCode, {id: fullObj.value[field].originalValue}) as APIReply<PostalCodes[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${postcode2.data[0].postalcode}`;
		}

		if (field === "country" || field === "postal_country") {
		const country1 = await mosapi.generic_entity_get(Entities.ConfigCountry, {id: fullObj.value[field].pendingValue.value}) as APIReply<PostalCountries[]>;
		fullObj.value[field].pendingValue[
			"pendingDisplayValue"
		] = `${country1.data[0].country}`;
		const country2 = await mosapi.generic_entity_get(Entities.ConfigCountry, {id: fullObj.value[field].originalValue}) as APIReply<PostalCountries[]>;
		fullObj.value[field].pendingValue[
			"originalDisplayValue"
		] = `${country2.data[0].country}`;
		}

	}
};

const loadSelects = async () => {
	CustomerCategoryList.value = [
		{
			value: "individual",
			label: t('DebtorDetailsView.individual'),
		},
		{
			value: "legal_entity", // Ιδιωτικό δίκαιο
			label: t('DebtorDetailsView.legal_entity'),
		},
		{
			value: "legal_entity_public", // Δημόσιο Δίκαιο
			label: t('DebtorDetailsView.legal_entity_public'),
		},
	];

	IdCardList.value = [
		{
			value: "id_card",
			label: "ID card",
		},
		{
			value: "passport",
			label: "Passport",
		},
	];
};

const clickAcceptAllInternal = async () => {
	await clickAcceptAll(props.row_id);

	mainStore.isLoading = true;

	await loadAgents();
	await loadLoanAgreements();

	mainStore.isLoading = false;
}

</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form>
			<AcceptAllPending v-show="hasPendingChanges" @accept="clickAcceptAllInternal" />
			<p />
			<div style="text-align: right; padding-bottom: 10px" v-if="loanAgreements[0]?.katast_sumbashs">
				<el-tag class="ml-2" style="margin: 12px">
					{{ $t("DebtorDetailsView.legal_status") }} of
					{{ loanAgreements[0]?.ar_sumbashs }}: <span
						v-if="loanAgreements[0]?.katast_sumbashs == 'denounced'">Denounced</span>
					<span v-if="loanAgreements[0]?.katast_sumbashs == 'non_performing'">Non performing</span>
					<span v-if="loanAgreements[0]?.katast_sumbashs == 'performing'">Performing</span>
				</el-tag>
			</div>

			<el-tabs v-model="activeName" type="card" class="demo-tabs">
				<el-tab-pane :label="$t('DebtorDetailsView.details')" name="first">
					<el-row :gutter="10">
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.cid')" :object="fullObj.cid"
								:validation-rules="[['.+', 'validation.insert_cid']]"
								@validation-change="onValidationChange" />
						</el-col>

						<el-col :span="24">
							<EntityField type="select" :label="$t('PlatformSettingsView.group_id')"
								:object="fullObj.group_id" :options="groups" @select-change="GenerateGroupName" />
						</el-col>

						<el-col :span="24">
							<EntityField type="input" :label="$t('PlatformSettingsView.group_name')"
								:object="fullObj.group_name" :is-disabled="true" />
						</el-col>

						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.portfolio')"
								:object="fullObj.portfolio" :options="portfolios" @decision="onDecision"
								:validation-rules="[['.+', 'validation.insert_portfolio']]"
								@validation-change="onValidationChange" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.former_asset_owner_fao')"
								:object="fullObj.fao" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.cid_fao')"
								:object="fullObj.cid_fao" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :object="fullObj.customer_type"
								:label="$t('DebtorDetailsView.customer_category')" :options="CustomerCategoryList" />
						</el-col>

						<el-col :span="24">
							<el-table :data="loanAgreements" stripe border table-layout="fixed" size="small"
								style="margin-top: 20px; margin-bottom: 30px">
								<el-table-column fixed sortable prop="ar_sumbashs"
									:label="$t('LoanAgreementView.loan_agreement')" type="text" />
								<el-table-column fixed sortable :formatter="getTranslationLegalStatus"
									prop="katast_sumbashs" :label="$t('LoanAgreementView.legal_status')" type="text" />
								<el-table-column fixed sortable prop="re_kalipsi"
									:label="$t('real_estate_collateral.prenotation_amount')" type="text" />
								<el-table-column fixed sortable prop="greatest"
									:label="$t('real_estate_collateral.kalimaNRE')" type="text" />
								<el-table-column fixed sortable prop="synol_poso_sumbashs"
									:label="$t('real_estate_collateral.synol_poso_sumbashs')" type="text" />
							</el-table>
						</el-col>

						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.company_fullname')"
								:object="fullObj.fullname" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.id_card_type')"
								:object="fullObj.id_card_type" :options="IdCardList" />
						</el-col>
						<el-col :span="24" v-if="fullObj.id_card_type?.originalValue">
							<EntityField type="input" label="ID Card Number" :object="fullObj.id_card" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.tax_registration_nbr')"
								:object="fullObj.vat_number" @decision="onDecision"
								:validation-rules="[['.+', 'validation.tax_registration_nbr']]"
								@validation-change="onValidationChange" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.gemi')" :object="fullObj.gemh" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :object="fullObj.klados" :label="$t('DebtorDetailsView.klados')"
								:options="allKladoi" @select-change="loadKAD" />
							<!-- <EntityField type="input" :label="$t('DebtorDetailsView.klados')" :object="fullObj.klados"
                                        /> -->
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :object="fullObj.kad" :label="$t('DebtorDetailsView.kad')"
								:options="allKAD" />
							<!-- <EntityField type="input" :label="$t('DebtorDetailsView.kad')" :object="fullObj.kad"
                                        /> -->
						</el-col>

						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.street')"
								:object="fullObj.address" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.street_number')"
								:object="fullObj.address_number" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.region')"
								:object="fullObj.region" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.city')" :object="fullObj.city" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.prefecture')"
								:object="fullObj.district" :options="allPrefectures" @decision="onDecision"
								@select-change="filterPostCode" @clear="filterPostCode" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.zip_code')"
								:object="fullObj.postcode" :options="filteredPostCodes" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.country')"
								:object="fullObj.country" :options="countriesData" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.phone')"
								:object="fullObj.phone_number" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" label="Email" :object="fullObj.email" />
						</el-col>
					</el-row>
				</el-tab-pane>

				<el-tab-pane :label="$t('DebtorDetailsView.postal_address')" name="third">
					<el-row :gutter="10">
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.postal_street')"
								:object="fullObj.postal_address" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.postal_nbr')"
								:object="fullObj.postal_address_number" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.postal_region')"
								:object="fullObj.postal_region" />
						</el-col>
						<el-col :span="24">
							<EntityField type="input" :label="$t('DebtorDetailsView.postal_city')"
								:object="fullObj.postal_city" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.postal_prefecture')"
								:object="fullObj.postal_district" :options="allPrefectures" @decision="onDecision"
								@select-change="filterPostalPostCode" @clear="filterPostalPostCode" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.postal_zip_code')"
								:object="fullObj.postal_postcode" :options="filteredPostalPostCodes" />
						</el-col>
						<el-col :span="24">
							<EntityField type="select" :label="$t('DebtorDetailsView.postal_country')"
								:object="fullObj.postal_country" :options="countriesData" />
						</el-col>
					</el-row>
				</el-tab-pane>

				<el-tab-pane :label="$t('DebtorDetailsView.rest_of_legal_details')" name="fourth">
					<el-row :gutter="10">
						<el-col :span="24">
							<EntityField type="datepicker" :label="$t('DebtorDetailsView.legal_start_date')"
								:object="fullObj.hmer_enar_isx_nomim" />
							<EntityField type="datepicker" :label="$t('DebtorDetailsView.legal_end_date')"
								:object="fullObj.hmer_liks_isx_nomim" />
						</el-col>
						<el-col :span="24">
							<EntityField type="datepicker"
								:label="$t('DebtorDetailsView.social_security_clearance_issue_date')"
								:object="fullObj.hmer_ekd_asfa_enim" />
							<EntityField type="datepicker"
								:label="$t('DebtorDetailsView.social_security_clearance_end_date')"
								:object="fullObj.hmer_liks_asfa_enim" />
						</el-col>
					</el-row>
				</el-tab-pane>

				<el-tab-pane label="MOS" name="fifth">

					<div style="border-radius:10px; border: 1px transparent solid; overflow: hidden">
						<div style="background-color:#EEEEEE; padding-top: 10px; padding-bottom: 10px;">
							<el-row style="padding-left: 10px;">
								<el-col :span="24">
									<EntityField type="input"
										:label="$t('DebtorDetailsView.mos_client_account_officer')"
										:object="fullObj.mos_clients_acc_off" />
								</el-col>
							</el-row>
						</div>
					</div>
					<div style="border-radius:10px; border: 1px transparent solid; overflow: hidden; margin-top: 5px;">
						<div style="background-color:#EEEEEE; padding-top: 10px; padding-bottom: 10px;">
							<el-row>
								<el-col :span="12" style="padding-left: 10px">
									{{ t("DebtorDetailsView.mos_officer") }}
								</el-col>
								<el-col :span="12">
									<el-tag class="ml-2" type="success" style="margin: 2px;" v-for="item in agents"
										effect="light">
										{{ item }}
									</el-tag>
									<span v-if="agents.length == 0">
										No Data
									</span>
								</el-col>
							</el-row>
						</div>
					</div>

				</el-tab-pane>
			</el-tabs>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-dialog v-if="warningMultipleVisible" v-model="warningMultipleVisible" title="Linked fields" width="80%">
			Both changes must be accepted or rejected at the same time.
			<p />
			<el-table :data="linkTable" style="width: 100%">
				<el-table-column prop="field" label="Field" width="180" />
				<el-table-column prop="pendingValue" label="Pending Value" />
				<el-table-column prop="username" label="Editor" width="140" />
				<el-table-column prop="timestamp" label="Edit date/time" width="150" />
			</el-table>
			<p />
			<el-button @click="linkAnswer(1)">Accept</el-button>
			<el-button @click="linkAnswer(2)">Reject</el-button>
			<el-button @click="warningMultipleVisible = false">Back</el-button>
		</el-dialog>
	</div>
</template>

<style scoped>
#customers {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

#customers td,
#customers th {
	padding: 8px;
}

#customers tr:nth-child(even) {
	background-color: #f2f2f2;
}

#customers tr:hover {
	background-color: #ddd;
}

#customers th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #04AA6D;
	color: white;
}
</style>
