<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import type { FormInstance } from "element-plus";
import ConfigProduct from "../classes/DB_Entities/ConfigProduct";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { useMainStore } from "../store/main";
import { Entities } from "@/enums/Entities";
const { showError } = useGenericMethodsVariables();
const props = defineProps({
  productid: Number,
});
const emits = defineEmits(["save", "close", "fail-save"]);
const data = ref(new ConfigProduct());
const ruleFormRef = ref<FormInstance>();
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const isLoading = ref(false);
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => props.productid,
  async (newVal, oldVal) => {
    await loadProduct(props.productid as number);
  }
);

onMounted(async () => {
  await loadProduct(props.productid as number);
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const product_id = data.value.id as number;
  delete data.value.id;
  const result = await mosapi.generic_entity_patch(Entities.ConfigProduct, product_id, data.value);
  if (result.error) {
    showError(result.error);
    return;
  }
  emits("save");
};

const loadProduct = async (product_id: number) => {
  mainStore.isLoading = true;
  if (product_id === -1) return;

  const result = await mosapi.generic_entity_get(Entities.ConfigProduct, { id: product_id });

  if (result.error) {
    showError(result.error);
    return;
  }

  data.value = result.data[0];
  mainStore.isLoading = false;
};
const StoreProductType = [
  {
    label: "summary_check_another_loan",
    value: "summary_check_another_loan",
  },
  {
    label: "summary_check_KOD",
    value: "summary_check_KOD",
  },
];
</script>

<template>
  <div>
    <el-form :model="data" ref="ruleFormRef" v-loading="mainStore.isLoading">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_product_details')">
            <el-input v-model="data.product" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_summarycheck_details')" prop="type">
            <el-select v-model="data.type" class="m-2 header-filter" size="small" placeholder=" ">
              <el-option v-for="item in StoreProductType" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_greek_trans')">
            <el-input v-model="data.greek_trans" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('PlatformSettingsView.la_english_trans')">
            <el-input v-model="data.english_trans" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item :label="$t('PlatformSettingsView.omologiako')">
            <el-switch v-model="data.omologiako" autocomplete="off" />
          </el-form-item>
          <el-form-item v-show="data.omologiako" :label="$t('PlatformSettingsView.koinopraktiko')">
            <el-switch v-model="data.koinopraktiko" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <div style="display: flex; justify-content: flex-end; width: 100%; padding: 0">
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<style scoped></style>
