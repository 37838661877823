<script lang="ts" setup>
import { ref, onMounted, reactive, watch } from "vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { ElMessage } from "element-plus";
import type { FormRules, FormInstance } from "element-plus";
import { DateTime } from "luxon";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
const { showError } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();

const emits = defineEmits(["save", "close", "fail-save"]);

const StoreLoanAgreements = ref<any[]>([]);
const StoreEktokismos = ref<any>([]);
const ruleFormRef = ref<FormInstance>();

const rules = reactive<FormRules>({
  loan_agreement_id: [
    {
      required: true,
      trigger: "blur",
      message: "Loan agreement is required",
    },
  ],
  ektokismos_id: [
    {
      required: true,
      trigger: "blur",
      message: "Interest period is required",
    },
  ],
});

const data = ref<any>({
  loan_agreement_id: null,
  ektokismos_id: null,
  total_remaining_balance: null,
  our_participation: null,
  repayment_date: null,
  interest_calc_start_date: null,
  interest_calc_end_date: null,
  interest_rate: null,
  margin: null,
  interest_amount: null,
  withholdind_tax: null,
  interest_amount_due: null,
  comments: null,
  tax_certificate: null,
  days_overdue: null,
  cert_sent: null,
  alarm_date: null,
  due_date: null,
});

const props = defineProps({
  globalla: String,
});

watch(
  () => data.value.ektokismos_id,
  async (newVal, oldVal) => {
    if (data.value.ektokismos_id === null) return;
    if (data.value.ektokismos_id === 0) return;
    //result = await .get("ektokismoi_loan_agreement?id=eq." + data.value.ektokismos_id);
    const result = await mosapi.generic_entity_get(Entities.EktokismoiLoanAgreement, { id: data.value.ektokismos_id });
    data.value.interest_calc_start_date = result.data[0].start_date;
    data.value.interest_calc_end_date = result.data[0].end_date;
    data.value.interest_rate = result.data[0].base_rate_value;
    data.value.interest_amount = parseFloat(result.data[0].interest_on_due_principal) + parseFloat(result.data[0].interest_on_overdue_amount);
  }
);

watch(
  () => data.value.interest_amount,
  async (newVal, oldVal) => {
    let interest_amount_value = 0;
    let withholding_tax_15 = 0;
    if (data.value.interest_amount !== null) interest_amount_value = data.value.interest_amount;
    if (data.value.withholdind_tax == true) withholding_tax_15 = interest_amount_value * 0.15;
    data.value.interest_amount_due = interest_amount_value - withholding_tax_15;
  }
);

watch(
  () => data.value.withholdind_tax,
  async (newVal, oldVal) => {
    let interest_amount_value = 0;
    let withholding_tax_15 = 0;
    if (data.value.interest_amount !== null) interest_amount_value = data.value.interest_amount;
    if (data.value.withholdind_tax == true) withholding_tax_15 = interest_amount_value * 0.15;
    data.value.interest_amount_due = interest_amount_value - withholding_tax_15;
  }
);

watch(
  () => props.globalla,
  async (newVal, oldVal) => {
    data.value.loan_agreement_id = newVal;
    await loadEktokismos();
  }
);

watch(
  () => data.value.loan_agreement_id,
  async (newVal, oldVal) => {
    await loadEktokismos();
    if (data.value.loan_agreement_id === null) return;
    if (data.value.loan_agreement_id === "") return;

    //let result: Result = new Result();

    //result = await api.get(`loan_agreement?id=eq.${data.value.loan_agreement_id}`);
    const result = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: data.value.loan_agreement_id });


    if (result.error) {
      showError(result.error);

      return;
    }

    //console.log(JSON.stringify(result.data))
    //data.value.kathgor_epitok_bashs = result.data[0].kathgor_epitok_bashs;
    data.value.margin = result.data[0].perithorio;
    //data.value.epitok_uperhm = result.data[0].epitok_uperhm;
    //data.value.parakra_forou = result.data[0].parakra_forou;
    //data.value.eisfora_n128_1975 = result.data[0].eisfora_n128_1975;
  }
);

const loadLoanAgreements = async () => {
  const result = await mosapi.get_view(UIViews.LoanAgreementView, { approve_status: 1, debtor_approve_status: 1});

  if (result.error) {
    showError(result.error);
    return;
  }

  StoreLoanAgreements.value = result.data;
};

const loadEktokismos = async () => {
  if (props.globalla) {

    //let result: Result = new Result();
    //result = await api.get("ektokismoi_loan_agreement?select=*,loan_agreement!inner(ar_sumbashs)&orderFIX=start_date.desc&loan_agreement_id=eq." + props.globalla);
    //const result = await mosapi.GenericgetEktokismoiLoanAgreementWithRelation(props.globalla as string);
    const result = await mosapi.generic_entity_get(Entities.EktokismoiLoanAgreement, {loan_agreement_id: props.globalla, orderBy: 'start_date.desc'});

    if (result.error) {
      ElMessage({
        showClose: true,
        message: result.error,
        type: "error",
      });

      return;
    }

    StoreEktokismos.value = result.data;
    //  data.value.interest_calc_start_date = result.data.start_date;
    //  console.log(JSON.stringify(StoreEktokismos.value))

    //  result= await api.get(`loan_agreement?id=eq.${props.globalla}`);
    //  data.value.margin = result.data[0].perithorio;
  } else if (data.value.loan_agreement_id) {

    // let result: Result = new Result();
    //result = await api.get("ektokismoi_loan_agreement?select=*,loan_agreement!inner(ar_sumbashs)&orderFIX=start_date.desc&loan_agreement_id=eq." + data.value.loan_agreement_id);
    //const result = await mosapi.GenericgetEktokismoiLoanAgreementWithRelation(data.value.loan_agreement_id as string);
    const result = await mosapi.generic_entity_get(Entities.EktokismoiLoanAgreement, {loan_agreement_id: data.value.loan_agreement_id, orderBy: 'start_date.desc'});
    if (result.error) {
      ElMessage({
        showClose: true,
        message: result.error,
        type: "error",
      });

      return;
    }
    StoreEktokismos.value = result.data;
    //  data.value.interest_calc_start_date = result.data.start_date;
    //  result= await api.get(`loan_agreement?id=eq.${data.value.loan_agreement_id}`);
    //  data.value.margin = result.data[0].perithorio;

  } else {

    //let result: Result = new Result();
    //result = await api.get("ektokismoi_loan_agreement?select=*,loan_agreement!inner(ar_sumbashs)&orderFIX=start_date.desc");
    //const result = await mosapi.GenericgetEktokismoiLoanAgreementWithRelation("");
    const result = await mosapi.generic_entity_get(Entities.EktokismoiLoanAgreement, {orderBy: 'start_date.desc'});
    if (result.error) {
      ElMessage({
        showClose: true,
        message: result.error,
        type: "error",
      });

      return;
    }

    StoreEktokismos.value = result.data;

  }


};

onMounted(async () => {
  await loadLoanAgreements();
  await loadEktokismos();
  data.value.loan_agreement_id = props.globalla;
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const obj = JSON.parse(JSON.stringify(data.value));
  //let result = await api.post_data("la_liksiario", obj);
  const result = await mosapi.generic_entity_post(Entities.LALiksiario, data.value);
  console.log(obj);
  if (result.error) {
    showError(result.error); // emits("fail-save");
    return;
  }
  resetData();
  emits("save");
};

const resetData = async () => {
  data.value.loan_agreement_id = null;
  data.value.ektokismos_id = null;
  data.value.total_remaining_balance = null;
  data.value.our_participation = null;
  data.value.repayment_date = null;
  data.value.interest_calc_start_date = null;
  data.value.interest_calc_end_date = null;
  data.value.interest_rate = null;
  data.value.margin = null;
  data.value.interest_amount = null;
  data.value.withholdind_tax = null;
  data.value.interest_amount_due = null;
  data.value.comments = null;
  data.value.tax_certificate = null;
  data.value.days_overdue = null;
  data.value.cert_sent = null;
  data.value.alarm_date = null;
  data.value.due_date = null;
};

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

</script>

<template>
  <div>
    <el-form :model="data" :rules="rules" ref="ruleFormRef">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item :label="$t('LoanAccountDetails.loan_agreement')" prop="loan_agreement_id">
            <el-select v-model="data.loan_agreement_id" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreLoanAgreements" :key="item.id" :label="item.ar_sumbashs"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.ektokismos')" prop="ektokismos_id">
            <el-select v-model="data.ektokismos_id" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreEktokismos" :key="item.id" :label="item.ar_sumbashs +
                ' (' + $t('LiksiarioView.from') + ' ' +
                DateTime.fromISO(item.start_date).setLocale('en-gb').toLocaleString() +
                ' ' + $t('LiksiarioView.to') + ' ' +
                DateTime.fromISO(item.end_date).setLocale('en-gb').toLocaleString() + ')'" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.total_remaining_balance')">
            <el-input v-model="data.total_remaining_balance" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.our_participation')">
            <el-input v-model="data.our_participation" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.repayment_date')" :width="100">
            <el-date-picker v-model="data.repayment_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_calc_start_date')" :width="100">
            <el-date-picker v-model="data.interest_calc_start_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_calc_end_date')" :width="100">
            <el-date-picker v-model="data.interest_calc_end_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_rate')">
            <el-input v-model="data.interest_rate" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.margin')">
            <el-input v-model="data.margin" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_amount')">
            <el-input v-model="data.interest_amount" autocomplete="off" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.withholdind_tax')">
            <el-switch v-model="data.withholdind_tax" :active-text="$t('components.switch.yes')"
              :inactive-text="$t('components.switch.no')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.interest_amount_due')">
            <el-input disabled v-model="data.interest_amount_due" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.comments')">
            <el-input v-model="data.comments" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.tax_certificate')">
            <el-switch v-model="data.tax_certificate" :active-text="$t('components.switch.yes')"
              :inactive-text="$t('components.switch.no')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.days_overdue')">
            <el-input v-model="data.days_overdue" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.cert_sent')">
            <el-input v-model="data.cert_sent" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.alarm_date')" :width="100">
            <el-date-picker v-model="data.alarm_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('LiksiarioView.due_date')" :width="100">
            <el-date-picker v-model="data.due_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
