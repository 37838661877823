<script lang="ts" setup>
import { useMainStore } from "../store/main";
import { watch, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import ROB_Bond from "../classes/DB_Entities/ROB_Bond";
import Decision from "../classes/Decision";
import EntityField from "./EntityField.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";

const emits = defineEmits(["close", "save", "fail-save"]);
const mainStore = useMainStore();
const props = defineProps<{
  rob_bond_id: number;
  rob_title_id: number;
  newEntity?: boolean;
  view_only: boolean;
}>();

const { t } = useI18n({ useScope: "global" });

const {
  setup_decision_listener,
  updateDecision,
  isLoading,
  data,
  fullObj,
  table_name,
  cleanObject,
  loadData,
  save,
  TransformFullObj,
  close,
} = useGenericMethodsVariables();

watch(
  () => props.rob_bond_id,
  async (newVal, oldVal) => {
    console.log("bond from bond", props.rob_bond_id);
    resetObjects();
    if (props.newEntity === true) return;
    mainStore.isLoading = true;
    await loadData(props.rob_bond_id);
    mainStore.isLoading = false;
  }
);

const resetObjects = () => {
  data.value = new ROB_Bond();
  fullObj.value = new ROB_Bond();
  data.value.rob_title_id = props.rob_title_id;
  fullObj.value.rob_title_id = props.rob_title_id;
  TransformFullObj();
};

onMounted(async () => {
  setup_decision_listener();
  table_name.value = ROB_Bond.getTableNameStatic();
  cleanObject.value = new ROB_Bond();

  console.log('props', JSON.stringify(props))
  mainStore.isLoading = true;
  if (!props.newEntity) {
    await loadData(props.rob_bond_id as number);
  } else {
    resetObjects();
  }

  mainStore.isLoading = false;
});

const onPreSave = async () => {
  if (props.newEntity as boolean) {
    if (fullObj.value.start_bond_number.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_start_bond_number"),
        type: "warning",
        duration: 1000,
      });
      return;
    }

    if (fullObj.value.end_bond_number.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_end_bond_number"),
        type: "warning",
        duration: 1000,
      });
      return;
    }

    if (fullObj.value.nominal_value.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_nominal_value"),
        type: "warning",
        duration: 1000,
      });
      return;
    }

    if (fullObj.value.issue_date.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_issue_date"),
        type: "warning",
        duration: 1000,
      });
      return;
    }

    if (fullObj.value.end_date.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_end_date"),
        type: "warning",
        duration: 1000,
      });
      return;
    }

    if (fullObj.value.status.originalValue === "") {
      ElMessage({
        showClose: true,
        message: t("errors.missing_bond_status"),
        type: "warning",
        duration: 1000,
      });
      return;
    }
  }

  await save();
  resetObjects();
};

const statusList = [
  {
    value: "paid",
    label: "PAID",
  },
  {
    value: "part_paid",
    label: "PARTIALLY PAID",
  },
  {
    value: "unpaid",
    label: "UNPAID",
  },
  {
    value: "cancel",
    label: "CANCELLED",
  },
];
</script>

<template>
  <div v-loading="mainStore.isLoading">
    <el-form>
      <EntityField type="input" label="From" :object="fullObj.start_bond_number" 
        :is-disabled="props.view_only" />

      <EntityField type="input" label="To" :object="fullObj.end_bond_number" 
        :is-disabled="props.view_only" />

      <EntityField type="money" label="Nominal Value" :object="fullObj.nominal_value" 
        :is-disabled="props.view_only" />

      <EntityField type="datepicker" label="Issue Date" :object="fullObj.issue_date" 
        :is-disabled="props.view_only" />

      <EntityField type="datepicker" label="End Date" :object="fullObj.end_date" 
        :is-disabled="props.view_only" />

      <EntityField type="select" label="Status" :object="fullObj.status" :options="statusList" 
        :is-disabled="props.view_only" />

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onPreSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
