<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Edit, Plus, SuccessFilled, CircleCloseFilled, Delete } from "@element-plus/icons-vue";
import { useMOSAPIStore } from "../store/mos_api";
import papa from 'papaparse';
import DebitCreditType from "../enums/DebitCreditType";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import AddEdit_DebitCredit from "./AddEdit_DebitCredit.vue";
import AcceptAllPending from "./AcceptAllPending.vue";
import { DateTime } from "luxon";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";
import { Emitter, EventType } from "mitt";
import { orderBy } from "lodash";

const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const props = defineProps<{
	loan_account_id: number;
	type: DebitCreditType;
	view_only: boolean;
}>();

const tableRowClassName = ({
	row,
	rowIndex,
}: {
	row: any
	rowIndex: number
}) => {
	if (row.status === 2) {
		return 'warning-row';
	}

	return '';
}

const { setup_decision_listener, showError, showLocalizedError, showSuccess } = useGenericMethodsVariables();

const dataList = ref<any[]>([]);
const showAddEdit = ref(false);
const selectedRow = ref(0);
const euroFormat = new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' });
const hasPendingNumberChanges = ref(false);
const hasPendingStatusChanges = ref(false);
const hasPendingDateChanges = ref(false);
const hasPendingPerigrafiChanges = ref(false);
const showRejected = ref(false);

const showUpload = ref(false);

onMounted(async () => {
	setup_decision_listener();
	await loadData();
});

const queryProps = ref<any>({
	loan_account_id: 0,
	type: 0,
	status: 0,
});

const loadData = async () => {
	mainStore.isLoading = true;

	//let url = `view_debits_credits_list_internal?loan_account_id=eq.${props.loan_account_id}&type=eq.${props.type}&status=in.(0, 1${showRejected.value ? ', 2' : ''})&orderFIX=date.desc`;
	//let result = await .get(url);
	queryProps.value.loan_account_id = props.loan_account_id;
	queryProps.value.type = props.type;
	let show_rejected = showRejected.value ? 2 : 1;
	queryProps.value.status = show_rejected;


	const result = await mosapi.get_view(UIViews.DebitCreditsInternal, {...queryProps.value, orderBy:'date.desc'});

	dataList.value = result.data as any[];

	hasPendingNumberChanges.value = false;
	hasPendingStatusChanges.value = false;
	hasPendingDateChanges.value = false;
	hasPendingPerigrafiChanges.value = false;

	for (let i = 0; i < dataList.value.length; ++i) {
		dataList.value[i].date = DateTime.fromISO(dataList.value[i].date).toFormat('yyyy-MM-dd');
		if (!dataList.value[i].entity_changes) {
			continue;
		}

		for (let j = 0; j < dataList.value[i].entity_changes.length; ++j) {
			if (dataList.value[i].entity_changes[j].approval_status === 0) {
				switch (dataList.value[i].entity_changes[j].field) {
					case "amount":
						hasPendingNumberChanges.value = true;
						break;
					case "status":
						hasPendingStatusChanges.value = true;
						break;
					case "date":
						hasPendingDateChanges.value = true;
						break;
					case "perigrafi":
						hasPendingPerigrafiChanges.value = true;
						break;
				}
				// console.log("Pending change found", dataList.value[i].entity_changes[j].field);
			}
		}
	}

	// console.log("hasPendingNumberChanges", hasPendingNumberChanges.value);
	// console.log("hasPendingStatusChanges", hasPendingStatusChanges.value);
	// console.log("hasPendingDateChanges", hasPendingDateChanges.value);
	// console.log("hasPendingPerigrafiChanges", hasPendingPerigrafiChanges.value);

	mainStore.isLoading = false;
}

const openAddEdit = (row_id: number) => {
	selectedRow.value = row_id;
	showAddEdit.value = true;
}

const deleteRow = async (row_id: number) => {
	mainStore.isLoading = true;
	const result = await mosapi.generic_entity_delete(Entities.DebitsCredits, row_id);
	// await .delete_data("debits_credits?id=eq." + row_id);
	mainStore.isLoading = false;

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	await loadData();
}

const onCloseAddEdit = async () => {
	showAddEdit.value = false;
	await loadData();
}

const getDebitCreditTypeName = (type: DebitCreditType) => {
	switch (type) {
		case DebitCreditType.Debit:
			return "Debit";
		case DebitCreditType.Credit:
			return "Credit";
		case DebitCreditType.Installment:
			return "Installment";
		case DebitCreditType.InterestTypeChanges:
			return "Interest Type Change";
		case DebitCreditType.Exoda:
			return "Expense";
		case DebitCreditType.TokoiTokwn:
			return "Initial Interest";
		case DebitCreditType.HmerTokou:
			return " Interest Payment Date";
	}
}

const formatEuroCurrency = (value: number) => {
	return euroFormat.format(value);
}

const AcceptChange = async (entity_debit_credit_changes_row: any, reload: boolean = true, changeLoading: boolean = true) => {
	// console.log(JSON.stringify(entity_debit_credit_changes_row))
	console.log("AcceptChange", entity_debit_credit_changes_row.id);

	if (changeLoading) {
		mainStore.isLoading = true;
	}

	//let result = await .patch("entity_debits_credits_changes?id=eq." + entity_debit_credit_changes_row.id, { approval_status: ChangeApprovalStatus.Approved });
	const result = await mosapi.update_entity_change({ id: entity_debit_credit_changes_row.id, entity_type: Entities.DebitsCredits, field: entity_debit_credit_changes_row.field, value: entity_debit_credit_changes_row.value, approval_status: ChangeApprovalStatus.Approved, });

	if (changeLoading) {
		mainStore.isLoading = false;
	}

	if (result.error) {
		showError(result.error);
		return;
	}

	if (reload) {
		await loadData();
	}
}

const RejectChange = async (entity_debit_credit_changes_row: any) => {
	console.log("RejectChange", entity_debit_credit_changes_row.id);

	mainStore.isLoading = true;
	//const result = await .patch("entity_debits_credits_changes?id=eq." + entity_debit_credit_changes_id, { approval_status: ChangeApprovalStatus.Rejected });
	const result = await mosapi.update_entity_change({ id: entity_debit_credit_changes_row.id, entity_type: Entities.DebitsCredits, approval_status: ChangeApprovalStatus.Rejected, field: entity_debit_credit_changes_row.field});

	// ????
	//const result2 = await .patch("debits_credits?id=eq." + get_id.data[0].entity_id, { status: ChangeApprovalStatus.Rejected });
	mainStore.isLoading = false;

	if (result.error) {
		showError(result.error);
		return;
	}

	await loadData();
}

const GetPendingEntryForRow = (row: any, field: string) => {
	if (!row.entity_changes) {
		return null;
	}

	const change = row.entity_changes.find((change: any) => {
		if (change.approval_status === 0 && change.field === field) {
			return true;
		}
	});

	if (change) {
		return change;
	}

	return null;
}

const AcceptAll = async () => {
	mainStore.isLoading = true;

	for (let i = 0; i < dataList.value.length; ++i) {
		if (!dataList.value[i].entity_changes)
			continue;

		for (let j = 0; j < dataList.value[i].entity_changes.length; ++j) {
			if (dataList.value[i].entity_changes[j].approval_status === 0) {
				await AcceptChange(dataList.value[i].entity_changes[j], false, false);
			}
		}
	}

	mainStore.isLoading = false;

	await loadData();
}

let lastFile: any = undefined;

const onUpload = async () => {
	console.log('do upload');
	const file = lastFile;

	if (file === undefined)
		return;

	mainStore.isLoading = true;

	const postEntries: any[] = [];
	papa.parse(file, {
		worker: true, // Don't bog down the main thread if its a big file
		step: function (result: any) {
			console.log(result);
			postEntries.push({
				loan_account_id: props.loan_account_id,
				type: props.type,
				status: 1,
				date: result.data[0],
				amount: result.data[1],
				perigrafi: result.data[2]
			});
		},
		complete: async function (results, file) {
			console.log('parsing complete read', postEntries.length, 'records.');
			console.log(JSON.stringify(postEntries));
			// const result = await .post_data("debits_credits", postEntries);
			const result = await mosapi.generic_entity_post(Entities.DebitsCredits, postEntries)
			if (result.error) {
				showError("Error uploading file: " + result.error);
				mainStore.isLoading = false;
				return;
			} else {
				showSuccess("File uploaded successfully");
				await loadData();
			}
		}
	});
}

const onAddedFile = (e: any) => {
	console.log('added file');
	lastFile = e.target.files[0];
	showUpload.value = true;
}

</script>

<template>
	<div class="content-wrapper">
		<AcceptAllPending
			v-show="hasPendingNumberChanges || hasPendingDateChanges || hasPendingStatusChanges || hasPendingPerigrafiChanges"
			@accept="AcceptAll" />
		<p />

		<el-checkbox v-model="showRejected" @change="loadData">Show Rejected Entries</el-checkbox>

		<p />

		<el-tooltip class="box-item" effect="dark" content="New entry" placement="top">
			<el-button type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px" :icon="Plus"
				@click="openAddEdit(0)" />
		</el-tooltip>

		<el-button v-if="props.type !== DebitCreditType.TokoiTokwn" :disabled="!showUpload" type="primary" size="small"
			class="btn-add" style="float: right; margin: 2.5px" @click="onUpload">
			Upload
		</el-button>
		<input v-if="props.type !== DebitCreditType.TokoiTokwn" type="file" style="float: right; margin: 2.5px"
			@change="onAddedFile">


		<!-- <div style="float: right; width: 110px;">
			<el-upload
				ref="upload"
				v-model:file-list="fileList"

				class="upload-demo"
				:auto-upload="false"
				:on-change="onAddedFile"
				:on-remove="onRemoveFile"
				:on-exceed="handleExceed"
				:limit="1"
			>
				<template #trigger>
					<el-tooltip class="box-item" effect="dark" content="CSV upload" placement="top">
						<el-button type="primary" size="small" class="btn-add" style="margin: 2.5px;" :icon="UploadFilled" />
					</el-tooltip>
				</template>

</el-upload>
</div> -->

		<el-table :data="dataList" stripe border table-layout="fixed" size="small" v-loading="mainStore.isLoading"
			:row-class-name="tableRowClassName">

			<el-table-column prop="date" :label="$t('DebitCreditList.date')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="interest"
				:label="$t('DebitCreditList.base_rate')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="perithorio"
				:label="$t('DebitCreditList.perithorio')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="eisfora"
				:label="$t('DebitCreditList.eisfora')" width="110" />
			<el-table-column v-if="props.type === DebitCreditType.InterestTypeChanges" prop="pososto_tokwn_yperhmerias"
				:label="$t('DebitCreditList.pososto_tokwn_yperhmerias')" width="110" />

			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges"
				:label="$t('DebitCreditList.newdate')" width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'date')" style="background-color: lightgrey;">
						{{ GetPendingEntryForRow(scope.row, 'date').value }}
						<br />

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'date') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'date').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'date').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'date'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'date') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'date').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'date').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'date'))" />
						</el-tooltip>
					</div>
				</template>
			</el-table-column>

			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges"
				:label="$t('DebitCreditList.amount')" width="110">
				<template #default="scope">
					{{ formatEuroCurrency(scope.row.amount) }}
				</template>
			</el-table-column>

			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges"
				:label="$t('DebitCreditList.newamount')" width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'amount')" style="background-color: lightgrey;">
						{{ formatEuroCurrency(GetPendingEntryForRow(scope.row, 'amount').value) }}
						<br />
						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'amount') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'amount').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'amount').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'amount'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'amount') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'amount').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'amount').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'amount'))" />
						</el-tooltip>

					</div>
				</template>
			</el-table-column>
			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges"
				:label="$t('DebitCreditList.perigrafi')" width="110">
				<template #default="scope">
					{{ scope.row.perigrafi }}
				</template>
			</el-table-column>
			<el-table-column v-if="props.type !== DebitCreditType.InterestTypeChanges"
				:label="$t('DebitCreditList.newperigrafi')" width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'perigrafi')" style="background-color: lightgrey;">
						{{ GetPendingEntryForRow(scope.row, 'perigrafi').value }}
						<br />
						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'perigrafi') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'perigrafi').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'perigrafi').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'perigrafi'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'perigrafi') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'perigrafi').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'perigrafi').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'perigrafi'))" />
						</el-tooltip>

					</div>
				</template>
			</el-table-column>

			<el-table-column :label="$t('DebitCreditList.newstatus')" width="110">
				<template #default="scope">
					<div v-if="GetPendingEntryForRow(scope.row, 'status')" style="background-color: lightgrey;">
						<el-tag v-if="GetPendingEntryForRow(scope.row, 'status').value === '1'" type="success">{{
							$t('DebitCreditList.addition') }}</el-tag>
						<el-tag v-else-if="GetPendingEntryForRow(scope.row, 'status').value === '2'" type="danger">{{
							$t('DebitCreditList.removal') }}</el-tag>
						<br />

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'status') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Accept proposed change by ' +
								GetPendingEntryForRow(scope.row, 'status').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'status').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
								@click="AcceptChange(GetPendingEntryForRow(scope.row, 'status'))" />
						</el-tooltip>

						<el-tooltip v-if="GetPendingEntryForRow(scope.row, 'status') && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark"
							:content="'Reject proposed change by ' +
								GetPendingEntryForRow(scope.row, 'status').editor_username + ', done on ' + DateTime.fromISO(GetPendingEntryForRow(scope.row, 'status').insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
								" placement="bottom">
							<el-button style="background-color: lightcoral; color: white" size="small"
								:icon="CircleCloseFilled"
								@click="RejectChange(GetPendingEntryForRow(scope.row, 'status'))" />
						</el-tooltip>

					</div>
				</template>
			</el-table-column>

			<el-table-column label="Actions">
				<template #default="scope">
					<el-button type="primary" size="small" :icon="Edit"
						@click="openAddEdit(scope.row.debit_credit_id)" />
					<el-tooltip v-if="scope.row.entity_change_id && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark" :content="'Accept proposed change by ' +
						scope.row.username + ', done on ' + DateTime.fromISO(scope.row.insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
						" placement="bottom">
						<el-button style="background-color: green; color: white" size="small" :icon="SuccessFilled"
							@click="AcceptChange(scope.row.entity_change_id)" />
					</el-tooltip>
					<el-tooltip v-if="scope.row.entity_change_id && mainStore.loggedUser.user_type !== UserType.Agent" class="box-item" effect="dark" :content="'Reject proposed change by ' +
						scope.row.username + ', done on ' + DateTime.fromISO(scope.row.insert_timestamp).toFormat('dd/MM/yyyy HH:mm')
						" placement="bottom">
						<el-button style="background-color: red; color: white" size="small" :icon="CircleCloseFilled"
							@click="RejectChange(scope.row.entity_change_id)" />
					</el-tooltip>
					<el-button type="danger"
						v-if="(mainStore.loggedUser.user_type < UserType.Agent)" size="small"
						:icon="Delete" @click="deleteRow(scope.row.debit_credit_id)" />
				</template>
			</el-table-column>

		</el-table>

		<el-dialog v-if="showAddEdit" v-model="showAddEdit" @update:model-value="onCloseAddEdit"
			:title="(selectedRow === 0 ? 'New ' : '') + getDebitCreditTypeName(props.type)" width="30%">
			<AddEdit_DebitCredit :row_id="selectedRow" :view_only="false" :loan_account_id="props.loan_account_id"
				:type="props.type" @close="onCloseAddEdit" @save="onCloseAddEdit" />
		</el-dialog>

	</div>
</template>

<style>
.el-table .warning-row {
	--el-table-tr-bg-color: var(--el-color-error-light-9);
}

.el-table .success-row {
	--el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
