import { ref, watch } from "vue";
import { useMainStore } from "../store/main";
import { useGenericMethodsVariables } from "./genericMethodsVariables";
import { storeToRefs } from "pinia";
import { Entities } from "@/enums/Entities";

export function useViewVariablesMethods() {
    const genericVM = useGenericMethodsVariables();
    const mainStore = useMainStore();

    const { globalPortfolioID } = storeToRefs(mainStore);
    const { globalDebtorID } = storeToRefs(mainStore);
    const { globalLoanAgreementID } = storeToRefs(mainStore);

    const addEditVisible = ref(false);
    const dataList = ref<any[]>([]);
    const dataCount = ref(0);
    const deleteDialogVisible = ref(false);
    const entityName = ref("");
    const filterReloadTimer = ref<any>(null);
    const lastPage = ref(0);
    const loadPageHook = ref<(page: number) => void>((page: number) => { });
    const reloadOnGlobalFilterChange = ref(false);
    const selectedRow = ref(-1);
    const viewOnly = ref(false);

    watch(globalPortfolioID, async () => {
        if (reloadOnGlobalFilterChange.value && mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
            await loadPageHook.value(1);
    });

    watch(globalDebtorID, async () => {
        if (reloadOnGlobalFilterChange.value && mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
            await loadPageHook.value(1);
    });

    watch(globalLoanAgreementID, async () => {
        if (reloadOnGlobalFilterChange.value && mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
            await loadPageHook.value(1);
    });

    const filterChanged = async (value: string) => {
        if (filterReloadTimer.value !== 0) {
            clearTimeout(filterReloadTimer.value);
        }

        filterReloadTimer.value = setTimeout(() => {
            loadPageHook.value(1);
            filterReloadTimer.value = 0;
        }, 500);
    };

    const handleClickAdd = () => {
        addEditVisible.value = true;
        selectedRow.value = -1;
    };

    const handleClickEdit = (row: any, isEditable: boolean) => {
        viewOnly.value = !isEditable; //!isEditable(row);
        selectedRow.value = row.id;
        addEditVisible.value = true;
    };

    const handleClickDelete = (row_id: number) => {
        selectedRow.value = row_id;
        deleteDialogVisible.value = true;
    };

    const onAdd = async (messageTimer: number = 2000) => {
        genericVM.showSuccess(`${entityName.value} added successfully`, messageTimer);
        addEditVisible.value = false;
        loadPageHook.value(lastPage.value);
    };

    const onApprove = async (entity_type: Entities, entity_id: number, state: boolean, messageTimer: number = 2000) => {
        console.log('Approve result', entity_type, entity_id, state);
        const fv = await genericVM.entityApprove(entity_type, entity_id, state);

        if (fv) {
            genericVM.showSuccess(`${entityName.value} approved successfully`, messageTimer);
        } else {
            genericVM.showError(`${entityName.value} approval failed`, messageTimer);
        }

        await loadPageHook.value(lastPage.value);
    };

    const onCloseAddEdit = () => {
        addEditVisible.value = false;
    };

    const onCloseDelete = () => {
        deleteDialogVisible.value = false;
    };

    const onDelete = async (messageDuration: number = 2000) => {
        console.log('boom1')
        genericVM.showSuccess(`${entityName.value} deleted successfully`, messageDuration);
        deleteDialogVisible.value = false;
        await loadPageHook.value(lastPage.value);
    };

    const onFailSave = async (message: string, messageDuration: number = 2000) => {
        genericVM.showError(message, messageDuration);
        addEditVisible.value = false;
    };

    return {
        addEditVisible,
        dataCount,
        dataList,
        deleteDialogVisible,
        entityName,
        filterReloadTimer,
        lastPage,
        loadPageHook,
        reloadOnGlobalFilterChange,
        selectedRow,
        viewOnly,
        filterChanged,
        handleClickAdd,
        handleClickDelete,
        handleClickEdit,
        onAdd,
        onApprove,
        onCloseAddEdit,
        onCloseDelete,
        onDelete,
        onFailSave,
    };
}
