<script lang="ts" setup>
import { ref, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import { useMainStore } from "../store/main";
import { ElMessage } from "element-plus";
import { Edit, Delete } from "@element-plus/icons-vue";
import ProductDetails from "../components/ConfigProductDetails.vue";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { Entities } from "@/enums/Entities";
import ConfigProduct from "@/classes/DB_Entities/ConfigProduct";
const { showError } = useGenericMethodsVariables();
const mainStore = useMainStore();
const formRef = ref<FormInstance>();
const DeleteDialogVisible = ref(false);
const mosapi = useMOSAPIStore();
const detailsVisible = ref(false);
const deleteVisible = ref(false);
const selectedRow = ref(0);
const DeleteSelectedRow = ref(0);
const dataList = ref<ConfigProduct[]>([]);
const lastPage = ref(0);
const dataCount = ref(0);

onMounted(async () => {
  loadPage(1);
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteProduct(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeleteProduct = async (row_id: number) => {
  const product_id = row_id;

  //let result = await mosapi.DeleteConfigProductById(mainStore.loggedUser.id, product_id);
  const result = await mosapi.generic_entity_delete(Entities.ConfigProduct, product_id);
  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Product Updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Product Saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Product Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Product failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const new_entities = ref<ProductItem[]>([]);

interface ProductItem {
  product: string;
  type: string;
  greek_trans: string;
  english_trans: string;
  omologiako: boolean;
  koinopraktiko: boolean;
}

const removeProduct = (item: ProductItem) => {
  const index = new_entities.value.indexOf(item);
  if (index !== -1) {
    new_entities.value.splice(index, 1);
  }
};

const addProduct = () => {
  new_entities.value.push({
    product: "",
    type: "",
    greek_trans: "",
    english_trans: "",
    omologiako: false,
    koinopraktiko: false
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  //let result = await mosapi.CreateConfigProduct(dynamicValidateForm.products);
  const result = await mosapi.generic_entity_post_bulk(Entities.ConfigProduct, new_entities.value);
  if (result.error) {
    onFailSave();
    return;
  }
  new_entities.value = [];
  onInsert();
  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;
  lastPage.value = page;

  //let result = await mosapi.getAllConfigProducts(mainStore.loggedUser.id, page, 10);
  const result = await mosapi.generic_entity_get<ConfigProduct[]>(Entities.ConfigProduct, { orderBy: "product"}, page, 10);
  if (result.error) {
    showError(result.error);
    return;
  }

  dataList.value = result.data;

  dataCount.value = result.total;
  mainStore.isLoading = false;
};
</script>

<template>
  <div style="height: 100%" v-loading="mainStore.isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="product" :label="$t('PlatformSettingsView.loan_agreement_product')"
            type="text" />
          <el-table-column fixed sortable prop="type" :label="$t('PlatformSettingsView.summary_check_type')"
            type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;"
                :icon="Delete" />
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-model="DeleteDialogVisible" title="Loan Agreement Product Delete" width="50%">
          <span>{{ $t("PlatformSettingsView.la_delete_message") }}</span>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="Loan Agreement Product Details" width="50%">
          <ProductDetails :productid="selectedRow" @save="onSave" @close="
            detailsVisible = false;
          selectedRow = -1;
          loadPage(lastPage);
          " @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="new_entities" label-width="220px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(product, index) in new_entities"
                    :label="$t('PlatformSettingsView.la_product_details') + ' ' + index"
                    :prop="'products.' + index + '.value'" style="border-bottom: 0px; margin-top: 30px"
                    :key="index">
                    <el-col :span="10" style="padding: 13px">
                      <el-input :placeholder="$t('PlatformSettingsView.la_product_details')" v-model="product.product"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="10" style="padding: 13px">
                      <el-form-item style="margin-top: 0px">
                        <el-select v-model="product.type" :placeholder="$t('PlatformSettingsView.la_summarycheck_details')
                          ">
                          <el-option :label="'Summary Check Another Loan'" :value="'summary_check_another_loan'" />
                          <el-option :label="'Summary Check KOD'" :value="'summary_check_KOD'" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="10" style="padding: 13px">
                      <el-input :placeholder="$t('PlatformSettingsView.la_greek_trans')" v-model="product.greek_trans"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="10" style="padding: 13px">
                      <el-input :placeholder="$t('PlatformSettingsView.la_english_trans')"
                        v-model="product.english_trans" autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="4" style="padding: 13px">
                      <el-button style="float: right" @click.prevent="removeProduct(product)">
                        Delete
                      </el-button>
                    </el-col>
                    <el-col>
                      {{ $t('PlatformSettingsView.omologiako') }}
                      <el-switch v-model="product.omologiako" :active-text="product.omologiako ? 'Ναι' : 'Όχι'"
                        autocomplete="off" />
                    </el-col>
                    <el-col v-if="product.omologiako">
                      {{ $t('PlatformSettingsView.koinopraktiko') }}
                      <el-switch v-model="product.koinopraktiko" :active-text="product.koinopraktiko ? 'Ναι' : 'Όχι'"
                        autocomplete="off" />
                    </el-col>
                    <el-col>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="new_entities.length != 0" type="primary" @click="submitForm(formRef)">
                      {{ $t('generic.save') }}
                    </el-button>
                    <el-button @click="addProduct">{{
                      $t("PlatformSettingsView.la_new_product")
                    }}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
