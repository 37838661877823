<script lang="ts" setup>
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import { Edit, View } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import AddEdit_ROB from "../components/AddEdit_ROB.vue";
import ROB_BondTitles from "../components/ROB_BondTitles.vue";
import ROB_ParticipatingBanks from "../components/ROB_ParticipatingBanks.vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import RegisterOfBond from "@/classes/DB_Entities/RegisterOfBond";
import { ViewMosRegisterOfBondsView2 } from "@/interfaces/ViewMosRegisterOfBondsView2";

const mosapi = useMOSAPIStore();
//
// Use history.state instead
// -------------------------
// const props = defineProps<{
//   id: number;
//   view_only: boolean;
// }>();

const mainStore = useMainStore();
const router = useRouter();

const { t } = useI18n({ useScope: "global" });

const data = ref<RegisterOfBond>();
const rowid = ref<number>(-1);
const activeTab = ref<string>("info");
const showEditROB = ref<boolean>(false);
const hasPending = ref<boolean>(false);
const viewOnly = ref(false);
// const LockedRow = ref(false);

onMounted(async () => {
  // const ResultLockedRow = await .get("register_of_bonds?select=*&id=eq." + history.state.id);
  // LockedRow.value = ResultLockedRow.data[0].lockedrow;
  // if (LockedRow.value) {
  //   //await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow.value == false) {
  //   await .patch("register_of_bonds?id=eq." + history.state.id, { lockedrow: 'true' });
  //   //await loadPage(lastPage.value);
  // }
  await loadData();
});

const loadData = async () => {
  hasPending.value = false;
  mainStore.isLoading = true;
  rowid.value = history.state.id;
  viewOnly.value = history.state.view_only;

  const result = await mosapi.generic_entity_get<RegisterOfBond[]>(Entities.RegisterOfBonds, {id: rowid.value});
  // let url = `register_of_bonds?id=eq.${rowid.value}&select=*,loan_agreement!inner(ar_sumbashs)`;
  //result = await .get(url);

  if (result.data.length === 0) {
    ElMessage({
      showClose: true,
      message: t("errors.access_denied"),
      type: "error",
      duration: 2500,
    });

    router.push("/registerofbonds");
    return;
  }

  data.value = result.data[0];
  console.log('coco1-robselected'+JSON.stringify(data.value))
  // result = await .get("view_mosregisterofbonds_view1?select=pending_approvals&id=eq." + rowid.value);
	const result_view = await mosapi.get_view<ViewMosRegisterOfBondsView2>(UIViews.RegisterOfBonds, {id: rowid.value}, 1, 999);

  if (result_view.data[0].pending_approvals > 0) {
    hasPending.value = true;
  }

  mainStore.isLoading = false;
};

const editROB = async () => {
  showEditROB.value = true;
};

const onClose = async () => {
  //await .patch("register_of_bonds?id=eq." + history.state.id, { lockedrow: 'false' });
  showEditROB.value = false;
};

const onSave = async () => {
  showEditROB.value = false;
  await loadData();
};

const goBack = async () => {
  //await .patch("register_of_bonds?id=eq." + history.state.id, { lockedrow: 'false' });
  router.push('/registerofbonds');
}

</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading" style="height: 100%; padding: 50px">
    <el-row>
      <el-col>
        <el-button @click="goBack()" type="primary" style="float: right">Back</el-button>
      </el-col>
    </el-row>

    <el-tabs class="demo-tabs" v-model="activeTab">
      <el-tab-pane :label="$t('RegisterOfBondsView.information')" name="info">
        <el-row>
          <el-col>
            <el-button size="large" style="float: right; margin: 10px" class="btn-add" type="primary" @click="editROB"
              :icon="viewOnly ? View : Edit" />
          </el-col>
        </el-row>
        <el-row v-if="data?.loan_agreement">
          <div v-if="hasPending" class="el-tag el-tag--warning">
            {{ $t("notifications.pending_changes_register") }}
          </div>
          <el-col>
            <el-form :model="data" class="demo-form-inline" v-if="data?.loan_agreement">
              <el-row>
                <el-col :span="24" style="margin: 1px">
                  <el-form-item :label="$t('RegisterOfBondsView.loan_agreement')">
                    <el-input size="large" disabled v-model="data.loan_agreement.ar_sumbashs" />
                  </el-form-item>
                </el-col>
                <el-col :span="24" style="margin: 1px">
                  <el-form-item :label="$t('RegisterOfBondsView.payment_representative')">
                    <el-input size="large" disabled v-model="data.payment_representative" />
                  </el-form-item>
                </el-col>
                <el-col :span="24" style="margin: 1px">
                  <el-form-item :label="$t('RegisterOfBondsView.replacement_mechanism')">
                    <el-input :input-style="{ height: '200px' }" type="textarea" size="large" disabled
                      v-model="data.replacement_mechanism" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('RegisterOfBondsView.Participating_banks_servicers')" name="PB">
        <ROB_ParticipatingBanks :rob_id="rowid" :view_only="viewOnly" />
      </el-tab-pane>
      <el-tab-pane :label="$t('RegisterOfBondsView.bonds')" name="BT">
        <ROB_BondTitles :rob_id="rowid" :view_only="viewOnly" />
      </el-tab-pane>
    </el-tabs>

    <el-dialog v-if="showEditROB" v-model="showEditROB" @update:model-value="onClose" title="Edit Register of Bonds"
      width="50%">
      <AddEdit_ROB :rob_id="rowid" @save="onSave" @close="onClose" :view_only="viewOnly" />
    </el-dialog>
  </div>
</template>

<style scoped>
#tab-PB,
#tab-info,
#tab-BT {
  font-size: 17px !important;
}
</style>
