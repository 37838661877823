<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useMOSAPIStore } from "@/store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";
import Interest_Rates from "@/classes/DB_Entities/InterestRates";

const props = defineProps<{
  interestCategory: number;
}>();

const emits = defineEmits(["save", "close", "fail-save"]);

const mosapi = useMOSAPIStore();
const { showError } = useGenericMethodsVariables();

const data = ref<Interest_Rates>(new Interest_Rates());

const close = async () => {
  emits("close");
};

onMounted(async () => {
  data.value.interest_category = props.interestCategory;
});

const save = async () => {
  console.log(data.value);
  // TODO: Implement save functionality
  const { id, ...rest_data } = data.value; // eslint-disable-line
  const result = await mosapi.generic_entity_post(Entities.InterestRates, rest_data);

  if (result.error) {
    showError(result.error);
    return;
  }

  emits("save");
};

</script>

<template>
  <div>
    <el-form :model="data">
      <el-form-item :label="$t('PlatformSettingsView.interest_rate')">
        <el-input v-model="data.interest_value" autocomplete="off" type="number"
          style="width: 100%; border-bottom: 3px solid #e3e3e3" />
      </el-form-item>
      <el-form-item :label="$t('PlatformSettingsView.interest_value_date')">
        <el-date-picker format="DD-MM-YYYY" value-format="YYYY-MM-DD" v-model="data.interest_value_date" type="date"
          placeholder="Pick a date" style="width: 100%; border-bottom: 3px solid #e3e3e3" />
      </el-form-item>
    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped></style>
