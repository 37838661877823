<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "@/store/mos_api";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { ElTree } from "element-plus";
import { Delete, Download, Upload } from "@element-plus/icons-vue";
import { ElUpload, UploadFile, UploadFiles } from "element-plus";
import type { UploadInstance } from "element-plus";
import IUploadDocument from "../interfaces/IUploadDocument";
import { DateTime } from "luxon";
import UserType from "../enums/UserType";
import { Entities } from "@/enums/Entities";

interface Tree {
    id: number;
    label: string;
    db_string?: string;
    insert_timestamp?: string;
    username?: string;
    children: Tree[];
}

const props = defineProps<{
    entity_type: Entities;
    entity_id: number;
    row_id: number;
    allowed_folders: string[];
}>();

const defaultProps = {
    children: 'children',
    label: 'label',
}

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const { t } = useI18n({ useScope: "global" });

const {
    showError,
} = useGenericMethodsVariables();

const files = ref([] as any[]);
const tree = ref([] as Tree[]);

const showUploadDialog = ref(false);
const uploadRef = ref<UploadInstance>();
const fileSelected = ref(false);
const selectedUploadFolder = ref('');

const headers = ref({});

const loadData = async () => {
    mainStore.isLoading = true;

    if (props.entity_type !== Entities.RealEstate && props.entity_type !== Entities.Debtors && props.entity_type !== Entities.LoanAgreement) {
        throw new Error('Unknown entity type');
    }

    console.log('props', JSON.stringify(props))
    const res = await mosapi.get_file_list(props.entity_type, props.entity_id);
    // const res = await .get(`documents_${entity_table}?select=id,entity_id,filename,folder_name,insert_timestamp,uploader_user_id!left(username)&order=folder_name,insert_timestamp.desc,filename&entity_id=eq.${props.entity_id}&delete_timestamp=is.null`);

    files.value = res.data;
    tree.value = [];

    // Add allowed folders first
    for (const folder of props.allowed_folders) {
        tree.value.push({
            id: 0,
            label: t('documents_folders.' + folder),
            db_string: folder,
            children: [],
        });
    }

    // Add files, add also unknown folders
    for (const entry of files.value) {
        const treeItem = tree.value.find((x) => x.db_string === entry.folder_name);
        if (!treeItem) {
            tree.value.push({
                id: 0,
                label: t('documents_folders.' + entry.folder_name),
                db_string: entry.folder_name,
                children: [{ id: entry.id, label: entry.filename, children: [] }],
            });
        } else {
            treeItem.children?.push({ id: entry.id, label: entry.filename, insert_timestamp: entry.insert_timestamp, username: entry.uploader_username, children: [] });
        }
    }

    mainStore.isLoading = false;
}

onMounted(async () => {
    mainStore.isLoading = true;
    await loadData();
    mainStore.isLoading = false;
});

const onClickDownload = async (row: Tree) => {
    console.log('download', row.id);

    const filedata = await mosapi.download_file(props.entity_type, props.entity_id, row.id);
    // const filedata = await .download_document(props.entity_type, row.id);

    const blob = new Blob([filedata], { type: 'application/octet-stream' });
    // console.log(await blob.text());
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(blob));
    link.setAttribute(
        "download",
        row.label
    );
    document.body.appendChild(link); // Required for FF

    link.click(); // Trigger file download

    document.body.removeChild(link); // Remove link from body
};

const onClickDelete = async (row: Tree) => {
    mainStore.isLoading = true;

    const res = await mosapi.delete_file(props.entity_type, props.entity_id, row.id);
    // const res = await .patch(`documents_${entityType}?id=eq.${row.id}`, { delete_timestamp: timestamp, deleted_by_user_id: mainStore.loggedUser.id, filedata: null });
    console.log(res);
    await loadData();
    mainStore.isLoading = false;
};

const addedFile = async (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
    console.log('added file', uploadFile)
    headers.value.filename = encodeURI(uploadFile.name);

    for (const file of uploadFiles) {
        if (file.status === "ready") {
            fileSelected.value = true;
        } else if (file.status === "success") {
            fileSelected.value = false;
            uploadRef.value!.clearFiles();
        }
    }
}

const doUpload = async (test: any) => {
    // console.log('do upload it', test);
}

const submitUpload = async () => {
    headers.value.entitytype =  props.entity_type;
    headers.value.foldername = selectedUploadFolder.value;
    headers.value.entityid = props.entity_id;

    mainStore.isLoading = true;
    uploadRef.value!.submit();
};

const uploadComplete = async (response: any, uploadFile: UploadFile, uploadFiles: UploadFiles) => {
    console.log('upload completed');

    // const uwot =(await uploadFile.raw?.arrayBuffer());

    // const res = await mosapi.upload_file(props.entity_type, uwot, {
    //     entitytype: props.entity_type,
    //     filename: uploadFiles[0].name,
    //     foldername: selectedUploadFolder.value,
    //     uploaderuserid: mainStore.loggedUser.id,
    //     entityid: props.entity_id
    // } as IUploadDocument);

    // if (res.error) {
    //     showError(res.error);
    //     mainStore.isLoading = false;
    //     return;
    // }
    //, { entitytype: props.entity_type, filename: uploadFile.raw?.name, foldername: selectedUploadFolder.value, uploaderuserid: mainStore.loggedUser.id, entityid: props.entity_id } as IUploadDocument)
    // const res = await .upload_document((await uploadFile.raw?.arrayBuffer()), { entitytype: props.entity_type, filename: uploadFile.raw?.name, foldername: selectedUploadFolder.value, uploaderuserid: mainStore.loggedUser.id, entityid: props.entity_id } as IUploadDocument)

    showUploadDialog.value = false;
    await loadData();
    mainStore.isLoading = false;
};

const removedFile = async () => {
    console.log('removed file')
}

const onError = async () => {
    console.log('on error');
    mainStore.isLoading = false;
}

</script>

<template>
    <div v-loading="mainStore.isLoading">
        <div class="center">
            <el-button :icon="Upload" circle style="background-color: lightblue; " @click="showUploadDialog = true" />
            Upload new file
            <p />
            Allowed: txt, csv, pdf, xls, xlsx, doc, docx
        </div>

        <el-divider />

        <el-tree :data="tree" :props="defaultProps" default-expand-all>
            <template #default="{ node, data }">
                <span class="custom-tree-node">
                    <span style="display:inline-block; width: 250px;">{{ data.label }} {{ data.id === 0 ?
                        `(${data.children.length})` : '' }}</span>

                    <span v-if="data.id !== 0">
                        <span class="vl"></span>

                        {{ DateTime.fromISO(data.insert_timestamp).toFormat("dd/MM/yyyy HH:mm:ss") }}

                        <span class="vl"></span>

                        {{ data.username }}

                        <span class="vl"></span>

                        <el-button :icon="Download" circle size="small"
                            style="background-color: green; border: 0px; color: white; margin-left: 15px"
                            @click="onClickDownload(data)" />
                        <el-popconfirm
                            v-if="mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor"
                            title="Are you sure?" @confirm="onClickDelete(data)">
                            <template #reference>
                                <el-button :icon="Delete" circle size="small"
                                    style="background-color: red; border: 0px; color: white; margin-left: 15px" />
                            </template>
                        </el-popconfirm>
                    </span>
                </span>
            </template>
        </el-tree>

        <el-dialog v-if="showUploadDialog" v-model="showUploadDialog" :title="`Upload new file`">
            <div v-loading="mainStore.isLoading">
                
                <b>Upload folder</b>
                <p/>
                <el-select v-model="selectedUploadFolder" class="m-2 docselect" size="large" clearable filterable
                    placeholder=" ">
                    <el-option v-for="item in props.allowed_folders" :key="item" :label="t('documents_folders.' + item)"
                        :value="item" />
                </el-select>

                <el-upload ref="uploadRef" class="upload-demo" :action="`${mosapi.apiURL()}upload/${props.entity_type}`" :limit="1"
                    :auto-upload="false" :on-success="uploadComplete" :headers="headers"
                    @change="addedFile" @remove="removedFile" @error="onError" :with-credentials="true"
                    accept=".txt,.csv,.pdf,.xls,.xlsx,.doc,.docx">

                    <template #trigger>
                        <el-button class="topmargin50" type="primary">Select file</el-button>
                    </template>
                    <div class="el-upload el-upload--text leftpadding15" tabindex="0" style="border: 0px;">
                        <el-button v-if="fileSelected && selectedUploadFolder !== ''" class="ml-3" type="success"
                            @click="submitUpload">
                            Upload
                        </el-button>
                    </div>
                    <template #tip>
                        <div class="el-upload__tip text-red">
                            Limit 1 file. Extensions allowed: txt, csv, pdf, xls, xlsx, doc, docx
                        </div>
                    </template>
                </el-upload>
            </div>
        </el-dialog>
    </div>
</template>

<style scoped>
.center {
    padding-left: 125px;
    margin: auto;
}

.vl {
    border-left: 1px dotted gray;
    padding-left: 1px;
    padding-right: 1px;

}
</style>
