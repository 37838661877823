<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { Avatar, Bell, Edit, Delete, Document, House, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch, Lock } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddEdit_RE_Collateral from "../components/AddEdit_RE_Collateral.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import { UIViews } from "@/enums/UIViews";
import { Entities } from "@/enums/Entities";

const mainStore = useMainStore();
const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);

const filters = ref<any>({
  fullname: "",
  vat: "",
  ar_kalummatos: "",
  pending: false,
  pendingNew: false,
  hideRejected: false
});

const dataList = ref([]);
const dataCount = ref(0);
const lastPage = ref(0);
const addEditVisible = ref(false);
const selectedRow = ref(0);
const deleteVisible = ref(false);
const DeleteSelectedRow = ref(0);
const DeleteDialogVisible = ref(false);
const timer = ref<any>(null);
const isNewEntity = ref<boolean>(false);
const viewOnly = ref(false);
const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const locked_rows = ref(false);

const { entityApprove, showError } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '')
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '')
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '')
    await loadPage(1);
});
const handleClickEdit = async (row: any) => {
  viewOnly.value = !isEditable(row);
  selectedRow.value = row.id;
  addEditVisible.value = true;
  isNewEntity.value = false;
  // const ResultLockedRow = await .get("re_collaterals?select=*&id=eq." + row.id);
  // LockedRow.value = ResultLockedRow.data[0].lockedrow;

  // if (LockedRow.value) {
  //   await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow.value == false) {
  //   await .patch("re_collaterals?id=eq." + row.id, { lockedrow: 'true' });
  //   await loadPage(lastPage.value);
  // }
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteRealEstateCollateral(row_id);
};

const handleClickToUnlock = async (row_id: number) => {
  //await .patch("re_collaterals?id=eq." + row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
}

const UnlockAllLockedRows = async () => {
  //await .patch("re_collaterals?", { lockedrow: 'false' });
  // await loadPage(lastPage.value);
  // UnlockAllRowsVisible.value = false;
}

onMounted(async () => {

  //MiscSettings
  // await .get("config_misc_settings?select=*");
  // locked_rows.value = result.data[0].locked_rows;

  // console.log('locked_rows ' + locked_rows.value);

  // const ResultLockedRow = await .get("re_collaterals?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }
});

const DeleteRealEstateCollateral = async (row_id: number) => {
  const real_estate_id = row_id;
  const result = await mosapi.generic_entity_delete(Entities.RECollateral, real_estate_id);
  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }
  onDelete();
};

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  lastPage.value = page;


  // let filterUrl = "";

  // if (filtercoverNumber.value !== "") {
  //   filterUrl += `&ar_kalummatos=in.(${filtercoverNumber.value})`;
  // }

  // if (filterPending.value) {
  //   filterUrl += "&pending_approvals=gt.0";
  // }

  // if (filterLoanAgreementContractNumber.value !== "") {
  //   filterUrl += `&ar_sumbashs=eq.${filterLoanAgreementContractNumber.value}`;
  // }

  // if (filterKalumma.value !== "") {
  //   filterUrl += `&ar_kalummatos=eq.${filterKalumma.value}`;
  // }

  // if (filterFullname.value !== "") {
  //   filterUrl += `&fullname=ilike.*${filterFullname.value}*`;
  // }
  // if (filterVAT.value !== "") {
  //   filterUrl += `&vat_number=ilike.*${filterVAT.value}*`;
  // }
  // if (filterPendingNew.value) {
  //   filterUrl += "&approve_status=eq.0";
  // }

  // if (filterHideRejected.value) {
  //   filterUrl += "&approve_status=neq.2";
  // }

  // if (sortOrder.value !== "") {
  //   // if (filterUrl === "") {
  //   //   filterUrl = "?";
  //   // }
  //   filterUrl += `&orderFIX=${sortColumn.value}.${sortOrder.value}`;
  // }

  // let selectedPortfolio = "";
  // let selectedDebtor = "";
  // let selectedLoanAgreement = "";
  // let addedFilter = "";

  // if (mainStore.globalPortfolioID !== "") {
  //   selectedPortfolio = mainStore.globalPortfolioID;
  //   addedFilter += "&portfolio=eq." + selectedPortfolio;
  // }

  // if (mainStore.globalDebtorID !== "") {
  //   selectedDebtor = mainStore.globalDebtorID;
  //   addedFilter += "&debtor_id=eq." + selectedDebtor;
  // }

  // if (mainStore.globalLoanAgreementID !== "") {
  //   selectedLoanAgreement = mainStore.globalLoanAgreementID;
  //   addedFilter += "&loan_agreement_id=eq." + selectedLoanAgreement;
  // }
  // if (mainStore.loggedUser.user_type == UserType.Agent) {
  //   addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  // } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
  //   addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  // }
  // const result:  = await .getPaginated(
  //   "view_mosrealestatescollaterals_view1?select=*" + addedFilter + filterUrl,
  //   page
  // );
  // let result:  = await .getPaginated(
  //   "re_collaterals?select=*,loan_agreement!inner(*, debtor!inner(*)),real_estate!inner(*)" +
  //     addedFilter +
  //     filterUrl,
  //   page
  // );
	if (sortColumn.value === '') sortColumn.value = 'ar_kalummatos';
  const result = await mosapi.get_view(UIViews.RealEstateCollateralView, {...filters.value, orderBy: `${sortColumn.value}.${sortOrder.value}`}, lastPage.value, 10);
  if (result.error) {
    showError(result.error);
    return;
  }
  dataList.value = result.data;
  dataCount.value = result.total;

  // if (result.error) {
  //   showError(result.error);

  //   return;
  // }

  // if (result.headers)
  //   dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  //dataList.value = result.data;

  // const ResultLockedRow = await .get("re_collaterals?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }

  mainStore.isLoading = false;
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Real Estate Collateral added Successfully", // TODO: change text for update
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Real Estate Collateral Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const handleClickAdd = () => {
  addEditVisible.value = true;
  isNewEntity.value = true;
  selectedRow.value = -1;
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Real Estate Collateral failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const onCloseAddEdit = async () => {
  //await .patch("re_collaterals?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const approve = async (entity_id: number, state: boolean) => {
  const fv = await entityApprove(Entities.RECollateral, entity_id, state);

  if (fv) {
    ElMessage({
      showClose: true,
      message: `Real Estate Collateral updated`,
      type: "success",
      duration: 1500,
    });
  } else {
    ElMessage({
      showClose: true,
      message: `Real Estate Collateral update failed`,
      type: "error",
      duration: 1500,
    });
  }

  await loadPage(lastPage.value);
};

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("RealEstateCollateralsView.RealEstateCollaterals") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.ar_kalummatos" class="client-input"
            :placeholder="$t('RealEstateCollateralsView.collateral_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.fullname" class="client-input" :placeholder="$t('DebtorView.company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.vat" class="client-input" :placeholder="$t('DebtorView.tax_registration_nbr')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">

        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.pendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.hideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
          <el-row v-if="locked_rows == true">
            <el-col>
              <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('RealEstateCollateralsView.loan_agreement')" />
      <el-table-column sortable fixed prop="fullname" :label="$t('DebtorView.company_name')" />
      <el-table-column sortable fixed prop="vat_number" :label="$t('DebtorView.tax_registration_nbr')" />
      <el-table-column sortable fixed prop="ar_kalummatos" :label="$t('RealEstateCollateralsView.collateral_nbr')" />

      <el-table-column sortable fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" />
      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row)"
            :icon="isEditable(scope.row) ? Edit : View" :disabled="scope.row.lockedrow && locked_rows"
            style="background-color: #d6d6d6 !important;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
          <span v-if="
            mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
          ">
            <el-button class="btn-accept_square" type="primary" size="small" @click="approve(scope.row.id, true)"
              :icon="CircleCheck" />
            <el-button class="btn-reject_square" type="primary" size="small" @click="approve(scope.row.id, false)"
              :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.real_estate_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.real_estate_not_approved')">
            <template #reference>
              <el-button :icon="House" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="isNewEntity ? 'Add Real Estate Collateral' : 'Real Estate Collateral Details'">
      <AddEdit_RE_Collateral :re_collateral_id="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit"
        @save="onAdd" @fail-save="onFailSave" :view_only="viewOnly" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Real Estate Collaterals" width="50%">
      <span>Are you sure you want to delete this Real Estate Property Collateral?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
