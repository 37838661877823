<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMOSAPIStore } from "../store/mos_api";

import { useMainStore } from "../store/main";
import { Document } from "@element-plus/icons-vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { UIViews } from "@/enums/UIViews";

const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const runDebtorSummary = ref(false);
const runGroupSummary = ref(false);
const debtorsList = ref([]);
const debtorForReport = ref("");
const groupsList = ref([]);
const groupForReport = ref("");

const debtorReports = [
    {
        name: 'Summary',
    },
]

const groupReports = [
    {
        name: 'Summary',
    },
]

const loadDebtors = async () => {
    mainStore.isLoading = true;

    try {
        // await .get("view_mosdebtors_view1?select=id,fullname,vat_number,cid,group_id,group_name"+ addedFilter + "&approve_status=eq.1&orderFIX=fullname.asc");
		const result = await mosapi.get_view(UIViews.DebtorsView, {approve_status:1, orderBy : 'fullname.asc'});
        debtorsList.value = result.data;

        const dict: any = {};
        for (let i = 0; i < result.data.length; ++i) {
            if (result.data[i].group_id === null)
                continue;
            if (!(result.data[i].group_id in dict)) {
                dict[result.data[i].group_id] = result.data[i].group_name;
                console.log(result.data[i].group_id, result.data[i].group_name);
            }
        }

        for (const key in dict) {
            (groupsList.value as any).push({
                id: key,
                groupname: dict[key]
            });
        }
    } catch (error) {
        showError(error as any);
    }
    mainStore.isLoading = false;
};

onMounted(async () => {
    await loadDebtors();
});

const debtorChanged = async () => {
    console.log(debtorForReport.value);
}

const generateDebtorSummary = async () => {
    if (debtorForReport.value == "") {
        showError("Please select debtor");
        return;
    }

    mainStore.isLoading = true;
    await mosapi.getDebtorSummary(parseInt(debtorForReport.value)); // TODO
    mainStore.isLoading = false;
    runDebtorSummary.value = false;
    debtorForReport.value = "";
}

const groupChanged = () => {
    console.log(groupForReport.value);
}

const generateGroupSummary = async () => {
    if (groupForReport.value == "") {
        showError("Please select group");
        return;
    }

    mainStore.isLoading = true;
    await mosapi.getGroupSummary(parseInt(groupForReport.value)); // TODO
    mainStore.isLoading = false;
    runGroupSummary.value = false;
    groupForReport.value = "";
}
</script>

<template>
    <div class="content-wrapper">
        <div>
            Debtor reports
        </div>

        <el-table :data="debtorReports" border style="width: 100%">
            <el-table-column prop="name" label="Report name" width="350" />
            <el-table-column fixed="right" :label="$t('global.operations')">
                <template #default>
                    <el-tooltip effect="dark" content="Generate report" placement="top">
                        <el-button size="small" type="primary" class="btn-warn" :icon="Document" circle
                            @click="runDebtorSummary = true" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <br />

        <br />

        <div>
            Group reports (for Debtors)
        </div>

        <el-table :data="groupReports" border style="width: 100%">
            <el-table-column prop="name" label="Report name" width="350" />
            <el-table-column fixed="right" :label="$t('global.operations')">
                <template #default>
                    <el-tooltip effect="dark" content="Generate report" placement="top">
                        <el-button size="small" type="primary" class="btn-warn" :icon="Document" circle
                            @click="runGroupSummary = true" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <br />

        <br />

        <!-- <div>
            Loan Agreement reports
        </div>

        <el-table :data="loanAgreementReports" border style="width: 100%">
            <el-table-column prop="name" label="Report name" width="350" />
            <el-table-column fixed="right" :label="$t('global.operations')">
                <template #default="scope">
                    <el-tooltip effect="dark" content="Generate report" placement="top">
                        <el-button size="small" type="primary" class="btn-warn" :icon="Document" circle />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table> -->

        <el-dialog v-model="runDebtorSummary" title="Debtor Summary Report"
            @update:model-value="runDebtorSummary = false; debtorForReport = '';">
            <el-form v-loading="mainStore.isLoading" label-width="120px">
                <el-form-item label="Debtor" prop="debtor">
                    <el-select filterable clearable v-model="debtorForReport" placeholder="Select debtor"
                        @change="debtorChanged">
                        <el-option v-for="debtor in debtorsList" :key="(debtor as any).id"
                            :label="(debtor as any).fullname" :value="(debtor as any).id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <span class="dialog-footer">
                    <el-button :disabled="mainStore.isLoading" @click="runDebtorSummary = false">Cancel</el-button>
                    <el-button :disabled="mainStore.isLoading" type="primary"
                        @click="generateDebtorSummary">Run</el-button>
                </span>
            </template>
        </el-dialog>

        <el-dialog v-model="runGroupSummary" title="Group Summary Report"
            @update:model-value="runGroupSummary = false; groupForReport = '';">
            <el-form v-loading="mainStore.isLoading" label-width="120px">
                <el-form-item label="Group">
                    <el-select filterable clearable v-model="groupForReport" placeholder="Select group"
                        @change="groupChanged">
                        <el-option v-for="group in groupsList" :key="(group as any).id"
                            :label="(group as any).groupname" :value="(group as any).id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <span class="dialog-footer">
                    <el-button :disabled="mainStore.isLoading" @click="runGroupSummary = false">Cancel</el-button>
                    <el-button :disabled="mainStore.isLoading" type="primary"
                        @click="generateGroupSummary">Run</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped></style>
