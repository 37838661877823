<script lang="ts" setup>

// Vue stuff
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// Libs
import { DateTime } from "luxon";

// Stores
import { useMainStore } from "../store/main";

// Composables
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";

// Classes
import AmountEntryExtra from '../classes/AmountEntryExtra';
import LoanAccount_DB from "@/classes/DB_Entities/LoanAccount";
import LoanAccount from '../classes/LoanAccount';

// Enums
import LoanRepaymentType from '../enums/LoanRepaymentType';
import LoanAccountEventType from '../enums/LoanAccountEventType';
import DebitCredit from "../classes/DB_Entities/DebitCredit";
import DebitCreditType from "../enums/DebitCreditType";

import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import UserType from "../enums/UserType";
import { useI18n } from "vue-i18n";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "../store/mos_api";

import { UIViews } from "@/enums/UIViews";
import ConfigArgia from "@/classes/DB_Entities/ConfigArgia";
import APIReply from "@/classes/APIReply";
import ConfigLoanAccountProduct from "@/classes/DB_Entities/ConfigLoanAccountProduct";
import ConfigPostalcode from "@/classes/DB_Entities/ConfigPostalCode";
import Debtor from "@/classes/DB_Entities/Debtor";
import ViewMosLoanAccountsView2 from "@/interfaces/ViewMosLoanAccountsView2";
import Interest_Rates from "@/classes/DB_Entities/InterestRates";
import ConfigInterest from "@/classes/DB_Entities/ConfigInterest";
import Kinhsh from "@/interfaces/Kinhsh";

const mosapi = useMOSAPIStore();
const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();

const { showError } = useGenericMethodsVariables();
const onChangeApo = async () => {
    loanAccount.value.apoDate = fromDate.value;
}
const onChangeEos = async () => {
    loanAccount.value = new LoanAccount();
    loanAccount.value.mosapi_caller = mosapi;
    loanAccount.value.eosDate = toDate.value;
    try {
        await loadArgies();
        await loadLoanAccountData(parseInt(route.params.loan_account_id as string));
    } catch (ex) {
        mainStore.isLoading = false;
        showError('1 - ' + ex.message, 2500);
    }
}

// Refs

const euroFormat = new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' });
const loanAccount = ref(new LoanAccount());
let clientName = '';
let clientAddress = '';
// let postalClientAddress = '';
const loanAccountNumber = ref('');
let docStartingDate = '';
let docEndingDate = '';
const loanAgreementNumber = ref('');
const kathisterimeno_arxika = ref('');
const loan_product = ref('');
const startingDate = ref('');
const synolikoPoso = ref(0);
const loanCapitalRepaymentType = ref('');
const interestRepaymentEpeteiako = ref('');
const arnitika_epitokia = ref('');
const interestChangeIsEpeteiako = ref('');
const calculateArgies = ref('');
const base_rate = ref('');
const fromDate = ref('');
const toDate = ref('');

const enhmeroKefalaioList = ref<AmountEntryExtra[]>([]);
const kathysterhmenoKefalaio = ref<AmountEntryExtra[]>([]);
const kathysterhmenoiTokoi = ref<AmountEntryExtra[]>([]);
const listaKinhsewn = ref<Kinhsh[]>([]);

const loadDebitsCredits = async (loan_account_id: number) => {
    //let result = await .get("debits_credits?loan_account_id=eq." + loan_account_id + "&status=eq.1"); // Load only approved entries status=1
    const result = await mosapi.generic_entity_get(Entities.DebitsCredits, { loan_account_id: loan_account_id, status_int: 1 });
    const items = result.data as DebitCredit[];

    for (const item of items) {
        console.log('******* Convert to ISO here', JSON.stringify(item.date))

        item.date = DateTime.fromISO(item.date).toFormat('yyyy-MM-dd') as string;

        switch (item.type) {
            case DebitCreditType.Credit:
                loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Pistwsh, amount: item.amount as number, perigrafi: item.perigrafi as string, credit_type: item.credit_type });
                break;

            case DebitCreditType.Installment:
                if (loanAccount.value.loanCapitalRepaymentType !== LoanRepaymentType.Tokoxreolytiko) {
                    if (loanAccount.value.isOmologiako && loanAccount.value.calculateArgies && loanAccount.value.isArgia(item.date)) {
                        let epom = loanAccount.value.EpomenhErgasimh(item.date);

                        if (DateTime.fromISO(epom).month !== DateTime.fromISO(item.date).month || DateTime.fromISO(epom).year !== DateTime.fromISO(item.date).year) {
                            epom = loanAccount.value.ProhgoumenhErgasimh(item.date);
                        }

                        item.date = epom;
                    }
                    loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Dosh, amount: item.amount as number, perigrafi: item.perigrafi as string, });
                }
                break;

            case DebitCreditType.Exoda:
                loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Exodo, amount: item.amount as number, perigrafi: item.perigrafi as string, });
                break;

            // case DebitCreditType.Debit:
            //     loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Xrewsh, amount: item.amount as number, perigrafi: item.perigrafi as string, });
            //     break;
            case DebitCreditType.TokoiTokwn:
                let found = false;
                for (let i = 0; i < loanAccount.value.kathisterimenotokoiArxika.length; ++i) {
                    if (loanAccount.value.kathisterimenotokoiArxika[i].date === item.date) {
                        found = true;
                        loanAccount.value.kathisterimenotokoiArxika[i].amount += item.amount;
                        loanAccount.value.kathisterimenotokoiArxika[i].perigrafi += ' ' + item.perigrafi;
                        break;
                    }
                }
                if (!found)
                    loanAccount.value.kathisterimenotokoiArxika.push(item);

                break;

            case DebitCreditType.HmerTokou:
                loanAccount.value.interest_change_dates.push(item.date);
                break;

            case DebitCreditType.InterestTypeChanges:
                loanAccount.value.interest_type_changes_list.push(item);
                loanAccount.value.eventsList.push({
                    date: item.date, processed: false, type: LoanAccountEventType.InterestChange, amount: 0 as number, perigrafi: item.perigrafi as string,
                    config_interest_id: item.config_interest_id, perithorio: item.perithorio, eisfora: item.eisfora, pososto_tokwn_yperhmerias: item.pososto_tokwn_yperhmerias
                });
                break;
        }
    }

    loanAccount.value.interest_type_changes_list.sort((a: { date: string }, b: { date: string}) => {
        if (a.date < b.date) return -1;
        else if (a.date > b.date) return 1;
        return 0;
    })
}

// const FixDate = (date: string): string => {
//     return (date >= (DateTime.now().toISODate() as string) ? DateTime.now().toISODate() as string : date);
// }

// TODO discuss when we should stop normally
const loadInterestRates = async (config_interest_id: number) => {
    /*GET automato_epitokio h oxi*/
    
    const getautomato = await mosapi.generic_entity_get(Entities.ConfigInterest, { id: config_interest_id }) as APIReply<ConfigInterest[]>;
    // const getautomato = await .get(`config_interest?select=automato_epitokio,interest&id=eq.${config_interest_id}`);

    base_rate.value = getautomato.data[0].interest;

    if (!getautomato.data[0].automato_epitokio) {
        let nextDate = loanAccount.value.loanStartingDate;
        let dates: string[] = []; // eslint-disable-line

        do {
            dates.push(`'${nextDate}'`);

            // We do not have any entries from DebitsCredits type = 4
            if (loanAccount.value.interest_type_changes_list.findIndex((item) => item.date === nextDate) === -1) {
                loanAccount.value.eventsList.push({ type: LoanAccountEventType.InterestChange, date: nextDate, amount: 0, processed: false, perigrafi: "" });
            }

            nextDate = AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), loanAccount.value.interestChangeFrequency, loanAccount.value.interestChangeIsEpeteiako).toISODate() as string;
        } while (nextDate <= loanAccount.value.FixDate(DateTime.now().toISODate(), loanAccount.value.eosDate));

        // const result = await .get(`interest_rates?select=date:interest_value_date,interest:interest_value&interest_category=eq.${config_interest_id}&orderFIX=interest_value_date.asc`);
        const result = await mosapi.generic_entity_get(Entities.InterestRates, { interest_category: config_interest_id, orderBy:'interest_value_date.asc' }) as APIReply<Interest_Rates[]>;
        // Use renamed fields
        loanAccount.value.interestRateList = result.data.map((item: Interest_Rates) => {
            return { date: DateTime.fromISO(item.interest_value_date).toFormat('yyyy-MM-dd'), interest: item.interest_value };
        });
    } else {
        const nextDate = loanAccount.value.loanStartingDate;
        // const result = await .get(`interest_rates?select=date:interest_value_date,interest:interest_value&interest_category=eq.${config_interest_id}&interest_value_date=gte.${nextDate}&orderFIX=interest_value_date.asc`);
        const result = await mosapi.generic_entity_get(Entities.InterestRates, { interest_category: config_interest_id, interest_value_date: { gte: nextDate }, orderBy : 'interest_value_date.asc' }) as APIReply<Interest_Rates[]>;
        
        // Use renamed fields
        loanAccount.value.interestRateList = result.data.map((item: Interest_Rates) => {
            return { date: DateTime.fromISO(item.interest_value_date).toFormat('yyyy-MM-dd'), interest: item.interest_value };
        });

        for (let i = 0; i < loanAccount.value.interestRateList.length; i++) {
            // We do not have any entries from DebitsCredits type = 4
            if (loanAccount.value.interest_type_changes_list.findIndex((item) => item.date === loanAccount.value.interestRateList[i].date) === -1) {
                loanAccount.value.eventsList.push({ type: LoanAccountEventType.InterestChange, date: loanAccount.value.interestRateList[i].date, amount: 0, processed: false, perigrafi: "" });
            }
        }
    }
}

const calculateDoseis = () => {
    if (loanAccount.value.loanCapitalRepaymentType !== LoanRepaymentType.Tokoxreolytiko) {
        return;
    }

    // Calculate doseis based on tokoi frequency

    let nextDate = loanAccount.value.loanStartingDate;

    do {
        if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), loanAccount.value.interestRepaymentFrequency, loanAccount.value.interestRepaymentEpeteiako).toISODate() as string) === loanAccount.value.FixDate(DateTime.now().toISODate(), loanAccount.value.eosDate)) {
            loanAccount.value.isTodayEventEnhmero = true;
        }

        nextDate = loanAccount.value.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), loanAccount.value.interestRepaymentFrequency, loanAccount.value.interestRepaymentEpeteiako).toISODate() as string, loanAccount.value.eosDate);
        loanAccount.value.eventsList.push({ type: LoanAccountEventType.Dosh, date: nextDate, amount: 0, processed: false, perigrafi: "" });
    } while (nextDate < loanAccount.value.FixDate(DateTime.now().toISODate(), loanAccount.value.eosDate));
}

const checkAccess = async (loan_account_id: number): Promise<boolean> => {
    const result = await mosapi.get_view(UIViews.LoanAccountView, { id: loan_account_id }) as APIReply<ViewMosLoanAccountsView2[]>;
    // let result = await .get("view_mosloanaccounts_view1?id=eq." + loan_account_id);
    if (result.data.length === 0) {
        showError('Δεν βρέθηκε το δάνειο');
        return false;
    }

    // Admin full access
    if (mainStore.loggedUser.user_type === UserType.Administrator) {
        return true;
    }

    if (mainStore.loggedUser.user_type === UserType.Supervisor) {
        if (!result.data[0].portfolio_allowed_users.includes(mainStore.loggedUser.id as number)) {
            showError('Δεν έχετε πρόσβαση στο δάνειο');
            return false;
        }

        return true;
    }

    if (mainStore.loggedUser.user_type === UserType.Agent) {
        if (!result.data[0].allowed_users.includes(mainStore.loggedUser.id as number)) {
            showError('Δεν έχετε πρόσβαση στο δάνειο');
            return false;
        }

        return true;
    }

    showError('Δεν έχετε πρόσβαση στο δάνειο');
    return false;
}

const loadLoanAccountData = async (loan_account_id: number) => {
    mainStore.isLoading = true;

    loanAccount.value.mosapi_caller = mosapi;

    // Stop processing if we don't have access
    if (!(await checkAccess(loan_account_id))) {
        mainStore.isLoading = false;
        return;
    }

    //let loanAccountData: LoanAccountEntity = (await .get("loan_accounts?id=eq." + loan_account_id)).data[0] as LoanAccountEntity;
    const result = (await mosapi.generic_entity_get<LoanAccount_DB[]>(Entities.LoanAccount, { id: loan_account_id }));
    const loanAccountData = result.data[0];
    // console.log(JSON.stringify(loanAccountData));
    //let loanAgreementDataResult:  = new ();
    //let clientDataResult:  = new ();

    //loanAgreementDataResult = await .get(`loan_agreement?id=eq.${loanAccountData.loan_agreement_id}`);
    const loanAgreementDataResult = await mosapi.generic_entity_get(Entities.LoanAgreement, { id: loanAccountData.loan_agreement_id }) as APIReply<LoanAgreement[]>;
    const loan_agreement: LoanAgreement = loanAgreementDataResult.data[0] as LoanAgreement;

    console.log(loan_agreement.ar_sumbashs);

    //clientDataResult = await .get(`debtor?id=eq.${loan_agreement.debtor_id}`);
    const result_debtor = (await mosapi.generic_entity_get(Entities.Debtors, { id: loan_agreement.debtor_id })) as APIReply<Debtor[]>;
    const clientDataResult = result_debtor.data[0];
    console.log('yogo4', JSON.stringify(clientDataResult));
    let post_codeClient: ConfigPostalcode | null = null;

    if (clientDataResult.postcode !== null) {
        //post_codeClient = await .get("config_postalcode?id=eq." + clientDataResult[0].postcode);
        const result_postcode = await mosapi.generic_entity_get(Entities.ConfigPostalCode, { id: clientDataResult.postcode }) as APIReply<ConfigPostalcode[]>;
        post_codeClient = result_postcode.data[0];
    }

    let post_codePostal: ConfigPostalcode | null = null;
    if (clientDataResult.postal_postcode !== null) {
        //post_codePostal = await .get("config_postalcode?id=eq." + clientDataResult[0].postal_postcode);
        const result_postcodePostal = (await mosapi.generic_entity_get(Entities.ConfigPostalCode, { id: clientDataResult.postal_postcode }));
        post_codePostal = result_postcodePostal.data[0];
    }

    let postCodeToPrint = '';
    let postCodeToPrintPostal = '';

    if (post_codeClient) {
        postCodeToPrint = post_codeClient.postalcode;
    }

    if (post_codePostal) {
        postCodeToPrintPostal = post_codePostal.postalcode;
    }

    loanAgreementNumber.value = loan_agreement.ar_sumbashs;

    clientName = clientDataResult.fullname;
    if (clientDataResult.address !== null) clientAddress += clientDataResult.address;
    if (clientDataResult.address_number !== null) clientAddress += " " + clientDataResult.address_number;
    if (clientDataResult.city !== null && clientAddress.length > 0) clientAddress += ", " + clientDataResult.city;
    if (postCodeToPrintPostal !== '' && clientAddress.length > 0) clientAddress += ", " + postCodeToPrint;

    //clientAddress = clientDataResult[0].address + ' ' + clientDataResult[0].address_number + ', ' + clientDataResult[0].city + ', ' + postCodeToPrint;    
    if (clientDataResult.postal_address?.length > 0) {
        clientAddress = clientDataResult.postal_address + ' ';
        if (clientDataResult.postal_address_number !== null) clientAddress += clientDataResult.postal_address_number;
        if (clientDataResult.postal_city !== null) clientAddress += ', ' + clientDataResult.postal_city;
        if (postCodeToPrintPostal !== '') clientAddress += ', ' + postCodeToPrintPostal;
    }
    loanAccount.value.arnitika_epitokia = loanAccountData.arnitika_epitokia as boolean;
    if (loanAccount.value.arnitika_epitokia) arnitika_epitokia.value = 'Ναι'; else arnitika_epitokia.value = 'Όχι';
    if (loanAccountData.dedoul_tokoi !== null) {
        loanAccount.value.dedoul = loanAccountData.dedoul_tokoi as number;
    }
    if (loanAccountData.dedoul_tokoi_kath_kef !== null) {
        loanAccount.value.dedoul_kath_kef = loanAccountData.dedoul_tokoi_kath_kef as number;
    }
    if (loanAccountData.dedoul_tokoi_kath_tok !== null) {
        loanAccount.value.dedoul_kath_tok = loanAccountData.dedoul_tokoi_kath_tok as number;
    }
    if (loanAccountData.hmer_enarkshs !== null && (loanAccountData.hmer_enarkshs as string) !== '') {
        loanAccount.value.loanStartingDate = DateTime.fromISO(loanAccountData.hmer_enarkshs as string).toFormat('yyyy-MM-dd');
        if (fromDate.value === undefined || fromDate.value === '') {
            docStartingDate = DateTime.fromISO(loanAccountData.hmer_enarkshs as string).toFormat('yyyy-MM-dd'); // loanAccountData.hmer_enarkshs as string;
            docStartingDate = DateTime.fromISO(docStartingDate).toFormat('dd/MM/yyyy');
        } else {
            docStartingDate = DateTime.fromISO(fromDate.value).toFormat('dd/MM/yyyy');
        }
    } else if (loan_agreement.hmer_sumbashs !== null && loan_agreement.hmer_sumbashs !== '') {
        loanAccount.value.loanStartingDate = DateTime.fromISO(loan_agreement.hmer_sumbashs).toFormat('yyyy-MM-dd'); // loan_agreement.hmer_sumbashs
        docStartingDate =  DateTime.fromISO(loan_agreement.hmer_sumbashs).toFormat('yyyy-MM-dd'); // loan_agreement.hmer_sumbashs as string;
        docStartingDate = DateTime.fromISO(docStartingDate).toFormat('dd/MM/yyyy');
    } else {
        throw new Error('Δεν υπάρχει ημερομηνία έναρξης δανείου');
    }
    kathisterimeno_arxika.value = 'Όχι';
    startingDate.value = loanAccount.value.loanStartingDate;
    if (loanAccountData.kathisterimeno === true) {
        kathisterimeno_arxika.value = "Ναι"
        loanAccount.value.kathisterimenoARXIKA = loanAccountData.kathisterimeno;
        loanAccount.value.kathisterimeno_enim_arx = loanAccountData.kathisterimeno_enim_arx as number;

        if (loanAccountData.kathisterimenodate !== null) {
            loanAccount.value.kathisterimenodate = loanAccountData.kathisterimenodate as string;
            startingDate.value = loanAccount.value.kathisterimenodate;
        }
        else if (loanAccount.value.loanStartingDate !== null) {
            loanAccount.value.kathisterimenodate = loanAccount.value.loanStartingDate;
            startingDate.value = loanAccount.value.kathisterimenodate;
        }
        else if (loan_agreement.hmer_sumbashs !== null) {
            loanAccount.value.kathisterimenodate = loan_agreement.hmer_sumbashs as string;
        }
        else {
            throw new Error('Δεν υπάρχει ημερομηνία έναρξης καθυστέρημενου δανείου');
        }

    }
    else { loanAccount.value.kathisterimenoARXIKA = false; }


    if (loanAccountData.kathisterimenokef !== null) {
        loanAccount.value.kathisterimenokef = loanAccountData.kathisterimenokef as number;
    }
    if (loanAccountData.kathisterimenotok !== null) {
        loanAccount.value.kathisterimenotok = loanAccountData.kathisterimenotok as number;
    }


    if (loanAccountData.nea_hmer_lukshs !== null)
        loanAccount.value.loanEndingDate = loanAccountData.nea_hmer_lukshs as string;
    else if (loanAccountData.arxikh_hmer_lukshs !== null)
        loanAccount.value.loanEndingDate = loanAccountData.arxikh_hmer_lukshs as string;
    else if (loan_agreement.arxikh_hmer_lukshs !== null)
        loanAccount.value.loanEndingDate = loan_agreement.arxikh_hmer_lukshs as string;
    else {
        throw new Error('Δεν υπάρχει ημερομηνία λήξης δανείου');
    }



    if (loanAccountData.synoliko_poso !== null) {
        loanAccount.value.loanCapitalAmount = loanAccountData.synoliko_poso as number;
    } else if (loan_agreement.synol_poso_sumbashs !== null) {
        loanAccount.value.loanCapitalAmount = loan_agreement.synol_poso_sumbashs as number;
    }
    synolikoPoso.value = loanAccount.value.loanCapitalAmount;
    if (loanAccountData.loan_capital_repayment_type !== null) {
        loanAccount.value.loanCapitalRepaymentType = loanAccountData.loan_capital_repayment_type as number;
        switch (loanAccount.value.loanCapitalRepaymentType) {
            case LoanRepaymentType.XreolytikoIsoposo:
                loanCapitalRepaymentType.value = t('payment_type_list.xreolitiko');
                break;
            case LoanRepaymentType.Tokoxreolytiko:
                loanCapitalRepaymentType.value = t('payment_type_list.tokoxreolitiko');
                break;
            case LoanRepaymentType.XreolytikoAnisoposo:
                loanCapitalRepaymentType.value = t('payment_type_list.balloon');
                break;
        }


    } else if (loan_agreement.tropos_apopl !== null) {
        if (loan_agreement.tropos_apopl === 'xreolitiko') {
            loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoIsoposo;/*δεν ξερω το νουμερο που εβαλες για το χρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/ // TODO: Check LoanRepaymentType -- ti ginetai me isoposa/anisoposa akribws
            loanCapitalRepaymentType.value = t('payment_type_list.xreolitiko');
        } else if (loan_agreement.tropos_apopl === 'tokoxreolitiko') {
            loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.Tokoxreolytiko;/*δεν ξερω το νουμερο που εβαλες για το τοκοχρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            loanCapitalRepaymentType.value = t('payment_type_list.tokoxreolitiko');
        } else if (loan_agreement.tropos_apopl === 'balloon') {
            loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;/*δεν ξερω το νουμερο που εβαλες για το τοκοχρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            loanCapitalRepaymentType.value = t('payment_type_list.balloon');
        }
    }
    if (loanAccountData.loan_capital_repayment_frequency !== null) {
        loanAccount.value.loanCapitalRepaymentFrequency = loanAccountData.loan_capital_repayment_frequency as number;
    } else if (loan_agreement.program_apopl_kefal !== null) {
        loanAccount.value.loanCapitalRepaymentFrequency = loan_agreement.program_apopl_kefal as number;
    }

    loanAccount.value.loanRepaymentEpeteiako = loanAccountData.loan_capital_repayment_epeteiako as boolean;

    if (loanAccountData.loan_interest_repayment_frequency !== null) {
        loanAccount.value.interestRepaymentFrequency = loanAccountData.loan_interest_repayment_frequency as number;
    } else if (loan_agreement.program_apopl_tokon !== null) {
        loanAccount.value.interestRepaymentFrequency = loan_agreement.program_apopl_tokon as number;
    }
    //let config_product = await .get("config_loan_account_product?id=eq." + loanAccountData.product);
    const config_product = await mosapi.generic_entity_get(Entities.ConfigLoanAccountProduct, { id: loanAccountData.product }) as APIReply<ConfigLoanAccountProduct[]>;
    if (config_product.data.length === 0) {
        throw new Error("Δεν έχει οριστεί τύπος δανειακού λογαριασμού.")
        return;
    }
    loan_product.value = config_product.data[0].product;
    loanAccount.value.calculateArgies = (config_product.data[0].calculate_argies);
    if (loanAccount.value.calculateArgies)
        calculateArgies.value = 'Ναι';
    else
        calculateArgies.value = 'Όχι';
    loanAccount.value.isOmologiako = (config_product.data[0].omologiako);
    loanAccount.value.isAllhloxreo = (config_product.data[0].allhloxreo);

    loanAccount.value.interestRepaymentEpeteiako = loanAccountData.loan_interest_repayment_epeteiako as boolean;
    loanAccount.value.interestRepaymentEpeteiakoORIGINAL = loanAccountData.loan_interest_repayment_epeteiako as boolean;

    if (loanAccount.value.interestRepaymentEpeteiako) { interestRepaymentEpeteiako.value = 'Επετειακός' }
    else { interestRepaymentEpeteiako.value = 'Ημερολογιακός' }
    loanAccountNumber.value = loanAccountData.ar_trexontos_logariasmou as string;

    loanAccount.value.interestChangeFrequency = loanAccountData.loan_interest_change_frequency as number;
    loanAccount.value.interestChangeIsEpeteiako = loanAccountData.loan_interest_change_epeteiako as boolean;
    if (loanAccount.value.interestChangeIsEpeteiako) interestChangeIsEpeteiako.value = 'Επετειακά'; else interestChangeIsEpeteiako.value = 'Ημερολογιακά';
    loanAccount.value.perithorio = loanAccountData.perithorio as number;
    loanAccount.value.eisfora = loanAccountData.eisfora_n128_1975 as number;

    if (loan_agreement.vasi_ektokismou === null) {
        throw new Error('Δεν υπάρχει βάση εκτοκισμού στη σύμβαση');
    }

    loanAccount.value.bash_ektokismou = loan_agreement.vasi_ektokismou as number;
    loanAccount.value.pososto_tokwn_yperhmerias = loanAccountData.epitok_uperhm as number;

    if (loanAccount.value.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko) {
        loanAccount.value.interestRepaymentEpeteiako = true;
    }

    await loadDebitsCredits(loan_account_id);

    if (loanAccountData.loan_interest_change_frequency !== null && loanAccountData.loan_interest_change_epeteiako !== null && loanAccountData.kathgor_epitok_bashs !== undefined) {
        await loadInterestRates(loanAccountData.kathgor_epitok_bashs);
    }

    //return;

    //vasi_ektokismou
    //kathgor_epitok_bashs
    //perithorio
    //epitok_uperhm
    //eisfora_n128_1975
    //plithos_doseon_kefal

    // await loadArgies();

    await calculateDoseis();

    await loanAccount.value.Initialize();
    await loanAccount.value.ProcessEvents();

    enhmeroKefalaioList.value = loanAccount.value.enhmeroKefalaioList;
    kathysterhmenoKefalaio.value = loanAccount.value.kathysterhmenoKefalaio;
    kathysterhmenoiTokoi.value = loanAccount.value.kathysterhmenoiTokoi;
    listaKinhsewn.value = loanAccount.value.listaKinhsewn;

    mainStore.isLoading = false;
}

const doPDF = async () => {
    try {
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        let page = pdfDoc.addPage([595.28, 841.89]);
        const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
        const urlBold = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-B.ttf'
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
        const fontBytesBold = await fetch(urlBold).then((res) => res.arrayBuffer())
        const ubuntuFont = await pdfDoc.embedFont(fontBytes);
        const ubuntuFontBold = await pdfDoc.embedFont(fontBytesBold);
        const fontSize = 10;
        let selidaYpoloipo = 0;
        let selidaDate = '';
        const maxLineAddress = 30;

        const textX = 50;
        let textY = 718;
        const innerX = 65;
        const lineHeight = 18;
        const lineThickness = 2;
        const initialPageY = 758;
        const currentDate1 = loanAccount.value.FixDate(DateTime.now().toISODate(), loanAccount.value.eosDate);
        const currentDate = DateTime.fromISO(currentDate1).toFormat('dd/MM/yyyy');
        let j = 0;
        let positionStart = 0;
        let positionEnd = maxLineAddress;
        docEndingDate = currentDate;
        page.drawText('Αθήνα, ' + DateTime.now().toFormat('dd/MM/yyyy'), { font: ubuntuFont, x: textX, y: textY, size: fontSize });
        /* Η διευθυνση και η επωνυμια πρεπει καπως να περιοριστουν στη σελιδα clientName*/
        if (clientName.length <= maxLineAddress) {
            page.drawText(clientName, { font: ubuntuFont, x: textX + 270, y: textY, size: fontSize });
        }
        else {
            for (j = 0; j < 3; j++) {

                positionEnd = clientName.substring(positionEnd, positionEnd + maxLineAddress).lastIndexOf(' ');
                if (positionEnd < 0) { positionEnd = maxLineAddress * (j + 1) };
                page.drawText(clientName.substring(positionStart, positionEnd), { font: ubuntuFont, x: textX + 270, y: textY - j * lineHeight, size: fontSize });
                positionStart = positionEnd;

            }
        }
        textY -= lineHeight * (j + 1);
        positionStart = 0;
        positionEnd = maxLineAddress;
        if (clientAddress.length <= maxLineAddress) {
            page.drawText(clientAddress, { font: ubuntuFont, x: textX + 270, y: textY, size: fontSize });
        }
        else {
            for (j = 0; j < 3; j++) {
                positionEnd = clientAddress.substring(positionEnd, positionEnd + maxLineAddress).lastIndexOf(' ');
                if (positionEnd < 0) { positionEnd = maxLineAddress };
                page.drawText(clientAddress.substring(j * maxLineAddress, (j + 1) * maxLineAddress), { font: ubuntuFont, x: textX + 270, y: textY - j * lineHeight, size: fontSize });
                positionStart = positionEnd;
            }
        }
        textY -= lineHeight - 20;
        page.drawText('Παρατίθενται ακολούθως κινήσεις του: ', { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        page.drawText('Δανειακού Λογαριασμού: ' + loanAccountNumber.value, { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        page.drawText('Περίοδος από: ' + docStartingDate + ' έως ' + docEndingDate, { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        page.drawText('Αρ. Σύμβασης ' + loanAgreementNumber.value, { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        textY -= lineHeight;
        page.drawText('Ημερομηνία', { font: ubuntuFontBold, x: innerX, y: textY, size: 9 });
        page.drawText('Χρεώσεις', { font: ubuntuFontBold, x: innerX + 100, y: textY, size: 9 });
        page.drawText('Πιστώσεις', { font: ubuntuFontBold, x: innerX + 200, y: textY, size: 9 });
        page.drawText('Λεπτομερής περιγραφή κίνησης', { font: ubuntuFontBold, x: innerX + 300, y: textY, size: 9 });
        page.drawLine({ start: { x: 25, y: textY - 8 }, end: { x: 595.28 - 25, y: textY - 8 }, thickness: lineThickness, opacity: 0.75, });
        textY = textY - lineHeight - lineThickness;
        // let initialDate = loanAccount.value.listaKinhsewn[0].date;
        if (fromDate.value === undefined || fromDate.value === '') { fromDate.value = loanAccount.value.listaKinhsewn[0].date };
        if (toDate.value === undefined || toDate.value === '') { toDate.value = loanAccount.value.FixDate(DateTime.now().toISODate(), loanAccount.value.eosDate) };
        for (let i = 0; i < loanAccount.value.listaKinhsewn.length - 1; i++) {
            if (loanAccount.value.listaKinhsewn[i].date < fromDate.value || loanAccount.value.listaKinhsewn[i].date > toDate.value) {
                if (loanAccount.value.listaKinhsewn[i].xrewsh > 0) {
                    selidaYpoloipo += loanAccount.value.listaKinhsewn[i].xrewsh;
                }
                continue;
            }
            //length - 1 για να μην παρει το τελικο υπολοιπο
            //loanAccount.value.listaKinhsewn
            if (textY < 50) {
                page.drawText(selidaDate, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
                page.drawText(formatEuroCurrency(selidaYpoloipo), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
                page.drawText('Μεταφορά οφειλής σε επόμενη σελίδα', { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
                page = pdfDoc.addPage([595.28, 841.89]);
                textY = initialPageY;
                page.drawText('Ημερομηνία', { font: ubuntuFontBold, x: innerX, y: textY, size: 9 });
                page.drawText('Χρεώσεις', { font: ubuntuFontBold, x: innerX + 100, y: textY, size: 9 });
                page.drawText('Πιστώσεις', { font: ubuntuFontBold, x: innerX + 200, y: textY, size: 9 });
                page.drawText('Λεπτομερής περιγραφή κίνησης', { font: ubuntuFontBold, x: innerX + 300, y: textY, size: 9 });
                page.drawLine({ start: { x: 25, y: textY - 8 }, end: { x: 595.28 - 25, y: textY - 8 }, thickness: lineThickness, opacity: 0.75, });
                textY = initialPageY - lineHeight - lineThickness;
                page.drawText(selidaDate, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
                page.drawText(formatEuroCurrency(selidaYpoloipo), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
                page.drawText('Μεταφορά οφειλής από προηγούμενη σελίδα', { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
                textY -= lineHeight;
            }
            selidaDate = loanAccount.value.listaKinhsewn[i].date;
            page.drawText(loanAccount.value.listaKinhsewn[i].date, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
            if (loanAccount.value.listaKinhsewn[i].xrewsh > 0) {
                page.drawText(formatEuroCurrency(loanAccount.value.listaKinhsewn[i].xrewsh), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
                selidaYpoloipo += loanAccount.value.listaKinhsewn[i].xrewsh;
            }
            else if (loanAccount.value.listaKinhsewn[i].pistwsh > 0) {
                page.drawText(formatEuroCurrency(loanAccount.value.listaKinhsewn[i].pistwsh), { font: ubuntuFont, x: innerX + 200, y: textY, size: 9 });
                selidaYpoloipo -= loanAccount.value.listaKinhsewn[i].pistwsh;
            }

            page.drawText(loanAccount.value.listaKinhsewn[i].perigrafh.replace('Καθυστερημένου', 'Καθυστ.'), { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
            textY -= lineHeight;
        }

        if (textY >= 50) {
            selidaDate = loanAccount.value.FixDate(DateTime.now().toISODate(), loanAccount.value.eosDate);

            page.drawText(selidaDate, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
            page.drawText(formatEuroCurrency(selidaYpoloipo), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
            page.drawText('Υπόλοιπο οφειλής', { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
            textY -= lineHeight; textY -= lineHeight;
            page.drawText('πλέον δεδουλευμένων τόκων έως ' + currentDate + ' συνολικού ποσού ' + formatEuroCurrency(loanAccount.value.latestDedoul) + '.', { font: ubuntuFont, x: innerX, y: textY, size: 9 });
        }
        else {
            page = pdfDoc.addPage([595.28, 841.89]);
            textY = initialPageY;
            page.drawText('πλέον δεδουλευμένων τόκων έως ' + currentDate + ' συνολικού ποσού ' + formatEuroCurrency(loanAccount.value.latestDedoul) + '.', { font: ubuntuFont, x: innerX, y: textY, size: 9 });
        }

        const pdfData = await pdfDoc.saveAsBase64({ dataUri: true });

        const blob = await (await fetch(pdfData)).blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `LoanCalculations_${DateTime.now().toISODate()}_${loanAccountNumber.value}.pdf`;
        link.click();
    } catch (ex) {
        console.log(ex);
    }
}

// TODO: Check if user has access to loan account
onMounted(async () => {

    try {
        await loadArgies();
        await loadLoanAccountData(parseInt(route.params.loan_account_id as string));

        //Καθαρισμος αδειων γραμμων ΠΡΟΣΟΧΗ, τις υπολογιζει εως σημερα!!! 
        if (loanAccount.value.kathysterhmenoiTokoi.length > 0 && loanAccount.value.kathysterhmenoiTokoi[loanAccount.value.kathysterhmenoiTokoi.length - 1].startDate === loanAccount.value.kathysterhmenoiTokoi[loanAccount.value.kathysterhmenoiTokoi.length - 1].endDate
            && loanAccount.value.kathysterhmenoiTokoi[loanAccount.value.kathysterhmenoiTokoi.length - 1].dedouleymenoi_tokoi < 0.01
            && loanAccount.value.kathysterhmenoiTokoi[loanAccount.value.kathysterhmenoiTokoi.length - 1].tokoi_periodou < 0.01
        ) {
            loanAccount.value.kathysterhmenoiTokoi.pop();
        }
        if (loanAccount.value.kathysterhmenoKefalaio.length > 0 && loanAccount.value.kathysterhmenoKefalaio[loanAccount.value.kathysterhmenoKefalaio.length - 1].startDate === loanAccount.value.kathysterhmenoKefalaio[loanAccount.value.kathysterhmenoKefalaio.length - 1].endDate
            && loanAccount.value.kathysterhmenoKefalaio[loanAccount.value.kathysterhmenoKefalaio.length - 1].dedouleymenoi_tokoi < 0.01
            && loanAccount.value.kathysterhmenoKefalaio[loanAccount.value.kathysterhmenoKefalaio.length - 1].tokoi_periodou < 0.01
        ) {
            loanAccount.value.kathysterhmenoKefalaio.pop();
        }

        if (loanAccount.value.enhmeroKefalaioList.length > 0) {
            let i = loanAccount.value.enhmeroKefalaioList.length - 1;
            for (; i > 0; i--) {
                if (loanAccount.value.enhmeroKefalaioList[i].startDate > loanAccount.value.loanEndingDate
                    && loanAccount.value.enhmeroKefalaioList[i].dedouleymenoi_tokoi < 0.01
                    && loanAccount.value.enhmeroKefalaioList[i].tokoi_periodou < 0.01
                    && loanAccount.value.enhmeroKefalaioList[i].amount < 0.01
                    && loanAccount.value.enhmeroKefalaioList[i].doshAmount < 0.01)
                    loanAccount.value.enhmeroKefalaioList.pop();
            }
        }
    } catch (ex) {
        mainStore.isLoading = false;
        showError('2 - ' + ex.message, 2500);
    }
});

const formatEuroCurrency = (value: number) => {
    return euroFormat.format(value);
}

const backToLoanAccountsView = () => {
    router.push({ name: 'Loan Accounts' });
}

const loadArgies = async () => {
    //let result = await .get("config_argies?select=hmer_argias&orderFIX=hmer_argias.asc");
    const result = await mosapi.generic_entity_get(Entities.ConfigArgies, { orderBy : 'hmer_argias.asc' }) as APIReply<ConfigArgia[]>;
    loanAccount.value.argiesList = [];

    for (const item of result.data) {
        loanAccount.value.argiesList.push(item.hmer_argias);
    }
}

// import { ElTag, type Column } from 'element-plus'


// const columns_enhmero: Column<any>[] = [
//     { key: 'startDate', title: 'Από', dataKey: 'startDate', width: 75, cellRenderer: ({ cellData: startDate }) => h('span', { style: 'font-size: 12px;', innerHTML: startDate }) },
//     { key: 'endDate', title: 'Έως', dataKey: 'endDate', width: 75, cellRenderer: ({ cellData: endDate }) => h('span', { style: 'font-size: 12px;', innerHTML: endDate }) },
//     { key: 'days', title: 'Μέρες', width: 60, cellRenderer: ({ rowData }) => h('span', { style: 'font-size: 12px;', innerHTML: `${DateTime.fromISO(rowData.endDate).diff(DateTime.fromISO(rowData.startDate), 'days').days} ${rowData.calculatedDays ? `(${rowData.calculatedDays})` : ''}` }) },
// ];

</script>

<template>
    <div class="content-wrapper" v-loading="mainStore.isLoading">
        <!-- <a download="PDF Title" :href="pdfData">Download PDF document</a> -->
        <el-button style=" background-color: lightgrey; color: black" @click="backToLoanAccountsView">&#x2190; To Loan
            Accounts List</el-button>
        <p />
        <el-row :gutter="10">
            <el-col :span="1"> <span class="top5">{{ t('ektokismos.apodate') }}</span></el-col>
            <el-col :span="3">

                <el-date-picker format="DD-MM-YYYY" value-format="YYYY-MM-DD" v-model="fromDate" type="date"
                    placeholder="From date" style="width: 100%; border-bottom: 3px solid #e3e3e3"
                    @change="onChangeApo" />
            </el-col>
            <el-col :span="1"> <span class="top5">{{ t('ektokismos.todate') }}</span></el-col>
            <el-col :span="3">

                <el-date-picker format="DD-MM-YYYY" value-format="YYYY-MM-DD" v-model="toDate" type="date"
                    placeholder="To date" style="width: 100%; border-bottom: 3px solid #e3e3e3"
                    @change="onChangeEos" />
            </el-col><el-col :span="16">
                <el-button style="float: right; background-color: lightgrey; color: black" @click="doPDF">Download
                    PDF</el-button>
            </el-col>
        </el-row>


        <p />

        <table id="ektokismos_info">
            <thead>
                <tr>
                    <td colspan="4" class="infotitleheader">Πληροφορίες υπολογισμών</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="infotitle">Δανειακός Λογαριασμός:</td>
                    <td>{{ loanAccountNumber }}</td>
                    <td class="infotitle">Καθυστερημένο αρχικά:</td>
                    <td>{{ kathisterimeno_arxika }}</td>
                </tr>
                <tr>
                    <td class="infotitle">Σύμβαση:</td>
                    <td>{{ loanAgreementNumber }}</td>
                    <td class="infotitle">Συνολικό ποσό</td>
                    <td>{{ formatEuroCurrency(synolikoPoso) }}</td>
                </tr>
                <tr>
                    <td class="infotitle">Προϊόν</td>
                    <td>{{ loan_product }}</td>
                    <td class="infotitle">Έναρξη</td>
                    <td>{{ startingDate }}</td>

                </tr>
                <tr>
                    <td class="infotitle">Δόσεις:</td>
                    <td>{{ loanAccount.loanCapitalRepaymentFrequency }}</td>
                    <td class="infotitle">Capital Repayment type:</td>
                    <td>{{ loanCapitalRepaymentType }}</td>
                </tr>
                <tr>
                    <td class="infotitle">Δόσεις κεφαλαίου</td>
                    <td>{{ interestRepaymentEpeteiako }}</td>
                    <td class="infotitle">Αλλαγή επιτοκίου</td>
                    <td>{{ interestChangeIsEpeteiako }}</td>

                </tr>
                <tr>
                    <td class="infotitle">Συχνότητα εκτοκισμού Κεφαλαίου</td>
                    <td>{{ loanAccount.interestRepaymentFrequency }}</td>
                    <td class="infotitle">Συχνότητα αλλαγής επιτοκίου</td>
                    <td>{{ loanAccount.interestChangeFrequency }}</td>

                </tr>

                <tr>
                    <td class="infotitle">Αργίες</td>
                    <td>{{ calculateArgies }}</td>
                    <td class="infotitle">Αρνητικά επιτόκια</td>
                    <td>{{ arnitika_epitokia }}</td>
                </tr>
                <tr>
                    <td class="infotitle">Αρχικό Επιτόκιο βάσης</td>
                    <td>{{ base_rate }}</td>
                    <td class="infotitle">Αρχικό Περιθώριο</td>
                    <td>{{ loanAccount.perithorio }}%</td>
                </tr>
                <tr>
                    <td class="infotitle">Αρχική Υπερημερία</td>
                    <td>{{ loanAccount.pososto_tokwn_yperhmerias }}%</td>
                    <td class="infotitle">Αρχική Εισφορά N128/75</td>
                    <td>{{ loanAccount.eisfora }}%</td>
                </tr>
                <tr>
                    <td class="infotitle">Δεδουλευμένοι Τόκοι</td>
                    <td>{{ loanAccount.dedoul }}</td>
                    <td class="infotitle">Δεδουλευμένοι Τόκοι Καθ. Κεφ.</td>
                    <td>{{ loanAccount.dedoul_kath_kef }}</td>
                </tr>
                <tr>
                    <td class="infotitle">Δεδουλευμένοι Τόκοι Καθ. Τόκων</td>
                    <td>{{ loanAccount.dedoul_kath_tok }}</td>
                    <td class="infotitle"></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <p />
        <p />

        <b>Ενήμερο Κεφάλαιο</b>
        <p />
        <!-- <el-table-v2 :columns="columns_enhmero" :data="enhmeroKefalaioList" stripe border table-layout="fixed" size="small" :width="700" :height="500" /> -->

        <el-table :data="enhmeroKefalaioList" stripe border table-layout="fixed" size="small">
            <el-table-column prop="startDate" label="Από" />
            <el-table-column prop="endDate" label="Έως" />
            <el-table-column label="Ημέρες">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.endDate).diff(DateTime.fromISO(scope.row.startDate), 'days').days }}
                    {{ scope.row.calculatedDays ? `(${scope.row.calculatedDays})` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="valeurDate" label="Valeur" />
            <!-- <el-table-column label="ΑΡΓΙΕΣ">
                <template #default="scope">
                    {{ 
                    scope.row.valeurDate !==scope.row.endDate ? "ΑΡΓΙΑ" : "-"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Επιτόκιο">
                <template #default="scope">
                    {{ Number(scope.row.interest / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits:
                            4
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Περιθώριο">
                <template #default="scope">
                    {{ Number(scope.row.perithorio / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    }) }}
                </template>
            </el-table-column>
            <el-table-column label="Εισφορά">
                <template #default="scope">
                    {{ Number(scope.row.eisfora / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <!-- <el-table-column label="Ποσοστό Υπερημερίας">
                <template #default="scope">
                    {{ Number(scope.row.pososto_tokwn_yperhmerias / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 2 })
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Ενήμερο Κεφάλαιο">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column v-if="loanAccount.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko"
                label="Τοκοχρεολύσιο">
                <template #default="scope">
                    <span>{{ formatEuroCurrency(scope.row.tokoxreolusio) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δόση">
                <template #default="scope">
                    <span v-if="scope.row.doshAmount !== 0">{{ formatEuroCurrency(scope.row.doshAmount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση">
                <template #default="scope">
                    <span v-if="scope.row.usedPistwsh && scope.row.usedPistwsh !== 0">{{
                        formatEuroCurrency(scope.row.usedPistwsh) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δεδουλευμένοι Τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dedouleymenoi_tokoi) }}
                </template>
            </el-table-column>
            <el-table-column label="Τόκοι Περιόδου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.tokoi_periodou) }}
                </template>
            </el-table-column>
            <el-table-column label="# Οφειλής">
                <template #default="scope">
                    <span v-if="scope.row.ofeilhIndex !== 0">{{ scope.row.ofeilhIndex }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="# Recalculate PMT">
                <template #default="scope">
                    <span >{{ JSON.stringify(scope.row.recalculatePMTEntry) }}</span>
                </template>
            </el-table-column> -->
            <!--<el-table-column label="Δόση Κεφαλαίου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dosh) }}
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση έναντι ενήμ. κεφαλαίου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.pistwsh_enanti_enhm) }}
                </template>
            </el-table-column>
            
             -->
            <el-table-column prop="periodos_index" label="# Περιόδου" />
            <!-- <el-table-column prop="original_endDate" label="dummy" /> -->
        </el-table>

        <p />
        <b>Καθυστερημένο Κεφάλαιο</b>
        <p />
        <el-table :data="kathysterhmenoKefalaio" stripe border table-layout="fixed" size="small">
            <el-table-column prop="startDate" label="Από" />
            <el-table-column prop="endDate" label="Έως">
                <template #default="scope">
                    <b v-if="scope.row.isEndDate">
                        {{ scope.row.endDate }}
                    </b>
                </template>
            </el-table-column>
            <el-table-column label="Ημέρες">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.endDate).diff(DateTime.fromISO(scope.row.startDate), 'days').days }}
                </template>
            </el-table-column>
            <el-table-column prop="valeurDate" label="Valeur">
                <template #default="scope">
                    <b v-if="scope.row.isEndDate">
                        {{ scope.row.valeurDate }}
                    </b>
                </template>
            </el-table-column>
            <!-- <el-table-column label="ΑΡΓΙΕΣ">
            <template #default="scope">
                    {{ 
                    scope.row.valeurDate !==scope.row.endDate ? "ΑΡΓΙΑ" : "-"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Επιτόκιο">
                <template #default="scope">
                    {{ Number(scope.row.interest / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits:
                            4
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Περιθώριο">
                <template #default="scope">
                    {{ Number(scope.row.perithorio / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    }) }}
                </template>
            </el-table-column>
            <el-table-column label="Εισφορά">
                <template #default="scope">
                    {{ Number(scope.row.eisfora / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Ποσοστό Υπερημερίας">
                <template #default="scope">
                    {{ Number(scope.row.pososto_tokwn_yperhmerias / 100).toLocaleString('el', {
                        style: 'percent',
                        minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Καθυστερημένο Κεφάλαιο">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση">
                <template #default="scope">
                    <span v-if="scope.row.usedPistwsh && scope.row.usedPistwsh !== 0">{{
                        formatEuroCurrency(scope.row.usedPistwsh) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δεδουλευμένοι Τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dedouleymenoi_tokoi) }}
                </template>
            </el-table-column>
            <el-table-column label="Τόκοι Περιόδου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.tokoi_periodou) }}
                </template>
            </el-table-column>
            <el-table-column label="# Οφειλής">
                <template #default="scope">
                    <span v-if="scope.row.ofeilhIndex !== 0">{{ scope.row.ofeilhIndex }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="periodos_index" label="# Περιόδου" />
            <!-- <el-table-column prop="dummytext" label="dummy" /> -->
            <!-- <el-table-column prop="original_endDate" label="dummy" />  -->
        </el-table>

        <p />
        <b>Καθυστερημένοι Τόκοι</b>
        <p />
        <el-table :data="kathysterhmenoiTokoi" stripe border table-layout="fixed" size="small">
            <el-table-column prop="startDate" label="Από" />
            <el-table-column prop="endDate" label="Έως" />


            <el-table-column label="Ημέρες">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.endDate).diff(DateTime.fromISO(scope.row.startDate), 'days').days }}
                </template>
            </el-table-column>
            <el-table-column prop="valeurDate" label="Valeur" />
            <!-- <el-table-column label="ΑΡΓΙΕΣ">
            <template #default="scope">
                    {{ 
                    scope.row.valeurDate !==scope.row.endDate ? "ΑΡΓΙΑ" : "-"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Επιτόκιο">
                <template #default="scope">
                    {{ Number(scope.row.interest / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits:
                            4
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Περιθώριο">
                <template #default="scope">
                    {{ Number(scope.row.perithorio / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    }) }}
                </template>
            </el-table-column>
            <el-table-column label="Εισφορά">
                <template #default="scope">
                    {{ Number(scope.row.eisfora / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Ποσοστό Υπερημερίας">
                <template #default="scope">
                    {{ Number(scope.row.pososto_tokwn_yperhmerias / 100).toLocaleString('el', {
                        style: 'percent',
                        minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Καθυστερημένοι τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση">
                <template #default="scope">
                    <span v-if="scope.row.usedPistwsh && scope.row.usedPistwsh !== 0">{{
                        formatEuroCurrency(scope.row.usedPistwsh) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δεδουλευμένοι Τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dedouleymenoi_tokoi) }}
                </template>
            </el-table-column>
            <el-table-column label="Τόκοι Περιόδου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.tokoi_periodou) }}
                </template>
            </el-table-column>
            <el-table-column label="# Οφειλής">
                <template #default="scope">
                    <span v-if="scope.row.ofeilhIndex !== 0">{{ scope.row.ofeilhIndex }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="periodos_index" label="# Περιόδου" />
            <!-- <el-table-column prop="origin" label="Origin" /> -->
            <!-- <el-table-column prop="dummytext" label="dummy" /> -->
            <!-- <el-table-column prop="original_endDate" label="dummy" />  -->
        </el-table>

        <p />
        <b>Κινήσεις</b>
        <p />
        <el-table :data="listaKinhsewn" stripe border table-layout="fixed" size="small">
            <el-table-column prop="date" label="Ημερονηνία">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.date).toFormat('dd/MM/yyyy') }}
                </template>
            </el-table-column>
            <el-table-column label="Χρεώσεις">
                <template #default="scope">
                    <!-- {{ scope.row.xrewsh }} -->
                    <span v-if="scope.row.xrewsh && scope.row.xrewsh !== 0">{{ formatEuroCurrency(scope.row.xrewsh)
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Πιστώσεις">
                <template #default="scope">
                    <span v-if="scope.row.pistwsh && scope.row.pistwsh !== 0">{{ formatEuroCurrency(scope.row.pistwsh)
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="perigrafh" label="Λεπτομερής περιγραφή κίνησης" />
        </el-table>
        πλέον δεδουλευμένων τόκων έως {{ DateTime.now().toFormat('dd/MM/yyyy') }} συνολικού ποσού {{
            formatEuroCurrency(loanAccount.latestDedoul) }} EUR.
    </div>
</template>

<style scoped></style>