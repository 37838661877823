<script lang="ts" setup>
import { ref, watch, onBeforeMount, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Avatar, Bell, Edit, Delete, Document, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddEdit_Guarantee from "../components/AddEdit_Guarantee.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "../store/mos_api";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import { Entities } from "@/enums/Entities";
import { UIViews } from "@/enums/UIViews";
import { GuaranteeFilters } from "@/interfaces/GuaranteeFilters";
import { ViewMosGuaranteesView2 } from "@/interfaces/ViewMosGuaranteesView2";

const { showError, showSuccess } = useGenericMethodsVariables();
const mosapi = useMOSAPIStore();
const { onSortChange } = useSort();
const mainStore = useMainStore();
const DeleteDialogVisible = ref(false);
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);

const filters = ref<GuaranteeFilters>({
  fullname: "",
  vat: "",
  guarantee_full_name: "",
  guarantee_vat_number: "",
  pending: false,
  pendingNew: false,
  hideRejected: false
})
const LockedRow = ref(false);
const UnlockAllRowsVisible = ref(false);
const locked_rows = ref(false);
const dataList = ref<ViewMosGuaranteesView2[]>([]);
const dataCount = ref(0);
const lastPage = ref(0);
const selectedRow = ref(0);
const addEditVisible = ref(false);
const DeleteSelectedRow = ref(0);
const isNewEntity = ref(false);
const viewOnly = ref(false);
const timer = ref<any>(null);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

onBeforeMount(() => {
  mainStore.isLoading = true;
})

onMounted(async () => {
  mainStore.isLoading = false;
  loadPage(1);
});

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Guarantee added Successfully",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;

  lastPage.value = page;

  //const result = await mosapi.Guarantees_get_all(filters, page, 10);
  const result = await mosapi.get_view<ViewMosGuaranteesView2>(UIViews.GuaranteesView, filters.value, lastPage.value, 10);
  if (result.error) {
    showError(result.error);
    return;
  }
  dataList.value = result.data;
  dataCount.value = result.total;

  mainStore.isLoading = false;
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Guarantee failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const handleClickAdd = () => {
  addEditVisible.value = true;
  isNewEntity.value = true;
  selectedRow.value = -1;
};

const handleClickEdit = async (row: any) => {
  viewOnly.value = !isEditable(row);
  selectedRow.value = row.id;
  isNewEntity.value = false;
  addEditVisible.value = true;
  // let ResultLockedRow = await .get("guarantees?select=*&id=eq." + row.id);
  // LockedRow = ResultLockedRow.data[0].lockedrow;

  // if (LockedRow) {
  //   await loadPage(lastPage.value);
  //   return
  // } else if (LockedRow == false) {
  //   let result = await .patch("guarantees?id=eq." + row.id, { lockedrow: 'true' });
  //   await loadPage(lastPage.value);
  // }
};

// const handleClickToUnlock = async (row_id: number) => {
//   let result = await .patch("guarantees?id=eq." + row_id, { lockedrow: 'false' });
//   await loadPage(lastPage.value);
// }

// const UnlockAllLockedRows = async () => {
//   let result = await .patch("guarantees?", { lockedrow: 'false' });
//   await loadPage(lastPage.value);
//   UnlockAllRowsVisible.value = false;
// }

onMounted(async () => {

  //MiscSettings
  // let result = await .get("config_misc_settings?select=*");
  // locked_rows.value = result.data[0].locked_rows;

  // console.log('locked_rows ' + locked_rows);

  // const ResultLockedRow = await .get("guarantees?select=*&lockedrow=eq.true");
  // if (ResultLockedRow.data.length > 0) {
  //   UnlockAllRowsVisible.value = true;
  // } else {
  //   UnlockAllRowsVisible.value = false;
  // }
});

const handleClickDelete = (row_id: number) => {
  DeleteDialogVisible.value = true;
  DeleteSelectedRow.value = row_id;
};

const deleteSelectedGuarantee = async () => {
  
  // result = await .delete_data(
  //   "entity_rest_of_collaterals_changes?entity_id=eq." + DeleteSelectedRow.value
  // );
  // if (result.error) {
  //   showError(result.error);
  //   return;
  // }
  const Guarantee = DeleteSelectedRow.value;
  const new_result = await mosapi.generic_entity_delete(Entities.Guarantees, Guarantee);
  if (new_result.error) {
    showError(new_result.error);
    DeleteDialogVisible.value = false;
    return;
  }
  onDelete();
};

const onDelete = async () => {

  showSuccess("Guarantee Deleted Successfully", 1000);

  DeleteDialogVisible.value = false;
  DeleteSelectedRow.value = -1;
  loadPage(lastPage.value);
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const onCloseAddEdit = async () => {
  // let result = await .patch("guarantees?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const entityApprove = async (entity_id: number, accepted: boolean) => {
  // console.log("entity id", entity_id, "approved", accepted);
  if (entity_id === undefined || entity_id === null) return;

  const result = await mosapi.generic_entity_patch(Entities.Guarantees, entity_id, {
    approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected,
  });
  // let result = await .patch(`${Guarantee.getTableNameStatic()}?id=eq.${entity_id}`, { approve_status: accepted ? ChangeApprovalStatus.Approved : ChangeApprovalStatus.Rejected, });

  if (result.error) {
    showError(result.error);
    return;
  }

  ElMessage({
    showClose: true,
    message: `Guarantee${!accepted ? " not " : " "}approved`,
    type: "success",
    duration: 1000,
  });

  await loadPage(lastPage.value);
};

const isEditable = (row: ViewMosGuaranteesView2) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="mainStore.isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("GuaranteeView.guarantees") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.fullname" class="client-input" :placeholder="$t('DebtorView.debtor_company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.vat" class="client-input"
            :placeholder="$t('DebtorView.debor_tax_registration_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.guarantee_full_name" class="client-input"
            :placeholder="$t('GuaranteeView.guarantee_full_name')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.guarantee_vat_number" class="client-input"
            :placeholder="$t('GuaranteeView.guarantee_vat')" @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.pendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.hideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd" :icon="Plus" />
          <!-- <el-row v-if="locked_rows == true">
            <el-col>
              <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible">
                <el-button link type="primary" size="small" class="btn-add"
                  style="float: right; margin: 2.5px; background-color: #f56c6c !important;"
                  @click="UnlockAllLockedRows" :icon="Lock" />
              </el-tooltip>
            </el-col>
          </el-row> -->
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('GuaranteeView.loan_agreement')" />
      <el-table-column fixed prop="fullname" :label="$t('DebtorView.debtor_company_name')" sortable />
      <el-table-column fixed prop="vat_number" :label="$t('DebtorView.debor_tax_registration_nbr')" sortable />
      <el-table-column fixed prop="guarantee_full_name" :label="$t('GuaranteeView.guarantee_full_name')" sortable />
      <el-table-column fixed prop="guarantee_vat_number" :label="$t('GuaranteeView.guarantee_vat')" sortable />
      <el-table-column fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" sortable />

      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row)"
            :icon="isEditable(scope.row) ? Edit : View" :disabled="scope.row.lockedrow && locked_rows"
            style="background-color: #d6d6d6 !important;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
          <span v-if="
            mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
          ">
            <el-button class="btn-accept_square" @click="entityApprove(scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="entityApprove(scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <!-- <el-tooltip :content="$t('global.lockedrow')" placement="top"
            v-if="scope.row.lockedrow == true && locked_rows == true">
            <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)" />
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
        @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="isNewEntity ? 'Add Guarantee' : 'Guarantee Details'">
      <AddEdit_Guarantee :guaranteeid="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit" @save="onAdd"
        :view_only="viewOnly" @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Guarantee Property Delete" width="50%">
      <span>Are you sure you want to delete this Guarantee?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteSelectedGuarantee()">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
