<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "@/store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "@/store/mos_api";
import { PrivilegeResponseDto } from "@/models";
import { Entities } from "@/enums/Entities";
const { showLocalizedError } = useGenericMethodsVariables();

const props = defineProps({
	privilegeId: Number,
});

const emits = defineEmits(["save", "close", "fail-save"]);
const mosapi = useMOSAPIStore();
const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();

const privilege = ref<PrivilegeResponseDto>();

type FormData = {
	name: string
};
const formDataInitialState = {
	name: ""
}
const formData = ref<FormData>(formDataInitialState);

onMounted(async () => {
	if (props.privilegeId === 0) return;  // Add new privilege
	await loadPrivilege(props.privilegeId as number);
});

const close = async () => {
	emits("close");
};

const save = async () => {
	const id = privilege.value?.id;

	const result = id ? await mosapi.generic_entity_patch(Entities.Privileges, id as number, formData.value) : await mosapi.generic_entity_post(Entities.Privileges, formData.value);

	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	emits("save");
};

const loadPrivilege = async (privilegeId: number) => {
	mainStore.isLoading = true;
	const result = await mosapi.generic_entity_get(Entities.Privileges, {id: privilegeId});
	if (result.error) {
		showLocalizedError(result.error);
		return;
	}

	privilege.value = result.data[0];
	formData.value.name = result.data[0]?.name || '';
	mainStore.isLoading = false;
};
</script>

<template>
	<div v-loading="mainStore.isLoading">
		<el-form :model="formData">
			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item label="Name">
						<el-input v-model="formData.name" autocomplete="off" />
					</el-form-item>
				</el-col>
			</el-row>

			<el-row :gutter="10">
				<el-col :span="24">
					<el-form-item class="btn-wrapper-components">
						<el-button type="primary" @click="save">{{ $t('generic.save') }}</el-button>
						<el-button @click="close">{{ $t('generic.close') }}</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<style scoped></style>
