import ITableName from "../../interfaces/ITableName";

class Debtor implements ITableName {
  id: number = -1;
  cid: string = "";
  portfolio?: string = "";
  fao: string = "";
  cid_fao: string = "";
  customer_type: string = "";
  fullname: string = "";
  id_card: string = "";
  id_card_type: string = "";
  vat_number: string = "";
  gemh: string = "";
  klados: string = "";
  kad: string = "";
  address: string = "";
  address_number: string = "";
  region: string = "";
  city: string = "";
  district: string = "";
  postcode: string = "";
  country: string = "";
  phone_number: string = "";
  email: string = "";
  postal_address: string = "";
  postal_address_number: string = "";
  postal_region: string = "";
  postal_city: string = "";
  postal_district: string = "";
  postal_postcode: string = "";
  postal_country: string = "";
  hmer_enar_isx_nomim: string = "";
  hmer_liks_isx_nomim: string = "";
  hmer_ekd_asfa_enim: string = "";
  hmer_liks_asfa_enim: string = "";
  mos_clients_acc_off: string = "";
  mos_officer: string = "";
  group_id: string = "";
  group_name: string = "";
  allowed_users: Array<number> = [];
  lockedrow: boolean = false;

  static getTableNameStatic(): string {
    return "debtor";
  }

  getTableName(): string {
    return Debtor.getTableNameStatic();
  }
}

export default Debtor;
