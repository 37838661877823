<script lang="ts" setup>
import { ref, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import type { FormRules, FormInstance } from "element-plus";
import { useMOSAPIStore } from "@/store/mos_api";
import { Entities } from "@/enums/Entities";
import UserType from "@/enums/UserType";
import { useMainStore } from "../store/main";
const { showError } = useGenericMethodsVariables();
const mainStore = useMainStore();
const StoreMosOfficers = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
const { t } = useI18n({ useScope: "global" });
const StoreLoanAgreements = ref<any>([]);
const StoreConfigShortDesc = ref<any>([]);
const StoreConfigInsComp = ref<any>([]);
const StoreObjOfIns = ref<any>([]);
const StoreTypeOfAgencyFee = ref<any>([]);
const StoreInsRisk = ref<any>([]);
const StoreFeePaymentFreq = ref<any>([]);
const ar_sumbashs = ref<string>("");
const fullname = ref<string>("");
const portf_name = ref<string>("");
const ruleFormRef = ref<FormInstance>();
const mosapi = useMOSAPIStore();
const rules = reactive<FormRules>({
  loan_agreement_id: [
    {
      required: true,
      trigger: "blur",
      message: "Loan agreement is required",
    },
  ],
});

const data = ref<any>({
  loan_agreement_id: null,
  short_desc: null,
  actions_comments_clarifications: null,
  due_date: null,
  alarm_date: null,
  end_date: null,
  mos_officer: null,
  account_manager: null,
  due_date_financials: null,
  obligations: null,
  recruit_final_stat: null,
  comments: null,
  account_manager_financials: null,
  insurance_company: null,
  insurance_policy: null,
  start_date: null,
  end_date_as: null,
  alarm_date_as: null,
  object_of_insurance: null,
  insurance_risk: null,
  insured_capital: null,
  cession_of_insurance_policy: null,
  actions_comments_clarifications_as: null,
  type_of_agency_fee: null,
  agency_fee_amount: null,
  agency_fee_percentage_first: null,
  agency_fee_percentage_second: null,
  fee_payment_freq: null,
  due_date_p: null,
  alarm_date_p: null,
  payment_date: null,
  comments_p: null,
  monitoring_type: 0
});

const props = defineProps({
  globalla: String,
});

const monitoring_types = [
  {
    value: 0,
    label: "Monitoring.pending_issues",
  },
  {
    value: 1,
    label: "Monitoring.financials",
  },
  {
    value: 2,
    label: "Monitoring.asfalistiria",
  },
  {
    value: 3,
    label: "Monitoring.promitheies",
  },
];

watch(
  () => props.globalla,
  async (newVal, oldVal) => {
    data.value.loan_agreement_id = newVal;
    console.log(newVal);
  }
);

const loadMosOfficers = async () => {
  //let result: Result = new Result();
  //result = await api.get("users?user_type=eq.2");
  //let result = await mosapi.getUserMosOfficers();
  //const result = await mosapi.getUserMosOfficers();
  const result = await mosapi.users_get_all_active(UserType.Agent, false);
  if (result.error) {
    showError(result.error);
    return;
  }
  if(mainStore.loggedUser.user_type === UserType.Agent){
    StoreMosOfficers.value = [result.data];
  } else {
    StoreMosOfficers.value = result.data;
  }
};

const loadLoanAgreements = async () => {
  //let result: Result = new Result();
  const result = await mosapi.generic_entity_get(Entities.LoanAgreement);
  // if (mainStore.loggedUser.user_type === 2) {
  //   result = await api.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 1) {
  //   result = await api.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 0) {
  //   result = await api.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  // }
  //  result = await api.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  //http://127.0.0.1:5173/loan_agreement?select=*,debtor!inner(*,loan_agreement.debtor.approve_status=eq.1)&loan_agreements.approve_status=eq.1

  if (result.error) {
    showError(result.error);
    return;
  }

  StoreLoanAgreements.value = result.data;
};

const loadConfigShortDesc = async () => {
  //let result: Result = new Result();
  //result = await api.get("config_short_descriptions");
  let result = await mosapi.generic_entity_get(Entities.ConfigShortDescriptions);

  if (result.error) {
    showError(result.error);

    return;
  }

  StoreConfigShortDesc.value = result.data;
};

const loadConfigInsComp = async () => {
  //let result: Result = new Result();
  //result = await api.get("config_insurance_company");
  let result = await mosapi.generic_entity_get(Entities.ConfigInsuranceCompany);
  if (result.error) {
    showError(result.error);

    return;
  }

  StoreConfigInsComp.value = result.data;
};

const loadConfigObjOfIns = async () => {
  //let result: Result = new Result();
  //result = await api.get("config_obj_of_ins");
  let result = await mosapi.generic_entity_get(Entities.ConfigObjectOfInsurances);
  if (result.error) {
    showError(result.error);

    return;
  }

  StoreObjOfIns.value = result.data;
};

const loadConfigTypeOfAgencyFee = async () => {
  //let result: Result = new Result();
  //result = await api.get("config_type_of_agency_fee");
  let result = await mosapi.generic_entity_get(Entities.ConfigTypeOfAgencyFee);
  if (result.error) {
    showError(result.error);

    return;
  }

  StoreTypeOfAgencyFee.value = result.data;
};

const loadConfigInsRisk = async () => {
 // let result: Result = new Result();
  //result = await api.get("config_insurance_risk");
  let result = await mosapi.generic_entity_get(Entities.ConfigInsuranceRisk);
  if (result.error) {
    showError(result.error);

    return;
  }

  StoreInsRisk.value = result.data;
};

const loadConfigFeePaymentFreq = async () => {
  //let result: Result = new Result();
  //result = await api.get("config_fee_payment_freq");
  let result = await mosapi.generic_entity_get(Entities.ConfigFeePaymentFrequency);
  if (result.error) {
    showError(result.error);

    return;
  }

  StoreFeePaymentFreq.value = result.data;
};

onMounted(async () => {
  await loadLoanAgreements();
  await loadMosOfficers();
  await loadConfigShortDesc();
  await loadConfigInsComp();
  await loadConfigObjOfIns();
  await loadConfigInsRisk();
  await loadConfigTypeOfAgencyFee();
  await loadConfigFeePaymentFreq();
  data.value.loan_agreement_id = props.globalla;
});

const close = async () => {
  emits("close");
};

const save = async () => {
  const obj = JSON.parse(JSON.stringify(data.value));
  //let result = await api.post_data("la_monitoring", obj);
  let result = await mosapi.generic_entity_post(Entities.LAMonitoring, obj);
  if (result.error) {
    showError(result.error); // emits("fail-save");
    return;
  }
  resetData();
  emits("save");
};

const resetData = async () => {
  data.value.loan_agreement_id = null;
  data.value.short_desc = null;
  //data.value.description = null;
  data.value.actions_comments_clarifications = null;
  data.value.due_date = null;
  data.value.alarm_date = null;
  data.value.end_date = null;
  data.value.mos_officer = null;
  data.value.account_manager = null;
  data.value.due_date_financials = null;
  data.value.obligations = null;
  data.value.recruit_final_stat = null;
  data.value.comments = null;
  data.value.account_manager_financials = null;
  data.value.insurance_company = null;
  data.value.insurance_policy = null;
  data.value.start_date = null;
  data.value.end_date_as = null;
  data.value.alarm_date_as = null;
  data.value.object_of_insurance = null;
  data.value.insurance_risk = null;
  data.value.insured_capital = null;
  data.value.cession_of_insurance_policy = null;
  data.value.actions_comments_clarifications_as = null;
  data.value.type_of_agency_fee = null;
  data.value.agency_fee_amount = null;
  data.value.agency_fee_percentage_first = null;
  data.value.agency_fee_percentage_second = null;
  data.value.fee_payment_freq = null;
  data.value.due_date_p = null;
  data.value.alarm_date_p = null;
  data.value.payment_date = null;
  data.value.comments_p = null;
  data.value.monitoring_type = 0;
};

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};
const onChange = async (event: any) => {
  //let result: Result = new Result();
  //result = await api.get("loan_agreement?select=ar_sumbashs,debtor(fullname,portfolio(portf_name))&id=eq." + event);
  // let result = await mosapi.getLoanAgreementRelationsById(mainStore.loggedUser.id, event);
  let result = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: event});
  //console.log(result.data[0].debtor.fullname);
  ar_sumbashs.value = result.data[0].ar_sumbashs;
  fullname.value = result.data[0].debtor.fullname;
  portf_name.value = result.data[0].debtor.portfolio.portf_name;
}
</script>

<template>
  <div>
    <el-form :model="data" :rules="rules" ref="ruleFormRef">
      <div style="text-align: right; padding-bottom: 10px" v-if="data.loan_agreement_id">
        <el-tag class="ml-2" style="margin: 12px">
          {{ 'Portfolio: ' + portf_name }}
        </el-tag>
        <el-tag class="ml-2" style="margin: 12px">
          {{ 'Loan Agreement: ' + ar_sumbashs }}
        </el-tag>
        <el-tag class="ml-2" style="margin: 12px">
          {{ 'Debtor: ' + fullname }}
        </el-tag>
      </div>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item :label="$t('Monitoring.monitoring_type')">
            <el-select clearable v-model="data.monitoring_type" autocomplete="off" class="m-2 header-filter"
              placeholder=" " value-key="id" style="width: 100%">
              <el-option v-for="item in monitoring_types" :key="item.value" :label="t(item.label)" :value="item.value" />
            </el-select>
          </el-form-item></el-col>
        <el-col :span="12"> <el-form-item :label="$t('LoanAccountDetails.loan_agreement')" prop="loan_agreement_id">
            <el-select v-model="data.loan_agreement_id" class="m-2 header-filter" clearable filterable placeholder=" "
              @change="onChange($event)">
              <el-option v-for="item in StoreLoanAgreements" :key="item.id" :label="item.ar_sumbashs"
                :value="item.id" />
            </el-select>
          </el-form-item></el-col>
      </el-row>
      <el-row :gutter="10" v-if="data.monitoring_type === 0">
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.short_desc')">
            <el-select v-model="data.short_desc" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreConfigShortDesc" :key="item.id" :label="item.short_desc"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.actions_comments_clarifications')">
            <el-input v-model="data.actions_comments_clarifications" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.due_date')" :width="100">
            <el-date-picker v-model="data.due_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.alarm_date')" :width="100">
            <el-date-picker v-model="data.alarm_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.end_date')" :width="100">
            <el-date-picker v-model="data.end_date" autocomplete="off" format="DD-MM-YYYY" value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.mos_officer')" prop="mos_officer">
            <el-select v-model="data.mos_officer" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreMosOfficers" :key="item.id" :label="item.username" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.account_manager')">
            <el-input v-model="data.account_manager" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="data.monitoring_type === 1">
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.due_date')">
            <el-date-picker v-model="data.due_date_financials" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.obligations')">
            <el-input v-model="data.obligations" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.recruit_final_stat')">
            <el-input v-model="data.recruit_final_stat" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.comments')">
            <el-input v-model="data.comments" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.account_manager_financials')">
            <el-input v-model="data.account_manager_financials" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>



      <el-row :gutter="10" v-if="data.monitoring_type === 2">

        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insurance_company')">
            <el-select v-model="data.insurance_company" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreConfigInsComp" :key="item.id" :label="item.ins_comp" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insurance_policy')">
            <el-input v-model="data.insurance_policy" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.start_date')">
            <el-date-picker v-model="data.start_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.end_date_as')">
            <el-date-picker v-model="data.end_date_as" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.alarm_date_as')">
            <el-date-picker v-model="data.alarm_date_as" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.object_of_insurance')">
            <el-select v-model="data.object_of_insurance" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreObjOfIns" :key="item.id" :label="item.obj_of_ins" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insurance_risk')">
            <el-select v-model="data.insurance_risk" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreInsRisk" :key="item.id" :label="item.ins_risk" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.insured_capital')">
            <el-input v-model="data.insured_capital" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.cession_of_insurance_policy')">
            <el-switch v-model="data.cession_of_insurance_policy" :active-text="$t('components.switch.yes')"
              :inactive-text="$t('components.switch.no')" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.actions_comments_clarifications_as')">
            <el-input v-model="data.actions_comments_clarifications_as" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>


      <el-row v-if="data.monitoring_type === 3">
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.type_of_agency_fee')">
            <el-select v-model="data.type_of_agency_fee" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreTypeOfAgencyFee" :key="item.id" :label="item.type_of_agency_fee"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.agency_fee_amount')">
            <el-input v-model="data.agency_fee_amount" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.agency_fee_percentage_first')">
            <el-input v-model="data.agency_fee_percentage_first" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.agency_fee_percentage_second')">
            <el-input v-model="data.agency_fee_percentage_second" autocomplete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.fee_payment_freq')">
            <el-select v-model="data.fee_payment_freq" class="m-2 header-filter" size="small" clearable filterable
              placeholder=" ">
              <el-option v-for="item in StoreFeePaymentFreq" :key="item.id" :label="item.fee_payment_freq"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.due_date_p')">
            <el-date-picker v-model="data.due_date_p" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.alarm_date_p')">
            <el-date-picker v-model="data.alarm_date_p" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.payment_date')">
            <el-date-picker v-model="data.payment_date" autocomplete="off" format="DD-MM-YYYY"
              value-format="YYYY-MM-DD" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$t('Monitoring.comments_p')">
            <el-input v-model="data.comments_p" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item class="btn-wrapper-components">
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
          <el-button @click="close">{{ $t('generic.close') }}</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>

</style>
