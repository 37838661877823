enum UIViews {
    ClientsView = "view_mosclients_view2",
    PortfoliosView = "view_mosportfolios_view2",
    DebtorsView = "view_mosdebtors_view2",
    AuctionsView = "view_mosauctions_view2",
    RealEstateView = "view_mosreal_estates_view2",
    GuaranteesView = "view_mosguarantees_view2",
    GreekStateGuaranteesView = "view_mosgreekstateguarantees_view2",
    MonitoringView = "view_lamonitoring_view2",
    LaLiksiarioView = "view_laliksiario_view2",
    RestOFCollateralsView = "view_mosrest_of_collaterals_view2",
    RealEstateCollateralView = "view_mosrealestatescollaterals_view2",
    LoanAccountView = "view_mosloanaccounts_view2",
    DebitsCredits = "view_debits_credits_list2",
    DebitCreditsInternal = "view_debits_credits_list_internal2",
    ScreensView = "ui_screensview",
    SummaryCheck = "view_summary_check_xtra_view2",
    SummaryCheckBonds = "view_mosbonds_view2",
    PendingChanges = "view_pending_changes",
    PendingNewEntities = "view_pending_new_entities",
    ProjectTemplatesMainList = "view_project_templates_mainlist",
    ProjectTasksList = "view_project_tasks_list",
    Users = 'view_users',
    RegisterOfBonds = "view_mosregisterofbonds_view2",
    RegisterOfBondsParticipatingBanks = "view_mosrob_participating_banks_view2",
    RegisterOfBondsFullBonds = "view_mosregisterofbonds_fullbonds2",
    ApprovalsView = "view_approval_view2",
    LegalStatusView = "view_legal_status_view2",
    LoanAgreementView = "view_mosloanagreement_view2",
    LoanAgreementView2 = "view_mosloanagreement_view3",
	Ektokismoi_Loan_Agreement = "view_ektokismoi_loan_agreement",
    DDebtors_View = "view_d_debtors_view2",
    DigitizedFoldersView = "view_DigitizedFolders_view",
    ProjectTaskDataView = "view_project_task_data",
    ProjectTaskView= "view_project_task",
    ApografiFrontier= "view_apografi_frontier_view",


	Reporting_Register_of_bonds= "view_rob_report_view2",
	Reporting_Monitoring = "reporting_view_la_monitoring",
	Reporting_NRECollaterals = "reporting_view_rest_of_collaterals",
	Reporting_RECollaterals = "reporting_view_re_collaterals",
	Reporting_Auctions = "reporting_view_auctions",
	Reporting_GreekStateGuarantees = "reporting_view_greek_state_guarantees",
	Reporting_Guarantees = "reporting_view_guarantees",
	Reporting_Approvals = "reporting_view_approvals",
	Reporting_LoanAccount = "reporting_view_loan_accounts",
	Reporting_LoanAgreement = "reporting_view_loan_agreement",
	Reporting_Debtor = "reporting_view_debtor",
	Reporting_RealEstate = "reporting_view_real_estate",
	Reporting_DigitizedLA = "reporting_view_d_debtor",
	Reporting_Frontier = "reporting_view_apografi_frontier"


}

export { UIViews };
