<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { Edit, Plus, Delete } from "@element-plus/icons-vue";
import PrivilegeDetails from "../components/AddEdit_Privilege.vue";
import { useMainStore } from "@/store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMOSAPIStore } from "@/store/mos_api";
import { PrivilegeResponseDto } from "@/models";
import { useI18n } from "vue-i18n";
import { Entities } from "@/enums/Entities";

const { showError } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });

const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();

// Reactive variables
const dataList = ref<PrivilegeResponseDto[]>([]);
const dataCount = ref(0);
const lastPage = ref(1);
const detailsVisible = ref(false);
const selectedRow = ref<number | undefined>(undefined);

// Methods
const openPrivilegeDialog = (privilegeId: number) => {
	selectedRow.value = privilegeId;
	detailsVisible.value = true;
};

const deletePrivilege = async (privilegeId: number) => {
	try {
		//const result = await mosapiStore.deletePrivilege(privilegeId);
		const result = await mosapiStore.generic_entity_delete(Entities.Privileges, privilegeId);
		if (result.error) throw new Error(t(`errors.${result.error}`));

		ElMessage.success("Privilege deleted successfully");
		await loadPage(lastPage.value);
	} catch (error) {
		handleError(error, "Failed to delete privilege");
	}
};

const loadPage = async (page: number) => {
	try {
		mainStore.isLoading = true;
		lastPage.value = page;

		const result = await mosapiStore.generic_entity_get(Entities.Privileges, {}, lastPage.value, 10);
		if (result.error) throw new Error(t(`errors.${result.error}`));
		dataCount.value = result.total || 0;
		console.log('dataCount', dataCount.value);

		dataList.value = result.data || [];
	} catch (error) {
		handleError(error, "Failed to load privileges");
	} finally {
		mainStore.isLoading = false;
	}
};

const handleError = (error: unknown, defaultMessage: string) => {
	const message = error instanceof Error ? error.message : defaultMessage;
	showError(message);
};

const onSave = async () => {
	ElMessage.success("Privilege updated successfully");
	detailsVisible.value = false;
	await loadPage(lastPage.value);
};

const onFailSave = () => {
	ElMessage.error("Failed to update privilege");
	detailsVisible.value = false;
};

// Lifecycle hook
onMounted(() => {
	loadPage(1);
});
</script>

<template>
	<div class="content-wrapper">
		<h3 class="heading-page">Privileges</h3>

		<div style="margin: 5px;">
			<el-row justify="end">
				<el-col :span="1" class="user-add-new-btn">
					<el-button link type="primary" size="small" class="btn-add" @click="openPrivilegeDialog(0)">
						<el-icon>
							<Plus />
						</el-icon>
					</el-button>
				</el-col>
			</el-row>
		</div>

		<el-table v-loading="mainStore.isLoading" :data="dataList" stripe border table-layout="fixed">
			<el-table-column fixed prop="name" label="Name" />
			<el-table-column fixed="right" label="Operations">
				<template #default="scope">
					<el-button link type="primary" size="small" class="btn-detail"
						@click="openPrivilegeDialog(scope.row.id)" :icon="Edit" />
					<el-button link type="primary" size="small" class="btn-delete"
						@click="deletePrivilege(scope.row.id)" :icon="Delete" />
				</template>
			</el-table-column>
		</el-table>

		<el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
			@current-change="loadPage" />

		<el-dialog v-if="detailsVisible" v-model="detailsVisible" title="Privilege Details">
			<PrivilegeDetails :privilegeId="selectedRow" @save="onSave" @close="detailsVisible = false"
				@fail-save="onFailSave" />
		</el-dialog>
	</div>
</template>

<style scoped>
.content-wrapper {
	padding: 16px;
}
</style>
