<script lang="ts" setup>
import { ref, onMounted, } from "vue";
import type { FormInstance } from "element-plus";
import { useMainStore } from "../store/main";
import { ElMessage } from "element-plus";
import { Edit, Delete } from "@element-plus/icons-vue";
import KladosDetails from "../components/ConfigKladosDetails.vue";
import UserType from "../enums/UserType";
import { useMOSAPIStore } from "../store/mos_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { Entities } from "@/enums/Entities";

const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const mosapi = useMOSAPIStore();

const formRef = ref<FormInstance>();
const DeleteDialogVisible = ref(false);
const detailsVisible = ref(false);
const deleteVisible = ref(false);
const selectedRow = ref(0);
const DeleteSelectedRow = ref(0);
const dataList = ref([]);
const lastPage = ref(0);
const dataCount = ref(0);
const timer = ref<any>(null);

const filters = ref<any>({
  kodikos_klados: "",
  perigrafi_klados: "",
})

onMounted(async () => {
  loadPage(1);
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteKlados(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeleteKlados = async (row_id: number) => {
  const result = await mosapi.generic_entity_delete(Entities.ConfigKlados, row_id);
  if (result.error) {
    showError(result.error);
    DeleteDialogVisible.value = false;
    return;
  }
  onDelete()
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Klados updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Klados saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Klados Code Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Klados Code failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};


const new_entities = ref<AgentItem[]>([]);

interface AgentItem {
  kodikos: string;
  perigrafi: string;
}

const removeKlados = (item: AgentItem) => {
  const index = new_entities.value.indexOf(item);
  if (index !== -1) {
    new_entities.value.splice(index, 1);
  }
};

const addKlados = () => {
  new_entities.value.push({
    kodikos: "",
    perigrafi: "",
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  const result = await mosapi.generic_entity_post_bulk(Entities.ConfigKlados, new_entities.value);
  if (result.error) {
    onFailSave();
    return;
  }
  new_entities.value = [];
  onInsert();
  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  mainStore.isLoading = true;
  lastPage.value = page;

  const result = await mosapi.generic_entity_get(Entities.ConfigKlados, filters.value, page, 10);

  dataList.value = result.data;
  dataCount.value = result.total;

  mainStore.isLoading = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};
</script>

<template>
  <div style="height: 100%" v-loading="mainStore.isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="8">
        <el-input v-model="filters.kodikos_klados" class="client-input"
          :placeholder="$t('PlatformSettingsView.klados_code')" @input="filterChanged" style="width: 100%" />
      </el-col>
      <el-col :span="8">
        <el-input v-model="filters.perigrafi_klados" class="client-input" :placeholder="$t('PlatformSettingsView.description')"
          @input="filterChanged" style="width: 100%" />
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="kodikos" :label="$t('PlatformSettingsView.klados_code')" type="text" />
          <el-table-column fixed sortable prop="perigrafi" :label="$t('PlatformSettingsView.description')"
            type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;"
                :icon="Delete" />
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination size="small" layout="prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>
        <el-dialog v-model="DeleteDialogVisible" title="Klados Delete" width="50%">
          <span>Are you sure you want to delete this Code ?</span>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
              </el-button>
            </span>
          </template>
        </el-dialog>
        <el-dialog v-model="detailsVisible" title="Klados Details" width="50%">
          <KladosDetails :kladosid="selectedRow" @save="onSave" @close="
            detailsVisible = false;
          selectedRow = -1;
          loadPage(lastPage);
          " @fail-save="onFailSave" />
        </el-dialog>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="new_entities" label-width="320px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(kad, index) in new_entities"
                    :label="$t('PlatformSettingsView.klados_code_details') + ' ' + index"
                    :prop="'Kad.' + index + '.value'"
                    style="border-bottom: 0px; margin-top: 30px; padding-bottom: 30px">
                    <el-col :span="6">
                      <el-input :placeholder="$t('PlatformSettingsView.klados_code_details')" v-model="kad.kodikos"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="6">
                      <el-input :placeholder="$t('PlatformSettingsView.description_details')" v-model="kad.perigrafi"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="6">
                      <el-button style="float: left" @click.prevent="removeKlados(kad)">{{ $t('generic.delete') }}
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="new_entities.length != 0" type="primary"
                      @click="submitForm(formRef)">
                      {{ $t('generic.save') }}
                    </el-button>
                    <el-button @click="addKlados">{{
                      $t("PlatformSettingsView.new_klados_code")
                    }}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
