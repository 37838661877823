<script lang="ts" setup>
import { ref, onMounted, reactive, watch } from "vue";
import type { FormRules, FormInstance } from "element-plus";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";
import { Money3Component } from 'v-money3';
import LoanRepaymentType from "../enums/LoanRepaymentType";
import ConfigProduct from "@/classes/DB_Entities/ConfigProduct";
import ConfigCurrencies from "@/classes/DB_Entities/ConfigCurrency";
import { Entities } from "@/enums/Entities";
import { useMOSAPIStore } from "../store/mos_api";
import ConfigInterest from "@/classes/DB_Entities/ConfigInterest";
import { UIViews } from "@/enums/UIViews";
import { orderBy } from "lodash";
const { showError } = useGenericMethodsVariables();
const emits = defineEmits(["save", "close", "fail-save"]);
const ruleFormRef = ref<FormInstance>();
const interests = ref<any>([]);
const products = ref<any>([]);
const currencies = ref<any>([]);
const activeName = ref("loan_agreement_details");
const { t } = useI18n({ useScope: "global" });
const mainStore = useMainStore();
const StoreLoanAgreements = ref<any>([]);
const mosapi = useMOSAPIStore();
const data = ref<any>({
  loan_agreement_id: null,
  ar_trexontos_logariasmou: null,
  ar_arxikou_logariasmou: null,
  palaios_logariasmos: null,
  currency: null,
  product: null,
  hmer_enarkshs: null,
  arxikh_hmer_lukshs: null,
  nea_hmer_lukshs: null,
  hmer_ektam: null,
  poso_ektam: "",
  date_of_current_balance: null,
  trexon_upol: "",
  enhm_kefal: "",
  kathister_kefal: "",
  logistikop_tokoi: "",
  sumbatikoi_log_tokoi: "",
  yperhmerias_log_tokoi: "",
  n2601_1998_log_tokoi: "",
  mh_logistikop_tokoi: "",
  eksoda: "",
  diegram_poso: "",
  dedoul_tokoi: "",
  dedoul_tokoi_kath_kef: "",
  dedoul_tokoi_kath_tok: "",
  hmer_prwths_kathister: null,
  hmeres_kathister: null,
  kathgor_epitok_bashs: null,
  epitok_bashs: null,
  perithorio: null,
  epitok_uperhm: null,
  eisfora_n128_1975: null,
  parakra_forou: null,
  proskom_bebeos: null,
  hmerom_plhrom: null,
  poso_plhrom: null,
  kefal_pou_eksofl: null,
  tokoi_pou_eksofl: null,
  eksoda_pou_eksofl: null,
  components_ofeilhs: null,
  hmer_epomen_doshs: null,
  kefal_epom_doshs: null,
  tokos_epomen_doshs: null,
  status: null,
  sumbatikoi_mh_log_tokoi: "",
  yperhmerias_mh_log_tokoi: "",
  n2601_1998_mh_log_tokoi: "",
  approve_status: 0,
  hmer_enarxis_xaritos:"",
  hmer_1is_dosis_tokon:"",
  hmer_lixis_xaritos:"",
  synoliko_poso: null,
  loan_capital_repayment_type: null,
  loan_capital_repayment_frequency: null,
  loan_capital_repayment_epeteiako: null,
  loan_interest_repayment_frequency: null,
  loan_interest_repayment_epeteiako: null,
  loan_interest_change_frequency: null,
  loan_interest_change_epeteiako: null,
  kathisterimeno: null,
  kathisterimenokef: null,
  kathisterimenotok: null,
  kathisterimenodate:null,
  kathisterimeno_enim_arx:null,
  arnitika_epitokia:false,
});

const rules = reactive<FormRules>({
  loan_agreement_id: [
    {
      required: true,
      trigger: "blur",
      message: "Loan agreement is required",
    },
  ],
});


const capitalRepaymentTypes = [
  {
    value: LoanRepaymentType.Tokoxreolytiko,
    label: t("payment_type_list.tokoxreolitiko"),
  },
  {
    value: LoanRepaymentType.XreolytikoIsoposo,
    label: t("payment_type_list.xreolitiko"),
  },
  {
    value: LoanRepaymentType.XreolytikoAnisoposo,
    label: t("payment_type_list.balloon"),
  },
];

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

onMounted(async () => {
  await loadLoanAgreements();
  await loadInterests();
  await loadProducts();
  await loadCurrencies();
});

const loadProducts = async () => {
  //result = await .get("config_loan_account_product?orderFIX=product");
	let result = await mosapi.generic_entity_get<ConfigProduct[]>(Entities.ConfigProduct, {orderBy:'product'}, 1, 999);
  if (result.error) {
    showError(result.error);
    return;
  }

  products.value = result.data;
};

const loadCurrencies = async () => {
  //result = await .get("config_currency?orderFIX=currency");
	let result = await mosapi.generic_entity_get<ConfigCurrencies[]>(Entities.ConfigCurrency, {orderBy:'currency'}, 1, 999);
  if (result.error) {
    showError(result.error);
    return;
  }
  currencies.value = result.data;
};

const loadInterests = async () => {
  //result = await .get("config_interest?orderFIX=interest");
	let result = await mosapi.generic_entity_get<ConfigInterest[]>(Entities.ConfigInterest, {orderBy:'interest'}, 1, 999);
  if (result.error) {
    showError(result.error);
    return;
  }

  interests.value = result.data;
};

const loadLoanAgreements = async () => {
  //result = await .get("view_mosloanagreement_view1?approve_status=eq.1&debtor_approve_status=eq.1");

  // if (mainStore.loggedUser.user_type === 2) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 1) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  // }
  // if (mainStore.loggedUser.user_type === 0) {
  //   result = await .get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  // }

  let result = await mosapi.get_view(UIViews.LoanAgreementView, { approve_status: 1, debtor_approve_status: 1}, 1, 9999);

  if (result.error) {
    showError(result.error);

    return;
  }

  StoreLoanAgreements.value = result.data;
};

const close = async () => {
  emits("close");
};

const resetData = async () => {
  data.value.loan_agreement_id = null;
  data.value.ar_trexontos_logariasmou = null;
  data.value.ar_arxikou_logariasmou = null;
  data.value.palaios_logariasmos = null;
  data.value.currency = null;
  data.value.product = null;
  data.value.hmer_enarkshs = null;
  data.value.arxikh_hmer_lukshs = null;
  data.value.nea_hmer_lukshs = null;
  data.value.hmer_ektam = null;
  data.value.poso_ektam = "";
  data.value.date_of_current_balance = null;
  data.value.trexon_upol = null;
  data.value.enhm_kefal = null;
  data.value.kathister_kefal = null;
  data.value.logistikop_tokoi = null;
  data.value.sumbatikoi_log_tokoi = null;
  data.value.yperhmerias_log_tokoi = null;
  data.value.n2601_1998_log_tokoi = null;
  data.value.mh_logistikop_tokoi = null;
  data.value.eksoda = null;
  data.value.diegram_poso = null;
  data.value.dedoul_tokoi = null;
  data.value.hmer_prwths_kathister = null;
  data.value.hmeres_kathister = null;
  data.value.kathgor_epitok_bashs = null;
  data.value.epitok_bashs = null;
  data.value.perithorio = null;
  data.value.epitok_uperhm = null;
  data.value.eisfora_n128_1975 = null;
  data.value.parakra_forou = null;
  data.value.proskom_bebeos = null;
  data.value.hmerom_plhrom = null;
  data.value.poso_plhrom = null;
  data.value.kefal_pou_eksofl = null;
  data.value.tokoi_pou_eksofl = null;
  data.value.eksoda_pou_eksofl = null;
  data.value.components_ofeilhs = null;
  data.value.hmer_epomen_doshs = null;
  data.value.kefal_epom_doshs = null;
  data.value.tokos_epomen_doshs = null;
  data.value.status = null;
  data.value.sumbatikoi_mh_log_tokoi = null;
  data.value.yperhmerias_mh_log_tokoi = null;
  data.value.n2601_1998_mh_log_tokoi = null;
  data.value.hmer_enarxis_xaritos = null;
  data.value.hmer_lixis_xaritos = null;
  data.value.hmer_1is_dosis_tokon = null;
  data.value.synoliko_poso= null;
  data.value.loan_capital_repayment_type= null;
  data.value.loan_capital_repayment_frequency= null;
  data.value.loan_capital_repayment_epeteiako= null;
  data.value.loan_interest_repayment_frequency= null;
  data.value.loan_interest_repayment_epeteiako= null;
  data.value.loan_interest_change_frequency= null;
  data.value.loan_interest_change_epeteiako= null;
  data.value.kathisterimeno=null;
  data.value.kathisterimenokef= null;
  data.value.kathisterimenotok= null;
  data.value.kathisterimenodate=null;
  data.value.kathisterimeno_enim_arx=null;
  data.value.arnitika_epitokia=false;
};

const save = async () => {

  data.value.insert_user = mainStore.loggedUser.id;

  if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
    //const timestamp: string = await (await .get("rpc/customnew")).data;

    data.value.approve_status = 1;
    data.value.approve_user = mainStore.loggedUser.id;
    //data.value.approve_timestamp = timestamp;
  }

  // console.log(data.value.status)

  if (data.value.status == null) {
    data.value.status = 'active';
  }

  // Make previous loan accounts inactive
  //let result = await .patch("loan_accounts?loan_agreement_id=eq." + data.value.loan_agreement_id, { status: 'inactive' });

  const obj = JSON.parse(JSON.stringify(data.value));
  // console.log(obj);

  // Convert empty strings to NULL for DB
  for (const key of Object.keys(obj)) {
    if (obj[key] === '')
      obj[key] = null;
  }

  // const result = await .post_data("loan_accounts", obj);
  const result = await mosapi.generic_entity_post(Entities.LoanAccount, obj);
  if (result.error) {
    showError(result.error); // emits("fail-save");
    return;
  }
  resetData();
  emits("save");
};

watch(
  () => data.value.loan_agreement_id,
  async (newVal, oldVal) => {
    // console.log('changed' + data.value.loan_agreement_id)

    if (data.value.loan_agreement_id === null) return;

    // result = await .get(`loan_agreement?id=eq.${data.value.loan_agreement_id}`);
    let result = await mosapi.generic_entity_get(Entities.LoanAgreement, {id: data.value.loan_agreement_id});
    if (result.error) {
      showError(result.error);
      return;
    }

    data.value.kathgor_epitok_bashs = result.data[0].kathgor_epitok_bashs;
    data.value.perithorio = result.data[0].perithorio;
    data.value.epitok_uperhm = result.data[0].epitok_uperhm;
    data.value.parakra_forou = result.data[0].parakra_forou;
    data.value.eisfora_n128_1975 = result.data[0].eisfora_n128_1975;
  }
);

</script>

<template>
  <div>
    <el-form :model="data" :rules="rules" ref="ruleFormRef">
      <el-tabs type="card" class="demo-tabs" v-model="activeName">
        <el-tab-pane :label="$t('LoanAccountDetails.loan_account_details')" name="loan_agreement_details">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_agreement')" prop="loan_agreement_id">
                <el-select v-model="data.loan_agreement_id" class="m-2 header-filter" size="small" clearable filterable
                  placeholder=" ">
                  <el-option v-for="item in StoreLoanAgreements" :key="item.id" :label="item.ar_sumbashs"
                    :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_account')">
                <el-input v-model="data.ar_trexontos_logariasmou" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.original_loan_accound')">
                <el-input v-model="data.ar_arxikou_logariasmou" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.previous_loan_accound')">
                <el-input v-model="data.palaios_logariasmos" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.currency')">
                <el-select v-model="data.currency" :value="data.currency" placeholder=" ">
                  <el-option v-for="currency in currencies" :label="currency.currency" :value="currency.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.product')">
                <el-select v-model="data.product" :value="data.product" placeholder=" ">
                  <el-option v-for="product in products" :label="product.product" :value="product.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.kathisterimeno')">
                <el-switch v-model="data.kathisterimeno"  />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.synoliko_poso')">
                <el-input v-model="data.synoliko_poso" autocomplete="off" />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_capital_repayment_type')">
                <el-select v-model="data.loan_capital_repayment_type" :value="data.loan_capital_repayment_type" placeholder=" ">
                  <el-option v-for="capitalRepaymentType in capitalRepaymentTypes" :label="capitalRepaymentType.label" :value="capitalRepaymentType.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_capital_repayment_frequency')">
                <el-input v-model="data.loan_capital_repayment_frequency" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_capital_repayment_epeteiako')">
                <el-switch v-model="data.loan_capital_repayment_epeteiako"  />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_interest_repayment_frequency')">
                <el-input v-model="data.loan_interest_repayment_frequency" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_interest_repayment_epeteiako')">
                <el-switch v-model="data.loan_interest_repayment_epeteiako"  />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.arnitika_epitokia')">
                <el-switch v-model="data.arnitika_epitokia"  />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.start_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_enarkshs" type="date"
                  style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.original_end_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.arxikh_hmer_lukshs"
                  type="date" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.end_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.nea_hmer_lukshs" type="date"
                  style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.disbursment_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_ektam" type="date"
                  style="width: 100%" />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.hmer_enarxis_xaritos')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_enarxis_xaritos" type="date"
                  style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.hmer_lixis_xaritos')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_lixis_xaritos" type="date"
                  style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.hmer_1is_dosis_tokon')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_1is_dosis_tokon" type="date"
                  style="width: 100%" />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.disbursment_amount')">
                <Money3Component class="el-input__inner" v-model.number="data.poso_ektam" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.status')">
                <el-select v-model="data.status" placeholder=" ">
                  <el-option label="Active" value="active" />
                  <el-option label="Inactive" value="inactive" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.base_rate')">
                <el-select v-model="data.kathgor_epitok_bashs" :value="data.kathgor_epitok_bashs" placeholder=" ">
                  <el-option v-for="interest in interests" :label="interest.interest" :value="interest.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.base_rate_value')">
                <el-input v-model="data.epitok_bashs" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_interest_change_frequency')">
                <el-input v-model="data.loan_interest_change_frequency" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.loan_interest_change_epeteiako')">
                <el-switch v-model="data.loan_interest_change_epeteiako" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.margin')">
                <el-input v-model="data.perithorio" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.overude_rate')">
                <el-input v-model="data.epitok_uperhm" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.levy_N128/75')">
                <el-input v-model="data.eisfora_n128_1975" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.tax_withholding')">
                <el-input v-model="data.parakra_forou" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.presentation_of_certificate')">
                <el-select v-model="data.proskom_bebeos" placeholder=" ">
                  <el-option :label="$t('global.true')" value="true" />
                  <el-option :label="$t('global.false')" value="false" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Balance" name="balance">
          <el-row>
            <div>
              <div v-if="(parseFloat(data.trexon_upol) !== (
                parseFloat(data.enhm_kefal) +
                parseFloat(data.kathister_kefal) +
                parseFloat(data.sumbatikoi_log_tokoi) +
                parseFloat(data.yperhmerias_log_tokoi) +
                parseFloat(data.n2601_1998_log_tokoi) +
                parseFloat(data.sumbatikoi_mh_log_tokoi) +
                parseFloat(data.yperhmerias_mh_log_tokoi) +
                parseFloat(data.n2601_1998_mh_log_tokoi) +
                parseFloat(data.eksoda)


              ) && (

                  (parseFloat(data.trexon_upol) > 0) ||
                  (parseFloat(data.enhm_kefal) > 0) ||
                  (parseFloat(data.kathister_kefal) > 0) ||
                  (parseFloat(data.sumbatikoi_log_tokoi) > 0) ||
                  (parseFloat(data.yperhmerias_log_tokoi) > 0) ||
                  (parseFloat(data.n2601_1998_log_tokoi) > 0) ||
                  (parseFloat(data.sumbatikoi_mh_log_tokoi) > 0) ||
                  (parseFloat(data.yperhmerias_mh_log_tokoi) > 0) ||
                  (parseFloat(data.n2601_1998_mh_log_tokoi) > 0) ||
                  (parseFloat(data.eksoda) > 0)


                ))" class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error") }}
              </div>
              <div v-if="(parseFloat(data.logistikop_tokoi)) !== (
                parseFloat(data.sumbatikoi_log_tokoi) +
                parseFloat(data.yperhmerias_log_tokoi) +
                parseFloat(data.n2601_1998_log_tokoi)
              )
                && (
                  (parseFloat(data.logistikop_tokoi) > 0) ||
                  (parseFloat(data.sumbatikoi_log_tokoi) > 0) ||
                  (parseFloat(data.yperhmerias_log_tokoi) > 0) ||
                  (parseFloat(data.n2601_1998_log_tokoi) > 0)
                )

              " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error_logistik") }}
              </div>
              <div v-if="(parseFloat(data.mh_logistikop_tokoi)) !== (
                parseFloat(data.sumbatikoi_mh_log_tokoi) +
                parseFloat(data.yperhmerias_mh_log_tokoi) +
                parseFloat(data.n2601_1998_mh_log_tokoi)
              )

                && (
                  (parseFloat(data.mh_logistikop_tokoi) > 0) ||
                  (parseFloat(data.sumbatikoi_mh_log_tokoi) > 0) ||
                  (parseFloat(data.yperhmerias_mh_log_tokoi) > 0) ||
                  (parseFloat(data.n2601_1998_mh_log_tokoi) > 0)
                )
              " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error_mh_logistik") }}
              </div>
            </div>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.date_of_current_balance')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.date_of_current_balance"
                  type="date" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.current_balance')">
                <Money3Component class="el-input__inner" v-model.number="data.trexon_upol" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.due_principal')">
                <Money3Component class="el-input__inner" v-model.number="data.enhm_kefal" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.overdue_principal')">
                <Money3Component class="el-input__inner" v-model.number="data.kathister_kefal" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24"><h3>{{$t('LoanAccountDetails.logistikopoihmenoi')}}</h3></el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.on_balance_sheet_interest')">
                <Money3Component class="el-input__inner" v-model.number="data.logistikop_tokoi" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <!--  sumbatikoi_log_tokoi
                    yperhmerias_log_tokoi
                    n2601_1998_log_tokoi -->
            <!-- TODO Check -->
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_on_due_balance')">
                <Money3Component class="el-input__inner" v-model.number="data.sumbatikoi_log_tokoi"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <!-- TODO Check -->
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_on_overdue_balance')">
                <Money3Component class="el-input__inner" v-model.number="data.yperhmerias_log_tokoi"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <!-- TODO Check -->
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_on_interest')">
                <Money3Component class="el-input__inner" v-model.number="data.eksoda" v-bind="mainStore.moneyConfig" />
                <el-input v-model="data.n2601_1998_log_tokoi" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.on_balance_sheet_interest')">
                <Money3Component class="el-input__inner" v-model.number="data.logistikop_tokoi" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24"><h3>{{$t('LoanAccountDetails.mhlogistikopoihmenoi')}}</h3></el-col>

            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.off_balance_sheet_interest')">
                <Money3Component class="el-input__inner" v-model.number="data.mh_logistikop_tokoi" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_on_due_balance')">
                <Money3Component class="el-input__inner" v-model.number="data.sumbatikoi_mh_log_tokoi"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_on_overdue_balance')">
                <Money3Component class="el-input__inner" v-model.number="data.yperhmerias_mh_log_tokoi"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.interest_on_interest')">
                <Money3Component class="el-input__inner" v-model.number="data.n2601_1998_mh_log_tokoi"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.commissions_expenses')">
                <Money3Component class="el-input__inner" v-model.number="data.eksoda" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.conditional_write_off_amount')">
                <Money3Component class="el-input__inner" v-model.number="data.diegram_poso" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.accruals')">
                <Money3Component class="el-input__inner" v-model.number="data.dedoul_tokoi" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.accruals_kath_kef')">
                <Money3Component class="el-input__inner" v-model.number="data.dedoul_tokoi_kath_kef" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.accruals_kath_tok')">
                <Money3Component class="el-input__inner" v-model.number="data.dedoul_tokoi_kath_tok" v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.1st_delay_date')">
                <el-date-picker value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.hmer_prwths_kathister"
                  type="date" style="width: 100%" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.days_of_delay')">
                <el-input v-model="data.hmeres_kathister" autocomplete="off" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane v-if="data.kathisterimeno" :label="$t('LoanAccountDetails.kathisterimeno')" name="kathisterimeno">
          <el-col :span="24">
            <el-form-item :label="$t('LoanAccountDetails.kathisterimeno_enim_arx')">
                <Money3Component class="el-input__inner hasborder" v-model.number="data.kathisterimeno_enim_arx"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.kathisterimenoKEF')">
                <Money3Component class="el-input__inner hasborder" v-model.number="data.kathisterimenokef"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.kathisterimenoTOK')">
                <Money3Component class="el-input__inner hasborder" v-model.number="data.kathisterimenotok"
                  v-bind="mainStore.moneyConfig" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('LoanAccountDetails.kathisterimenodate')">
                <el-date-picker class="hasborder" value-format="YYYY-MM-DD" format="DD-MM-YYYY" v-model="data.kathisterimenodate"
                  type="date" style="width: 100%" />

              </el-form-item>
            </el-col>

        </el-tab-pane>
      </el-tabs>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped></style>
