import { createApp } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./style.css";
import "./assets/custom-theme/index.css";
import App from "./App.vue";
import router from "./router/index";
import { createI18n } from "vue-i18n";
import axios, { AxiosError, AxiosResponse } from "axios";
import mitt from 'mitt';
// import { VueReCaptcha } from 'vue-recaptcha-v3';

const emitter = mitt();

const onResponse = (response: AxiosResponse): AxiosResponse => {
	return response;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
	if (axios.isAxiosError(error)) {
		console.log(JSON.stringify(error));

		if (error.code === 'ERR_NETWORK') {
			console.log('network error');
			(error as any).response = { data: { error: 'NETWORK_ERROR', status_code: 502 } };
			(error as any).status = 502;
		}

		// console.log('axios error', 'status code', error.status, 'message', error.message, 'data', JSON.stringify(error.response?.data));
	} else {
		// console.log('error', 'message', error.message);
		(error as any).response = { data: { error: 'NETWORK_ERROR', status_code: 502 } };
		(error as any).status = 502;
	}

	return Promise.resolve(error as AxiosError);
};

// axios.defaults.transformResponse = x => x
axios.defaults.withCredentials = true // Allow cookie session saving
axios.defaults.validateStatus = (): boolean => true; // Disable exceptions on non-200 status codes
axios.interceptors.response.use(onResponse, onErrorResponse);

import en from "./locales/en.json";
import el from "./locales/el.json";

const i18n = createI18n({
	legacy: false,
	locale: "en",
	fallbackLocale: "en",
	messages: {
		en,
		el,
	},
});

const pinia = createPinia();
const app = createApp(App)
	.use(router)
	.use(pinia)
	.use(ElementPlus)
	.use(i18n)
// .use(VueReCaptcha, {
// 	siteKey: "6Lcgc-QqAAAAAB7LodwJHxlk5nRcRO6ZqaSCMyTS",
// 	loaderOptions: {
// 		useRecaptchaNet: true,
// 	},
// });
app.provide('emitter', emitter);
app.mount("#app");